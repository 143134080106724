import React, { useEffect } from "react";
import { useState } from "react";
import { submitContactUs } from "../../API";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Selectall from "react-select";
import { usersList } from "../../API";
import ContactIcon from "../../assets/svg1/contacticon.svg";
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AOS from "aos";
import "aos/dist/aos.css";


const ContactUsComponent = () => {
  const navigate = useNavigate();

  const [contactInfo, setContactInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    discription: "",
    site: "",
    issue: "",
    user_id: []
  });
  const [uesrLists, setUserLists] = useState([]);
  const [selectUser, setSelectUser] = useState([]);

  const inputHandle = (e) => {
    setContactInfo({
      ...contactInfo,
      [e.target.name]: e.target.value,
    });
  };
  const onSubmit = async () => {
    try {
      contactInfo.type = 'website'
      let res = await submitContactUs(contactInfo);
      if (res.status === 200) {
        toast.success(res.data.message);
        navigate("/");
      } else {
        toast.error(res.data.message || res.response.statusText);
      }
    } catch (error) {
      
    }
  };
  useEffect(() => {
    userListGet();
  }, []);
  useEffect(() => {
    AOS.init({
      easing: 'ease-out-sine',
      duration: 800,
    });
    AOS.refresh();
  }, []);
  const userListGet = async () => {
    const items = await usersList();
    if (items.status === 200) {
      let userDataForSelect = [];
      items.data.data.rows.map((value, key) => userDataForSelect.push({ value: value.id, label: value.name }));
      // setUserLists(items.data.data);
      setUserLists(userDataForSelect);
    }
  }
  return (
    <div className="rightsidebar" data-aos="slide-up">
      <div className=" w-full h-full desbordcard">
        <div className="handleimg"></div>
        <div className="flex contacthead">
          <img src={ContactIcon} />
          <h3 className=" dsopForm-heading text-2xl">
            Raise Your Question here
          </h3>
        </div>
        <div className="flex w-full h-4/5 mt-4 contactformmain">
          <form style={{ width: "100%" }}>
            <div className="gap-4 w-full contactformflex">
              <div className="contactinputform">
                <div className="inputformwidth">
                  <TextField label="First Name" variant="outlined"
                    type="text"
                    className="placeholder:text-font1 font-normal text-left inputformwidth1 "
                    placeholder="First Name"
                    name="firstName"
                    onChange={inputHandle}
                  />

                </div>
                <div className="inputformwidth">
                  <TextField label="Last Name" variant="outlined"
                    type="text"
                    className="placeholder:text-font1 font-normal text-left inputformwidth1 "
                    placeholder="Last Name"
                    name="lastName"
                    onChange={inputHandle}
                  />
                </div>
              </div>
              <div className="contactinputform">
                <div className="inputformwidth">
                  <TextField label="Email" variant="outlined"
                    type="email"
                    className="placeholder:text-font1 font-normal text-left inputformwidth1 "
                    placeholder="Email"
                    name="email"
                    onChange={inputHandle}
                  />
                </div>
                <div className="inputformwidth">
                  <TextField label="Mobile Number" variant="outlined"
                    type="number"
                    className="placeholder:text-font1 font-normal text-left inputformwidth1 "
                    placeholder="Mobile Number"
                    name="mobile"
                    onChange={inputHandle}
                  />
                </div>
              </div>
              <div className="contactinputform">
                <div className="inputformwidth">
                  <FormControl variant="outlined" className=" inputformwidth2 ">
                    <InputLabel>Issue Raised</InputLabel>
                    <Select
                      name="issue"
                      className=" inputformwidth1 "
                      onChange={inputHandle}
                      label="Issue Raised"
                    >
                      <MenuItem value="">--No Options--</MenuItem>
                      <MenuItem value="technical">Technical</MenuItem>
                      <MenuItem value="networkProblem">Network Problem</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="inputformwidth">


                  <FormControl variant="outlined" className=" inputformwidth2 ">
                    <InputLabel>Site</InputLabel>
                    <Select
                      name="site"
                      onChange={inputHandle}
                      label="Type"
                    >
                      <MenuItem value="site">Site</MenuItem>
                      <MenuItem value="admin">Admin</MenuItem>
                      <MenuItem value="user">User</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              {contactInfo.site === "user" &&
                <div className="flex  justify-start">
                  <div className="m-2 ml-3 p-1.5 pl-1 w-80">
                    <Selectall
                      isMulti
                      value={selectUser}
                      onChange={(value) => {
                        setSelectUser(value);
                        setContactInfo({ ...contactInfo, "user_id": value, "userIds": value.map((e) => e.value) });
                      }}
                      options={uesrLists}
                    />
                  </div>
                </div>
              }
              <div className="contactinputform">
                <div className="inputformwidth" style={{ width: '100%' }}>
                  <TextField label="Description" variant="outlined" rows={4}
                    name="discription"
                    onChange={inputHandle}
                    className="flex w-full overflow-invisible p-2 pb-5"
                    placeholder="Description"
                  />
                </div>
              </div>
              <div className="contactsubmit">
                <div className=" textoverno p-2 ">
                  <p>
                    By creating an account you agree to out Terms and Privacy
                    Policy
                  </p>
                </div>
                <div className="flex justify-center mr-3">
                  <button
                    type="button"
                    className="btn-dsopForm"
                    onClick={onSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUsComponent;
