import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import "tui-image-editor/dist/tui-image-editor.css";
import "tui-color-picker/dist/tui-color-picker.css";
import {
  Grid,
  Button,
  ListItem,
  ListItemText,
} from "@mui/material";
import {
  GetImageEditingDetail,
  UpdateEditedImage,
} from "../../API/imageEditorApi";
import TuiImageEditor from "tui-image-editor";
import whiteTheme from "../../Image_theme";
import { apiBaseUrl, reactAppImageUri } from "./../../utils/constants";
class ImageEditor extends React.Component {
  rootEl = React.createRef();
  imageEditorInst = null;

  componentDidMount() {
    this.imageEditorInst = new TuiImageEditor(this.rootEl.current, {
      ...this.props.Imageprops,
    });

    const resize = this.imageEditorInst;
    window.onresize = function () {
      // resize.ui.resizeEditor();
    };

    //   imgEl.src = imageEditor.toDataURL();

    console.log(this.imageEditorInst.toDataURL());

    // this.imageEditorInst.registerIcons({
    //   customIcon: "M 0 0 L 20 20 L 10 10 Z",
    //   customArrow: "M 60 0 L 120 60 H 90 L 75 45 V 180 H 45 V 45 L 30 60 H 0 Z"
    // });
  }

  componentWillUnmount() {
    // this.unbindEventHandlers();
    this.imageEditorInst.destroy();
    this.imageEditorInst = null;
  }

  render() {
    return (
      <>
        <div ref={this.rootEl} />
        <div className="formgrop" style={{ paddingTop: 10, paddingBottom: 10 }}>
          <Grid item md={12} className="new-header-containt1">
            <Button
              variant="contained"
              fullWidth
              className="addbutne"
              disabled={this.props.isSubmitBtn === "processing"}
              onClick={() => {
                const myImage = this.imageEditorInst.toDataURL();
                this.props.saveImage(myImage);
              }}
            >
              {this.props.isSubmitBtn === "submit"
                ? "SUBMIT"
                : this.props.isSubmitBtn === "processing"
                ? <><div className="dot-pulse-text">Processing</div> <div className="dot-pulse-container"><div className="dot-pulse"></div></div></>
                : "NEXT"}
            </Button>
          </Grid>
        </div>
      </>
    );
  }
}

const EditImage = (props) => {
  const [vis, setVisible] = useState(true);
  const [isSubmitBtn, setIsSubmitBtn] = useState("next");
  const navigate = useNavigate();

  const [Imageprops, setImageprops] = useState({
    // includeUI: {
    //   menu: ["shape", "filter", "text"],
    //   initMenu: "filter",
    //   uiSize: {
    //     width: "1000px",
    //     height: "700px"
    //   },
    //   menuBarPosition: "bottom"
    // },
    // cssMaxWidth: 700,
    // cssMaxHeight: 500,
    // selectionStyle: {
    //   cornerSize: 20,
    //   rotatingPointOffset: 70
    // }
    includeUI: {
      loadImage: {
        // path: "/CsuiteMind.jpg",
        path: null,
        name: "SampleImage",
      },
      theme: whiteTheme,
      menu: [
        "crop",
        // "flip",
        // "rotate",
        "draw",
        // "shape",
        // "icon",
        "text",
        // "mask",
        // "filter",
      ],
      initMenu: "",
      uiSize: {
        width: "100%",
        height: "100vh",
      },

      menuBarPosition: "left",
    },
    cssMaxWidth: 700,
    cssMaxHeight: 500,
    // cssMaxWidth: 800,
    // cssMaxHeight: 800,
    selectionStyle: {
      cornerSize: 20,
      rotatingPointOffset: 70,
    },
    usageStatistics: true,
  });

  useEffect(() => {
    // #root > div > div > div.tui-image-editor-main-container > div.tui-image-editor-main.tui-image-editor-menu-filter >
    let submenu =
      document.querySelector("div.tui-image-editor-submenu") || null;
    if (submenu !== null && vis) {
      setVisible(false);
      submenu.style.position = "relative";
    }
  }, [vis]);
//   const [imageData, SetImageData] = useState({});
//   const [userData, setUserData] = useState({
//     loading: false,
//     data: null,
//     message: "",
//     status: 0,
//   });
  const { id } = useParams();

  const SaveEditedImage = async (image_string) => {
    console.log("image_string");
    console.log(image_string);
    try {
    //   setUserData((v) => ({
    //     ...v,
    //     loading: true,
    //   }));
      let res = null;

      res = await UpdateEditedImage({
        image_string: image_string,
        id: id,
      });

      if (res.status === 200) {
        toast.success(res.data.message);
        navigate("/editors?type=image");

        // setUserData((v) => ({
        //   ...v,
        //   loading: false,
        // }));
        GetInfo();
      } else {
        toast.error(res.data.message || res.response.statusText);
      }
    } catch (error) {
      toast.error("something went wrong");
    //   setUserData((v) => ({
    //     ...v,
    //     loading: false,
    //   }));
    }
  };

  const GetInfo = async () => {
    if (id !== undefined) {
      try {
        const res = await GetImageEditingDetail(id);
        if (res.status === 200) {
          const info = res.data.data;
          let img = "";
          if (info.edited_image) {
            img = info.edited_image;
          } else {
            img = info.editable_image;
          }

          Imageprops.includeUI.loadImage.path = `${
            apiBaseUrl + "/public"
          }/${reactAppImageUri}/${img}`;

          console.log("e................................")
          console.log(Imageprops.includeUI.loadImage.path);

          setImageprops(JSON.parse(JSON.stringify(Imageprops)) );
          // SetImageData(info);
        }
      } catch (error) {
        toast.error("something went wrong");
      }
    }
  };

  useEffect(() => {
    GetInfo();
  }, []);
  const submitFunc = () => {
    setIsSubmitBtn("processing");
    setTimeout(() => {
      const body = document.querySelector(".sidbarmain");
      body.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      setIsSubmitBtn("submit");
    }, 2000);
  };

  return (
    <>
      <div className="rightsidebar">
        <div className="nofles">
        <div className="d-flex ">
            <div className="cursor-pointer" onClick={() => navigate(-1)}>
              <i
                style={{margin: 10}}
                className="fa fa-arrow-left back_arrow_style"
                aria-hidden="true"
              ></i>
            </div>
          </div>
          <div className="mainprogrs">
            <ul className="multi-step-bar">
              <li className="sucses">
                <p>Details</p>
              </li>
              {isSubmitBtn === "submit" ? (
                <li className="sucses">
                  <p>Edit Image</p>
                </li>
              ) : (
                <li className="active">
                  <p>Edit Image</p>
                </li>
              )}
              {isSubmitBtn === "submit" ? (
                <li className="active">
                  <p>Submit</p>
                </li>
              ) : (
                <li>
                  <p>Submit</p>
                </li>
              )}
            </ul>
          </div>
          <ListItem>
            <ListItemText className="black-text" primary="Image Editor" />
          </ListItem>
          
          {Imageprops.includeUI.loadImage.path !== null && (
            <ImageEditor
              Imageprops={Imageprops}
              isSubmitBtn={isSubmitBtn}
              saveImage={(e) => {
                isSubmitBtn === "submit"
                  ? SaveEditedImage(e)
                  : isSubmitBtn === "processing"
                  ? console.log("")
                  : submitFunc();
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default EditImage;


// import React from 'react'
// import 'tui-image-editor/dist/tui-image-editor.css';
// import ImageEditor from '@toast-ui/react-image-editor';
// import * as _ from "lodash";

// const myTheme = {
//     // Theme object to extends default dark theme.
//   };
// let editorInstance;
// function Editor() {
//    const  editorRef = React.createRef();
//    const [editor,setEditor]=React.useState("")
//    const objectsArr = []


//    async function addLayer(layer){

//     console.log("addLayer [START]", layer.aCoords);

//     let options = {
//       aCoords: layer.aCoords,
//       angle: layer.angle,
//       fill: layer.fill,
//       fontSize: layer.fontSize,
//       fontWeight: layer.fontWeight,
//       fontStyle: layer.fontStyle,
//       textDecoration: layer.textDecoration,
//       underline: layer.underline,
//       height: layer.height,
//       stroke: layer.stroke,
//       strokeWidth: layer.strokeWidth,
//       top: layer.top,
//       left: layer.left,
//       rotatingPointOffset: layer.rotatingPointOffset,
//       rx: layer.rx,
//       ry: layer.ry,
//       selected: false,
//       width: layer.width,
//       isRegular: layer.isRegular,
//       path: layer.path,
//       pathOffset: layer.pathOffset,
//       lineCoords: layer.lineCoords,
//       originX: layer.originX,
//       originY: layer.originY,
//       origins: layer.origins,
//       position: layer.position,
// 	  startPoint: layer.startPoint,
//       scaleX: layer.scaleX,
//       scaleY: layer.scaleY,
//       styles: { //Duplicate of some properties for addText()
//         angle: layer.angle,
//         fontSize: layer.fontSize,
//         fontWeight: layer.fontWeight,
//         fontStyle: layer.fontStyle,
//         textDecoration: layer.textDecoration,
//         underline: layer.underline,
//         fill: layer.fill,
//         top: layer.top,
//         left: layer.left,
//         originX: "left", //layer.originX, The 'left' value is reset to 'center' when there is no change, so we force it here
//         originY: "top", //layer.originY, The 'top' value is reset to 'center' when there is no change, so we force it here
//         aCoords: layer.aCoords,
//         position: layer.aCoords.tl,
//       },
//     }

//     //Add layer Object based on type
//     switch (layer.type) {
//       case "i-text":
//         console.log("addLayer [TEXT]", layer.text);
//        await  editorInstance.addText(layer.text, options).then(objectProps => {
//           //Assign all other saved values to the newly created layer
//           layer.originX= "left";
//           layer.originY= "top";
//           _.assign(objectProps, layer);
//           console.log("addLayer [TEXT completed]", objectProps.id, objectProps);
//         });
//         break;

//       case "circle":
//       case "triangle":
//       case "rect":
//         console.log("addLayer [SHAPE]", layer.type);
//        await editorInstance.addShape(layer.type, options).then(objectProps => {
//           //Assign all other saved values to the newly created layer
//           _.assign(objectProps, layer);
//           console.log("addLayer [SHAPE completed]", objectProps.id, objectProps);
//         });
//         break;

//       case "icon":
//         console.log("addLayer [ICON]", layer.type);

//        await editorInstance.addIcon('arrow', options).then(objectProps => {

//           //Assign all other saved values to the newly created layer
//           _.assign(objectProps, layer);
//           console.log("addLayer [ICON completed]", objectProps.id, objectProps);
//         });
//         break;

//       default:
//         console.error("addLayer type is not managed", layer, layer.type);
//     }

//   }


//    function setFilteredTUIObject(editorObjects){


//     //Properties pick
//     var modelProp = {
//       aCoords:null, //Text
//       lineCoords:null,
//       angle:null,
//       fontSize:null,
//       fontWeight:null,
//       fill:null,
//       fontStyle:null,
//       height:null,
//       left:null,
//       originX:null,
//       originY:null,
//       origins:null,
//       value:null,
//       rotatingPointOffset:null,
//       text:null,
//       textLines:null,
//       textDecoration:null,
//       top:null,
//       underline:null,
//       width:null,
//       hasBorders:null, //Shape
//       rx:null,
//       ry:null,
//       type:null,
// 	  scaleX:null,
//       scaleY:null,
//       startPoint:null,
//       stroke:null,
//       strokeWidth:null,
//       path:null,
//       pathOffset:null,
//       position:null,
//       lockSkewingX:null,
//       lockSkewingY:null,
//     };

//     for (let i = 0; i < editorObjects.length; i++) {

//       if(editorObjects[i].type != "path" && editorObjects[i].type != "line"){
//         //Strip off not needed properties like "_", "__", canvas, mouseMoveHandler
//         let filteredProp = _.pick(editorObjects[i], _.keys(modelProp));
//         objectsArr.push(filteredProp);
//       }

     

//     }
//     console.log(objectsArr)
//   }
//    function handleClickButton()  {
//     setFilteredTUIObject(editorInstance._graphics.getCanvas()._objects);


//     };
//    function handleClickButton1()  {
//     console.log(editorInstance._graphics.getObjectProperties(3));


//     };
//     function objectAdded(props){
//         console.log(props)

//     }
//     React.useEffect(()=>{
//          editorInstance = editorRef.current.getInstance();
//         setEditor(editorRef.current.getInstance())
//         // editorInstance.loadImageFromURL('https://image.shutterstock.com/image-photo/mountains-under-mist-morning-amazing-600w-1725825019.jpg', 'lena')
//     })  

//     React.useEffect(()=>{
//       if(editorInstance){
//         editorInstance.on('redoStackChanged',(props)=>console.log(props,"redoStackChanged"))
//         editorInstance.on('objectAdded',(props)=>console.log(props,"objectAdded"))
//         editorInstance.on('undoStackChanged',(props)=>console.log(props,"undoStackChanged"))
//         editorInstance.on('addText',(props)=>console.log(props,"addText"))
//         // editorInstance.on('textEditing',(props)=>console.log(props,"addText"))
//         console.log(editorInstance._initHistory())
//         // editorInstance.on('objectMoved',(props)=>console.log(props,"addText"))

//       }
//     })
//     try{

    
//   return (
//     <div>

// <ImageEditor
//         ref={editorRef}
//         includeUI={{
//             objectAdded,
//         loadImage: {
//             path: 'https://image.shutterstock.com/image-photo/mountains-under-mist-morning-amazing-600w-1725825019.jpg',
//             name: 'SampleImage'
//           },
//       theme: myTheme,
//       menu: ['shape', 'filter','text','icon','crop',],
//       initMenu: 'filter',
//       uiSize: {
//         width: '1000px',
//         height: '700px',
//       },
//       menuBarPosition: 'bottom',
//     }}
//     cssMaxHeight={500}
//     cssMaxWidth={700}
//     selectionStyle={{
//       cornerSize: 20,
//       rotatingPointOffset: 70,
//     }}
//     usageStatistics={true}
//   />
//   <button onClick={handleClickButton}>Save all</button>
//   <button onClick={()=>{

// let asyncAddLayout = [];

//     objectsArr.forEach(async (el)=>{
//       setTimeout(async () => {
//         asyncAddLayout.push( await addLayer(el))
//     }, 100);
//     })
//   }}>Re paste all</button>
//     </div>
//   )
// }catch(e){
//     console.log(e);
//     window.location.reload()

// }
// }

// export default Editor