export const locationList = [
  { id: "Andhra Pradesh", name: "Andhra Pradesh", active: true },
  { id: "Arunachal Pradesh", name: "Arunachal Pradesh", active: true },
  { id: "Assam", name: "Assam", active: true },
  { id: "West Bengal", name: "West Bengal", active: true },
  { id: "Bihar", name: "Bihar", active: true },
  { id: "Chhattisgarh", name: "Chhattisgarh", active: true },
  { id: "Goa", name: "Goa", active: true },
  { id: "Gujarat", name: "Gujarat", active: true },
  { id: "Haryana", name: "Haryana", active: true },
  { id: "Himachal Pradesh", name: "Himachal Pradesh", active: true },
  { id: "Jharkhand", name: "Jharkhand", active: true },
  { id: "Karnataka", name: "Karnataka", active: true },
  { id: "Kerala", name: "Kerala", active: true },
  { id: "Madhya Pradesh", name: "Madhya Pradesh", active: true },
  { id: "Maharashtra", name: "Maharashtra", active: true },
  { id: "Manipur", name: "Manipur", active: true },
  { id: "Meghalaya", name: "Meghalaya", active: true },
  { id: "Mizoram", name: "Mizoram", active: true },
  { id: "Nagaland", name: "Nagaland", active: true },
  { id: "Odisha", name: "Odisha", active: true },
  { id: "Punjab", name: "Punjab", active: true },
  { id: "Rajasthan", name: "Rajasthan", active: true },
  { id: "Sikkim", name: "Sikkim", active: true },
  { id: "Telangana", name: "Telangana", active: true },
  { id: "Tamil Nadu", name: "Tamil Nadu", active: true },
  { id: "Tripura", name: "Tripura", active: true },
  { id: "Uttarakhand", name: "Uttarakhand", active: true },
  { id: "Uttar Pradesh", name: "Uttar Pradesh", active: true },
  { id: "Andaman and Nicobar Islands", name: "Andaman and Nicobar Islands", active: true },
  { id: "Chandigarh", name: "Chandigarh", active: true },
  { id: "Delhi", name: "Delhi", active: true },
  { id: "Daman and Diu", name: "Daman and Diu", active: true },
  { id: "Dadra and Nagar Haveli", name: "Dadra and Nagar Haveli", active: true },
  { id: "Lakshadweep", name: "Lakshadweep", active: true },
  { id: "Puducherry", name: "Puducherry", active: true },
  { id: "Jammu and Kashmir", name: "Jammu and Kashmir", active: true },
  { id: "Ladakh", name: "Ladakh", active: true },
]
/*
export  const locationList = [
    { id: "Bhutan", name: "Bhutan", active: true },
    { id: "Democratic Republic of the Congo", name: "Democratic Republic of the Congo", active: true },
    { id: "Liechtenstein", name: "Liechtenstein", active: true },
    { id: "Maldives", name: "Maldives", active: true },
    { id: "Sudan", name: "Sudan", active: true },
    { id: "Zimbabwe", name: "Zimbabwe", active: true },
    { id: "Mauritania", name: "Mauritania", active: true },
    { id: "Mozambique", name: "Mozambique", active: true },
    { id: "Nigeria", name: "Nigeria", active: true },
    { id: "Swaziland", name: "Swaziland", active: true },
    { id: "Tanzania", name: "Tanzania", active: true },
    { id: "Iraq", name: "Iraq", active: true },
    { id: "Guyana", name: "Guyana", active: true },
    { id: "Namibia", name: "Namibia", active: true },
    { id: "Senegal", name: "Senegal", active: true },
    { id: "Turkmenistan", name: "Turkmenistan", active: true },
    { id: "Afghanistan", name: "Afghanistan", active: true },
    { id: "Andorra", name: "Andorra", active: true },
    { id: "Fiji", name: "Fiji", active: true },
    { id: "Gabon", name: "Gabon", active: true },
    { id: "Uzbekistan", name: "Uzbekistan", active: true },
    { id: "Cameroon", name: "Cameroon", active: true },
    { id: "Cuba", name: "Cuba", active: true },
    { id: "Faroe Islands", name: "Faroe Islands", active: true },
    { id: "El Salvador", name: "El Salvador", active: true },
    { id: "Caribbean", name: "Caribbean", active: true },
    { id: "Ethiopia", name: "Ethiopia", active: true },
    { id: "Mongolia", name: "Mongolia", active: true },
    { id: "Puerto Rico", name: "Puerto Rico", active: true },
    { id: "Samoa", name: "Samoa", active: true },
    { id: "Myanmar", name: "Myanmar", active: true },
    { id: "Nicaragua", name: "Nicaragua", active: true },
    { id: "Seychelles", name: "Seychelles", active: true },
    { id: "Tajikistan", name: "Tajikistan", active: true },
    { id: "Dominican Republic", name: "Dominican Republic", active: true },
    { id: "Guinea", name: "Guinea", active: true },
    { id: "Barbados", name: "Barbados", active: true }, 
    { id: "Laos", name: "Laos", active: true },
    { id: "Libya", name: "Libya", active: true },
    { id: "Panama", name: "Panama", active: true },
    { id: "Bahrain", name: "Bahrain", active: true },
    { id: "Benin", name: "Benin", active: true },
    { id: "Ghana", name: "Ghana", active: true },
    { id: "Haiti", name: "Haiti", active: true },
    { id: "Montenegro", name: "Montenegro", active: true },
    { id: "Somalia", name: "Somalia", active: true },
    { id: "Syria", name: "Syria", active: true },
    { id: "Ecuador", name: "Ecuador", active: true },
    { id: "Honduras", name: "Honduras", active: true },
    { id: "Madagascar", name: "Madagascar", active: true },
    { id: "Papua New Guinea", name: "Papua New Guinea", active: true },
    { id: "Tunisia", name: "Tunisia", active: true },
    { id: "Angola", name: "Angola", active: true },
    { id: "Botswana", name: "Botswana", active: true },
    { id: "Cyprus", name: "Cyprus", active: true },
    { id: "Algeria", name: "Algeria", active: true },
    { id: "Bahamas", name: "Bahamas", active: true },
    { id: "New Caledonia", name: "New Caledonia", active: true },
    { id: "Uganda", name: "Uganda", active: true },
    { id: "Yemen", name: "Yemen", active: true },
    { id: "Zambia", name: "Zambia", active: true },
    { id: "Antarctica", name: "Antarctica", active: true },
    { id: "Paraguay", name: "Paraguay", active: true },
    { id: "Jamaica", name: "Jamaica", active: true },
    { id: "Palestine", name: "Palestine", active: true },
    { id: "Bolivia", name: "Bolivia", active: true },
    { id: "Bosnia and Herzegovina", name: "Bosnia and Herzegovina", active: true },
    { id: "Vietnam", name: "Vietnam", active: true },
    { id: "Kenya", name: "Kenya", active: true },
    { id: "Luxembourg", name: "Luxembourg", active: true },
    { id: "Niger", name: "Niger", active: true },
    { id: "Kuwait", name: "Kuwait", active: true },
    { id: "Hawaii", name: "Hawaii", active: true },
    { id: "Scotland", name: "Scotland", active: true },
    { id: "Cambodia", name: "Cambodia", active: true },
    { id: "Uruguay", name: "Uruguay", active: true },
    { id: "Kyrgyzstan", name: "Kyrgyzstan", active: true },
    { id: "Saudi Arabia", name: "Saudi Arabia", active: true },
    { id: "Indonesia", name: "Indonesia", active: true },
    { id: "Azerbaijan", name: "Azerbaijan", active: true },
    { id: "United Arab Emirates", name: "United Arab Emirates", active: true },
    { id: "Mauritius", name: "Mauritius", active: true },
    { id: "Morocco", name: "Morocco", active: true },
    { id: "Albania", name: "Albania", active: true },
    { id: "South Korea", name: "South Korea", active: true },
    { id: "Kazakhstan", name: "Kazakhstan", active: true },
    { id: "Macedonia", name: "Macedonia", active: true },
    { id: "Venezuela", name: "Venezuela", active: true },
    { id: "Taiwan", name: "Taiwan", active: true },
    { id: "Qatar", name: "Qatar", active: true },
    { id: "Jordan", name: "Jordan", active: true },
    { id: "Iceland", name: "Iceland", active: true },
    { id: "Guatemala", name: "Guatemala", active: true },
    { id: "Costa Rica", name: "Costa Rica", active: true },
    { id: "Hong Kong", name: "Hong Kong", active: true },
    { id: "San Marino", name: "San Marino", active: true },
    { id: "Colombia", name: "Colombia", active: true },
    { id: "Moldova", name: "Moldova", active: true },
    { id: "Armenia", name: "Armenia", active: true },
    { id: "Malta", name: "Malta", active: true },
    { id: "Nepal", name: "Nepal", active: true },
    { id: "Lebanon", name: "Lebanon", active: true },
    { id: "Malaysia", name: "Malaysia", active: true },
    { id: "Serbia", name: "Serbia", active: true },
    { id: "Peru", name: "Peru", active: true },
    { id: "Trinidad and Tobago", name: "Trinidad and Tobago", active: true },
    { id: "Lithuania", name: "Lithuania", active: true },
    { id: "Estonia", name: "Estonia", active: true },
    { id: "Georgia", name: "Georgia", active: true },
    { id: "Iran", name: "Iran", active: true },
    { id: "Chile", name: "Chile", active: true },
    { id: "Latvia", name: "Latvia", active: true },
    { id: "Thailand", name: "Thailand", active: true },
    { id: "Egypt", name: "Egypt", active: true },
    { id: "Slovenia", name: "Slovenia", active: true },
    { id: "Mexico", name: "Mexico", active: true },
    { id: "Belarus", name: "Belarus", active: true },
    { id: "Slovakia", name: "Slovakia", active: true },
    { id: "Sri Lanka", name: "Sri Lanka", active: true },
    { id: "Croatia", name: "Croatia", active: true },
    { id: "Philippines", name: "Philippines", active: true },
    { id: "Bangladesh", name: "Bangladesh", active: true },
    { id: "Turkey", name: "Turkey", active: true },
    { id: "Romania", name: "Romania", active: true },
    { id: "Italy", name: "Italy", active: true },
    { id: "South Africa", name: "South Africa", active: true },
    { id: "Hungary", name: "Hungary", active: true },
    { id: "Pakistan", name: "Pakistan", active: true },
    { id: "Portugal", name: "Portugal", active: true },
    { id: "Ukraine", name: "Ukraine", active: true },
    { id: "Greece", name: "Greece", active: true },
    { id: "Oman", name: "Oman", active: true },
    { id: "Argentina", name: "Argentina", active: true },
    { id: "Singapore", name: "Singapore", active: true },
    { id: "Bulgaria", name: "Bulgaria", active: true },
    { id: "Japan", name: "Japan", active: true },
    { id: "Czech Republic", name: "Czech Republic", active: true },
    { id: "Ireland", name: "Ireland", active: true },
    { id: "China", name: "China", active: true },
    { id: "Finland", name: "Finland", active: true },
    { id: "Brazil", name: "Brazil", active: true },
    { id: "Norway", name: "Norway", active: true },
    { id: "Austria", name: "Austria", active: true },
    { id: "Denmark", name: "Denmark", active: true },
    { id: "Belgium", name: "Belgium", active: true },
    { id: "New Zealand", name: "New Zealand", active: true },
    { id: "Spain", name: "Spain", active: true },
    { id: "Switzerland", name: "Switzerland", active: true },
    { id: "Russia", name: "Russia", active: true },
    { id: "Poland", name: "Poland", active: true },
    { id: "Israel", name: "Israel", active: true },
    { id: "Sweden", name: "Sweden", active: true },
    { id: "Netherlands", name: "Netherlands", active: true },
    { id: "France", name: "France", active: true },
    { id: "Australia", name: "Australia", active: true },
    { id: "Canada", name: "Canada", active: true },
    { id: "India", name: "India", active: true },
    { id: "Germany", name: "Germany", active: true },
    { id: "United Kingdom", name: "United Kingdom", active: true },
    { id: "United States", name: "United States", active: true }
  ] */