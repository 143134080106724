import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/dashbord.css";
import AOS from "aos";
import "aos/dist/aos";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import { quizVideoList } from "../../API";
import { PaginationControl } from "react-bootstrap-pagination-control";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import { Stack } from "react-bootstrap";

const QuizesVideo = () => {
  const navigate = useNavigate();
  const [videoList, setvideoList] = useState({
    data: [],
    count: 0,
  });
  const [tableConfig, setTableConfig] = useState({
    page: 0,
    pageSize: 15,
    orderBy: "id",
    orderType: "desc",
  });
  useEffect(() => {
    AOS.init({
      easing: "ease-out-sine",
      duration: 800,
    });
    AOS.refresh();
  }, []);

  const quizVideoListfunction = async () => {
    const params = `orderby=${tableConfig.orderBy}&ordertype=${tableConfig.orderType}&limit=${tableConfig.pageSize}&page=${tableConfig.page}`;
    const videoList = await quizVideoList(params);

    if (
      videoList.data &&
      videoList.data.data &&
      videoList.data.data.rows &&
      videoList.data.data.rows.length > 0
    ) {
      setvideoList({
        data: videoList.data.data.rows,
        count: videoList.data.data.count
      });
    }
  };

  useEffect(() => {
    quizVideoListfunction();
  }, [tableConfig.page, tableConfig.limit, tableConfig.orderBy, tableConfig.orderType]);

  const viewUserForVideo = async (e, id) => {
    navigate(`/quiz-result/${id}`);
  };

  const columns = [
    {
      field: "title",
      width: 300,
      headerName: "Vide SOP Title",
      renderCell: (cellValues) => {
        return <>{cellValues.row.title}</>;
      },
    },
    {
      field: "Createdby",
      width: 200,
      headerName: "Created by",
      sortable: false,
      renderCell: (cellValues) => {
        return <>{cellValues.row.own.name}</>;
      },
    },
    {
      field: "assign",
      width: 150,
      headerClassName: 'auto-height-header',
      headerName: "Total Assigned users",
      align:'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: (cellValues) => {
        return <>{cellValues.row.user.length}</>;
      },
    },
    {
      field: "response",
      width: 150,
      headerClassName: 'auto-height-header',
      headerName: "Total Responses",
      align:'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: (cellValues) => {
        return <>{cellValues.row.response.length}</>;
      },
    },
    {
      field: "createdAt",
      headerName: "Time & Date",
      renderCell: (cellValues) => {
        return (
          <>
            {cellValues.row.createdAt
              ? moment(cellValues.row.createdAt).format("DD/MM/YY, HH:mm A")
              : ""}
          </>
        );
      },
      width: 200,
    },
    {
      headerName: "Shared with",
      sortable: false,
      renderCell: (cellValues) => {
        return <>{cellValues.row.user.map((ob) => ob.user.name).join(",")}</>;
      },
      width: 200,
    },
    {
      field: "id",
      headerName: "Action",
      width: 150,
      sortable: false,
      align:'center',
      headerAlign: 'center',
      renderCell: (cellValues) => {
        return (
          <div className="d-flex">
            <i
              className="fa fa-eye table_icon"
              aria-hidden="true"
              onClick={(e) => viewUserForVideo(e, `${cellValues.row.id}`)}
            ></i>
          </div>
        );
      },
    },
  ];

  const setPage = (data) => {
    setTableConfig({
      ...tableConfig,
      page: data,
    });
  };
  const setPageSize = (data) => {
    setTableConfig({
      ...tableConfig,
      pageSize: data,
    });
  };

  const handleSortModelChange = (data) => {
    if (data && data[0]) {
      setTableConfig({
        ...tableConfig,
        orderBy: data[0].field,
        orderType: data[0].sort,
      });
    } else {
      setTableConfig({
        ...tableConfig,
        orderBy: "id",
        orderType: "desc",
      });
    }
  };

  return (
    <>
      <div className="rightsidebar" data-aos="slide-up">
        <div className="top_button">
          <button className="gray_button p-2 mx-2">Download CSV</button>
        </div>
        <div className="flex-row bg-primary2 text-white w-full font-comfortaa user-list">
          <div className="bordernone" style={{ height: 500, width: "100%" }}>
            <DataGrid
              className="painnation"
              getRowHeight={() => 'auto'}
              // rowHeight={120}
              
              columns={columns}
              disableColumnMenu
              rowsPerPageOptions={[15, 50, 100]}
              rows={videoList.data}
              rowCount={videoList.count}
              paginationModel={tableConfig}
              pagination
              paginationMode="server"
              page={tableConfig.page}
              pageSize={tableConfig.pageSize}
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              onSortModelChange={handleSortModelChange}
            />
          </div>

          {/* <div className="grid backgtable">
            <div className="w-full flex">
              <table
                className="flex w-full text-black text-center items-center font-comfortaa"
                style={{ justifyContent: "space-between" }}
              >
                <input style={{ width: "20%" }} type="checkbox" />

                <th className="py-4 " style={{ width: "10%" }}>
                  SOP 
                </th>
                <th className="py-4 " style={{ width: "60%" }}>
                  View Assignments
                </th>
              </table>
            </div>
            {tableConfig.isApiCall && (!videoList?.length || !videoList?.length === 0) ? (
              <div className="flex backgtablecolor">
                <table className="flex w-full  text-black text-center">
                  <tr className="flex w-full">
                    <td colSpan={7} className="text-center w-full">
                      Please wait ...{" "}
                    </td>
                  </tr>
                </table>
              </div>
            ) : videoList?.length ? (
              <>
                {videoList?.map((el, i) => (
                  <div className=" backtablecolor ">
                    <table className="flex w-full  text-black text-center">
                      <tr

                        className="flex w-full"
                        style={{ justifyContent: "space-between" }}
                      >
                        <td className="py-4" style={{ width: "20%" }}>
                          <input type="checkbox" />
                        </td>
                        <td className="py-4" style={{ width: "10%" }}>
                          {el.title}
                        </td>
                        <td
                          className="py-2"
                          style={{ width: "60%" }}
                        >
                          <Button
                            variant="contained"
                            className="black_button"
                            style={{ maxWidth: '100%' }}
                            onClick={(e) => viewUserForVideo(e, `${el.id}`)}
                          >
                            View Assignments
                          </Button>
                        </td>
                      </tr>
                    </table>

                  </div>
                ))}
                <div className="bt_pagination">
                  <PaginationControl
                    page={tableConfig.page}
                    between={4}
                    total={tableConfig.pageCount}
                    limit={tableConfig.limit}
                    changePage={(page) => {
                      setTableConfig((conf) => ({ ...conf, page: page }))
                    }}
                    ellipsis={1}
                  />
                </div>
              </>

            ) : (
              <div className="flex backtablecolor">
                <table className="flex w-full  text-black text-center">
                  <tr className="flex w-full">
                    <td colSpan={7} className="text-center w-full">
                      No data found{" "}
                    </td>
                  </tr>
                </table>
              </div>
            )}
          </div> */}
        </div>
      </div>
    </>
  );
};

export default QuizesVideo;
