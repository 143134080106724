/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { SET_VIDEO_CURRENTTIMEactions, SET_VIDEO_CURRENTTIME_STARTactions, SET_PROJECT_VIDEOaction, SET_POSITION_ANOTATIONactions, SET_HEIGHTWIDTHactions } from '../../../../redux/Reducers/rendervideoReducer'
import Box from '@mui/material/Box';
import ReactPlayer from 'react-player'
import { fabric } from 'fabric';
import { useCallback } from "react";

// const format = (seconds) => {
//   if (isNaN(seconds)) {
//     return `00:00`;
//   }
//   const date = new Date(seconds * 1000);
//   const hh = date.getUTCHours();
//   const mm = date.getUTCMinutes();
//   const ss = date.getUTCSeconds().toString().padStart(2, "0");
//   const ssm = date.getUTCMilliseconds().toString().padStart(2, "0");
//   if (hh) {
//     return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
//   }
//   return `${mm}:${ss}:${ssm}`;
// };

// let count = 0;
let moouseMoveTimeout = ''; 
let objectScaledTimeout = '';
let objectRotatingTimeOut = ''
const RenderPlayer = (props) => {
  const rendervideoState = useSelector(state => state.rendervideoReducer);

  // const [value, setValue] = React.useState(30);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };
  const dispatch = useDispatch();

  // const [showControls, setShowControls] = useState(false);
  // const [count, setCount] = useState(0);
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const [timeDisplayFormat, setTimeDisplayFormat] = React.useState("normal");
  // const [bookmarks, setBookmarks] = useState([]);
  const [canvas, setCanvas] = useState('');


  const initCanvas = (height, width) => (
    new fabric.Canvas('edit_video_canvas', {
      height: height,
      width: width,
      position: 'absolute',
      backgroundColor: 'rgba(0,0,0,0)'
    })
  );

  const [state, setState] = useState({
    pip: false,
    playing: true,
    controls: false,
    light: false,
    url: null,
    muted: true,
    played: 0,
    duration: 0,
    playbackRate: 1.0,
    volume: 1,
    loop: false,
    seeking: false,
    shoCanvas: false,
    canvasWidth: 0,
    canvasHeight: 0
  });

  const playerRef = useRef(null);
  const editCanvasRef = useRef(null);
  // const playerContainerRef = useRef(null);
  // const controlsRef = useRef(null);
  // const canvasRef = useRef(null);
  // var isObjectMoving = false;
  // const [isObjectMoving, setIsObjectMoving] = useState(false);

  const [isTextChanged, setIsTextChanged] = useState(false);
  const [videoState, setVideoState] = useState(false);
  // var isTextChanged = false;
  // const [currentSec, setCurrentSec] = useState(0);
  // var  = {}
  const {
    playing,
    light,
    muted,
    loop,
    playbackRate,
    pip,
    volume,
  } = state;
  const handlePlayPause = () => {
    setState({ ...state, playing: !state.playing });
    console.log('Playing player 2', !state.playing)
    dispatch(SET_VIDEO_CURRENTTIME_STARTactions(!state.playing))
    // playerRef.current.seekTo(rendervideoState.project.video.video_currentTime.seconds);
  };

  
  const currentTime =
    playerRef && playerRef.current
      ? playerRef.current.getCurrentTime()
      : 0;


  // useEffect(() => {
  //   console.log('useEffect 1')
  //   if (rendervideoState.project.margeArr.length > 0 && rendervideoState.project.video.video_currentTime.start === false) {
  //     playerRef.current.seekTo(rendervideoState.project.video.video_currentTime.seconds);
  //     setState({ ...state, played: (rendervideoState.project.video.video_currentTime.seconds / rendervideoState.project.margeArr[0].actions[0].end) * 1 });
  //   }
  // }, [rendervideoState.project.video.video_currentTime.seconds])

  useEffect(() => {
    console.log('useEffect 2')
    // handlePlayPause()

    if (rendervideoState.project.video !== undefined) {
     // console.log('Playing player 1', state.playing)
      handlePlayPause()
      // setState({ ...state, playing: !state.playing });

      // dispatch(SET_VIDEO_CURRENTTIME_STARTactions(!state.playing))
    }

    // setState({ ...state, playing: rendervideoState.project.video.video_currentTime.start });
  }, [rendervideoState.project.video.video_currentTime.start])

  useEffect(() => {
    console.log(rendervideoState.project.video.video_currentTime.seconds)
    console.log(currentTime)
     if(!rendervideoState.project.video.video_currentTime.start && currentTime !== null && 
      rendervideoState.project.video.video_currentTime.seconds.toFixed(2) !==currentTime.toFixed(2)) {
        if(rendervideoState.project.video.video_currentTime.bytimeline) {
           playerRef.current.seekTo(rendervideoState.project.video.video_currentTime.seconds);
           setState({ ...state, playing: false, played: (rendervideoState.project.video.video_currentTime.seconds / rendervideoState.project.margeArr[0].actions[0].end) * 1 });
           console.log(state.playing)
           dispatch(SET_VIDEO_CURRENTTIMEactions({
            sec: rendervideoState.project.video.video_currentTime.seconds,
            bytimeline: false
          }))
        } else {
          dispatch(SET_VIDEO_CURRENTTIMEactions({
            sec: currentTime,
            bytimeline: false
          }))
        }
        
        
      }
 }, [rendervideoState.project.video.video_currentTime, currentTime])

  useEffect(() => {

    console.log('useEffect 3----------------'+rendervideoState.project.video.video_currentTime.seconds)
    console.log('useEffect 3----------------'+currentTime)
    
    if (canvas && rendervideoState.project.margeArr) {
      var objs = canvas.getObjects().map(function (o) {
        return o.set('active', true);
      });
      for(let ob1 of objs) {
        const anno = rendervideoState.project.margeArr.find((o) => ob1.id=== o.id);
        if(anno && anno.actions[0] && (anno.actions[0].start > currentTime || anno.actions[0].end < currentTime)) {
          canvas.remove(ob1);
        } else if(!anno) {
          canvas.remove(ob1);
        } 
      }
      let isUpdate = false;
      const dataTosave = JSON.parse(JSON.stringify(rendervideoState.project.margeArr));
      for (let ob of dataTosave) {
        const index = objs.findIndex((o) => o.id === ob.id);
        if (index === -1 && ob.actions[0] && ob.actions[0].type === 'ICON') {
          if (ob.actions[0].key === 0) {
            let rectangle = new fabric.Rect({
              stroke: 'rgb(38, 153, 251)',
              strokeWidth: 3,
              top: ob.actions[0].y ? ob.actions[0].y : 10,
              left: ob.actions[0].x ? ob.actions[0].x : 10,
              id: ob.id,
              angle: ob.actions[0].angle ? ob.actions[0].angle : 0,
              width: ob.actions[0].width ? ob.actions[0].width : 50,
              height: ob.actions[0].height ? ob.actions[0].height : 50,
              fill: 'rgba(0,0,0,0)'
            });
            if(ob.actions[0].start <= currentTime && ob.actions[0].end >= currentTime) {
              canvas.add(rectangle)
              isUpdate = true;
              const img = rectangle.toDataURL({ format: "png" });
              ob.actions[0].img = img
              ob.actions[0].width = ob.actions[0].width ? ob.actions[0].width : 50
              ob.actions[0].height = ob.actions[0].height ? ob.actions[0].height : 50
            }
          } else if (ob.actions[0].key === 1) {
            let rectangle = new fabric.Rect({
              stroke: 'rgb(38, 153, 251)',
              strokeWidth: 3,
              top: ob.actions[0].y ? ob.actions[0].y : 10,
              left: ob.actions[0].x ? ob.actions[0].x : 10,
              id: ob.id,
              angle: ob.actions[0].angle ? ob.actions[0].angle : 0,
              width: ob.actions[0].width ? ob.actions[0].width : 80,
              height: ob.actions[0].height ? ob.actions[0].height : 50,
              fill: 'rgba(0,0,0,0)'
            });
            if(ob.actions[0].start <= currentTime && ob.actions[0].end >= currentTime) {
              canvas.add(rectangle)
              isUpdate = true;
              const img = rectangle.toDataURL({ format: "png" });
              ob.actions[0].img = img
              ob.actions[0].width = ob.actions[0].width ? ob.actions[0].width : 80
              ob.actions[0].height = ob.actions[0].height ? ob.actions[0].height : 50
            }
          } else if (ob.actions[0].key === 2) {

            let rectangle = new fabric.Ellipse({
              stroke: 'rgb(38, 153, 251)',
              strokeWidth: 3,
              top: ob.actions[0].y ? ob.actions[0].y : 10,
              left: ob.actions[0].x ? ob.actions[0].x : 10,
              rx: ob.actions[0].rx ? ob.actions[0].rx : 10,
              ry: ob.actions[0].ry ? ob.actions[0].ry : 10,
              angle: ob.actions[0].angle ? ob.actions[0].angle : 0,
              id: ob.id,
              fill: 'rgb(0, 0, 0, 0)'
            });

            if(ob.actions[0].start <= currentTime && ob.actions[0].end >= currentTime) {
              canvas.add(rectangle)
              isUpdate = true;
              const img = rectangle.toDataURL({ format: "png" });
              ob.actions[0].img = img
              ob.actions[0].rx = ob.actions[0].rx ? ob.actions[0].rx : 10
              ob.actions[0].ry = ob.actions[0].ry ? ob.actions[0].ry : 10
            }
            
          } else if (ob.actions[0].key === 3) {
            let rectangle = new fabric.Triangle({
              stroke: 'rgb(38, 153, 251)',
              strokeWidth: 3,
              top: ob.actions[0].y ? ob.actions[0].y : 10,
              left: ob.actions[0].x ? ob.actions[0].x : 10,
              angle: ob.actions[0].angle ? ob.actions[0].angle : 0,
              width: ob.actions[0].width ? ob.actions[0].width : 50,
              height: ob.actions[0].height ? ob.actions[0].height : 50,
              id: ob.id,
              fill: 'rgb(0, 0, 0, 0)'
            });

            if(ob.actions[0].start <= currentTime && ob.actions[0].end >= currentTime) {
            canvas.add(rectangle)
            isUpdate = true;
            const img = rectangle.toDataURL({ format: "png" });
            ob.actions[0].img = img
            ob.actions[0].width = ob.actions[0].width ? ob.actions[0].width : 50
            ob.actions[0].height = ob.actions[0].height ? ob.actions[0].height : 50
            }
          } else if (ob.actions[0].key === 4) {
            
            ob.actions[0].width = ob.actions[0].width ? ob.actions[0].width : 150
            ob.actions[0].height = ob.actions[0].height ? ob.actions[0].height : 0 
            // if(ob.actions[0].angle > 0) {
            //     ob.actions[0].y = ob.actions[0].y - (ob.actions[0].height / 2)
            //     ob.actions[0].x = ob.actions[0].x - (ob.actions[0].height / 2)
            //   // ob.actions[0].y = ob.actions[0].y - ob.actions[0].height
            // }
            var points = [ob.actions[0].x, ob.actions[0].y, ob.actions[0].x + ob.actions[0].width, ob.actions[0].y ];
            var rotation_origin = new fabric.Point(points[0], points[1]);
            var angle_radians = fabric.util.degreesToRadians(ob.actions[0].angle ? ob.actions[0].angle : 0);
            var start = fabric.util.rotatePoint(new fabric.Point(points[0], points[1]), rotation_origin, angle_radians);

            let rectangle = new fabric.Line([start.x, start.y, start.x + ob.actions[0].width, start.y], {
              fill: 'rgb(38, 153, 251)',
              strokeWidth: 3,
              id: ob.id,
             
              // height: ob.actions[0].height ? ob.actions[0].height: 10,
              // width:ob.actions[0].width ? ob.actions[0].width: 100,
              angle: ob.actions[0].angle ? ob.actions[0].angle: 0,
              stroke: 'rgb(38, 153, 251)',
              originX: 'left',
              originY: 'center'
            });
            if(ob.actions[0].start <= currentTime && ob.actions[0].end >= currentTime) {
              canvas.add(rectangle)
              const img = rectangle.toDataURL({ format: "png" });
              isUpdate = true;
              ob.actions[0].img = img
            }

            
            // ob.actions[0].width = 0
            // ob.actions[0].height = ob.actions[0].height ? ob.actions[0].height : 3
          } else if (ob.actions[0].key === 5) {

            ob.actions[0].x = ob.actions[0].x ? ob.actions[0].x : 50
            ob.actions[0].y = ob.actions[0].y ? ob.actions[0].y : 0
            ob.actions[0].width = ob.actions[0].width ? ob.actions[0].width : 150
            ob.actions[0].height = ob.actions[0].height ? ob.actions[0].height : 0 
            // ob.actions[0].x2 = ob.actions[0].x2 ? ob.actions[0].x2 : 50
            // ob.actions[0].y2 = ob.actions[0].y2 ? ob.actions[0].y2 : 70
            ob.actions[0].angle = ob.actions[0].angle ? ob.actions[0].angle : 0
            if(ob.actions[0].start <= currentTime && ob.actions[0].end >= currentTime) {
              const group = addArrow(ob.actions[0].x, ob.actions[0].y, ob.actions[0].width, ob.id, ob.actions[0].angle)
              isUpdate = true;
              const img = group.toDataURL({ format: "png" });
              ob.actions[0].img = img
            }
          }
        } else if (index === -1 && ob.actions[0] && ob.actions[0].type === 'TEXT') {
          ob.actions[0].x = ob.actions[0].x ? ob.actions[0].x : 50
          ob.actions[0].y = ob.actions[0].y ? ob.actions[0].y : 50
          
          const text = new fabric.IText(ob.actions[0].text, {
            id: ob.id,
            angle: ob.actions[0].angle ? ob.actions[0].angle : 0,
            left: ob.actions[0].x, //Take the block's position
            top: ob.actions[0].y,
            lockUniScaling: true,
            scaleX: ob.actions[0].scaleX ? ob.actions[0].scaleX : 1,
            scaleY: ob.actions[0].scaleY ? ob.actions[0].scaleY : 1,
            width: ob.actions[0].width ? ob.actions[0].width : 50,
            height: ob.actions[0].height ? ob.actions[0].height : 50,
            // fontSize : ob.actions[0].fontSize ? ob.actions[0].fontSize: 12 ,
            fill: 'rgb(38, 153, 251)'
          })
          
          if(ob.actions[0].start <= currentTime && ob.actions[0].end >= currentTime) { 
            isUpdate = true;
            const img = text.toDataURL({ format: "png" });
            ob.actions[0].img = img
            canvas.add(text)
          }
          
        }
      }
      if (isUpdate && !state.playing) {
        dispatch(SET_POSITION_ANOTATIONactions(dataTosave))
      }
    }
  }, [canvas, rendervideoState.project.margeArr, currentTime, state.playing])

  function adjustArrowsSize(arrowObjects, objectScaleY) {
    var triangleCount = 0, triangles = [];

    arrowObjects.forEach(function (object) {
      if (object.get('type') === 'triangle') {
        triangles[triangleCount] = object;
        triangleCount++;
        let ratio = 1 / objectScaleY;
        object.set('scaleY', ratio);
      }
    });
  }

  const addArrow = (x1, y1, width, id, angle) => {
    
    var points = [x1, y1, x1 + width, y1 ];
    var rotation_origin = new fabric.Point(points[0], points[1]);
    var angle_radians = fabric.util.degreesToRadians(angle ? angle : 0);
    var start = fabric.util.rotatePoint(new fabric.Point(points[0], points[1]), rotation_origin, angle_radians);
    var end = fabric.util.rotatePoint(new fabric.Point(points[2], points[3]), rotation_origin, angle_radians);

    let line = new fabric.Line([start.x, start.y, end.x, end.y], {
      fill: 'rgb(38, 153, 251)',
      strokeWidth: 3,
      // height: ob.actions[0].height ? ob.actions[0].height: 10,
      // width:ob.actions[0].width ? ob.actions[0].width: 100,
      // angle: ob.actions[0].angle ? ob.actions[0].angle: 0,
      stroke: 'rgb(38, 153, 251)',
      originX: 'center',
      originY: 'center'
    });

    // let line = new fabric.Line(arrowLinePoints, {
    //   fill: 'rgb(38, 153, 251)',
    //   stroke: 3,
    //   stroke: 'rgb(38, 153, 251)'
    // });

    let dx = line.x2 - line.x1,
      dy = line.y2 - line.y1;

    /* calculate angle of arrow */
    angle = Math.atan2(dy, dx);
    angle *= 180 / Math.PI;
    angle += 90;

    let arrow = new fabric.Triangle({
      angle: angle,
      fill: 'rgb(38, 153, 251)',
      strokeUniform: true,
      top: line.y2,
      left: line.x2,
      width: 15,
      height: 15,
      originX: 'center',
      originY: 'center',
      stroke: 'rgb(38, 153, 251)',
    });
    let selectedShape = new fabric.Group([line, arrow], {
      hasBorders: true,
      hasControls: true,
      id: id,
    });
    canvas.add(selectedShape);
    return selectedShape;
  }

  const textChanged = useCallback(e => {
    setIsTextChanged(true);
    console.log('isTextChanged.........1111'+isTextChanged)
    const dataTosave = JSON.parse(JSON.stringify(rendervideoState.project.margeArr));
    const index = dataTosave.findIndex((o) => o.id === e.target.id);
    console.log(e.target)
    if (index > -1) {
      
      dataTosave[index].actions[0].text = e.target.text;
    }
    setVideoState(dataTosave)
    // videoState = 
  }, [rendervideoState.project.margeArr])

  const onObjectScaled = useCallback(e => {

    if(objectScaledTimeout) {
      clearTimeout(objectScaledTimeout)
    } 
    objectScaledTimeout = setTimeout(function() { 
          // setIsObjectMoving(true);
    let pngFile = e.target.toDataURL({ format: "png" });
    const dataTosave = JSON.parse(JSON.stringify(rendervideoState.project.margeArr));
    if (!e.target.strokeWidthUnscaled && e.target.strokeWidth) {
      e.target.strokeWidthUnscaled = e.target.strokeWidth;
    }
    if (e.target.strokeWidthUnscaled) {
      e.target.strokeWidth = e.target.strokeWidthUnscaled / e.target.scaleX;
    }

    const index = dataTosave.findIndex((o) => o.id === e.target.id);
    if (index > -1) {
      
      dataTosave[index].actions[0].img = pngFile;

      dataTosave[index].actions[0].x2 = e.target.left + e.target.getScaledHeight();
      dataTosave[index].actions[0].y2 = e.target.top + e.target.getScaledWidth();

      dataTosave[index].actions[0].aCoords = e.target.aCoords;

      dataTosave[index].actions[0].height = e.target.getScaledHeight();
      // dataTosave[index].actions[0].width = e.target.getScaledWidth() * e.target.scaleX;
      dataTosave[index].actions[0].width = e.target.getScaledWidth() - 3;
      dataTosave[index].actions[0].fontSize = e.target.fontSize * e.target.scaleX;
      dataTosave[index].actions[0].scaleX = e.target.scaleX;
      dataTosave[index].actions[0].scaleY = e.target.scaleY;
   
      if (dataTosave[index].actions[0].key === 2) {
        dataTosave[index].actions[0].rx = e.target.getRx();
        dataTosave[index].actions[0].ry = e.target.getRy();
      } else if (dataTosave[index].actions[0].key === 4) {
        dataTosave[index].actions[0].x = e.target.aCoords.tl.x;
        dataTosave[index].actions[0].y = e.target.aCoords.tl.y;
        dataTosave[index].actions[0].x2 = e.target.aCoords.tr.x;
        dataTosave[index].actions[0].y2 = e.target.aCoords.tr.y;
      } else if (dataTosave[index].actions[0].key === 5) {
        dataTosave[index].actions[0].x = e.target.aCoords.tl.x;
        dataTosave[index].actions[0].y = e.target.aCoords.tl.y;
        dataTosave[index].actions[0].x2 = e.target.aCoords.bl.x;
        dataTosave[index].actions[0].y2 = e.target.aCoords.bl.y;
      } else if (dataTosave[index].actions[0].key === 3) {
        dataTosave[index].actions[0].x = e.target.aCoords.tl.x;
        dataTosave[index].actions[0].y = e.target.aCoords.tl.y;
      }
    }
    setVideoState(dataTosave)
    let type = e.target.get('type');
    if (type === 'group') {
      adjustArrowsSize(e.target._objects, e.target.get('scaleY'))
    }
      dispatch(SET_POSITION_ANOTATIONactions(dataTosave))
    })

  }, [rendervideoState.project.margeArr])

  const selectionChanged = useCallback(e => { 
    console.log('isTextChanged.........'+isTextChanged)
      if (isTextChanged) {
      setIsTextChanged(false)
      dispatch(SET_POSITION_ANOTATIONactions(videoState))
    }
  }, [isTextChanged, videoState])
  // const onMouseUp = useCallback(e => {
  //   console.log('isObjectMoving............'+isObjectMoving)
  //   if (isObjectMoving) {
  //     setIsObjectMoving(false);
  //     dispatch(SET_POSITION_ANOTATIONactions(videoState))
  //   }

  // }, [isObjectMoving, videoState])

  const onObjectMoving = useCallback(e => {
    if(moouseMoveTimeout) {
      clearTimeout(moouseMoveTimeout)
    } 

    
    const dataTosave = JSON.parse(JSON.stringify(rendervideoState.project.margeArr));
    moouseMoveTimeout = setTimeout(function() {
      console.log(dataTosave)
      console.log("----------------------------------------")
    const index = dataTosave.findIndex((o) => o.id === e.target.id);
    if (index > -1) {
      // setIsObjectMoving(true);  
      dataTosave[index].actions[0].x = e.target.left;
      dataTosave[index].actions[0].y = e.target.top;
      dataTosave[index].actions[0].aCoords = e.target.aCoords;
      if (dataTosave[index].actions[0].key === 4) {
        dataTosave[index].actions[0].x = e.target.aCoords.tl.x;
        dataTosave[index].actions[0].y = e.target.aCoords.tl.y;
        dataTosave[index].actions[0].x2 = e.target.aCoords.tr.x;
        dataTosave[index].actions[0].y2 = e.target.aCoords.tr.y;
      } else if (dataTosave[index].actions[0].key === 5) {
        dataTosave[index].actions[0].x = e.target.aCoords.tl.x;
        dataTosave[index].actions[0].y = e.target.aCoords.tl.y;
        dataTosave[index].actions[0].x2 = e.target.aCoords.bl.x;
        dataTosave[index].actions[0].y2 = e.target.aCoords.bl.y;
      }
    }

    setVideoState(dataTosave)
    dispatch(SET_POSITION_ANOTATIONactions(dataTosave))
    }, 50)
    // videoState = dataTosave
    // dispatch(SET_POSITION_ANOTATIONactions(dataTosave))
  }, [rendervideoState.project.margeArr])
  // useEffect(() => {
  //   const edit_video = document.getElementById('edit_video');
  //   setTimeout(() => {
  //     if(edit_video && edit_video.childNodes[0]) {
  //       edit_video.childNodes[0].style.width = 'auto !important'
  //       edit_video.childNodes[0].style.height  = 'auto !important'
  //     }
  //   }, 1000)

  //   console.log()
  // }, [document.getElementById('edit_video')])

  // const handleRewind = () => {
  //   playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10);
  // };

  // const handleFastForward = () => {
  //   playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10);
  // };

  const handleProgress = (changeState) => {
 //   setCurrentSec(changeState.playedSeconds)
    // if (count > 3) {
    //   controlsRef.current.style.visibility = "hidden";
    //   count = 0;
    // }
    // if (controlsRef.current && controlsRef.current.style && controlsRef.current.style.visibility === "visible") {
    //   count += 1;
    // }
    // if (!state.seeking) {
    //   setState({ ...state, ...changeState });
    //   console.log('changeState ', changeState)
    //   console.log(changeState.playedSeconds)
    //   setCurrentSec(changeState.playedSeconds)
    //   playerRef.current.seekTo(rendervideoState.project.video.video_currentTime.seconds);
    //   dispatch(SET_VIDEO_CURRENTTIMEactions({ sec: changeState.playedSeconds, bytimeline: false }))
    // }

  };

  // const handleSeekChange = (e, newValue) => {
  //   console.log('newValue', { newValue });
  //   setState({ ...state, played: parseFloat(newValue / 100) });
  // };

  // const handleSeekMouseDown = (e) => {
  //   setState({ ...state, seeking: true });
  // };

  // const handleSeekMouseUp = (e, newValue) => {
  //   console.log({ value: newValue / 100 });
  //   setState({ ...state, seeking: false });
  //   // console.log(sliderRef.current.value)
  //   playerRef.current.seekTo(newValue / 100, "fraction");
  // };

  // const handleDuration = (duration) => {
  //   setState({ ...state, duration });
  // };

  // const handleVolumeSeekDown = (e, newValue) => {
  //   setState({ ...state, seeking: false, volume: parseFloat(newValue / 100) });
  // };
  // const handleVolumeChange = (e, newValue) => {
  //   // console.log(newValue);
  //   setState({
  //     ...state,
  //     volume: parseFloat(newValue / 100),
  //     muted: newValue === 0 ? true : false,
  //   });
  // };

  // const toggleFullScreen = () => {
  //   // screenful.toggle(playerContainerRef.current);
  // };

  // const handleMouseMove = () => {
  //   console.log("mousemove");
  //   controlsRef.current.style.visibility = "visible";
  //   count = 0;
  // };

  // const hanldeMouseLeave = () => {
  //   controlsRef.current.style.visibility = "hidden";
  //   count = 0;
  // };

  // const handleDisplayFormat = () => {
  //   setTimeDisplayFormat(
  //     timeDisplayFormat === "normal" ? "remaining" : "normal"
  //   );
  // };

  // const handlePlaybackRate = (rate) => {
  //   setState({ ...state, playbackRate: rate });
  // };

  // const hanldeMute = () => {
  //   setState({ ...state, muted: !state.muted });
  // };

  const onObjectRotating = (e) => {
    if(objectRotatingTimeOut) {
      clearTimeout(objectRotatingTimeOut)
    } 

    
    objectRotatingTimeOut = setTimeout(function() {
    // setIsObjectMoving(true);
    let pngFile = e.target.toDataURL({ format: "png" });
    const dataTosave = JSON.parse(JSON.stringify(rendervideoState.project.margeArr));
    const index = dataTosave.findIndex((o) => o.id === e.target.id);
    if (index > -1) {
      
      dataTosave[index].actions[0].img = pngFile;
      dataTosave[index].actions[0].angle = e.target.angle
      dataTosave[index].actions[0].x = e.target.left;
      dataTosave[index].actions[0].y = e.target.top;
      dataTosave[index].actions[0].aCoords = e.target.aCoords;

      if (dataTosave[index].actions[0].key === 4) {
        
        // dataTosave[index].actions[0].x = e.target.aCoords.tl.x;
        // dataTosave[index].actions[0].y = e.target.aCoords.tl.y;
        // dataTosave[index].actions[0].x2 = e.target.aCoords.tr.x;
        // dataTosave[index].actions[0].y2 = e.target.aCoords.tr.y;
        // dataTosave[index].actions[0].angle = 0
      } else if (dataTosave[index].actions[0].key === 5) {
        console.log(e.target)
        
        dataTosave[index].actions[0].x = e.target.aCoords.tl.x;
        dataTosave[index].actions[0].y = e.target.aCoords.tl.y;
        // dataTosave[index].actions[0].x2 = e.target.aCoords.bl.x;
        // dataTosave[index].actions[0].y2 = e.target.aCoords.bl.y;
        dataTosave[index].actions[0].angle = e.target.angle
      }
    }
    setVideoState(dataTosave) 
    dispatch(SET_POSITION_ANOTATIONactions(dataTosave))
    })
    
  }

  useEffect(() => {
    console.log('useEffect 4')
    if (canvas) {
      canvas.off('object:scaling', onObjectScaled);
      canvas.off('text:changed', textChanged)
      canvas.off('text:editing:exited', selectionChanged)
      canvas.off('object:moving', onObjectMoving);
      canvas.off('object:rotating', onObjectRotating);
      // canvas.off('mouse:up', onMouseUp)

      canvas.on('object:scaling', onObjectScaled);
      canvas.on('text:changed', textChanged)
      canvas.on('text:editing:exited', selectionChanged)
      canvas.on('object:moving', onObjectMoving);
      canvas.on('object:rotating', onObjectRotating);
      // canvas.on('mouse:up', onMouseUp)
    }

  }, [canvas, rendervideoState.project.margeArr])

  const loadCanvas = () => {
    if (document.getElementsByTagName('video')[0]) {
      const info = document.getElementsByTagName('video')[0].getBoundingClientRect()
      setState({
        ...state,
        shoCanvas: true,
        canvasHeight: info.height,
        canvasWidth: info.width
      })
      dispatch(SET_HEIGHTWIDTHactions({ width: info.width, height: info.height }));
      setTimeout(() => {
        if(!canvas) {
          const c = initCanvas(info.height, info.width)
          setCanvas(c);
        }
        
      }, 500)
    }
  }


  // const duration =
  //   playerRef && playerRef.current ? playerRef.current.getDuration() : "00:00:000";
  // const elapsedTime =
  //   timeDisplayFormat === "normal"
  //     ? format(currentTime)
  //     : `-${format(duration - currentTime)}`;

  // const totalDuration = format(duration);

  return (
    <>
      <Box
        id="video_edit"
        component={'div'}
        sx={{

          // height: '100%',
          width: "66%",
          display: "flex",
          // backgroundColor: '#000000',
          // border: '1px solid #FFFFFF',
          borderRadius: "7px",
          // overflow: 'hidden',
          flexDirection: 'column',
          alignItems: 'center',
          // justifyContent: 'center',
          padding: '0%'
        }}>
        {/* {
          rendervideoState.project.margeArr.length !== 0 ?
            rendervideoState.project.margeArr.map((item, index) => {

              if (index !== 0) {
                console.log('Anotation item ', item)
                return (
                  <Anotation key={index} type={item.type} actions={item.actions} id={index} />
                )
              }


            })
            :

            <></>

        } */}

        {/* {console.log('rendervideoState.project.visibility.id',rendervideoState.project.visibility.id)} */}
        {/* {console.log('state.playing ', rendervideoState.project.videoVisibility.id !== null ? rendervideoState.project.videos.videoList[rendervideoState.project.videoVisibility.id].url : rendervideoState.project.videos.videoList[0].url)} */}
        <ReactPlayer
          id="edit_video"
          ref={playerRef}
          style={{ border: '1px solid #659EC7', backgroundColor: '#000000' }}
          width={(props.size !== undefined) ? parseInt(props.size.width) + "px" : '600px'}
          height={(props.size !== undefined) ? parseInt(props.size.height) + "px" : '425px'}
          // url={rendervideoState.project.videoVisibility.id !== null ? rendervideoState.project.videos.videoList[rendervideoState.project.videoVisibility.id].url : rendervideoState.project.videos.videoList[0].url}

          // url={`${process.env.REACT_APP_MEDIA_BASE_URI}/${process.env.REACT_APP_VIDEO_URI}/${props.videoPlayerUrl}`}
          url={rendervideoState.project.video.path}
          pip={pip}
          playing={playing}
          controls={false}
          light={light}
          onReady={loadCanvas}
          loop={loop}
          playbackRate={playbackRate}
          volume={volume}
          muted={muted}
          onProgress={handleProgress}
          onEnded={() => {
            setState({ ...state, playing: false });
            console.log('Playing player 2', false)
            dispatch(SET_VIDEO_CURRENTTIME_STARTactions(false))
            props.onFinish()
          }}
          onDuration={(e) => {
            console.log('onDuration ', e, rendervideoState.project.videos.videoList[0])
            // var per = {
            //   start: 0,
            //   end: 0
            // };
            var c = 0;
            rendervideoState.project.videos.videoList.map((item, index) => {

              return c = c + item.duration
            });
            console.log('rendervideoState.project.margeArr 1 ', rendervideoState.project.video);
            var t = {
              video: {
                //  ...rendervideoState.project.video,

                "filename": rendervideoState.project.video.filename,
                "path": rendervideoState.project.video.url,
                "videoId": "video_" + rendervideoState.project.video.videoId,
                Id: rendervideoState.project.video.videoId,
                video_currentTime: {
                  seconds: 0,
                  start: false,
                  bytimeline: false,

                },
                videoTiming: {
                  "startTime": 0,
                  "endTime": rendervideoState.project.video.videoTiming.endTime,
                  "duration": rendervideoState.project.video.videoTiming.duration
                },
                timelineTiming: {
                  "startTime": rendervideoState.project.video.timelineTiming.startTime,
                  "endTime": rendervideoState.project.video.timelineTiming.endTime,
                  "duration": rendervideoState.project.video.timelineTiming.duration
                }
              },

              margeArrdata: {
                id: rendervideoState.project.margeArr.length,
                
                actions: rendervideoState.project.videos.videoList.map((item, index) => {
                  // var hms =
                   '02:04:33';   // your input string
                  // var a = hms.split(':'); // split it at the colons
                  return {
                    ...item,
                    id: "Track" + index,
                    name: "Track" + index,
                    anotationId: index,
                    url: item.url,
                    start: rendervideoState.project.video.timelineTiming.startTime,
                    end: rendervideoState.project.video.timelineTiming.endTime,
                    /*   start: index === 0 ? 0 : rendervideoState.project.videos.videoList[index - 1].length,
                      end: index === 0 ? item.length : rendervideoState.project.videos.videoList[index - 1].length + item.length, */
                    effectId: "effect0",
                  }
                })


              }
            }
            console.log('rendervideoState.project.margeArr ', t)
            if (rendervideoState.project.margeArr.length === 0) {
              dispatch(SET_PROJECT_VIDEOaction(t))
            }

          }}
          config={{
            file: {
              attributes: {
                // crossorigin: "anonymous",
                controlsList: 'nodownload'

              },
            },
          }}
        />

        {state.shoCanvas && <canvas id="edit_video_canvas" ref={editCanvasRef} width={state.canvasWidth} height={state.canvasHeight} style={{ position: 'absolute', left: 0 }}  ></canvas>}

        <Box
          sx={{
            // display: "flex",
            // width: '100%',
            // justifyContent: 'space-around',
            // backgroundColor: '#FFFFFF',
            // border: '1p solid #FFFFFF'
          }}
        >

          {/* <ControlBar
            ref={controlsRef}
            onSeek={handleSeekChange}
            onSeekMouseDown={handleSeekMouseDown}
            onSeekMouseUp={handleSeekMouseUp}
            onDuration={handleDuration}
            onRewind={handleRewind}
            onPlayPause={handlePlayPause}
            onFastForward={handleFastForward}
            playing={playing}
            played={played}
            elapsedTime={elapsedTime}
            totalDuration={totalDuration}
            onMute={hanldeMute}
            muted={muted}
            onVolumeChange={handleVolumeChange}
            onVolumeSeekDown={handleVolumeSeekDown}
            onChangeDispayFormat={handleDisplayFormat}
            playbackRate={playbackRate}
            onPlaybackRateChange={handlePlaybackRate}
            onToggleFullScreen={toggleFullScreen}
            volume={volume}

          /> */}
          {/* <SkipPreviousIcon sx={{ fontSize: 33 }} color={'#FFFFFF'} onClick={() => { }} />
          <PlayArrowIcon sx={{ fontSize: 33 }} color={'#FFFFFF'} onClick={handlePlay} />
          <PauseIcon sx={{ fontSize: 33 }} color={'#FFFFFF'} onClick={handlePause} />
          <SkipNextIcon sx={{ fontSize: 33 }} color={'#FFFFFF'} onClick={() => { }} /> */}
        </Box>

      </Box>

    </>

  );
};

export default RenderPlayer;
