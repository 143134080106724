import {
    deleteApi,
    getApi,
    postApi,
    putApi
    
} from "./api-interface";

export const createVideoEditing = async (data) => {
    return await postApi(`/video_editing/add_videos`, data);
};
export const GetVideoEditingDetail = async (id) => {
    return await getApi("/video_editing/get_details/" + id);
};
export const GetAllVideoEditings = async (params) => {
    return await getApi("/video_editing/getAll?"+params);
};
export const updateVideoEditing = async (id, data) => {
    return await putApi("/video_editing/update/" + id, data);
};
export const deleteVideoEditing = async (id) => {
    return await deleteApi("/video_editing/delete/" + id);
};