import React from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import ContactUsComponent from "../../components/ContactUsComponent/ContactUsComponent";
import ContactListComponent from "../../components/ContactListComponent/ContactListComponent";

const ContactUs = () => {
  return (<>
      <div className="contactus rightsidebar userlistmain" data-aos="slide-up">
      {/* <div className="usermainaddbtn foralleditors">
        <Link to="/user/add/user" className="btn">

        </Link>
      </div> */}


      <div className="userstab">

        <Tabs 
          defaultActiveKey="contactus"
          id="noanim-tab-example"
          className=""
        >
          <Tab eventKey="contactus" title={<span> <PeopleAltOutlinedIcon /> CONTACT US</span>}  >
            <ContactUsComponent />
          </Tab>
          <Tab eventKey="contactRequest" title={<span> <AdminPanelSettingsOutlinedIcon /> CONTACT REQUEST </span>} >
            <ContactListComponent />
          </Tab>
        </Tabs>
      </div>
    </div>
  </>)
};

export default ContactUs;
