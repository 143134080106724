import React from "react";
import QuizesUser from "../../components/QuizesUser/QuizesUser";

const AssignmentsQuizesUser = () => {
  
  return (
    <>
    <QuizesUser></QuizesUser>
    </>
  );
};

export default AssignmentsQuizesUser;
