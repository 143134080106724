import { createAsyncThunk } from '@reduxjs/toolkit';
// import { apiCall } from "../../../API";
import { apiBaseUrl } from "../../../utils/constants";
import {
    postApi,
} from "../../../API/api-interface";

import { toast } from "react-toastify";

import {SHOW_subimtLoading} from './../../Reducers/rendervideoReducer';

export const videofromActions = createAsyncThunk( 
    'rendervideoReducer/videofromActions',
    async (data) => {
        //(path, method, data,auth,fileUpload)

        console.log('rendervideoReducer/videofromActions 1 -', data);
        // const auth = {
        //     "userToke": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJJZCI6MSwiUm9sZUlkIjoxLCJOYW1lIjoiUkMiLCJFbWFpbCI6InIuYy5wYXRlbEBnbWFpbC5jb20iLCJQYXNzd29yZCI6IiQyYiQxMCRTRDFkZlN4dTBEeDJZNnFyREhkc1VPRldJdm1ndHdFLkF6WFRLdFc0a2VIUHZkaDZGWjZKZSIsImlhdCI6MTY1OTk2MzM5NH0.H6AzHByxewDucR2Uwz4orephioQc-SMWk35uH9yehKk"
        // }

        //     return new Promise((myResolve, myReject) =>{
        //         let x = 0;
        //     var demo = {
        //     "data": [
        //         {
        //             "Id": 1,
        //             "userId": 1,
        //             "groupId": 1,
        //             "sequence": 1,
        //             "filename": "video1.mp4",
        //             "url": "https://ak.picdn.net/shutterstock/videos/311566/preview/stock-footage-speedometer-closeup-in-seconds-ntsc.mp4",
        //             "formate": "video/mp4",
        //             "editType": "Original",
        //             "length": 15.023,
        //             "Updated_Date": "2022-08-02T11:33:12.245Z",
        //             "Created_Date": "2022-08-02T11:33:12.245Z"
        //         },
        //         {
        //             "Id": 2,
        //             "userId": 1,
        //             "groupId": 1,
        //             "sequence": 1,
        //             "filename": "video2.mp4",
        //             "url": "https://ak.picdn.net/shutterstock/videos/1086253/preview/stock-footage-father-with-son.mp4",
        //             "formate": "video/mp4",
        //             "editType": "Original",
        //             "length": 14.414,
        //             "Updated_Date": "2022-08-02T11:33:12.245Z",
        //             "Created_Date": "2022-08-02T11:33:12.245Z"
        //         }
        //     ],
        //     "message": "Video uploaded successfully",
        //     "status": 200
        // }
        //       // The producing code (this may take some time)

        //         setTimeout(()=>{ 

        //             console.log('set Time')
        //             myResolve({
        //             "data": [
        //                 {
        //                     "Id": 1,
        //                     "userId": 1,
        //                     "groupId": 1,
        //                     "sequence": 1,
        //                     "filename": "video1.mp4",
        //                     "url": "https://ak.picdn.net/shutterstock/videos/311566/preview/stock-footage-speedometer-closeup-in-seconds-ntsc.mp4",
        //                     "formate": "video/mp4",
        //                     "editType": "Original",
        //                     "length": 15.023,
        //                     "Updated_Date": "2022-08-02T11:33:12.245Z",
        //                     "Created_Date": "2022-08-02T11:33:12.245Z"
        //                 },
        //                 {
        //                     "Id": 2,
        //                     "userId": 1,
        //                     "groupId": 1,
        //                     "sequence": 1,
        //                     "filename": "video2.mp4",
        //                     "url": "https://ak.picdn.net/shutterstock/videos/1086253/preview/stock-footage-father-with-son.mp4",
        //                     "formate": "video/mp4",
        //                     "editType": "Original",
        //                     "length": 14.414,
        //                     "Updated_Date": "2022-08-02T11:33:12.245Z",
        //                     "Created_Date": "2022-08-02T11:33:12.245Z"
        //                 }
        //             ],
        //             "message": "Video uploaded successfully",
        //             "status": 200
        //         });



        //     }, 3000);
        //       });

        // let res = await apiCall('files/upload', 'POST', data, auth, true)
        // .then((response) => {
        //     if (response.status === 200) {

        //         console.log('response.status ', response.data)
        //         return {
        //             ...response.data,
        //             data: response.data.data.map((item) => {
        //                 console.log('item ', item)
        //                 return {
        //                     ...item,
        //                     url: `http://localhost:8000/public/videos/UploadedVideos/1660036847925.mp4`
        //                 }
        //             }),
        //             status_code: response.status
        //         };
        //     }
        // })
        // .catch((error) => {
        //     return error
        // })

        // console.log(' res -->',res)
        // return apiCall('files/upload', 'POST', data, auth, true)
        //     .then((response) => {
        //         if (response.status === 200) {

        //             console.log('response.status ', response.data)
        //             return {
        //                 ...response.data,
        //                 data: response.data.data.map((item) => {
        //                     console.log('item ', item)
        //                     var hms = item.length;   // your input string
        //                     var a = hms.split(':'); // split it at the colons
        //                     var seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
        //                     return {
        //                         ...item,
        //                         length: seconds,
        //                         url: `http://localhost:8000/${item.url}`
        //                     }
        //                 }),
        //                 status_code: response.status
        //             };
        //         }
        //     })
        //     .catch((error) => {
        //         return error
        //     })
    }
)

export const cutvideosubmitActions = async (data) => {
    var file = {
        "videoId": data.videoId,
        "start": data.start,
        "starttime": data.starttime,
        "end": data.end,
        "current_video_url": data.current_video_url
    }
    console.log('cutvideo -', file);
    
    return postApi('/video/cutvideo', file); 
        
}

export const videosubmitActions = createAsyncThunk(
    'rendervideoReducer/videosubmitActions',
    async (data) => {
        SHOW_subimtLoading(true);
        // const auth = {
        //     "userToke": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJJZCI6MSwiUm9sZUlkIjoxLCJOYW1lIjoiUkMiLCJFbWFpbCI6InIuYy5wYXRlbEBnbWFpbC5jb20iLCJQYXNzd29yZCI6IiQyYiQxMCRTRDFkZlN4dTBEeDJZNnFyREhkc1VPRldJdm1ndHdFLkF6WFRLdFc0a2VIUHZkaDZGWjZKZSIsImlhdCI6MTY1OTk2MzM5NH0.H6AzHByxewDucR2Uwz4orephioQc-SMWk35uH9yehKk"
        // }
        var anotation = []
        var Text = []
        var Quiz = []
        // var Videos = []

         
        data.margeArr.map((track, trackIndex) => {
            console.log(track);
           return track.actions.forEach((action, actionIndex) => {
                if (action.type !== undefined) {
                    if (action.type === 'ICON') {
                        anotation.push({
                            ...action,
                           
                            "anotationId": action.id,
                            "imageUrl": action.imageUrl,
                            "scale_X": action.x,
                            "scale_Y": action.y,
                            "x": action.x,
                            "y": action.y,
                            "videoTiming": {
                                "startTime": action.start,
                                "endTime": action.end,
                                "duration": action.end - action.start
                            },
                            "timelineTiming": {
                                "startTime": action.start,
                                "endTime": action.end,
                                "duration": action.end - action.start
                            }
                        })
                    }
                    else if (action.type === 'TEXT' || action.type === 'CAPTION') {
                        Text.push({
                            ...action,
                            "textId": action.id,
                            "text": action.text,
                            "fontsize": action.fontSize,
                            "fontcolor": "#FFFFFF",
                            "scaleX": action.scaleX,
                            "scaleY": action.scaleY,
                            "x": action.x,
                            "y": action.y,    
                            "startTime": action.start,
                            "endTime": action.end

                        })
                    }
                    else if (action.type === 'QUIZ') {
                        Quiz.push({
                            ...action,
                            
                            "anotationId": action.id,
                            "textId": action.id,
                            "text": action.text,
                            "fontsize": 32,
                            "fontcolor": "#FFFFFF",
                            "startTime": action.start,
                            "endTime": action.end,
                            "questionlist": action.questionlist
                        })
                    }
                }

            })
        })
        
        console.log("Post Api : ");
        // if(anotation.length > 0 || Text.length > 0 || Quiz.length > 0)
        // {
            var file = {
                "video": {
                    "filename": data.video.filename,
                    "sourcePath": data.video.path.replace(apiBaseUrl+'/','')
                },
                "videoId": data.video.videoId,
                "anotation": [...anotation],
                "string": [...Text],
                "quiz": [...Quiz],
                "margeArr": JSON.stringify(data.margeArr),
                "videosize": {
                    "height":data.videosize.height,
                    "width":data.videosize.width
                }
            }
            // var filename = data.video.filename;
            console.log('rendervideoReducer/videosubmitActions 2 -', file);
            
            return postApi('/video/annotation', file) 
                .then((response) => {
                    if (response.status === 200) {
    
                        console.log('response.status ', response.data)
                        toast.success("Video Edited Successfully");
                        // setTimeout(() => {
                        //     window.location.href = "/videolist";
                        // },100);
                        // fetch(apiBaseUrl+'/'+response.data.data).then(response => {
                        //     response.blob().then(blob => {
                        //         // Creating new object of PDF file
                        //         const fileURL = window.URL.createObjectURL(blob);
                        //         // Setting various property values
                        //         let alink = document.createElement('a');
                        //         alink.href = fileURL;
                        //         console.log("file.quiz",file.quiz);
                        //         if(file.quiz.length > 0)
                        //         {
                        //             alink.download = "quiz"+file.videoId+".zip";
                        //         }
                        //         else 
                        //         {
                        //             alink.download = filename;
                        //         }
                        //         alink.click();
                                
    
                        //     })
                        // }) 
    
                    }
                })
                .catch((error) => {
                    console.log("error",error);
                    return error
                })
        // }
        // else 
        // {
        //     toast.error("Please add the video changes !!");
        // }
        
    }
)
