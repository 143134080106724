import React, { useState } from "react";
import "../../index.css";
import {
    List,
    ListItem,
    ListItemText,
    ListItemButton,
    ListItemIcon,
    Collapse,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";

const Submenu = (props) => {

    const [open, setOpen] = React.useState(false);
    const location =useLocation()
    const [active, setActive] = useState(null)
    const handleClick = () => {
        setOpen(!open);
    };

    
    return (
        <>
            <ListItem className="white_text tiem-lists">
                <div className={props?.isActiveButton&&"active-button"}>
                <ListItemButton onClick={handleClick} style={{paddingTop:4, paddingBottom:4}}>
                    <ListItemIcon className="white_text">
                        {props.item.icon}
                    </ListItemIcon>
                    <ListItemText primary={props.item.title} />
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                </div>
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit   style={{paddingLeft:70}}>
                {props.item.submenu.map((item, index) => {
                 active === item&&   console.log(item,'item',location.pathname)
                    const isActive=item.link===location.pathname
                    return (
                        <Link key={index} to={item.link} className={`tiem-list ${isActive && 'active'}`} onClick={() => setActive(item)}>
                            <List
                                component="div"
                                disablePadding
                                className="white_text tiem-list"
                                
                           style={{color:'#fff', fontSize:10 }} >
                                <ListItemButton sx={{ pl: 4 }} style={{color:'#fff', fontSize:10,paddingTop:4, paddingBottom:4}}>
                                    {/* <ListItemIcon className="black_text tiem-list">
                                        <StarBorder />
                                    </ListItemIcon> */}
                                    <ListItemText primary={item.subtitle} />
                                </ListItemButton>
                            </List>
                        </Link>
                    );
                })}
            </Collapse>
        </>
        // <>
        //     {toggleDropdown &&
        //         submenu?.map((i) => (
        //             <Link to={i.link}>
        //                 <div className=" sidebar-subMenu flex ">
        //                     <h1 className="sidebar-h1">{i.subtitle}</h1>
        //                 </div>
        //             </Link>
        //         ))}
        // </>
    );
};

export default Submenu;
