import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos";
import addImageIcon from "../../../src/image/addicon.png";
import { Box, Button } from "@mui/material";
import { mobileDocDetails, reportDetails } from "../../API";
import { useLocation, useSearchParams } from "react-router-dom";
import { apiBaseUrl, appVideoUri, imageUrl } from "../../utils/constants";
import { useNavigate } from "react-router-dom";

const MobileSOPDetailsAction = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [sopDetail, setSopDetail] = useState({});
  const [mobileSopId, setMobileSopId] = useState(0);
  const [selectedPage, setSelectedPage] = useState(0);
  useEffect(() => {
    AOS.init({
      easing: "ease-out-sine",
      duration: 800,
    });
    AOS.refresh();
    // viewReport()
  }, []);

  useEffect(() => {
    const id = location.pathname.split("/mobile-sop/")[1];
    if (id) {
      setMobileSopId(id);
      viewDetails(id);
    }
  }, [location]);

  const viewDetails = async (id) => {
    const res = await mobileDocDetails(id);
    if (res && res.data && res.data.data) {
      setSopDetail(res.data.data);
      if (res.data.data.page && res.data.data.page[0]) {
        setSelectedPage(res.data.data.page[0]);
      }
    }
  };

  return (
    <div>
      <div className="rightsidebar">
        <div className="flex-row text-white  w-full font-comfortaa desbordcard">
          <div class="d-flex ">
            <div class="cursor-pointer" onClick={() => navigate(-1)}>
              <i
                class="fa fa-arrow-left back_arrow_style"
                style={{ margin: 10 }}
                aria-hidden="true"
              ></i>
            </div>
            <div className="handleimg"></div>
          </div>
          <div className="flex aling-item-center ">
            <p class="titileditor">View Mobile SOP</p>
          </div>
          <div className=" backgtable editpdfpage wrapper-14">
            <div className="row">
              <div className="col-9 mt-4">
                <Box
                  id="ppt-container"
                  component={"div"}
                  sx={{
                    width: "fit-content",
                    minHeight: "100%",
                    width: "100%",
                    display: "flex",
                    backgroundColor: "white",
                    border: "1px solid #FFFFFF",
                    borderRadius: "7px",
                    // overflow: 'hidden',
                    flexDirection: "column",
                    alignItems: "center",
                    // justifyContent: 'center',
                    padding: "0%",
                    position: "relative",
                  }}
                >
                  {selectedPage && selectedPage.type === "image" && (
                    <img
                      src={
                        apiBaseUrl +
                        "/" +
                        imageUrl +
                        "/" +
                        selectedPage.filename
                      }
                      style={{ maxHeight: "500px" }}
                    ></img>
                  )}
                  {selectedPage && selectedPage.type === "video" && (
                    <video
                      controls="controls"
                      preload="metadata"
                      autoPlay="true"
                      src={
                        apiBaseUrl +
                        "/" +
                        appVideoUri +
                        "/" +
                        selectedPage.filename
                      }
                      style={{ width: "100%", maxHeight: "500px" }}
                    ></video>
                  )}
                  <div style={{color: 'black'}}>{selectedPage.notes}</div>
                </Box>
              </div>
              <div className="col-3 pl-0">
                <div className=" backgtable editpdfpage mt-2">
                  <div className="toolbaronright">
                    <div className="w-full file_container custom-scroller">
                      {sopDetail.page &&
                        sopDetail.page.length > 0 &&
                        sopDetail.page?.map((row, idx) => {
                          // console.log(row)
                          return (
                            <div className="bg-white">
                              <div
                                className="splited_ppt_file m-2"
                                key={idx}
                                style={{
                                  border: "1px solid lightgray",
                                  borderRadius: "5px",
                                }}
                                onClick={() => {
                                  setSelectedPage(row);
                                }}
                              >
                                {row.type === "image" && (
                                  <img
                                    src={
                                      apiBaseUrl +
                                      "/" +
                                      imageUrl +
                                      "/" +
                                      row.filename
                                    }
                                  ></img>
                                )}
                                {row.type === "video" && (
                                  <video preload="metadata">
                                    <source
                                      src={
                                        apiBaseUrl +
                                        "/" +
                                        appVideoUri +
                                        "/" +
                                        row.filename +
                                        "#t=1"
                                      }
                                      type="video/mp4"
                                    />
                                  </video>
                                )}
                              </div>
                              <p
                                style={{
                                  color: "#2699fb",
                                  textAlign: "center",
                                  fontSize: 12,
                                  maxWidth: 240,
                                  fontFamily: "Arial",
                                  fontWeight: 400,
                                }}
                              >
                                Page {idx + 1}
                              </p>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileSOPDetailsAction;
