import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos";

import { reportList, reportDelete } from "../../API";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { Stack } from "react-bootstrap";

const Reports = () => {
  const navigate = useNavigate();
  const [report, setreport] = useState({
    data: [],
    count: 0,
  });
  const [options, setOptions] = useState({
    page: 0,
    pageSize: 15,
    orderBy: "id",
    orderType: "desc",
  });

  useEffect(()=>{
    AOS.init({
      easing: "ease-out-sine",
      duration: 800,
    });
    AOS.refresh();
  }, [])

  useEffect(() => {
    
    reportlistfunction();
  }, [options]);

  const reportlistfunction = async () => {
    const params = `orderby=${options.orderBy}&ordertype=${options.orderType}&limit=${options.pageSize}&page=${options.page}`;

    const report = await reportList(params);

    if (report.data.data && report.data.data.count) {
      const list = report.data.data.report;
      if (list.length > 0) setreport({
        data: list,
        count: report.data.data.count
      });
    } else {
      setreport({
        data: [],
        count: 0,
      });
    }
  };

  const removereport = async (id, e) => {
    try {
      if (window.confirm("Are you sure you want to delete?")) {
        const reportDel = await reportDelete(id);
        if (reportDel.data.success) {
          toast.success("Delete successfully ", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          reportlistfunction();
        }
      }
    } catch (e) {
      toast.error("Something went wrong");
    }
  };

  const viewReport = async (e, id) => {
    navigate(`/report/view/${id}`);
  };

  const setPage = (data) => {
    setOptions({
      ...options,
      page: data,
    });
  };
  const setPageSize = (data) => {
    setOptions({
      ...options,
      pageSize: data,
    });
  };
  const handleSortModelChange = (data) => {
    if (data && data[0]) {
      setOptions({
        ...options,
        orderBy: data[0].field,
        orderType: data[0].sort,
      });
    } else {
      setOptions({
        ...options,
        orderBy: "id",
        orderType: "desc",
      });
    }
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      sortable: false,
      renderCell: (cellValues) => {
        return <>{cellValues.row.user?.name}</>;
      },
      width: 300,
    },
    {
      field: "Title",
      headerName: "SOP Title",
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <>
            {cellValues.row.type && cellValues.row[cellValues.row.type]
              ? cellValues.row[cellValues.row.type].title
              : ""}
          </>
        );
      },
      width: 450,
    },
    {
      field: "email",
      headerName: "Email",
      sortable: false,
      renderCell: (cellValues) => {
        return <>{cellValues.row.user?.email}</>;
      },
      width: 420,
    },
    {
      field: "id",
      headerName: "Action",
      width: 250,
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <Stack spacing={2} direction="row" className="editbut" style={{ flexDirection: "row" }}>
            <Button className="nocolor" variant="contained" onClick={(e) => viewReport(e, `${cellValues.row.id}`)}>
              <VisibilityIcon />
            </Button>{" "}
            <Button
              className="nocolor delete"
              color="error"
              variant="contained"
              onClick={(e) => removereport(`${cellValues.row.id}`, e)}
            >
              <DeleteIcon />
            </Button>
          </Stack>
        );
      },
    },
  ];

  return (
    <div className="rightsidebar userlistmain" data-aos="slide-up">
      <div className="usermainaddbtn foralleditors">
        {/* <Link to="/user/add/user" className="btn"></Link> */}
      </div>

      <div className="userstab">
        <Tabs
          defaultActiveKey="pdf"
          transition={false}
          id="noanim-tab-example"
          className="mt-5"
        >
          <Tab
            eventKey="video"
            title={
              <span>
                {" "}
                <AdminPanelSettingsOutlinedIcon />
                Report a problem
              </span>
            }
          ></Tab>
        </Tabs>
      </div>

      <div className="flex-row bg-primary2 text-white w-full font-comfortaa user-list">
        <div className="bordernone" style={{ height: 500, width: "100%" }}>
          <DataGrid
            className="painnation"
            rows={report.data}
            disableColumnMenu
            columns={columns}
            rowsPerPageOptions={[15, 50, 100]}
            getRowHeight={() => "auto"}
            rowCount={report.count}
            paginationModel={options}
            pagination
            paginationMode="server"
            page={options.page}
            pageSize={options.pageSize}
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            onSortModelChange={handleSortModelChange}
          />
        </div>
        {/* <div className="grid backgtable">
          <div className="w-full flex">
            <table
              className="flex w-full text-black text-center items-center font-comfortaa"
              style={{ justifyContent: "space-evenly" }}
            >
              <input style={{ width: "20%" }} type="checkbox" />

              <th className="py-4 " style={{ width: "10%" }}>
                Name
              </th>
              <th className="py-4 " style={{ width: "30%" }}>
                SOP Title
              </th>
              <th className="py-4 " style={{ width: "60%" }}>
                Email
              </th>
              <th className="py-2 " style={{ width: "10%" }}>
                View
              </th>
              <th className="py-4 " style={{ width: "10%" }}>
                Delete
              </th>
            </table>
          </div>
          {false ? (
            <div className="flex backgtablecolor">
              <table className="flex w-full  text-black text-center">
                <tr className="flex w-full">
                  <td colSpan={7} className="text-center w-full">
                    Please wait ...{" "}
                  </td>
                </tr>
              </table>
            </div>
          ) : report?.length ? (
            report?.map((el, i) => (
              <div className="flex backtablecolor ">
                <table className="flex w-full  text-black text-center">
                  <tr
                    className="flex w-full"
                  >
                    <td className="py-2" style={{ width: "20%" }}>
                      <input style={{marginLeft:"42px"}} type="checkbox" />
                    </td>
                    <td className="py-4" style={{ width: "10%" }}>
                      {el?.user?.name}
                    </td>
                    <td className="py-4" style={{ width: "30%" }}>
                      { el[el.type]?.title }
                    </td>
                    <td className="py-4" style={{ width: "60%" }}>
                      <p>
                      {el?.user?.email}
                      </p>
                    </td>
                    <td
                      className="py-2"
                      style={{ width:"10%"}}
                    >
                      <Button
                        variant="contained"
                        color="success"
                        onClick={(e) => viewReport(e, `${el.id}`)}
                      >
                        View
                      </Button>
                    </td>
                    <td className="py-2" style={{ width:"10%"}}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={(e) => removereport(`${el.id}`, e)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                </table>
              </div>
            ))
          ) : (
            <div className="flex backtablecolor">
              <table className="flex w-full  text-black text-center">
                <tr className="flex w-full">
                  <td colSpan={7} className="text-center w-full">
                    No data found{" "}
                  </td>
                </tr>
              </table>
            </div>
          )}
        </div> */}
      </div>
    </div>
  );
};

export default Reports;
