import React, { useContext } from "react";
import "../../index.css";
// import human from "../../assets/human.png";
// import { sidebarRoutes } from "../../routes/SidebarRoutes";
// import MenuItem from "./MenuItem";
import MenuItem from './components/MenuItem'


// import MenuIcon from '@mui/icons-material/Menu';
// import { useSelector, useDispatch } from "react-redux";
// import { DRAWERactions } from '../../redux/Reducers/headerReducer'
import AppContext from "../../appContext";
const Sidebar = () => {
  const contextApi = useContext(AppContext)
  const { sidebar } = contextApi.state;
  const { setSidebar, logout } = contextApi;
  const sidebarToggle = () => {
    setSidebar(!sidebar) 
  }



  return (

    <>
    <MenuItem logout={logout} sidebarToggle={sidebarToggle}/>
    </>
    // <div className=" main-sidebar">
    //   <div className="flex flex-row justify-evenly ">
    //     <div className="sidebar-logo">
    //       <img src={human} className="sidebar-human " alt="img"></img>
    //     </div>
    //     <div className="sidebar-userName ">
    //       <h1>XYZ user</h1>
    //       <h2 className="font-thin ">city, District name</h2>
    //     </div>
    //   </div>
    //   <div className="font-comfortaa cursor-pointer ">
    //     {sidebarRoutes?.map((i) => (
    //       <MenuItem menuItem={i} />
    //     ))}
    //   </div>
    // </div>
  );
};

export default Sidebar;