import React,{useEffect,useState,useRef} from "react";
import {
  Box,
  List
} from '@mui/material';
import Listitem from "./components/Listitem";
const TimeStampsNew = (props) => {

   const timeStampElement = useRef(); 
   const [isScrollHere, setIsScrollHere] = useState(false)
  

  useEffect(() => {
    if(!isScrollHere) {
      console.log('useEffect 11')
    // const element = document.getElementById("box");
    timeStampElement.current.scrollTo({
                top: props.scrollTop,
                behavior: 'smooth',
            });
            console.log("scrolled !!");
    }
    setTimeout(() => {
      setIsScrollHere(false)
    }, 500)
    

      
  },[props.scrollTop])

  return (
    <Box component={'div'}  sx={{marginBottom:'2px',marginTop: "62px",paddingBottom:'10px'}}>
      <div style={{ width: '100%', height: '270px',paddingTop: '10px',overflow:'auto'}} ref={timeStampElement}
      onScroll={(e) => {
        setIsScrollHere(true)
          const target = e.target;
          props.setScrollTop(target.scrollTop);
      }}>
        <List sx={{ height: '195px',  p: 0 }} >
          {/* <PerfectScrollbar> */}
          {
            props.stamps.map((item, index) => {
              return (<Listitem key={index} stamps={item} />)
            })
          }
          {/* </PerfectScrollbar> */}
        </List>
      </div>
    </Box>

  )
};

export default TimeStampsNew;
