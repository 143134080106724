import React, { useEffect, useState } from "react";
import "../../styles/pdfcomponent.css";
import "../../styles/dashbord.css";
import "../../styles/HelveticaNeueFont/stylesheet.css";
import "../../styles/Arial-font/stylesheet.css";
import StraightIcon from '@mui/icons-material/Straight';
import {
  Container,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link } from "react-router-dom";
import { toast } from "react-toastify"
import moment from "moment"
import { fetchDashboardDataAPI } from "../../API"
import { apiBaseUrl } from "../../utils/constants";
import AOS from "aos";
import "aos/dist/aos.css";
import Dashchart from "../charts/graphchart"
const BoxStyle = {
  borderRadius: '4px',
  padding: '3%',
  backgroundColor: '#fff',
  boxShadow: '0px 6px 9px -5px rgb(196, 196, 196)'
}


const DashboardComponent = () => {

  const [dashboardData, setDashboardData] = useState({
    loading: false,
    data: null,
    message: "",
    status: 0
  })

  const fetchDashboardData = async () => {
    try {
      setDashboardData((v) => ({
        ...v,
        loading: true
      }))

      const res = await fetchDashboardDataAPI()

      if (res.status == 200) {
        // toast.success(res.data.message); 
        setDashboardData((v) => ({
          ...v,
          loading: false,
          data: res.data.data
        }))

      }
    } catch (error) {
      toast.error(error.response.data.message || error.response.statusText);
      setDashboardData((v) => ({
        ...v,
        loading: false,
        data: null
      }))
    }
  };

  useEffect(() => {
    fetchDashboardData()
  }, []);
  useEffect(() => {
    AOS.init({
      easing: 'ease-out-sine',
      duration: 800,
    });
    AOS.refresh();
  }, []);

  const { data } = dashboardData;
  const latestUsers = data?.latestTenUsers

  return (
    <>
    <div className="rightsidebar"  data-aos="slide-up">
      <Container maxWidth="100" h={'Fullscreenl'} md={12}  className='deshboard-meneg'>

        {/* <Grid md={3} sx={{ p: 1 }}>
          <Box style={BoxStyle}>
            <Typography align="left" variant="h5" style={TypoCommStyle}>Total Users</Typography>
            <Typography sx={{ fontWeight: 'bold', fontSize: '20px' }} style={TypoCommStyle}>257</Typography>
            <Typography style={TypoCommStyle}>6.2%</Typography>
            <Typography style={TypoCommStyle}>Since Last Month</Typography>
          </Box>
        </Grid>
        <Grid md={3} sx={{ p: 1 }}>
          <Box style={BoxStyle}>
            <Typography align="left" variant="h5" style={TypoCommStyle}>Active Users</Typography>
            <Typography sx={{ fontWeight: 'bold', fontSize: '20px' }} style={TypoCommStyle}>257</Typography>
            <Typography style={TypoCommStyle}>6.2%</Typography>
            <Typography style={TypoCommStyle}>Since Last Month</Typography>
          </Box>
        </Grid>
        <Grid md={3} sx={{ p: 1 }}>
          <Box style={BoxStyle}>
            <Typography align="left" variant="h5" style={TypoCommStyle}>Categories</Typography>
            <Typography sx={{ fontWeight: 'bold', fontSize: '20px' }} style={TypoCommStyle}>257</Typography>
            <Typography style={TypoCommStyle}>6.2%</Typography>
            <Typography style={TypoCommStyle}>Since Last Month</Typography>
          </Box>
        </Grid>
        <Grid md={3} sx={{ p: 1 }}>
          <Box style={BoxStyle}>
            <Typography align="left" variant="h5" style={TypoCommStyle}>Courses</Typography>
            <Typography sx={{ fontWeight: 'bold', fontSize: '20px' }} style={TypoCommStyle}>257</Typography>
            <Typography style={TypoCommStyle}>6.2%</Typography>
            <Typography style={TypoCommStyle}>Since Last Month</Typography>
          </Box>
        </Grid> */}

        <div className="desbordcard">
          <div className="handleimg"></div>
          <div className="dashbordcadrmain">
            <div className="onecard">
              <div className="card">
                <h5 className="card-title">Total Users </h5>
                <div className="dashcarddetails">
                <h3 className="card-text">{data?.totalUsers || 0}</h3>
                {/* <h4 className="text-success"> <StraightIcon /> -</h4> */}
                {/* <p>Since Last month</p> */}</div>
              </div>
            </div>
            <div className="onecard">
              <div className="card">
                <h5 className="card-title">Total Admins</h5>
                <div className="dashcarddetails">
                <h3 className="card-text">{data?.totalAdminUsers || 0}</h3>
                {/* <h4 className="text-success" > <StraightIcon /> 5</h4> */}
                {/* <p>Since Last Month</p> */}</div>
              </div>
            </div>
            <div className="onecard">
              <div className="card">
                <h5 className="card-title">Total PDF SOP </h5>
                <div className="dashcarddetails">
                <h3 className="card-text">{data?.totalPdf || 0}</h3>
                {/* <h4 className="text-danger"> <StraightIcon /> -</h4> */}
                {/* <p>Since Last Month</p> */}</div>
              </div>
            </div>
            <div className="onecard">
              <div className="card">
                <h5 className="card-title">Total Video SOP</h5>
                <div className="dashcarddetails">
                <h3 className="card-text">{data?.totalVideo || 0}</h3>
                {/* <h4 className="text-success"> <StraightIcon /> -</h4> */}
                {/* <p>Since Last Month</p> */}</div>
              </div>
            </div>
            <div className="onecard">
              <div className="card">
                <h5 className="card-title">Total PPT SOP</h5>
                <div className="dashcarddetails">
                <h3 className="card-text">{data?.totalPpt || 0}</h3>
                {/* <h4 className="text-success"> <StraightIcon /> -</h4> */}
                {/* <p>Since Last Month</p> */}</div>
              </div>
            </div>
          </div>
          <div className="dashbordcadrmain">
            <div className="onecard">
              <div className="card">
                <h5 className="card-title">Published SOP</h5>
                <div className="dashcarddetails">
                <h3 className="card-text">{data?.totalPublish || 0}</h3>
                {/* <h4 className="text-success" > <StraightIcon /> 5</h4> */}
                {/* <p>Since Last Month</p> */}</div>
              </div>
            </div>
            <div className="onecard">
              <div className="card">
                <h5 className="card-title">Unpublished SOP </h5>
                <div className="dashcarddetails">
                <h3 className="card-text">{data?.totalUnPublish || 0}</h3>
                {/* <h4 className="text-success"> <StraightIcon /> -</h4> */}
                {/* <p>Since Last Month</p> */}</div>
              </div>
            </div>
            <div className="onecard">
              <div className="card">
                <h5 className="card-title">Contact Us </h5>
                <div className="dashcarddetails">
                <h3 className="card-text">{data?.contactUsCount || 0}</h3>
                {/* <h4 className="text-danger"> <StraightIcon /> -</h4> */}
                {/* <p>Since Last Month</p> */}</div>
              </div>
            </div>
            <div className="onecard">
              <div className="card">
                <h5 className="card-title">Reports</h5>
                <div className="dashcarddetails">
                <h3 className="card-text">{data?.reportCount|| 0}</h3>
                {/* <h4 className="text-success"> <StraightIcon /> -</h4> */}
                {/* <p>Since Last Month</p> */}</div>
              </div>
            </div>
            <div className="onecard">
              <div className="card">
                <h5 className="card-title">Assingment & Quiz</h5>
                <div className="dashcarddetails">
                <h3 className="card-text">{data?.quizCount|| 0}</h3>
                {/* <h4 className="text-success"> <StraightIcon /> -</h4> */}
                {/* <p>Since Last Month</p> */} </div>
              </div>
            </div>
          </div>
          <div className="mainflex">
            <div className="chart">
              <div className="charttitle">
                <p>GRAPHICAL REPRESENTATION</p>
                <Link to="/user/list/all" className="viewall"><ArrowForwardIosIcon/></Link>
                </div>
              <Dashchart/>
              
            </div>
            <div className="alluser">
            <div className="userheding">
              <h5>LATEST USERS</h5> <Link to="/user/list/all" className="viewall"><ArrowForwardIosIcon/></Link>
            </div>
            <ul>
              {latestUsers?.map(el => (
              
              <li key={`user-${el.id}`}><div className="user">
                {/* <span className="userphoto">{el.name.charAt(0).toUpperCase()}</span> */}
                <span className="userphoto" style={{overflow:'hidden'}}>
                {Boolean(el?.profileImage)
                        ?
                        <img src={apiBaseUrl + "/public/images/UploadedImages/" + el?.profileImage} />
                        :
                        el?.name.charAt(0).toUpperCase()}
                        </span>
                <p>{el.name}</p>
                <span>{moment(el.createdAt).format("MMM DD")}</span>
              </div>
              </li>
              ))}


            </ul>

            

          </div>
          </div>


        </div>
      </Container>
      </div>
    </>
  );
};

export default DashboardComponent;
