import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import {
    Box,
    OutlinedInput,
    IconButton,
    InputAdornment
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { EDITTEXTaction, DELETEactions } from '../../../../../../redux/Reducers/rendervideoReducer';
export default function InputBox(props) {
    const [textfield, setTextfield] = React.useState(null);
    const rendervideostate = useSelector(state => state.rendervideoReducer);
    const dispatch = useDispatch();
    const onHandleChange = (text) => {
        console.log('rendervideostate.project.margeArr[key].actions', rendervideostate.project.margeArr, text)
        console.log('newArr 1', rendervideostate.project.margeArr)
        var newArr = rendervideostate.project.margeArr.map((i, Tindex) => {

            return {
                ...i,
                actions: i.actions.map((j, index) => {
                    if (text.key === index && text.trackKey === Tindex) {
                        return {
                            ...j,
                            text: text.text !== null && text.text !== '' ? text.text : props.action.text
                        }
                    } else {
                        return { ...j }
                    }
                })
            }

        })
        // var newArr = rendervideostate.project.margeArr[text.trackKey].actions
        // {
        //     ...rendervideostate.project.margeArr[text.trackKey].actions,
        //     text:text.text
        // } 
        console.log('newArr 2', newArr)
        dispatch(EDITTEXTaction(newArr))

    }
    console.log("textfield", props)

    const onDelete = (id) => {
        console.log('state.project.margeArr ', id)
        console.log('rendervideostate.project.margeArr', rendervideostate.project.margeArr)

        var newMargeArr = rendervideostate.project.margeArr.filter((n) => {

            console.log('n---------------- ', n, id)
            return n.id !== id;
        })

        dispatch(DELETEactions(newMargeArr))
    }

    return (
        <Box sx={{ p: 2 }} >
            <OutlinedInput
                fullWidth
                sx={{ color: "#000000", border: '1px solid #9a9d9f', height: '42px' }}
                type={'text'}
                value={textfield !== null ? textfield.text : props.action.text}
                onChange={(e) => { setTextfield({ text: e.target.value, key: props.key2, trackKey: props.trackKey }) }}
                endAdornment={
                    <InputAdornment position="end">
                        {(textfield === null || textfield.text === "" || props.action.text === textfield.text) ?
                            <IconButton
                                onClick={() => { onDelete(props.action.anotationId) }}
                            >
                                <DeleteIcon sx={{ color: 'red' }} />
                            </IconButton> :
                            <IconButton
                                // aria-label="toggle password visibility"
                                onClick={() => { onHandleChange(textfield) }}
                            // onMouseDown={handleMouseDownPassword}
                            >
                                <EditIcon sx={{ color: 'green' }} />
                            </IconButton>}

                    </InputAdornment>
                }
            />
        </Box>
    )
}
