

import React, { useContext, useState, useEffect } from "react";
import "../../../../styles/pdfcomponent.css";
// import ProfilePic from '/src/assets/svg1/profile-icon.svg';
import ProfilePic from '../../../../../src/assets/svg1/profile-sec-img.svg';
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Typography,
  IconButton,
  ListItemIcon,
  Collapse,
  Avatar,
  Menu,
  
} from "@mui/material";
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import MenuIcon from "@mui/icons-material/Menu";
import { ExpandLess, ExpandMore, StarBorder } from "@mui/icons-material";
import Box from "@mui/material/Box";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { sidebarRoutes } from "../../../../routes/SidebarRoutes";
import { Link, useLocation } from "react-router-dom";
import Submenu from "../../Submenu";
import { useNavigate } from "react-router-dom";
import AppContext from "../../../../appContext";
import { apiBaseUrl } from "../../../../utils/constants";

export default function MenuItem() {
  

  var sidebarmain = document.getElementById("sidebarid")
  var headermain = document.getElementById("headergame")
  var overlay = document.getElementById("overlay");
  // var over = document.getElementsByClassName("leftmenu");
  const contextApi = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();
  
  const [active, setActive] = useState(null);
  const { setSidebar } = contextApi;
  const { user, sidebar } = contextApi?.state;
  

  const checkIsLinkActive = (itemList) =>
  itemList.map((item) => item.link === location.pathname).includes(true);
  const sidebarToggle = () => {
    if(sidebar){
      setSidebar(!sidebar);
      if(sidebarmain.classList.contains("closemenu")){
        headermain.classList.remove("activeheader")
        overlay.classList.remove("activeoverlay")
      }else{
        overlay.classList.add("activeoverlay")
        headermain.classList.add("activeheader")
      }
    }
    
  };
  
  const sidebarTogglebar = () => {
    console.log("ritiksahni")
    setSidebar(!sidebar);
    if(sidebarmain.classList.contains("closemenu")){
      headermain.classList.remove("activeheader")
      overlay.classList.remove("activeoverlay")
    }else{
      overlay.classList.add("activeoverlay")
      headermain.classList.add("activeheader")
      }
    };
    
    useEffect(()=>{

      if(document.getElementById("sidebarid").classList.contains("closemenu")){
        console.log("11")
        document.getElementById("overlay").classList.remove("activeoverlay")
      }else{
        console.log("22")
        document.getElementById("overlay").classList.add("activeoverlay")
       }

  })






  
  return (
    <>

    
    
      <List
        className={`leftmenu ${!sidebar ? "closemenu" : ""}`} 
        id= "sidebarid"
        sx={{ backgroundColor: "#2699fb", flex: 1, paddingTop: 0 }}
      >
         <div className="sidebarmenu">
           <IconButton
            className={`toggler sidebartoggle  ${
              !sidebar ? "closemenu" : "menu-item-active"
            }`}
            size="large"
            edge="start"
            color="inherit"
            backgroundColor="none"
            dropShadow="none"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={sidebarTogglebar}
          >
            <MenuIcon />
            <ArrowBackIcon />
          </IconButton>

        <div className="userleft" onClick={sidebarToggle}>
          <div className="userleftone">
            <span className="avtar" style={{ overflow: "hidden" }}>
              {Boolean(user?.data?.profileImage) ? (
                <img src={apiBaseUrl + "/public/images/UploadedImages/" + user?.data?.profileImage} />
                ) : (
                  <img src={ProfilePic} />
                  )}
            </span>
            <div className="content">
              <h4>{user?.data?.name}</h4>
              <h6>{user?.data?.email}</h6>
            </div>
            </div>

              <div className="userlefttwo" >  
              
            <div className="myprofileicon">
                <CreateOutlinedIcon onClick={() => {
                  navigate(`/user/edit/${user.data.id}`);
                  // setAnchorEl(null);
                }} />
            </div>
            </div>
          </div>

          <div className="userleft closed" onClick={sidebarTogglebar}>
                 {Boolean(user?.data?.profileImage) ? (
                   <img src={apiBaseUrl + "/public/images/UploadedImages/" + user?.data?.profileImage} />
                   ) : (
                     <img src={ProfilePic} />
                     )}
          </div>
        
        {sidebarRoutes.map((item, index) => {
          if (item.submenu) {
            return (
              <Submenu
                key={index}
                item={item}
                isActiveButton={checkIsLinkActive(item.submenu)}
                className={`tiem-list ${
                  checkIsLinkActive(item.submenu) && "active"
                }`}
                onClick={() => {setActive(item);sidebarToggle()}}
              />
            );
          } else {
            return (


              <Link
                className={`tiem-list ${checkIsLinkActive([item]) && "active"}`}
                key={index}
                to={item.link}
                onClick={() => {setActive(item);sidebarToggle()}}
              >
                <ListItem key={index} className="black_text">
                  <ListItemButton>
                    <ListItemIcon className="black_text" sx={{ color: "#000" }}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText className="sidenavtitle" primary={item.title} sx={{ color: "#000" }} />
                  </ListItemButton>
                </ListItem>
              </Link>



            );
          }
        })}
        </div>
      </List>


      <div id="overlay" onClick={sidebarTogglebar}>

      </div>
    </>
  );
}
