// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
    font-family: sans-serif;
    text-align: center;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .progress-container {
    width: 250px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    height: 19px;
    font-weight: normal;
    font-size: 14px;
    color: #363a43;
  }
  
  .file-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
  }
  .upload-list {
    display: flex;
    gap: 10px;
    align-items: center;
  
    border: 0.1rem solid #cfdbde;
    border-radius: 0.2rem;
    padding: 10px;
    width: 100%;
  }
  .file-size {
    color: #7eb631;
  }
  
  #upload-button {
    color: black;
    padding: 0.5rem 1rem;
    font-family: sans-serif;
    border-radius: 0.3rem;
    cursor: pointer;
    border:1px solid;
    border-style:dotted;
    border-width: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }`, "",{"version":3,"sources":["webpack://./src/components/FileUpload/styles.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;EACX;EACA;IACE,YAAY;IAEZ,aAAa;IACb,0BAA0B;IAC1B,uBAAuB;IACvB,YAAY;IACZ,mBAAmB;IACnB,eAAe;IACf,cAAc;EAChB;;EAEA;IACE,mBAAmB;IACnB,gBAAgB;IAChB,uBAAuB;IACvB,YAAY;EACd;EACA;IACE,aAAa;IACb,SAAS;IACT,mBAAmB;;IAEnB,4BAA4B;IAC5B,qBAAqB;IACrB,aAAa;IACb,WAAW;EACb;EACA;IACE,cAAc;EAChB;;EAEA;IACE,YAAY;IACZ,oBAAoB;IACpB,uBAAuB;IACvB,qBAAqB;IACrB,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,iBAAiB;IACjB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,WAAW;EACb","sourcesContent":[".App {\n    font-family: sans-serif;\n    text-align: center;\n    height: 80vh;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    gap: 20px;\n  }\n  .progress-container {\n    width: 250px;\n    color: #363a43;\n    display: grid;\n    grid-template-columns: 1fr;\n    grid-template-rows: 1fr;\n    height: 19px;\n    font-weight: normal;\n    font-size: 14px;\n    color: #363a43;\n  }\n  \n  .file-name {\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    width: 200px;\n  }\n  .upload-list {\n    display: flex;\n    gap: 10px;\n    align-items: center;\n  \n    border: 0.1rem solid #cfdbde;\n    border-radius: 0.2rem;\n    padding: 10px;\n    width: 100%;\n  }\n  .file-size {\n    color: #7eb631;\n  }\n  \n  #upload-button {\n    color: black;\n    padding: 0.5rem 1rem;\n    font-family: sans-serif;\n    border-radius: 0.3rem;\n    cursor: pointer;\n    border:1px solid;\n    border-style:dotted;\n    border-width: 3px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    width: 100%;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
