import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import undraw1 from "../../assets/undraw.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { adminLogin } from "../../API";
import { setToken } from "../../utils/helperFunctions";
import {toast} from "react-toastify"
import AppContext from "../../appContext";

import { apiBaseUrl } from "../../utils/constants";

const LoginSchema = yup.object().shape({
  email: yup
    .string()
    .email("Email must be Valid")
    .required("Email is Required"),
  password: yup
    .string()
    .min(4, "Password should be 4 characters Minimum")
    .max(15, "Password should be 15 charracters Maximum")
    .required("Password is must"),
});

const Login = () => {
  const navigate = useNavigate();
  const appContext = useContext(AppContext)
  const { fetchUserDetails } = appContext;
  // const [loginData, setLoginData] = useState({
  //   loading: false,
  //   data: null,
  //   message: "",
  //   status: 0
  // })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(LoginSchema),
  });

  const submitForm = async(values) => { 
    try {
      // setLoginData((v) => ({
      //   ...v,
      //   loading: true
      // }))
      const res = await adminLogin(values)
      if (res.status === 200) {  
        console.log(res.data.data)      
          setToken(res.data.data.token)
          document.cookie = "userId="+res.data.data.user.id+";  path="+apiBaseUrl;
          localStorage.setItem("userId", res.data.data.user.id)
          toast.success(res.data.message);
          fetchUserDetails()
          navigate("/")
          // setLoginData((v) => ({
          //   ...v,
          //   loading: false
          // }))
        
      }
    } catch (error) {
      toast.error("something went wrong");
      // setLoginData((v) => ({
      //   ...v,
      //   loading: false
      // }))
    }
  };

  return (
    <div className="login flex h-screen">
      <div className="login-main">
        <div className="left-sidelogin">
          <div className="loginfor">
          <h1 className="text-center mt-3 mb-3">Login</h1>
        <div className="login-start">
          <form onSubmit={handleSubmit(submitForm)}>
            <div className="m-3">
              <label>Email</label>
              <input
                type="email"
                {...register("email")}
                placeholder="Enter Email"
                className="w-full p-2.5  rounded-lg  text-black placeholder:text-black font-normal text-left"
              ></input>
              <p className="text-Red font-bold">{errors?.email?.message}</p>
            </div>
            <div className="m-3">
              <label>Password</label>
              <input
                type="password"
                {...register("password")}
                placeholder="Enter Password"
                className=" w-full p-2.5  rounded-lg  text-black placeholder:text-black font-normal text-left"
              ></input>
              <p className="text-Red font-bold">{errors?.password?.message}</p>
            </div>
            <div className="flex m-2 justify-center items-center">
              <button type="submit" className="btn-dsopForm m-1 p-3 w-full">
                Next
              </button>
            </div>
            {/* <div>
              <Link
                to="/signup"
                className="m-2 p-0.5 hover:text-secondary1 text-white"
              >
                Dont have Account? Create Account
              </Link>
            </div>
            <div>
              <Link
                to="/forget-password"
                className="m-2 p-0.5 hover:text-secondary1 text-white"
              >
                Forget Password
              </Link>
            </div> */}
          </form>
        </div>
        </div>
      </div>
      <div className="right-sidelogin">
        <div className="login-content">
        <div className="h-1/5 m-2 mt-4 justify-evenly ">
          <p>  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vel nihil
          dolorum esse cupiditate modi sapiente unde, corporis quasi non</p>
          suscipit!{" "}
        </div>
        <div className="h-4/5 m-8">
          <img alt="img" src={undraw1} />
        </div>
      </div>
      </div>
    </div>
    </div>
  );
};

export default Login;
