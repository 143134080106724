import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getUserDetailsById, registerUser, updateUserApi, UploadFiles } from "../../API";
import { FileUploader } from "react-drag-drop-files";
import FileUpload from '../FileUpload/index'
import { Thumbnail } from "../Common/Thmbnail";
import LogoutIcon from "../../assets/svg1/logouticon.svg";
import { apiBaseUrl, reactAppImageUri } from "../../utils/constants";
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import addProfileimg from "../../assets/svg1/addprofilebtn.svg"
import AOS from "aos";
import "aos/dist/aos.css";

const UserValidation = yup.object().shape({
  name: yup
    .string()
    .required("Name is Required"),
  phone: yup
    .number().nullable()
    .required("Phone no is Required"),
  email: yup
    .string()
    .email("Email must be Valid")
    .required("Email is Required"),
  role: yup
    .string()
    .required("Role is Required"),
  password: yup
    .string()
    .min(4, "Password should be 4 characters Minimum")
    .max(15, "Password should be 15 charracters Maximum")
    .required("Password is must"),
});
const InputStyle = {
  width: "100%",
  borderRadius: "3px",
};

const AddUser = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams()
  const id = params.id
  const userType = params.userType
  const isVieMode = Boolean(location.pathname.startsWith("/user/view"))
  const isUpdateMode = Boolean(location.pathname.startsWith("/user/edit"))
  const [userData, setUserData] = useState({
    loading: false,
    data: null,
    message: "",
    status: 0
  })

  const [state, setState] = useState({
    loading: false,
    data: null,
    message: "",
    status: 0,
    file: "",
    coverImage: "",
    errors: {},
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue
  } = useForm({
    resolver: yupResolver(UserValidation),
  });
  const roleValue = watch("role", false);

  const submitForm = async (values) => {
    try {
      setUserData((v) => ({
        ...v,
        loading: true
      }))
      if (state?.file) {
        values.profileImage = state.file
      }
      if (state?.coverImage) {
        values.coverImage = state.coverImage
      }
      let res = null;
      if (isUpdateMode) {
        res = await updateUserApi({ ...values, id })
      } else {
        res = await registerUser(values)
      }

      if (res.status == 200) {
        toast.success(res.data.message);
        if(userType === 'admin') {
          navigate("/usersmain?type=admin")
        } else {
          navigate("/usersmain")
        }
        
        setUserData((v) => ({
          ...v,
          loading: false
        }))

      }
    } catch (error) {
      toast.error(error.response.data.message || error.response.statusText);
      setUserData((v) => ({
        ...v,
        loading: false
      }))
    }
  };

  const fetchUserDetailsById = async (id) => {
    try {
      const res = await getUserDetailsById(id)
      if (res.status == 200) {
        const d = res.data.data;
        setValue('name', d.name)
        setValue('email', d.email)
        setValue('role', d.role)
        setValue('phone', d.phone)
        setValue('password', '00000')
        setState((b) => ({ ...b, file: d.profileImage, coverImage: d?.coverImage || "" }))
      }
    } catch (error) {
      toast.error(error.response.data.message || error.response.statusText);
    }
  }

  useEffect(() => {
    if (Boolean(id)) {
      fetchUserDetailsById(id)
    }
  }, [id])

  useEffect(() => {
    if (userType) {
      setValue('role', userType)
    }
  }, [userType])

  useEffect(() => {
    AOS.init({
      easing: 'ease-out-sine',
      duration: 800,
    });
    AOS.refresh();
  }, []);

  const handleChange = async (file, name) => {
    setState({
      ...state,
      [name]: file,
      loading: false
    });
  };


  return (
    <div className="rightsidebar" data-aos="slide-up">
      <div className=" adduser text-font1 w-full desbordcard">
        <div className="handleimg"></div>
        <div className="add-userform">
          <div className="flex contacthead logouthead">
            <img src={addProfileimg} />
            <h3 className=" dsopForm-heading text-2xl">{isUpdateMode ? 'Edit' : isVieMode ? 'View' : 'Add'} { userType === 'admin' ? 'Admin': 'User' } </h3>
          </div>
          <div className="heigfull">
            <div className="flex w-full  ">
              <form
                onSubmit={handleSubmit(submitForm)}
                className="justify-center w-full row">
                <div className="w-full" style={{ padding: "50px 0 0 0" }}>
                  <div className="flex form-werp">
                    <div className="widthd col-md-6">
                      <div className="form-group">
                        {/* <input
                          type="text"
                          className=" p-3 form-control rounded-lg  text-font1 placeholder:text-font1 color-black font-normal text-left"
                          placeholder="Name"
                          disabled={isVieMode}
                          {...register("name")}
                        /> */}

                        <TextField label="Name" variant="outlined"
                          type="text"
                          className="placeholder:text-font1 font-normal text-left inputformwidthuser "
                          placeholder="Name"
                          disabled={isVieMode}
                          {...register("name")}
                        />
                        <p className="text-Red font-bold">{errors?.name?.message}</p>
                      </div>
                    </div>
                    <div className="widthd col-md-6">
                      <div className="form-group">
                        {/* <input
                          type="email"
                          className=" p-3 form-control rounded-lg  text-font1 placeholder:text-font1 font-normal text-left "
                          placeholder="Email"
                          disabled={isUpdateMode || isVieMode}
                          {...register("email")}
                        /> */}
                        <TextField label="Email" variant="outlined"
                          type="email"
                          className="placeholder:text-font1 font-normal text-left inputformwidthuser "
                          placeholder="Email"
                          disabled={isUpdateMode || isVieMode}
                          {...register("email")}
                        />
                        <p className="text-Red font-bold">{errors?.email?.message}</p>
                      </div>
                    </div>
                    <div className="widthd col-md-6">
                      <div className="form-group">
                        {/* <input
                          type="number"
                          className=" p-3 form-control rounded-lg  text-font1 placeholder:text-font1 font-normal text-left"
                          disabled={isVieMode}
                          placeholder="Phone Number"
                          {...register("phone")}
                        /> */}

                        <TextField label="Phone" variant="outlined"
                          type="number"
                          className="placeholder:text-font1 font-normal text-left inputformwidthuser "
                          placeholder="Phone"
                          defaultValue="Phone No. "
                          disabled={isVieMode}
                          {...register("phone")}
                        />

                        <p className="text-Red font-bold">{errors?.phone?.message}</p>
                      </div>
                    </div>
                    <div className="widthd col-md-6">
                      <div className="form-group">
                        <FormControl variant="outlined" className=" inputformwidthuser ">
                          <InputLabel>Role</InputLabel>
                          <Select
                            name="issue"
                            className=" inputformwidthuser "
                            label="Type"
                            disabled={isVieMode}
                            value={roleValue}
                            // onChange={() => { navigate(`/user/add/${userType}`); }}
                            {...register("role")}
                          >

                            <MenuItem value="">Select Role</MenuItem>
                            <MenuItem value="admin">Admin</MenuItem>
                            <MenuItem value="user">User</MenuItem>
                          </Select>
                        </FormControl>
                        <p className="text-Red font-bold">{errors?.role?.message}</p>
                      </div>




                    </div>
                    {(!isVieMode && !isUpdateMode) &&
                      <div className="widthd col-md-6">
                        <div className="form-group">
                          {/* <input
                            type="password"
                            className=" p-3 form-control rounded-lg  text-font1 placeholder:text-font1 font-normal text-left "
                            placeholder="Password"
                            {...register("password")}
                          /> */}
                          <TextField label="Password" variant="outlined"
                            type="password"
                            className="placeholder:text-font1 font-normal text-left inputformwidthuser "
                            placeholder="Password"
                            {...register("password")}
                          />
                          <p className="text-Red font-bold">{errors?.password?.message}</p>
                        </div>
                      </div>}
                    <div className="widthd col-md-6 uploadstyle" style={{ color: "#3498db" }}>

                      <FileUpload
                        fileType={`.png, .jpeg, .jpg, .JPG, .PNG, .JPEG`}
                        showImage={true}
                        onUpload={(e)=>handleChange(e,'file')}
                      /> <div style={{ paddingLeft: "10px" }}><p>Upload Profile Pic<span>| drop a file here</span></p>
                        <p>png,jpeg,jpg</p></div>
                      {Boolean(state?.file) && <Thumbnail src={`${apiBaseUrl + "/public"
                        }/${reactAppImageUri}/${state.file}`} />}
                    </div>
                    {/* <div className="full-width form-group" style={{color : "#3498db"}}>
                      Upload a Cover Image
                      <FileUpload                
                          fileType={`.png, .jpeg, .jpg, .JPG, .PNG', .JPEG`}
                          showImage={true}

                          />
                           {Boolean(state?.coverImage) && <Thumbnail src={`${
                               apiBaseUrl + "/public"
                              }/${reactAppImageUri}/${state.coverImage}`}/>}
                          </div> */}
                    <p className="text-Red font-bold">
                      {state.errors.coverImage}
                    </p>


                  </div>
                  <div className="flex w-full" >
                    <div className="m-2 ml-6 p-4 text-right w-full customclass " style={{ display: "flex", justifyContent: "center", gap: "5%" }}>
                      {isVieMode
                        ?
                        <button
                          type="button"
                          onClick={() => { navigate(`/user/edit/${id}`) }}
                          className="btn btn-primary logoutno ">
                          Edit
                        </button>

                        :
                        null}
                      {userData.loading
                        ?
                        <button
                          disabled={userData?.loading}
                          hidden={isVieMode}
                          className="btn btn-primary logoutno">
                          Please wait...
                        </button>
                        :
                        <button
                          disabled={userData?.loading}
                          type="submit"
                          hidden={isVieMode}
                          className="btn btn-primary logoutno ">
                          {isUpdateMode ? 'Update' : 'Submit'}
                        </button>}

                      <button onClick={() => { 
                        if(userType === 'admin') {
                          navigate("/usersmain?type=admin")
                        } else {
                          navigate("/usersmain")
                        }
                       }} className=" btn btn-primary logoutno">
                        Back
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUser;
