// import React, { useEffect, useState } from "react";
// import { Link, useNavigate, useParams } from "react-router-dom";
// import { useForm } from "react-hook-form";
// import { toast } from "react-toastify";
// import { yupResolver } from "@hookform/resolvers/yup";
// import TextField from '@mui/material/TextField';
// import * as yup from "yup";
// import { FileUploader } from "react-drag-drop-files";
// import CircularProgress from "@mui/material/CircularProgress";
// import {
//   Box,
//   Grid,
//   Button,
//   Paper,
//   Container,
//   ListItem,
//   Checkbox,
//   ListItemText,
// } from "@mui/material";
// import Select from "react-select";
// import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
// import PDFMerger from "pdf-merger-js/browser"; // FABRIC LIBRARIES
// import { apiBaseUrl,reactAppImageUri } from '../../utils/constants'
// // PDFJS LIBRARIES

// // API CALLING
// import { allUserListApi, allUserPdfInfo, apiCall, UploadFiles, usersList } from "../../API";
// import { locationList } from "../../utils/locationList";
// import { display } from "@mui/system";
// import FileUpload from "../FileUpload";

// const InputStyle = {
//   width: "100%",
//   borderRadius: "3px",
// };

// const SelectStyle = {
//   border: '1px solid #bce0fd',
//   color: '#2699fb',
//   outline: 'none',
//   width: '100%',
//   fontWeight:'400',
// };
// const FormValidation = yup.object().shape({
//   title: yup.string().required("Title is Required"),
//   details: yup.string().required("Description is Required"),
//   requirement: yup.string().required("Requirement is Required"),
//   user_id: yup.string().required("Please select the user."),
//   location: yup.object().required("Please select the location."),
//   short_detail: yup.string().required("Please select the Short detail"),
// });

// const PdfForm = (props) => {
//   const { id } = useParams();
//   const navigate = useNavigate();
//   const groupId = new Date().getTime();

//   const [state, setState] = useState({
//     userId: localStorage.getItem("userId"),
//     loading: false,
//     data: null,
//     message: "",
//     status: 0,
//     errors: {},
//     fileTypes: ["PDF", "pdf"],
//   });
//   const [userData, setUserData] = useState({
//     loading: false,
//     data: null,
//     message: "",
//     status: 0
//   })
//   const {
//     register,
//     handleSubmit,
//     formState: { errors },
//     setValue,
//   } = useForm({
//     resolver: yupResolver(FormValidation),
//   });
//   const [uesrLists, setUserLists] = useState([]);
//   const [selectUser, setSelectUser] = useState([]);
//   const [thumb, setThumb] = useState(null);
//   const [locationLists, setLocationLists] = useState();

//   useEffect(() => {
//     setInfo();
//   }, [])

//   useEffect(() => {
//     if (state.user && state.user.length > 0 && uesrLists.length > 0) {
//       let dropDown = []
//       let userIds = []
//       for (let obj of state.user) {
//         const user = uesrLists.find(o => Number(o.value) === Number(obj.user_id))
//         dropDown.push({
//           value: obj.user_id,
//           label: user?.label
//         })
//         userIds.push(obj.user_id)
//       }
//       setState({
//         ...state,
//         userIds: userIds
//       })
//       setSelectUser(dropDown)
//     }
//   }, [state.user, uesrLists])
//   const inputHandle = (e) => {
//     setState({
//       ...state,
//       [e.target.name]: e.target.value
//     })
//   }

//   const setInfo = async () => {
//     let dataList = [];
//     locationList.map((val) => dataList.push({ "label": val.name, "value": val.id }))
//     setLocationLists(dataList);
//     if(id) {
//       let dataToShow = await allUserPdfInfo(id);
//       console.log('----------------------------------')
//       console.log(dataToShow);
//       if(!dataToShow || !dataToShow.data) {
//         return;
//       }
//       setThumb(dataToShow.data.thumnail)
//       setState({
//         file:  dataToShow.data.filename ,
//         title: dataToShow.data.title ,
//         details: dataToShow.data.details ,
//         requirement: dataToShow.data.requirement ,
//         shortDetails: dataToShow.data.shortDetails ,
//         pdfUser: dataToShow.data.pdfUser ,
//         user: dataToShow.data.user,
//         status: dataToShow.data.status,
//         location: dataList.find((ob) => ob.value === dataToShow.data.location),
//         splitedFile: [],
//         errors: {},
//       })
//     }
//   }

//   const fetchAllUserList = async (values) => {
//     try {
//       setUserData((v) => ({
//         ...v,
//         loading: true
//       }))
//       const res = await allUserListApi(values)

//       if (res.status === 200) {
//         // toast.success(res.data.message);
//         setUserData((v) => ({
//           ...v,
//           loading: false,
//           data: res.data.data
//         }))

//       }
//     } catch (error) {
//       toast.error("something went wrong");
//       setUserData((v) => ({
//         ...v,
//         loading: false,
//         data: null
//       }))
//     }
//   };

//   useEffect(() => {
//     fetchAllUserList();
//     userListGet();
//   }, []);

//   const userListGet = async () => {
//     const items = await usersList();
//     if (items.status === 200) {
//       let userDataForSelect = [];
//       items.data.data.map((value, key) =>
//         userDataForSelect.push({ value: value.id, label: value.name })
//       );
//       setUserLists(userDataForSelect);
//     }
//   }

//   const handleChange = async (file) => {
//     let errors = {};
//     errors.file = "";
//     let files = [];
//     files.push(file);
//     setState({
//       ...state,
//       loading: true,
//     });
//     try {
//       const res = await UploadFiles(files);
//       if (res.status === 200) {
//         const info = res.data.data[0];
//         setState({
//           ...state,
//           file:`${info.filename}`,
//           loading: false,
//           errors: errors,
//         });
//       }
//     } catch (error) {
//       console.log(error);
//       toast.error("something went wrong");
//     }
//   };

//   const isValid = () => {
//     let errors = {};
//     let isValid = true;
//     let isError = false;
//     if (state.title) {
//       isValid = true;
//       errors.title = "";
//     } else {
//       isValid = false;
//       errors.title = "Title is required";
//       isError = true;
//     }
//     if (state.requirement != null && state.requirement != "") {
//       isValid = true;
//       errors.requirement = "";
//     } else {
//       isValid = false;
//       errors.requirement = "Requirement is required";
//       isError = true;

//     }
//     console.log(state.shortDetails)
//     if (state.shortDetails) {
//       isValid = true;
//       errors.shortDetails = "";
//     } else {
//       isValid = false;
//       errors.shortDetails = "short details is required";
//       isError = true;

//     }
//     console.log(state)
//     if (state.userIds) {
//       isValid = true;
//       errors.user = "";
//     } else {
//       isValid = false;
//       errors.user = " user is required";
//       isError = true;

//     }
//     // if (state.pdfUser) {
//     //   isValid = true;
//     //   errors.pdfUser = "";
//     // } else {
//     //   isValid = false;
//     //   errors.pdfUser = "pdf user is required";
//     //   isError = true;

//     // }
//     if (state.location) {
//       isValid = true;
//       errors.location = "";
//     } else {
//       isValid = false;
//       errors.location = "Location is required";
//       isError = true;

//     }
//     if (state.details) {
//       isValid = true;
//       errors.details = "";
//     } else {
//       isValid = false;
//       errors.details = "Detail is required";
//       isError = true;

//     }
//     if (id !== undefined) {
//       isValid = true;
//       errors.file = "";
//     } else {
//       if (state.file) {
//         isValid = true;
//         errors.file = "";
//       } else {
//         isValid = false;
//         errors.file = "File is required";
//         isError = true;

//       }
//     }
//     setState({
//       ...state,
//       errors: errors,
//     });
//     return (isError) ? false : true;
//   };

//   const submitFile = async (event) => {
//     event.preventDefault();
//     if (isValid()) {
//       let path = "";
//       let method = "post";
//       let docId = 0;
//       if (id !== undefined) {
//         path = apiBaseUrl + "/document/update";
//         method = "put";
//         docId = id;
//       } else {
//         path = apiBaseUrl + "/document/split";
//         method = "post";
//         docId = 0;
//       }

//       let data = {
//         id: localStorage.getItem("userId"),
//         file: state.file,
//         userIds: state.userIds,
//         user: JSON.stringify(state.userIds),
//         groupId: groupId,
//         title: state.title,
//         details: state.details,
//         docId: docId,
//         requirement: state.requirement,
//         shortDetails: state.shortDetails,
//         pdfUser: localStorage.getItem("userId"),
//         location: state.location.value,
//         status: state.status,
//         thumnail:thumb,
//       };

//       let auth = {
//         userToke: localStorage.getItem("token"),
//       };
//       let fileUpload = true;
//       try {
//         setState({
//           ...state,
//           loading: true,
//         });

//         const res = await apiCall(path, method, data, auth, fileUpload);
//         if (res.status === 200) {
//           setState({
//             ...state,
//             file: null,
//             splitedFile: res.data.data,
//             loading: false,
//           });
//        //   localStorage.setItem("splitFiles", JSON.stringify(res.data.data));
//           let groupId_ = res.data.data[0].groupId;
//           window.localStorage.removeItem('submitredirct');

//           if (localStorage.getItem("urlForEditPdf")) {

//             localStorage.removeItem("urlForEditPdf")

//             navigate("/pdfview/" + groupId_);
//           } else {

//             navigate("/pdfview/" + groupId_);
//           }

//         } else {
//           setState({
//             ...state,
//             loading: false,
//           });
//         }
//       } catch (error) {
//         console.log(error);
//       }
//     }
//   };
//   const [numPages, setNumPages] = useState(null);
//   const [pageNumber, setPageNumber] = useState(1);

//   function onDocumentLoadSuccess({ numPages }) {
//     setNumPages(numPages);
//   }

//   useEffect(() => {
//     if (localStorage.getItem("canvas-appear")) {
//       localStorage.removeItem("canvas-appear");
//     }
//   }, []);

//   const removeLocalData = () => {
//     if (localStorage.getItem("urlForEditPdf")) {
//       localStorage.removeItem("urlForEditPdf")
//     }
//   }
//   return (
//     <div className="rightsidebar pdfeditform">
//       <div className=" adduser text-font1 w-full desbordcard">
//       <div className="handleimg"></div>
//       <p className="titileditor">Add PDF</p>
//       <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }} className="addeditorform">
//         <Grid container>
//           <div className="mainprogrs">

//             <ul className="multi-step-bar">
//               <li className="active"><p>Details</p></li>
//               <li><p>Edit PDF</p></li>
//               <li><p>Submit</p></li>
//             </ul>
//           </div>
//           <Grid item xs={12}>
//             <Paper sx={{ p: 2 }} className="paddnone" style={{ boxShadow: 'none', padding: 0 , backgroundColor: 'transparent' }}>
//               {/* <ListItem
//                 secondaryAction={
//                   <Button
//                     variant="contained"
//                     component={Link}
//                     to="/pdflist"
//                     className="addbutne"
//                   >
//                     Back
//                   </Button>
//                 }
//               >
//                 <ListItemText className="black-text" primary="Digital SOP - PDF Editor" />
//               </ListItem> */}
//               <div className="ditgitalform">
//                 <form onSubmit={(event) => submitFile(event)}>

//                   <div className="formgrop">
//                     <div className="flex">
//                     </div>
//                   </div>

//                   <div className="formgrop">
//                     <div className="flexfileds">
//                       <div className="forcontrol">
//                         {/* <input
//                           type="text"
//                           className=" p-3 form-control  font-normal text-left"
//                           style={InputStyle}
//                           placeholder="Title"
//                           name="title"
//                           value={state.title}
//                           onChange={(e) => inputHandle(e)}
//                         ></input> */}

//                   <TextField label="Title" variant="outlined"
//                     style={InputStyle}
//                     type="text"
//                     className="font-normal text-left "
//                     placeholder="Title"
//                     name="title"
//                     value={state.title}
//                     onChange={(e) => inputHandle(e)}
//                     />
//                         <p className="text-Red font-bold">
//                           {state.errors.title}
//                         </p>
//                       </div>
//                       <div className="forcontrol">
//                         {/* <input
//                           type="text"
//                           className=" p-3 form-control  font-normal text-left"
//                           style={InputStyle}
//                           placeholder="Requirement"
//                           name="requirement"
//                           value={state.requirement}
//                           onChange={(e) => inputHandle(e)}
//                         ></input> */}

//                     <TextField label="Requirement" variant="outlined"
//                     style={InputStyle}
//                     type="text"
//                     className="font-normal text-left "
//                     placeholder="Requirement"
//                     name="Requirement"
//                     value={state.requirement}
//                     onChange={(e) => inputHandle(e)}
//                     />
//                         <p className="text-Red font-bold">
//                           {state.errors.requirement}
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="formgrop">
//                     {/* <textarea
//                       style={InputStyle}
//                       placeholder="Short Details"
//                       rows={3}
//                       name="shortDetails"
//                       value={state.shortDetails}
//                       onChange={(e) => inputHandle(e)}
//                     ></textarea> */}

//         <TextField
//           style={InputStyle}
//           placeholder="Short Details"
//           label="Short Details"
//           name="shortDetails"
//           multiline
//           rows={3}
//           value={state.shortDetails}
//           onChange={(e) => inputHandle(e)}
//         />
//                     <p className="text-Red font-bold">
//                       {state.errors.shortDetails}
//                     </p>
//                   </div>
//                   <div className="formgrop">
//                     {/* <textarea
//                       style={InputStyle}
//                       placeholder="Details"
//                       rows={3}
//                       name="details"
//                       value={state.details}
//                       onChange={(e) => inputHandle(e)}
//                     ></textarea> */}

//           <TextField
//           style={InputStyle}
//           placeholder="Details"
//           label="Details"
//           name="details"
//           multiline
//           rows={3}
//           value={state.details}
//           onChange={(e) => inputHandle(e)}
//         />
//                     <p className="text-Red font-bold">
//                       {state.errors.details}
//                     </p>
//                   </div>
//                   <div className="formgrop">
//                     <div className="flex" style={{ gap:'5%' }}>
//                       <div className="flexfileds userlist locationpdf" >

//                         <Select styles={SelectStyle}
//                           name="user"
//                           isMulti
//                           value={selectUser}
//                           onChange={(value) => {
//                             console.log(value)
//                             setSelectUser(value)
//                             setState({
//                               ...state,
//                               "userIds": value.map((e) => e.value)
//                             })
//                           }}
//                           options={uesrLists}
//                         />
//                         <span>
//                           <p className="text-Red font-bold">
//                             {state.errors.user}
//                           </p>
//                         </span>
//                       </div>

//                       <div className="flexfileds locationpdf ">
//                         <Select styles={SelectStyle}
//                           value={state.location}
//                           onChange={(e) => {
//                             setState({
//                               ...state,
//                               "location": e
//                             })
//                           }}
//                           options={locationLists}
//                         />
//                         <p className="text-Red font-bold">
//                           {state.errors.location}
//                         </p>
//                       </div>
//                     </div>
//                   </div>

//                   <div className="formgrop" >
//                     <Checkbox defaultChecked /><label className="actij">Active</label>
//                   </div>
//                   <div className="formgrop imagecover">

//                     <div className="pdfupone uploadstyle">

//                         {/* <div>* Upload Thumbnail Image</div> */}

//                                <FileUpload
//                                                 fileType={`.png, .jpeg, .jpg, .JPG, .PNG, .JPEG`}
//                                                 onUpload={(img)=>{
//                                                   setThumb(img)

//                                                 }}/>
//                                                 <div style={{paddingLeft:"10px"}}><p>Upload<span>| Thumbnail Image</span></p>
//                           <p>png,jpeg,jpg</p></div>

//                             <div className="formgrop">
//                         {thumb !== null && (
//                           <img
//                           src={`${
//                             apiBaseUrl + "/public"
//                               }/${reactAppImageUri}/${thumb}`}
//                               />
//                               )}
//                               </div>

//                               </div>

//                       {/* <FileUploader
//                         handleChange={handleChange}
//                         name="file"
//                         types={state.fileTypes}
//                         height="100px"
//                         style={InputStyle}
//                         className="fileUploader"
//                       /> */}
//                       <div className="pdfuptwo uploadstyle">

//                           <FileUpload
//                           fileType={state.fileTypes}
//                           onUpload={(imgFile)=>{
//                             setState({
//                               ...state,
//                               file:imgFile.filename,
//                               loading: false,
//                               errors: errors,
//                             });
//                           }}
//                           />
//                           <div style={{paddingLeft:"10px"}}><p>Upload<span>| PDF File</span></p>
//                           <p>.pdf</p></div>
//                       <p className="text-Red font-bold">
//                         {state.errors.file}
//                       </p>
//                       {id !== undefined && (
//                         <Box
//                           className="w-full"
//                           style={{ width: "200px", height: "341px", border: "1px solid lightgray" }}
//                         >
//                           <Document
//                             file={ apiBaseUrl+ '/public/docFiles/uploadFiles/'+state.file}
//                             onLoadSuccess={onDocumentLoadSuccess}
//                             className="splited_pdf_file_canvac"
//                           >
//                             <Page
//                               pageNumber={pageNumber}
//                               width="240"
//                               scale={1.0}
//                             />
//                           </Document>
//                                <div>

//                           </div>
//                         </Box>
//                       )}
//                        {/* <div>* Upload File</div> */}
//                     </div>
//                   </div>

//               <div className="buttonsave pdfsavebtn">

//                     {id === undefined && (
//                       <Button
//                         variant="contained"
//                         type="submit"
//                         disabled={state.loading}
//                         style={{ width: "16%" }}
//                         className="addbutne logoutno"
//                       >
//                         {state.loading === true ? "Processing...." : "Next"}
//                       </Button>
//                     )}
//                     {id !== undefined && (
//                       <Button
//                         variant="contained"
//                         type="submit"
//                         disabled={state.loading}
//                         style={{ width: "16%", marginTop: "-20px" }}
//                         className="addbutne logoutno"
//                       >
//                         Next
//                       </Button>
//                     )}
//                   </div>
//                 </form>
//               </div>
//             </Paper>
//           </Grid>
//         </Grid>
//       </Container>
//     </div>
//     </div>
//   );
// };
// export default PdfForm;

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Grid, Button, Paper, Container, Checkbox } from "@mui/material";
import { apiCall, usersList, allUserPdfInfo } from "../../API";

import { apiBaseUrl, reactAppImageUri } from "../../utils/constants";
import { locationList } from "../../utils/locationList";

import Select from "react-select";
import FileUpload from "../FileUpload";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

const InputStyle = {
  width: "100%",
  borderRadius: "3px",
};

const FormValidation = yup.object().shape({
  title: yup.string().required("Title is Required"),
  requirement: yup.string().required("Requirement is Required"),
  userIds: yup.array().required("Please select the user."),
  location: yup.object().required("Please select the location."),
  short_detail: yup.string().required("Please select the Short detail"),
  details: yup.string().required("Description is Required"),
});

const PdfForm = (props) => {
  
  const [userData, setUserData] = useState({
    loading: false,
    data: null,
    message: "",
    status: 0,
  });
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(FormValidation),
  });

  const [thumb, setThumb] = useState(null);
  const [editedPdf, setEditedPdf] = useState(null);
  const [uesrLists, setUserLists] = useState([]);
  const [selectUser, setSelectUser] = useState([]);
  const [locationLists, setLocationLists] = useState([]);
  const [selectLocation, setSelectLocation] = useState([]);

  const onError = (errors, e) => console.log(errors, e, "fi");
  const onSubmit = async (values) => {
    try {
      setUserData((v) => ({
        ...v,
        loading: true,
      }));
      let res = null;
      values.location = values.location.value;
      values.editable_pdf = editedPdf;
      values.thumnail = thumb;
      if (id !== undefined) {
        values.id = id;
        res = await submitFile(values);
      } else {
        res = await submitFile(values);
      }

      // if (res.status === 200) {
      //   toast.success(res.data.message);
      //   navigate("/ppt-split-view/" + res.data.data.id);
      //   setUserData((v) => ({
      //     ...v,
      //     loading: false,
      //   }));
      // } else {
      //   toast.error(res.data.message || res.response.statusText);
      // }
    } catch (error) {
      toast.error("something went wrong");
      setUserData((v) => ({
        ...v,
        loading: false,
      }));
    }
  };

  const submitFile = async (data) => {
    let path = "";
    let method = "post";
    if (data.id !== undefined) {
      path = apiBaseUrl + "/document/update";
      method = "put";
    } else {
      path = apiBaseUrl + "/document/split";
      method = "post";
      data.status = 0
    }

    let auth = {
      userToke: localStorage.getItem("token"),
    };
    try {
      data.groupId = new Date().getTime();
      const res = await apiCall(path, method, data, auth);
      if (res.status === 200) {
        let groupId_ = res.data.data[0].groupId;
        window.localStorage.removeItem("submitredirct");

        if (localStorage.getItem("urlForEditPdf")) {
          localStorage.removeItem("urlForEditPdf");
          navigate("/pdfview/" + groupId_);
        } else {
          navigate("/pdfview/" + groupId_);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetInfo = async () => {
    userListGet();
    let dataList = [];
    locationList.map((val) =>
      dataList.push({ label: val.name, value: val.id })
    );
    setLocationLists(dataList);

    const items = await usersList();
    let userDataForSelect = [];
    if (items.status === 200) {
      items.data.data.rows.map((value, key) =>
        userDataForSelect.push({ value: value.id, label: value.name })
      );
      setUserLists(userDataForSelect);
    }
    if (id !== undefined) {
      try {
        const res = await allUserPdfInfo(id);
        if (res.status === 200) {
          const info = res.data;
          setValue("title", info.title);
          setValue("details", info.details);
          setValue("requirement", info.requirement);
          setValue("short_detail", info.short_detail);

          // setValue("userIds", info.user_id);
          setValue(
            "location",
            dataList.find((ob) => ob.value === info.location)
          );
          console.log(info.location);
          setSelectLocation(dataList.find((ob) => ob.value === info.location));
          setThumb(info.thumnail);
          setEditedPdf(info.filename);
          if (
            info.user &&
            info.user.length > 0 &&
            userDataForSelect.length > 0
          ) {
            const dataSelected = [];
            for (let obj of info.user) {
              const user = userDataForSelect.find(
                (o) => Number(o.value) === Number(obj.user_id)
              );
              dataSelected.push(user);
            }
            console.log(dataSelected);
            setSelectUser(dataSelected);
            // setValue("user_id", JSON.stringify(value));
            setValue(
              "userIds",
              dataSelected.map((e) => e.value)
            );
          }

          //setSelectUser(JSON.parse(info.user_id))
          // setSelectLocation(JSON.parse(info.location))
          // setState({
          //     location: info.location,
          //     errors: {},
          // });

          if (info.image_urls !== null) {
            // setaddMore(JSON.parse(info.image_urls));
          }
        }
      } catch (error) {
        toast.error("something went wrong");
      }
    }
  };

  useEffect(() => {
    GetInfo();
  }, []);

  const userListGet = async () => {
    const items = await usersList();
    if (items.status === 200) {
      let userDataForSelect = [];
      items.data.data.rows.map((value, key) =>
        userDataForSelect.push({ value: value.id, label: value.name })
      );
      // setUserLists(items.data.data);
      setUserLists(userDataForSelect);
    }
  };

  return (
    <>
      <div className="rightsidebar pdfeditform">
        <div className=" adduser text-font1 w-full desbordcard">
          <div className="d-flex ">
            <div className="cursor-pointer" onClick={() => navigate(-1)}>
              <i className="fa fa-arrow-left back_arrow_style" aria-hidden="true"></i>
            </div>
            <div className="handleimg"></div>
          </div>

          <p className="titileditor">Add PDF</p>
          <Container
            maxWidth="xl"
            sx={{ mt: 4, mb: 4 }}
            className="addeditorform"
          >
            <Grid container>
              <div className="mainprogrs">
                <ul className="multi-step-bar">
                  <li className="active">
                    <p>Details</p>
                  </li>
                  <li>
                    <p>Edit PDF</p>
                  </li>
                  <li>
                    <p>Submit</p>
                  </li>
                </ul>
              </div>
              <Grid item xs={12}>
                <Paper
                  sx={{ p: 2 }}
                  style={{
                    boxShadow: "none",
                    padding: 0,
                    backgroundColor: "transparent",
                  }}
                >
                  {/* <ListItem>
                                <ListItemText className="black-text" primary="PPT Editor" />
                            </ListItem> */}
                  <form
                    className="w-full ditgitalform"
                    onSubmit={handleSubmit(onSubmit, onError)}
                  >
                    <div className="formgrop">
                      <div className="flexfileds">
                        <div className="forcontrol">
                          <TextField
                            label="Title"
                            variant="outlined"
                            style={InputStyle}
                            type="text"
                            className="font-normal text-left "
                            placeholder="Title"
                            {...register("title")}
                          />

                          <p className="text-Red font-bold">
                            {errors?.title?.message}
                          </p>
                        </div>
                        <div className="forcontrol">
                          <TextField
                            label="Requirement"
                            variant="outlined"
                            type="text"
                            style={InputStyle}
                            placeholder="Requirement"
                            className="font-normal text-left "
                            {...register("requirement")}
                          />

                          <p className="text-Red font-bold">
                            {" "}
                            {errors?.requirement?.message}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="formgrop">
                      <div className="flexfiledss">
                        <TextField
                          label="Short detail"
                          variant="outlined"
                          style={InputStyle}
                          type="text"
                          multiline
                          rows={2}
                          placeholder="Short Details"
                          {...register("short_detail")}
                        />

                        <p className="text-Red font-bold">
                          {" "}
                          {errors?.short_detail?.message}
                        </p>
                      </div>
                    </div>

                    <div className="formgrop">
                      <TextField
                        style={InputStyle}
                        placeholder="Details"
                        label="Details"
                        multiline
                        rows={3}
                        {...register("details")}
                      />

                      <p className="text-Red font-bold">
                        {errors?.details?.message}
                      </p>
                    </div>

                    <div className="formgrop">
                      <div className="flex" style={{ gap: "5%" }}>
                        <div className="flexfileds userlist locationpdf">
                          <Select
                            isMulti
                            value={selectUser}
                            onChange={(value) => {
                              setSelectUser(value);
                              const userIds = value.map((e) => e.value);
                              setValue("userIds", userIds);
                            }}
                            options={uesrLists}
                          />
                          <p className="text-Red font-bold">
                            {errors?.userIds?.message}
                          </p>
                        </div>

                        <div className="flexfileds locationpdf">
                          <Select
                            value={selectLocation}
                            onChange={(value) => {
                              setSelectLocation(value);
                              setValue("location", value);
                            }}
                            options={locationLists}
                          />
                          <p className="text-Red font-bold">
                            {errors?.location?.message}
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* <div
                      className="formgrop"
                      style={{ margin: "-30px 0 10px 0" }}
                    >
                      <Checkbox
                        {...register("status")}
                        defaultChecked
                        defaultValue={1}
                      />
                      <label className="actij">Active</label>
                    </div> */}

                    <div className="formgrop imagecover">
                      <div className="pdfupone uploadstyle">
                        <FileUpload
                          fileType={`.png, .jpeg, .jpg, .JPG, .PNG, .JPEG`}
                          onUpload={(img) => {
                            setThumb(img);
                          }}
                        />
                        <div style={{ paddingLeft: "10px" }}>
                          <p>
                            Upload<span>| Thumbnail Image</span>
                          </p>
                          <p>png,jpeg,jpg</p>
                        </div>
                      </div>
                      {thumb !== null && (
                        <Box className="w-full m-2 ml-3 mt-3">
                          <img
                            alt="img"
                            src={`${
                              apiBaseUrl + "/public"
                            }/${reactAppImageUri}/${thumb}`}
                            width={`150px`}
                            height={`150px`}
                          />
                        </Box>
                      )}{" "}
                      <div className="pdfupone uploadstyle">
                        <FileUpload
                          fileType={`.pdf`}
                          onUpload={(pdf) => {
                            setEditedPdf(pdf.filename);
                          }}
                        />
                        <div style={{ paddingLeft: "10px" }}>
                          <p>
                            Upload<span>| PDF File</span>
                          </p>
                          <p>.pdf</p>
                        </div>
                        {editedPdf && (
                          <Box
                            className="w-full"
                            style={{
                              width: "200px",
                              height: "341px",
                              border: "1px solid lightgray",
                            }}
                          >
                            <Document
                              file={
                                apiBaseUrl +
                                "/public/docFiles/uploadFiles/" +
                                editedPdf
                              }
                              className="splited_pdf_file_canvac"
                            >
                              <Page pageNumber={1} width="240" scale={1.0} />
                            </Document>
                            <div></div>
                          </Box>
                        )}
                      </div>
                    </div>
                    <div
                      className="formgrop pdfsavebtn"
                      style={{ paddingTop: 10, paddingBottom: 10 }}
                    >
                      <Button
                        variant="contained"
                        type="submit"
                        fullWidth
                        disabled={userData.loading}
                        style={{ width: 'max-content' }}
                        className="addbutne logoutno"
                      >
                        {userData.loading === true ? <><div className="dot-pulse-text">Processing</div> <div className="dot-pulse-container"><div className="dot-pulse"></div></div></> : "Next"}
                      </Button>{" "}
                    </div>
                  </form>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </>
  );
};

export default PdfForm;
