import React, { useEffect, useContext } from "react";
// import React, { useContext, useState } from "react";
// import { useState } from "react";
// import { submitContactUs } from "../../API";
// import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import AppContext from "../../appContext";  
import { usersList } from "../../API";
import LogoutIcon from "../../assets/svg1/logouticon.svg";
import AOS from "aos";
import "aos/dist/aos.css";


const LogoutComponent = () => {
  const contextApi = useContext(AppContext);
  const {  logout } = contextApi;
  const navigate = useNavigate();

  // const [contactInfo, setContactInfo] = useState({
  //   firstName: "",
  //   lastName: "",
  //   email: "",
  //   mobile: "",
  //   discription: "",
  //   site: "",
  //   issue: "",
  //   user_id:[]
  // });
  // const [uesrLists, setUserLists] = useState([]);
  
  // const inputHandle = (e) => {
  //   setContactInfo({
  //     ...contactInfo,
  //     [e.target.name]: e.target.value,
  //   });
  // };
  // const onSubmit = async () => {
  //   try {
  //     console.log(contactInfo);
  //     let res = await submitContactUs(contactInfo);
  //     if (res.status === 200) {
  //       toast.success(res.data.message);
  //       navigate("/");
  //     } else {
  //       toast.error(res.data.message || res.response.statusText);
  //     }
  //     // values.uploaded_image = thumb;
  //     // setUserData((v) => ({
  //     //     ...v,
  //     //     loading: true,
  //     // }));
  //     // let res = null;
  //     // if (id !== undefined) {
  //     //     res = await updateImageEditing(id, { ...values });
  //     // } else {
  //     //     res = await createImageEditing(values);
  //     // }

  //     // if (res.status === 200) {
  //     //     toast.success(res.data.message);
  //     //     navigate("/Edit-Image/"+res.data.data.id+"/manage");
  //     //     setUserData((v) => ({
  //     //         ...v,
  //     //         loading: false,
  //     //     }));
  //     // } else {
  //     //     toast.error(res.data.message || res.response.statusText);
  //     // }
  //   } catch (error) {
  //     // toast.error(
  //     //     error.response.data.message || error.response.statusText
  //     // );
  //     // setUserData((v) => ({
  //     //     ...v,
  //     //     loading: false,
  //     // }));
  //   }
  // };
  useEffect(() => {
    userListGet();
}, []);
useEffect(() => {
  AOS.init({
    easing: 'ease-out-sine',
    duration: 800,
  });
  AOS.refresh();
}, []);
  const userListGet = async () =>{
    const items = await usersList();
    if(items.status === 200){
        let userDataForSelect = [];
        items.data.data.rows.map((value,key) => userDataForSelect.push({ value: value.id, label: value.name }));
        // setUserLists(items.data.data);
        // setUserLists(userDataForSelect);
    }
}
  return (
    <div className="rightsidebar" data-aos="slide-up">
      <div className=" w-full h-full desbordcard logoutdash">
        <div className="handleimg"></div>
        <div className="logoutdashfirst">
        <div className="flex contacthead logouthead">
        <img alt="img" src={LogoutIcon}/>
          <h3 className="text-2xl">
            Log Out
          </h3>
          <p>are you sure you want to leave?</p>
        </div>
      <div className="logoutdashsecond">
        <div className="logoutno"onClick={() =>navigate(`/`)}>
          
          NO</div>
        <div className="logoutyes"
        onClick={() => logout(navigate)}
        >YES</div>
      </div>
      </div>
      </div>
    </div>
  );
};

export default LogoutComponent;
