import React from "react";
import {
  Box,
  List
} from '@mui/material';
import Listitem from "./components/Listitem";
const TimeStamps = (props) => {
  return (
    <Box component={'div'} sx={{border: "1px solid #659EC7",margin:'0px',height:'450px',overflowY: 'scroll' ,backgroundColor:"#ffff"}}>
      <div className="leayerse" style={{ width: '100%', height: '270px', padding: '6%'}}>
      <p style={{color:'#2699fb',fontWeight:'bold', fontSize:'14px', marginBottom:'10px'}}>Layers...</p>
        <List sx={{ height: '195px',  p: 0 }}>
          {
            props.stamps.map((item, index) => {
              return (<Listitem key={index} stamps={item} />)
            })
          }
        </List>
      </div>
    </Box>
  )
};

export default TimeStamps;
