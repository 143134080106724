import React, { useState, useRef } from "react";

import { useSelector, useDispatch } from 'react-redux';
import {cutvideosubmitActions} from '../../../../redux/actions/rendervideoActions';
import { SET_VIDEO_CURRENTTIMEactions, SET_EFFECTactions, SET_VIDEO_CURRENTTIME_STARTactions, SET_POSITION_ANOTATIONactions,SET_CUTVIDEOURLactions } from '../../../../redux/Reducers/rendervideoReducer'
import TimeField from "react-simple-timefield";
import moment from "moment";
import { Timeline } from '@xzdarcy/react-timeline-editor';
import PlayIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import {
    Box,
    Typography,
    Button,
    TextField,
    CircularProgress
} from '@mui/material';
import ContentCutIcon from '@mui/icons-material/ContentCut';

import {apiBaseUrl} from './../../../../utils/constants'
// import { content } from "../../../../../tailwind.config";
// import { Player } from 'video-react';

const _ = require('lodash')

// const WidthStyle = {
//     width: "intrinsic",           /* Safari/WebKit uses a non-standard name */
//     width: "-moz - max - content",   /* Firefox/Gecko */
//     width: "-webkit - max - content", /* Chrome */
//     width: "max - content"
// }


const RowMain = ({ action, row }) => {
    
    return (
        <Box className={'w-full h-full'} style={{ backgroundColor: '#fff', borderRadius: '2px',marginTop:'2px', border: '1px dotted #ffffff' }}>
            <Typography className="p-1">Video - 1</Typography>
        </Box>
    )
}
const Row1 = ({ action, row }) => {
    
    return (
        <Box className={'w-full h-full'} style={{ backgroundColor: '#fff', borderRadius: '2px', border: '1px dotted #ffffff',marginTop:'2px' }}>
            <Typography className="p-1">Anotation - {row.id}</Typography>
        </Box>
    )
}
const Row2 = ({ action, row }) => {
    
    return (
        <Box className={'w-full h-full'} style={{ backgroundColor: '#b0b08a', borderRadius: '2px', border: '1px dotted #ffffff',marginTop:'2px' }}>
            <Typography className="p-1">Text - {row.id}</Typography>
        </Box>
    )
}

const Row3 = ({ action, row }) => {
    
    return (
        <Box
            className={'w-full h-full  cropbox'}
            style={{
                top: 0,
                // backgroundColor: 'rgba(15, 201, 244, 0.39)',
                borderRadius: '2px',
               
                height: `${action.height}px`,
                // position: 'absolute',
                marginTop:'2px',
                borderLeft: '1px solid red',
                borderRight: '1px solid red',
                position:'absolute'               
            }} >
            <Typography className="p-1"></Typography>
        </Box>
    )
}

const Row4 = ({ action, row }) => {
    
    return (
        <Box className={'w-full h-full'} style={{ backgroundColor: '#2e7d32', borderRadius: '2px', border: '1px dotted #ffffff',marginTop:'2px' }}>
            <Typography className="p-1">{action.text}</Typography>
        </Box>
    )
}

const TimeLine = (props) => {
    const [timeSecondsstart, setTimeSecondsstart] = useState('00:00:00')
    const [timeSecondsend, setTimeSecondsend] = useState('00:00:00')
    const [mockData, setMockdata] = useState([])
    const state = useSelector(state => state.rendervideoReducer);
    // const timelineRef = useRef(null)
    const timelineRef = useRef(null)

    const dispatch = useDispatch();
    const [isPlaying, setIsPlaying] = useState(false);
    const [isCutVideo, setCutVideo] = useState(false);
    const [videoCutProgress, setVideoCutProgress] = useState(false);
    const [scrollTop, setScrollTop] = useState(0)
    const [isScrollHere, setIsScrollHere] = useState(false)
    const onTimeChangeStart = (event, value) => {
        const newTime = value.replace(/-/g, ':');
        setTimeSecondsstart(newTime.padEnd(8, timeSecondsstart.substr(5, 3)));
    }
    const onTimeChangeEnd = (event, value) => {
        const newTime = value.replace(/-/g, ':');
        setTimeSecondsend(newTime.padEnd(8, timeSecondsend.substr(5, 3)));
    }


    const cropBoxM = () => {
        if(document.getElementsByClassName('cropbox').length > 0)
        {   
            
            document.getElementsByClassName('cropbox')[0].parentNode.style.height = document.getElementsByClassName('cropbox')[0].style.height;
            document.getElementsByClassName('cropbox')[0].parentNode.style.zIndex = 10000;
            // document.getElementsByClassName('cropbox')[0].parentNode.style.top = '-'+(parseInt(document.getElementsByClassName('cropbox')[0].style.height) - 50)+'px';
           /*  document.getElementsByClassName('cropbox')[0].parentNode.style.height = document.getElementsByClassName('cropbox')[0].style.height;
            document.getElementsByClassName('cropbox')[0].parentNode.style.top = '-'+(parseInt(document.getElementsByClassName('cropbox')[0].style.height) - 50)+'px'; */
        }
    }
    const debounce = React.useCallback(
        _.debounce(() => {
            console.log('3...........')
            cropBoxM();
          //  dispatch(SET_VIDEO_CURRENTTIMEactions({ sec: timelineRef.current.getTime(), bytimeline: false })) 
        }, 100),
        []
    );
    // timelineRef.current.data = [...state.project.margeArr]
    React.useEffect(() => {
        console.log('useEffect 8')


        var data = state.project.margeArr.map((item, index) => {

            // if (index != 0) {
            return ({
                ...item,
                actions: item.actions.map((action, index) => {
                    
                    // if (action.writable === undefined) {
                    return ({
                        ...action,

                        start: Math.ceil(action.start) === 0 ? 0 : action.start,
                        end: Math.ceil(action.end) === 1 ? 1 : action.end,
                    })
                    // }

                })
            })
            // } else {
            //     return (item)
            // }

        })
        /* data.map((item,index) => {
            
            if(item.actions[0].anotationId)
            {
                dispatch(SET_EFFECTactions({ id: item.actions[0].anotationId, visibility: 'hidden' })) 
            }
        }); */
        
        console.log([...data])
        setMockdata([...data])
        
    }, [state.project.margeArr])


    React.useEffect(() => {
        console.log('useEffect 9')
        cropBoxM();
    }, [])   
    React.useEffect(() => {
        console.log('useEffect 10')
        if(props.play)
        {

            setTimeout(() => {
                if(isPlaying === false)
                {
                    timelineRef.current.play({ autoEnd: true }); 
                    setIsPlaying(true)
                    dispatch(SET_VIDEO_CURRENTTIME_STARTactions(true))
                }
            },100);
        }
        else 
        {
            setTimeout(() => {
                if(isPlaying === true)
                {
                    timelineRef.current.pause();
                    setIsPlaying(false)
                    dispatch(SET_VIDEO_CURRENTTIME_STARTactions(false))
                   
                }
            },100);
            
        }
        console.log(timelineRef)
        props.setNewRef(timelineRef);
    },[props.play])   

    React.useLayoutEffect(() => {
        cropBoxM();
    })   
    React.useLayoutEffect(() => {
        
        if (state.project.video.video_currentTime.bytimeline === false) {
            timelineRef.current.setTime(state.project.video.video_currentTime.seconds)
        }
        if (state.project.video.video_currentTime.start) {
            timelineRef.current.play({ toTime: state.project.video.timelineTiming.duration, autoEnd: true });

        } else {
            timelineRef.current.pause();
        } 

    }, [state.project.video.video_currentTime.start])


    // var last = parseInt(state.video_duration.toString().split('.')[1].charAt(0));

    const handleOnChange = (e) => {
    
        e.forEach((value,index) => {
            value.actions.forEach((value1,index1) => {
                if(value1.type === "CUTER")
                {
                    const startformatted = moment.utc(parseInt(value1.start) * 1000).format("HH:mm:ss");
                    const endformatted = moment.utc(parseInt(value1.end) * 1000).format("HH:mm:ss");
                    setTimeSecondsstart(startformatted)
                    setTimeSecondsend(endformatted)
                }
            })
        });
        
        dispatch(SET_POSITION_ANOTATIONactions(e))
    }

    const handleOnActionResizing = (e) => {
    
        if(e.action.type === "CUTER")
        {
            const startformatted = moment.utc(parseInt(e.action.start) * 1000).format("HH:mm:ss");
            const endformatted = moment.utc(parseInt(e.action.end) * 1000).format("HH:mm:ss");
            setTimeSecondsstart(startformatted)
            setTimeSecondsend(endformatted)
        }
        mockData.forEach((item,index) => {
           
            if(item.actions[0].anotationId)
            {
                dispatch(SET_EFFECTactions({ id: item.actions[0].anotationId, visibility: 'hidden' })) 
            }
             
        });
    }
    
    const handlePlayOrPause = () => {
        
        if (timelineRef.current.isPlaying) {
            timelineRef.current.pause();
            setIsPlaying(false)
            dispatch(SET_VIDEO_CURRENTTIME_STARTactions(false))
        } else {
            timelineRef.current.play({ autoEnd: true });
            setIsPlaying(true)
            dispatch(SET_VIDEO_CURRENTTIME_STARTactions(true))
        }
    }

    const cutVideoApply = () => {
        console.log("cutVideoApply...................")
        var starttimesec = timeSecondsstart.split(':').reduce((acc,time) => parseInt(60 * acc) + parseInt(time));
        var endtimesec = timeSecondsend.split(':').reduce((acc,time) => parseInt(60 * acc) + parseInt(time));
        var current_video_url = state.project.video.path.replace(apiBaseUrl+'/','');
        
        var data = {start:starttimesec,end:endtimesec,videoId:state.project.video.videoId,starttime:timeSecondsstart,current_video_url:current_video_url};
        setVideoCutProgress(true);
        cutvideosubmitActions(data).then((response) => {
            data.filename = response.data.data.filename;
            data.end = response.data.data.duration;
            data.endtime = endtimesec;
            dispatch(SET_CUTVIDEOURLactions(data)) 
            setCutVideo(false);
            setVideoCutProgress(false);

        }).catch((err) => {

        });
        timelineRef.current.setTime(0)
        dispatch(SET_VIDEO_CURRENTTIME_STARTactions(false))
    }


    const handleCutVideo = () => {
        setCutVideo(true)
        setIsPlaying(false)
        dispatch(SET_VIDEO_CURRENTTIME_STARTactions(false))
        
    }
    // const timer = ms => new Promise(res => setTimeout(res, ms));
    const handleHideShow = (time) => {
       /*  mockData.map(async(val,index)=> {
            if(val.actions[0].anotationId)
            {
                if(time >= val.actions[0].start && time < val.actions[0].end)
                { 
                    console.log('ddddd 11 enter show  ->', val, time);
                    dispatch(SET_EFFECTactions({ id: val.actions[0].anotationId, visibility: 'visible' }))
                }
                else 
                {
                    console.log('ddddd 11 enter hidden ->', val, time);
                    dispatch(SET_EFFECTactions({ id: val.actions[0].anotationId, visibility: 'hidden' }))
                }
            }
            
            await timer(1000);
        }); */
    }
    React.useEffect(() =>{
        if(!isScrollHere) {
            setScrollTop(props.scrollTop)
        }
        setIsScrollHere(false);

    }, [isScrollHere, props.scrollTop])
    const openCutVideo = () => {
        var mockDataIndex = mockData.length;
        // var mockDataacitonsIndex = mockData[mockDataIndex - 1].actions.length
        var margeArrLen = state.project.margeArr.length
        var mergeIndex = mockDataIndex - 1;
        var newIndex = -1;
        mockData.forEach((value,index) => {
            value.actions.forEach((value1,index1) => {
                if(value1.type === "CUTER")
                {
                    mergeIndex = index;
                    newIndex = index1;
                }
            })
        });
        if(newIndex !== -1)
        {

            mockData[mergeIndex].actions[newIndex] = {
                id: `Cuter`,
                type: 'CUTER',
                top: `${mockData[0].id * 49}`,
                start: 0, end: 5,
                effectId: `effect${2}`,
                height: `${(margeArrLen) * 49}`
            };
        }
        else 
        {
            mockData[0].actions.push({
                id: `Cuter`,
                type: 'CUTER',
                top: `${mockData[0].id * 49}`,
                start: 0, end: 5,
                effectId: `effect${2}`,
                height: `${(margeArrLen) * 49}`
            })
        }
        setMockdata([...mockData])
        const startformatted = moment.utc(parseInt(0) * 1000).format("HH:mm:ss");
        const endformatted = moment.utc(parseInt(5) * 1000).format("HH:mm:ss");
        setTimeSecondsstart(startformatted)
        setTimeSecondsend(endformatted)
        setCutVideo(true);
    }
    return (
        <>
            <Box className="timeline-player" style={{ display: 'flex',backgroundColor:'rgb(118 158 191)' }}>
                <div className="play-control" onClick={handlePlayOrPause} style={{ width: '4%', display: 'flex', alignItems: 'center', justifyContent: "center" }} >
                    {/* {isPlaying ? <PauseOutlined /> : <CaretRightOutlined />} */}
                    {isPlaying
                        ?
                        <PauseIcon sx={{ fontSize: '32px', cursor: 'pointer', color: '#cacaca' }} /> : <PlayIcon sx={{ fontSize: '32px', cursor: 'pointer', color: '#cacaca' }} />}
                </div>
                <div style={{ width: '4%', display: 'flex', alignItems: 'center', justifyContent: "center" }} onClick={openCutVideo}>
                  <ContentCutIcon sx={{ cursor: 'pointer', fontSize: '23px', color: '#cacaca' }} />
                </div>
                {isCutVideo ?
                    <>
                        <div>
                            <TimeField
                                showSeconds
                                value={timeSecondsstart}
                                onChange={onTimeChangeStart}
                                style={{ width: '100%' }}
                                input={<TextField variant="filled" label="Start" value={timeSecondsstart} size="small" />}
                            />
                        </div>
                        <div style={{ marginLeft: '2%' }}>
                            <TimeField
                                showSeconds
                                value={timeSecondsend}
                                onChange={onTimeChangeEnd}
                                style={{ width: '100%', color: '#ffffff' }}
                                input={<TextField variant="filled" label="End" value={timeSecondsend} size="small" sx={{ color: "#ffffff", }} />}
                            />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                            {(videoCutProgress) ? (<CircularProgress />) : (<Button variant="contained" sx={{ width: '30%' }} onClick={() => { cutVideoApply() }} >Crop</Button>)}
                            
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                            <Button variant="contained" sx={{ width: '30%' }} color="error"
                                onClick={() => {
                                    setCutVideo(false)
                                    setIsPlaying(false)
                                    dispatch(SET_VIDEO_CURRENTTIME_STARTactions(false))
                                    let mergeIndex = -1;
                                    let newIndex = -1;
                                    mockData.forEach((value,index) => {
                                        value.actions.forEach((value1,index1) => {
                                            if(value1.type === "CUTER")
                                            {
                                                mergeIndex = index;
                                                newIndex = index1;
                                            }
                                        })
                                    });
                                    if(mergeIndex !== -1 )
                                    {
                                        mockData[mergeIndex].actions.splice(newIndex, 1);
                                    }

                                }} >Cancel</Button>
                        </div>
                    </>
                    :
                    null
                }

            </Box>
            <Box sx={{ height: '88%' }}>

                
                <Timeline
                    ref={timelineRef}
                    editorData={mockData.length > 0 ? mockData : []}
                    effects={{
                        "effect0": {
                            id: "effect0",
                            name: 'Play animation',
                            source: {
                                enter: ({ action, time }) => {
                                    console.log('4...........')
                                    dispatch(SET_VIDEO_CURRENTTIMEactions({ sec: time, bytimeline: true }))
                                    
                                    handleHideShow(time);
                                    /* if(time >= action.start && time < action.end)
                                    {
                                        dispatch(SET_EFFECTactions({ id: action.anotationId, visibility: 'visible' }))
                                    }
                                    else 
                                    {
                                        dispatch(SET_EFFECTactions({ id: action.anotationId, visibility: 'hidden' }))
                                    } */
                                   // dispatch(SET_VIDEOactions({ id: action.anotationId, visibility: 'visible' }))
                                },
                                update: ({ action, time }) => {
                                    console.log('5...........')
                                    handleHideShow(time);
                                    dispatch(SET_VIDEO_CURRENTTIMEactions({ sec: time, bytimeline: true }))

                                    /* if(time >= action.start && time < action.end)
                                    {
                                        dispatch(SET_EFFECTactions({ id: action.anotationId, visibility: 'visible' }))
                                    }
                                    else 
                                    {
                                        dispatch(SET_EFFECTactions({ id: action.anotationId, visibility: 'hidden' }))
                                    } */

                                },
                                leave: ({ action, time }) => {
                                    console.log('6...........')
                                    dispatch(SET_VIDEO_CURRENTTIMEactions({ sec: time, bytimeline: true }))

                                   /*  if(time >= action.start && time < action.end)
                                    {
                                        dispatch(SET_EFFECTactions({ id: action.anotationId, visibility: 'visible' }))
                                    }
                                    else 
                                    {
                                        dispatch(SET_EFFECTactions({ id: action.anotationId, visibility: 'hidden' }))
                                    } */
                                },
                            },
                        },
                        // "effect0": {
                        //     id: "effect0",
                        //     name: 'Play animation',
                        //     source: {
                        //         enter: ({ action, time }) => {
                        //             if(time >= action.start && time < action.end)
                        //             {
                        //                 dispatch(SET_EFFECTactions({ id: action.anotationId, visibility: 'visible' }))
                        //             }
                        //             else 
                        //             {
                        //                 dispatch(SET_EFFECTactions({ id: action.anotationId, visibility: 'hidden' }))
                        //             }
                                   
                        //         },
                        //         update: ({ action, time }) => {
                        //             if(time >= action.start && time < action.end)
                        //             {
                        //                 dispatch(SET_EFFECTactions({ id: action.anotationId, visibility: 'visible' }))
                        //             }
                        //             else 
                        //             {
                        //                 dispatch(SET_EFFECTactions({ id: action.anotationId, visibility: 'hidden' }))
                        //             }
                        //         },
                        //         leave: ({ action, time }) => {
                        //             if(time >= action.start && time < action.end)
                        //             {
                        //                 dispatch(SET_EFFECTactions({ id: action.anotationId, visibility: 'visible' }))
                        //             }
                        //             else 
                        //             {
                        //                 dispatch(SET_EFFECTactions({ id: action.anotationId, visibility: 'hidden' }))
                        //             }
                        //         },
                        //     },
                        // },
                        // "effect2": {
                        //     id: "effect2",
                        //     name: 'Play animation',
                        //     source: {
                        //         enter: ({ action, time }) => {
                        //             handleHideShow(time);
                        //             if(time >= action.start && time < action.end)
                        //             {
                        //                 dispatch(SET_EFFECTactions({ id: action.anotationId, visibility: 'visible' }))
                        //             }
                        //             else 
                        //             {
                        //                 dispatch(SET_EFFECTactions({ id: action.anotationId, visibility: 'hidden' }))
                        //             }

                        //         },
                        //         update: ({ action, time }) => {
                        //             handleHideShow(time);
                        //             if(time >= action.start && time < action.end)
                        //             {
                        //                 dispatch(SET_EFFECTactions({ id: action.anotationId, visibility: 'visible' }))
                        //             }
                        //             else 
                        //             {
                        //                 dispatch(SET_EFFECTactions({ id: action.anotationId, visibility: 'hidden' }))
                        //             }

                                    
                        //         },
                        //         leave: ({ action, time }) => {
                        //             handleHideShow(time);
                        //             if(time >= action.start && time < action.end)
                        //             {
                        //                 dispatch(SET_EFFECTactions({ id: action.anotationId, visibility: 'visible' }))
                        //             }
                        //             else 
                        //             {
                        //                 dispatch(SET_EFFECTactions({ id: action.anotationId, visibility: 'hidden' }))
                        //             }
                                    

                        //         },
                        //     },
                        // },
                    }}
                    scrollTop={scrollTop}
                    // onScroll={(e)=> {
                    //     console.log(e);
                    //     props.handleScroll(e)
                    //     setIsScrollHere(true)
                    // }}
                    minScaleCount={state.video_duration}
                    style={{ height: '100%', width: '100%', backgroundColor:'#ffffff', overflowX: 'auto'}}
                    scaleWidth={props.scalewidth}
                    autoScroll={true}
                    autoReRender={true}
                    scale={5}
                    startLeft={10}
                    // scaleSplitCount={13}
                    rowHeight={46}
                    onChange={handleOnChange}
                    onActionResizing={handleOnActionResizing}
                    disableDrag={false}
                    dragLine={true}
                    onDoubleClickRow={(e, { row, time }) => {
                     openCutVideo()
                    }}
                    onCursorDragStart={(time) => {
                        console.log('079797987')
                        console.log(time);
                    }}
                    onCursorDragEnd={(time) => {
                        console.log('079797987')
                        console.log(time);
                    }}
                    getActionRender={(action, row) => {
                        
                        debounce()
                        
                        if (timelineRef.current.getTime() === state.project.video.timelineTiming.duration) {
                           
                            handlePlayOrPause()
                        }
                        // console.log('current get time ', timelineRef.current.getTime())
                        if (action.type === undefined) {
                            return <RowMain action={action} row={row} />
                        } else if (action.type === "ICON") {
                            return <Row1 action={action} row={row} />
                        } else if (action.type === "TEXT" || action.type === "CAPTION") {
                            return <Row2 action={action} row={row} />
                        } 
                        else if (action.type === "QUIZ") {
                            return <Row4 action={action} row={row} />
                        } 
                        else if (action.type === "CUTER") {
                            
                            return <Row3 action={action} row={row} />
                            
                        }

                        
                    }}

                />

            </Box>
        </>


    );
};

export default TimeLine;