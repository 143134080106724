/* eslint-disable react-hooks/exhaustive-deps */
import React, {  useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

// import AssignmentsQuizes from "./pages/AssignmentsQuizes/AssignmentsQuizes";
// import FrontendSettings from "./pages/FrontendSettings/FrontendSettings";
// import SubmitVideoPage from "./pages/SubmitVideoPage/SubmitVideoPage";

import { apiBaseUrl } from "./utils/constants";
import AppContext from "./appContext";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "react-toastify/dist/ReactToastify.css";

import { getToken, removeToken } from "./utils/helperFunctions";
import PrivateRoutes from "./Auth/PrivateRoutes";
import PublicRoutes from "./Auth/PublicRoutes";
import DashboardPage from "./pages/Dashboard/DashboardPage1";
import {
  createSessions,
  getUserDetails,
  logoutUser,
} from "./API/imageEditorApi";


// =============================

/* login component import start */
import Login from "./pages/Login/Login";
import ContactUs from "./pages/ContactUs/ContactUs";
import LogOut from "./pages/logout/logout"
/* login component import end */

/* user component import start  */
import UsersListMain from "./pages/Usersmain/usersmain"
import ListOfUserPage from "./pages/User/ListOfUserPage";
import AddAdminPage from "./pages/User/AddAdminPage";
/* user component import end  */

/* pdf component import start */
import ListOfEditors from "./pages/Editors/alleditor"
import MobileSOPDetailsAction from "./pages/MobileSop/details";
import ListOfPdfPage from "./pages/PdfEditor/ListOfPdfPage";
import PdfFormPage from "./pages/PdfEditor/PdfFormPage";
import PdfViewPage from "./pages/PdfEditor/PdfViewPage";
import PdfEditorPage from "./pages/PdfEditor/PdfEditorPage";
/* pdf component import end */

/* video component import start */
import ListOfVideoPage from "./pages/VideoPlayerPages/ListOfVideoPage";
import VideoFormPage from "./pages/VideoPlayerPages/VideoFormPage";
import VideoPlayer from "./pages/VideoPlayerPages/VideoPlayer";
import VideoPlayerFinish from "./pages/VideoPlayerPages/VideoPlayerFinish";
/* video component import end */

/* image component import start */
import ListOfImagePage from "./pages/ImageEditor/ListOfImagePage";
import AddImagePage from "./pages/ImageEditor/AddImagePage";
import ImageEditorPage from "./pages/ImageEditor/ImageEditorPage";
/* image component image end */

/* ppt component import start */
import ListOfPptPage from "./pages/PptEditor/ListOfPptPage";
import PptFormPage from "./pages/PptEditor/PptFormPage";
import PptEditorPage from "./pages/PptEditor/PptEditorPage";
import PptViewPage from "./pages/PptEditor/PptViewPage";
import { PrivacyPolicyComponent } from "./pages/FrontendSettings/PrivacyPolicy";
import { TermsAndConditions } from "./pages/FrontendSettings/TermsAndConditions";

import Faq from "./pages/Faq/Faq";
import BannerTextControl from "./pages/FrontendSettings/BannerTextControl/BannerTextControl";
import Reports from "./pages/Reports/Reports";
import ReportView from "./pages/Reports/View";
import ReportAction from "./pages/Reports/Action"
import AssignmentsQuizes from "./pages/AssignmentsQuizes/AssignmentsQuizes";
import AssignmentsQuizesUser from "./pages/AssignmentsQuizes/AssignmentsQuizesUser";
import AssignmentsQuizesResult from "./pages/AssignmentsQuizes/AssignmentsQuizesResult";
// import SplitPptView from "./components/DigitalSOP-Ppt-Editor/splitPptShow";
/* ppt component import end */

function App() {
  const [user, setUser] = useState({
    loading: true,
    data: null,
  });
  const [sidebar, setSidebar] = useState(false);

  const logout = (navigate) => {
    removeToken();
    document.cookie =
      "userId=0;  path=" +
      apiBaseUrl +
      ";Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    navigate("/login");
  };

  const fetchUserDetails = async () => {
    try {
      setUser((v) => ({
        ...v,
        loading: true,
      }));

      const res = await getUserDetails();

      if (res.status === 200) {
        // toast.success(res.data.message);
        setUser((v) => ({
          ...v,
          loading: false,
          data: res.data.data,
        }));
      }
    } catch (error) {
      toast.error("something went wrong");
      setUser((v) => ({
        ...v,
        loading: false,
        data: null,
      }));
    }
  };

  useEffect(() => {
    if (Boolean(getToken())) {
      fetchUserDetails();
    }
  }, []);

  return (
    <div>
      <AppContext.Provider
        value={{
          state: {
            user,
            sidebar,
          },
          setUser,
          setSidebar,
          logout,
          fetchUserDetails,
        }}
      >
        <BrowserRouter>
          <div
            className="flex flex-row w-full h-screen"
            style={{ backgroundColor: "#fff" }}
          >
            <div
              className="sidebarflex w-full"
              style={{ backgroundColor: "#fff" }}
            >
              <Routes>
                <Route
                  path="/Login"
                  element={<PublicRoutes Component={Login} />}
                />
                <Route
                  path="/"
                  element={<PrivateRoutes Component={DashboardPage} />}
                />

                <Route
                  path="/contactUs"
                  element={<PrivateRoutes Component={ContactUs} />}
                />
                <Route
                  path="/faq"
                  element={<PrivateRoutes Component={Faq} />}
                />

                <Route
                  path="/faq"
                  element={<PrivateRoutes Component={Faq} />}
                />

                <Route
                  path="/report"
                  element={<PrivateRoutes Component={Reports} />}
                />

                <Route
                  path="/report/view/:id"
                  element={<PrivateRoutes Component={ReportView} />}
                />

                <Route
                  path="/report/action/:id"
                  element={<PrivateRoutes Component={ReportAction} />}
                />

                <Route
                  path="/frontend-settings/banner-text-control"
                  element={<PrivateRoutes Component={BannerTextControl} />}
                />

                <Route
                  path="/logOut"
                  element={<PrivateRoutes Component={LogOut} />}
                />


                {/* user listing start */}
                <Route
                  path="/usersmain"
                  element={<PrivateRoutes Component={UsersListMain} />}
                />



                <Route
                  path="/user/list/:userType"
                  element={<PrivateRoutes Component={ListOfUserPage} />}
                />
                <Route
                  path="/user/add/:userType"
                  element={<PrivateRoutes Component={AddAdminPage} />}
                />
                <Route
                  path="/user/edit/:id"
                  element={<PrivateRoutes Component={AddAdminPage} />}
                />
                <Route
                  path="/user/view/:id"
                  element={<PrivateRoutes Component={AddAdminPage} />}
                />
                {/* user listing end */}

                {/* pdf listing start */}


                <Route
                  path="/editors"
                  element={<PrivateRoutes Component={ListOfEditors} />}
                />
                <Route
                  path="/mobile-sop/:id"
                  element={<PrivateRoutes Component={MobileSOPDetailsAction} />}
                />
                
                <Route
                  path="/quiz-result"
                  element={<PrivateRoutes Component={AssignmentsQuizes} pageName="Assignment & quiz"/>}
                />
                <Route
                  path="/quiz-result/:videoId"
                  element={<PrivateRoutes Component={AssignmentsQuizesUser} pageName="Assignment & quiz"/>}
                />
                <Route
                  path="/quiz-result/:videoId/:resultId"
                  element={<PrivateRoutes Component={AssignmentsQuizesResult} pageName="Assignment & quiz"/>}
                />


                {/* <Route
                  path="/pdflist"
                  element={<PrivateRoutes Component={ListOfPdfPage} />}
                /> */}
                <Route
                  path="/pdfform"
                  element={<PrivateRoutes Component={PdfFormPage} />}
                />
                <Route
                  path="/pdfform/:id"
                  element={<PrivateRoutes Component={PdfFormPage} />}
                />
                <Route
                  path="/pdfview/:groupId"
                  element={<PrivateRoutes Component={PdfViewPage} />}
                />
                <Route
                  path="/pdfform/:id/:page"
                  element={<PrivateRoutes Component={PdfEditorPage} />}
                />
                {/* pdf listing end */}

                {/* video list start */}
                {/* <Route
                  path="/videolist"
                  element={<PrivateRoutes Component={ListOfVideoPage} />}
                /> */}
                <Route
                  path="/videoform"
                  element={<PrivateRoutes Component={VideoFormPage} />}
                />
                <Route
                  path="/videoform/:id/manage"
                  element={<PrivateRoutes Component={VideoFormPage} />}
                />
                <Route
                  path="/videoeditor/:id"
                  element={<PrivateRoutes Component={VideoPlayer} />}
                />
                <Route
                  path="/videoeditfinish/:id"
                  element={<PrivateRoutes Component={VideoPlayerFinish} />}
                />
                {/* video list end */}

                {/* image list start */}
                <Route
                  path="/imageform"
                  element={<PrivateRoutes Component={AddImagePage} />}
                />
                <Route
                  path="/imageform/:id/manage"
                  element={<PrivateRoutes Component={AddImagePage} />}
                />
                {/* <Route
                  path="/imagelist"
                  element={<PrivateRoutes Component={ListOfImagePage} />}
                /> */}
                <Route
                  path="/editimage/:id/manage"
                  element={<PrivateRoutes Component={ImageEditorPage} />}
                />
                {/* image list end */}

                <Route
                  path="/pptform"
                  element={<PrivateRoutes Component={PptFormPage} />}
                />
                <Route
                  path="/pptlist"
                  element={<PrivateRoutes Component={ListOfPptPage} />}
                />
                <Route
                  path="/ppt-split-view/:pptId"
                  element={<PrivateRoutes Component={PptViewPage} />}
                />

                <Route
                  path="/pptform/:id/manage"
                  element={<PrivateRoutes Component={PptFormPage} />}
                />
                <Route
                  path="/Edit-Ppt/:id/:pageId"
                  element={<PrivateRoutes Component={PptEditorPage} />}
                />
                <Route
                  path="/frontend-settings/privacy-policy"
                  element={<PrivateRoutes Component={PrivacyPolicyComponent} />}
                />

                <Route
                  path="/frontend-settings/terms-n-conditions"
                  element={<PrivateRoutes Component={TermsAndConditions} />}
                />
              </Routes>
            </div>
          </div>
        </BrowserRouter>
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover={false}
          theme="light"
        />
      </AppContext.Provider>
    </div>
  );
}

export default App;
