import React, { useEffect, useState } from "react";
import {
  Link,
  useNavigate
} from "react-router-dom";
import { toast } from "react-toastify";
import {
  getPptDetails
} from "../../API";

import { apiBaseUrl } from '../../utils/constants';
function SplitPptView(props) {
  const baseURL = apiBaseUrl+'/';
  const url = window.location.pathname;
  const lastSegment = url.split("/").pop();
  let search = window.location.search;
  let params = new URLSearchParams(search);
  // let foo = params.get('submit');

  const navigate = useNavigate();

  // const [numPages, setNumPages] = useState(null);
  // const [pageNumber, setPageNumber] = useState(1);

  // function onDocumentLoadSuccess({ numPages }) {
  //   setNumPages(numPages);
  // }
  const [state, setState] = useState({
    loading: false,
    data: [],
    message: "",
    status: 0,
    pptInfo : {}
  });

  const fetchAllSplitedPpt = async (values) => {
    try {
      const res = await getPptDetails(values);
      if (res.status === 200) {
        setState({
          ...state,
          pptInfo:res.data.data, 
          data: res.data.data.pages,
        });
      }
    } catch (error) {
      toast.error("something went wrong");
      //   setUserData((v) => ({
      //     ...v,
      //     loading: false,
      //     data: null
      //   }))
    }
  };

  useEffect(() => {
    fetchAllSplitedPpt(lastSegment);

  },[]);

  window.Buffer = window.Buffer || require('buffer').Buffer;
  const downloadFile = () => {
    let row = state.pptInfo
    var link = document.createElement('a');
    if(row.edited_ppt) {
      link.href = apiBaseUrl +'/public/ppt/uploadedFile/' +row.edited_ppt;
    } else {
      link.href = apiBaseUrl +'/public/ppt/uploaded File/' +row.editable_ppt;
    }
    link.target = '__blank'
    link.download = 'file.pdf';
    link.dispatchEvent(new MouseEvent('click')); 
  }

  const { data } = state;

  return (
    <div className="rightsidebar">
    <div className="flex-row bg-primary2 text-white h-screen w-full font-comfortaa user-list">
    <div class="d-flex ">
          <div class="cursor-pointer" onClick={() => navigate(-1)}>
            <i class="fa fa-arrow-left back_arrow_style" style={{margin: 10}} aria-hidden="true"></i>
          </div>
        </div>
    <div className="mainprogrs">
      
      <ul className="multi-step-bar">
          <li className="sucses"><p>Details</p></li>
          {((params.get('submit') === '1')) 
              ?
              (<li className="sucses"><p>Edit PPT</p></li>)
              : 
              (<li className="active"><p>Edit PPT</p></li>)
          }
          {(params.get('submit') === '1') 
              ?
              (<li className="active"><p>Submit</p></li>)
              : 
              (<li><p>Submit</p></li>)
          }
          
      </ul>
      
      </div>
      <div className="flex aling-item-center ">
        <div className="  mb-1 w-1/2 pt-2 black-text">View PPT</div>
        <div className="flex font-comfortaa m-1 w-1/2 justify-end customclass">
          <Link
            to={"/pptlist"}
            className="btn"
          >
            View PPT List
          </Link>

          <Link
            to={"#"}
            className="btn btn-success"
            onClick={() => downloadFile()}
          >
            Download
          </Link>
        </div>
      </div>
      <div className="grid backgtable">
        <div className="w-full splited_ppt_file_container">
          {data?.map((row, idx) => {
            // console.log(row)
            return (
              <div
                className="splited_ppt_file_icons m-2"
                key={idx}
                onClick={() =>navigate(`/Edit-Ppt/${lastSegment}/${row.id}`)}
                style={{ border: "1px solid lightgray", borderRadius: "7px" }}
              >
                <img alt="img" src={baseURL + 'public/ppt/split/' + row.file}></img>
                
              </div>
            );
          })}
        </div>
      </div>
            
            {((params.get('submit') === '1')) 
              ? 
              (<div className="pptbackandnext">
                  <button
                    onClick={() => { toast.success("Ppt successfully saved !!");  navigate("/pptlist"); }}
                    className="btn btn-primary addbutne btn-sm mr-2"
                    id="savePpt"
                  >
                  Submit 
                  </button>
                </div>
              )
              :
              (<div className="Pptbackandnext">
                <button
                    onClick={() => {(data.length > 0) ? navigate(`/Edit-Ppt/${lastSegment}/${data[0].id}`) : alert("No Ppt found !!")}}
                    className="btn btn-primary addbutne btn-sm mr-2"
                    id="savePpt"
                  >
                  Next 
                </button>
                </div>
              )
            }

              
    </div>
    </div>
  );
}

export default SplitPptView;
