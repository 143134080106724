import React from "react";
import QuizesResult from "../../components/QuizesResult/QuizesResult";

const AssignmentsQuizesResult = () => {
  
  return (
    <>

    <QuizesResult></QuizesResult>
    </>
  );
};

export default AssignmentsQuizesResult;
