import { createSlice } from '@reduxjs/toolkit';

var INITIAL_STATE = {

    "project": {
        "projectId": "",
        "title": "",
        margeArr: [],
        "anotation": [
            // {
            //     "anotationId":"anotation_1",
            //     "imageUrl": "D:/S.M.Patel/My_Files/removedBg.png",
            //     "scale_X": 0,
            //     "scale_Y": 0,
            //     "videoTiming":{
            //         "startTime": 3,
            //         "endTime": 6,
            //         "duration": 25
            //     },
            //     "timelineTiming":{
            //         "startTime": 3,
            //         "endTime": 6,
            //         "duration": 25
            //     }
            // }
        ],
        "texts": [
            // {
            //     "textsId":"text_1",
            //     "text": "This is first Text",
            //     "fontsize": 32,
            //     "fontcolor": "red",
            //     "scale_X": 100,
            //     "scale_Y": 100,
            //     "videoTiming":{
            //         "startTime": 3,
            //         "endTime": 6,
            //         "duration": 25
            //     },
            //     "timelineTiming":{
            //         "startTime": 3,
            //         "endTime": 6,
            //         "duration": 25
            //     }
            // },

        ],

    }



};

const photoeditorReducer = createSlice({
    name: 'photoeditorReducer',
    initialState: INITIAL_STATE,
    reducers: {
        SET_ANOTATIONactions(state, action) {
            // var i = state.edited_tile.length;
            // state.project.anotation = action.payload;
            console.log('action ', action.payload)
            // state.project.anotation.push({ ...action.payload });
            state.project.margeArr.push({ ...action.payload });
            // state.project.anotation = [...state.project.anotation]
            state.project.margeArr = [...state.project.margeArr]
            console.log('state.project.margeArr ', state.project.margeArr)
        },
        SET_EDITEDTITLEactions(state, action) {

            // var i = state.edited_tile.length;
            // state.project.texts.push({ ...action.payload });
            state.project.margeArr.push({ ...action.payload });
            // state.project.texts = [...state.project.texts]
            state.project.margeArr = [...state.project.margeArr]
            console.log('aaaa', state.project.margeArr)
        },
        SET_POSITION_ANOTATIONactions(state, action) {
            // var i = state.edited_tile.length;
            console.log('payload ', action.payload)
            // state.project.margeArr[action.payload.annotationId] = action.payload.action;
            state.project.margeArr = [...action.payload]

        },
    }
})

export const {
    SET_ANOTATIONactions,
    SET_EDITEDTITLEactions,
    SET_POSITION_ANOTATIONactions } = photoeditorReducer.actions;
export default photoeditorReducer.reducer;