import "../../styles/pdfcomponent.css";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
// import ScriptTag from 'react-script-tag';
import "../../assets/pdf-custom.js";

import { allUserSplitedPdfApi, SingleSplitedPdftApi } from "../../API";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

import { toast } from "react-toastify";
// import { data } from "autoprefixer";
import { apiBaseUrl } from "./../../utils/constants";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FaHighlighter } from "react-icons/fa";

const PdfEditor = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [numPages, setNumPages] = useState(null);
  // const [pageNumber, setPageNumber] = useState(1);
  const [activePage, setActivePage] = useState(0);
  // const [currentPage, setCurrentPage] = useState(0)
  const [isViewOnly, setIsViewOnly] = useState(false);
  const [submitting, SetSubmitting] = useState(false);
  const [isInit, setIsInit] = useState(true);
  const { id, page } = useParams();
  const [estate, setEstate] = useState({
    loading: false,
    data: [],
    message: "",
    status: 0,
  });

  function onDocumentLoadSuccess({ numPages }) {
    // setNumPages(numPages);
  }

  const [menuStatus, setMenuStatus] = React.useState({
    brush: false,
    textHighlights: false,
    pencil: false,
    text: false,
    arrow: false,
    rectangle: false,
  });

  const handleClick = (name) => {
    setMenuStatus({
      ...menuStatus,
      [name]: true,
    });
  };
  const handleClose = () => {
    setMenuStatus({
      brush: false,
      textHighlights: false,
      pencil: false,
      text: false,
      arrow: false,
      rectangle: false,
    });
    setAnchorEl(null);
  };

  const editPdf = async (nextPageId) => {
    console.log("editPdf...............................");
    try {
      if (activePage !== nextPageId) {
        setEstate((v) => ({
          ...v,
          loading: true,
        }));
        SetSubmitting(true);
        SingleSplitedPdftApi(nextPageId).then((res) => {
          SetSubmitting(false);
          // currentPage = page
          if (res.status === 200) {
            setActivePage(nextPageId);
            setEstate((v) => ({
              ...v,
              loading: false,
              pdfFile: res.data.data[0],
            }));
            window.pdfInfo = {};
            window.pdfInfo.editingFile = res.data.data[0].url;
            window.pdfInfo.editFilename = res.data.data[0].filename;
            window.pdfInfo.editFormate = res.data.data[0].formate;
            window.pdfInfo.groupId = res.data.data[0].groupId;
            window.pdfInfo.splitFileId = res.data.data[0].id;
            // localStorage.setItem("editingFile", res.data.data[0].url);
            // localStorage.setItem("editType", "split + edit");
            // localStorage.setItem("editFilename", res.data.data[0].filename);
            // localStorage.setItem("editFormate", res.data.data[0].formate);
            // localStorage.setItem("groupId", res.data.data[0].groupId);
            // localStorage.setItem("splitFileId", res.data.data[0].id);
            // localStorage.setItem("editedFilePath", "public/docFiles/splitFiles/");
            //   localStorage.setItem("activePage", idx + 1);
            // document.getElementById("pdf-container").innerHTML = "";
            console.log(
              "-------------------------------------Enter0000000----------------"
            );
            // toast.error("Pdf edit successfully");
            setTimeout(() => {
              console.log(
                "-------------------------------------Enter----------------"
              );
              //  navigate("/DigitalSop-Pdf-Editor/" + id + "/" + page);
              // setTimeout(() => {

              // }, 1000)
              if (window.setPdfToCanvas) {
                window.setPdfToCanvas(false);
              }
            }, 500);
          }
        });
      }
    } catch (error) {
      toast.error("something went wrong" || error.response.statusText);
      SetSubmitting(false);
      setEstate((v) => ({
        ...v,
        loading: false,
        data: null,
      }));
    }
  };

  // const AddCssLibrary = (urlOfTheLibrary) => {
  //   const link = document.createElement("link");
  //   link.href = urlOfTheLibrary;
  //   link.rel = "stylesheet";
  //   document.body.appendChild(link);
  // };

  // AddCssLibrary(
  //   "https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"
  // );
  // AddCssLibrary(
  //   "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
  // );
  // AddCssLibrary(
  //   "https://cdnjs.cloudflare.com/ajax/libs/prettify/r298/prettify.min.css"
  // );

  // window.addEventListener(
  //   "popstate",
  //   function (event) {
  //     console.log(event);
  //     if (event.state.idx === 5) {
  //       localStorage.removeItem("canvas-appear");
  //     }
  //   },
  //   false
  // );

  const navigate = useNavigate();
  // const AddLibrary = (urlOfTheLibrary) => {
  //   const script = document.createElement("script");
  //   script.src = urlOfTheLibrary;
  //   script.async = true;
  //   document.body.appendChild(script);
  // };

  const baseURL = apiBaseUrl + "/";

  // const url = window.location.pathname;
  // const pdfFileId = parseInt(url.split("/").pop());

  const [state, setState] = useState({
    loading: false,
    pdfFile: "",
    viewer: useRef(null),
    brushSize: 1,
    borderColor: "#212121",
    backgroundColor: "#000",
  });

  function changeActiveTool(event) {
    var { $ } = window;
    var element = $(event.target).hasClass("tool-button")
      ? $(event.target)
      : $(event.target).parents(".tool-button").first();
    $(".tool-button.active").removeClass("active");
    $(element).addClass("active");
  }

  function enableSelector(event) {
    event.preventDefault();
    changeActiveTool(event);
    window.pdf.enableSelector();
  }

  function enablePencil(event, bg) {
    console.log("enable pages");
    event.preventDefault();

    var width = state.brushSize;
    window.pdf.setBrushSize(width);

    window.pdf.setColor(bg);

    changeActiveTool(event);
    window.pdf.enablePencil();
  }

  function checkColor() {
    if (
      window.pdf.color === "rgba(111, 76, 182, 0.315)" ||
      window.pdf.color === "rgba(182, 87, 76, 0.396)" ||
      window.pdf.color === "rgba(76, 182, 85, 0.396)" ||
      window.pdf.color === "rgba(175, 182, 76, 0.396)" ||
      window.pdf.color === "rgba(7, 7, 7, 0.31)"
    ) {
      window.pdf.setColor(state.borderColor);
      window.pdf.setBrushSize(1);
    }
  }

  function enableAddText(event, bg) {
    event.preventDefault();

    window.pdf.setColor(bg);

    changeActiveTool(event);
    window.pdf.enableAddText();
  }

  // function enableAddTextHighlight(event) {
  //   event.preventDefault();
  //   changeActiveTool(event);
  //   window.pdf.enableAddTextHighlight();
  // }

  function enableRectangle(event, bg) {
    event.preventDefault();
    changeActiveTool(event);
    window.pdf.setColor("transparent");
    // window.pdf.setColor("rgba(255, 0, 0, 0.3)");
    window.pdf.setBorderColor(bg);
    window.pdf.enableRectangle();
  }

  function deleteSelectedObject(event) {
    event.preventDefault();
    window.pdf.deleteSelectedObject();
  }

  function enableAddArrow(event, bg) {
    event.preventDefault();
    window.pdf.setColor(bg);

    changeActiveTool(event);
    window.pdf.enableAddArrow();
  }

  function addImage(event) {
    event.preventDefault();
    window.pdf.addImageToCanvas();
  }

  function undoAction(event) {
    event.preventDefault();
    window.pdf.undoAction();
  }

  function redoAction(event) {
    event.preventDefault();
    window.pdf.redoAction();
  }

  async function savePDF() {
    // const pdfInfo = await window.pdf.savePdf();
    // if (pdfInfo) {
    //   console.log("Hello");
    //   setTimeout(() => {
    //     localStorage.removeItem("canvas-appear");
    //     navigate("/pdfview/" + localStorage.getItem("groupId"));
    //   })

    // }
    // setTimeout(() => {
    //   localStorage.removeItem("canvas-appear");
    //   window.localStorage.setItem('submitredirct', 1);
    //   navigate("/pdfview/" + id);
    // })
    setIsViewOnly(true);
  }

  async function submit() {
    try {
      SetSubmitting(true);
      await window.pdf.savePdf();
      SetSubmitting(false);
      navigate("/editors?type=pdf");
    } catch (e) {
      SetSubmitting(false);
      toast.error("something went wrong");
    }
  }
  function tempSavePDF() {
    window.pdf.tempSavePDF();
  }

  function clearPage() {
    window.pdf.clearActivePage();
  }

  function highlighter(event, bg) {
    event.preventDefault();
    changeActiveTool(event);
    var width = 15;
    window.pdf.setBrushSize(width);
    var color = bg;
    window.pdf.setColor(color);
    window.pdf.enablePencil();
  }

  const setBorderColor = (bdrColor) => {
    setState({
      ...state,
      borderColor: bdrColor,
    });
    window.pdf.setColor(bdrColor);
  };

  // const reload = () => {
  //   localStorage.setItem("canvas-appear", "false");
  //   window.location.reload();
  // };

  const goBack = () => {
    // navigate(-1)
  };

  useEffect(() => {
    if (isInit === true) {
      setIsInit(false);
      let values = {
        groupId: id,
      };
      const currentPageLoc = localStorage.getItem("currentPage");
      allUserSplitedPdfApi(values).then((res) => {
        setEstate({
          ...estate,
          data: res.data.data,
        });
        let dataToSet = res.data.data[0];
        if (currentPageLoc) {
          const dataT = res.data.data.find(
            (ob) => ob.id.toString() === currentPageLoc.toString()
          );
          if (dataT) {
            dataToSet = dataT;
          }
        }
        window.pdfInfo = {};
        window.pdfInfo.editingFile = dataToSet.url;
        window.pdfInfo.editFilename = dataToSet.filename;
        window.pdfInfo.editFormate = dataToSet.formate;
        window.pdfInfo.groupId = dataToSet.groupId;
        window.pdfInfo.splitFileId = dataToSet.id;

        // localStorage.setItem("editingFile", dataToSet.url);
        // localStorage.setItem("editType", "split + edit");
        // localStorage.setItem("editFilename", dataToSet.filename);
        // localStorage.setItem("editFormate", dataToSet.formate);
        // localStorage.setItem("groupId", dataToSet.groupId);
        // localStorage.setItem("splitFileId", dataToSet.id);
        //  localStorage.setItem("editedFilePath", "public/docFiles/splitFiles/");
        if (window.setPdfToCanvas) {
          window.setPdfToCanvas(true);
        }
      });
    }

    // if (localStorage.getItem("canvas-appear")) {
    // } else {
    // localStorage.setItem("canvas-appear", "true");

    //   // window.location.reload();
    // }
  }, [isInit, estate, id]);

  // const fetchAllSplitedPdf = async (values) => {
  //   try {

  //   } catch (error) {
  //     toast.error("something went wrong" || error.response.statusText);
  //   }
  // };

  useEffect(() => {
    console.log("page...............................", page);
    // setCurrentPage(page);
    setActivePage(Number(page));
  }, [page]);

  const { data } = estate;

  return (
    <>
      <div className="rightsidebar pdfeditform">
        <div className=" adduser text-font1 w-full desbordcard">
          <div class="d-flex ">
            <div
              class="cursor-pointer"
              onClick={() => {
                if (isViewOnly) {
                  setIsViewOnly(false);
                } else {
                  navigate(-1);
                }
              }}
            >
              <i
                class="fa fa-arrow-left back_arrow_style"
                aria-hidden="true"
              ></i>
            </div>
            <div className="handleimg"></div>
          </div>
          <p className="titileditor">Edit PDF</p>
          <div className="flex-row pt-4 pb-4 bg-primary2 text-white w-full font-comfortaa user-list editpdfmain">
            <div className="mainprogrs">
              <ul className="multi-step-bar">
                <li className="sucses">
                  <p>Details</p>
                </li>
                <li className={isViewOnly ? "sucses" : "active"}>
                  <p>Edit PDF</p>
                </li>
                <li className={isViewOnly ? "active" : ""}>
                  <p>Submit</p>
                </li>
              </ul>
            </div>

            {!isViewOnly && (
              <div className="flex toolbarmain editpdfpage">
                <div
                  className="toolbar"
                  style={{ width: "100%", gap: "2%", justifyContent: "center" }}
                >
                  <div className="tool">
                    {/* <a href="#" onClick={()=> {dispatch(UNDO_ANOTATIONactions())}} style={{textAlign:"right",paddingRight:"5px",borderRight:"1px solid gray"}}><UndoIcon style={{fill:"#659EC7"}}/></a>
              <a href="#"  onClick={()=> {dispatch(REDO_ANOTATIONactions())}} style={{textAlign:"right",paddingLeft:"5px"}}><RedoIcon style={{fill:"#659EC7"}}/></a> */}

                    <button
                      onClick={undoAction}
                      style={{
                        textAlign: "right",
                        paddingRight: "5px",
                        borderRight: "1px solid gray",
                      }}
                    >
                      <UndoIcon style={{ fill: "#659EC7" }} />
                    </button>
                    <button
                      onClick={redoAction}
                      style={{ textAlign: "right", paddingLeft: "5px" }}
                    >
                      <RedoIcon style={{ fill: "#659EC7" }} />
                    </button>
                  </div>
                  <div className="tool">
                    <label for="">Pencil size</label>
                    <input
                      type="number"
                      className="form-control text-right"
                      value={state.brushSize}
                      id="brush-size"
                      max="50"
                      onChange={(e) => {
                        setState({ ...state, brushSize: e.target.value });
                        window.pdf.setBrushSize(e.target.value);
                      }}
                    ></input>
                  </div>
                  <div className="tool">
                    <label for="">Font size</label>
                    <select
                      id="font-size"
                      className="form-control"
                      onChange={(e) => {
                        window.pdf.setFontSize(e.target.value);
                      }}
                    >
                      <option value="10">10</option>
                      <option value="12">12</option>
                      <option value="16" selected>
                        16
                      </option>
                      <option value="18">18</option>
                      <option value="24">24</option>
                      <option value="32">32</option>
                      <option value="48">48</option>
                      <option value="64">64</option>
                      <option value="72">72</option>
                      <option value="108">108</option>
                    </select>
                  </div>
                  {/* <div className="tool">
                    <button
                      onClick={() => setBorderColor("#212121")}
                      className={`color-tool ${
                        state.borderColor === "#212121" ? "active" : ""
                      }`}
                      style={{ backgroundColor: "#212121" }}
                    ></button>
                    <button
                      onClick={() => setBorderColor("red")}
                      className={`color-tool ${
                        state.borderColor === "red" ? "active" : ""
                      }`}
                      style={{ backgroundColor: "red" }}
                    ></button>
                    <button
                      onClick={() => setBorderColor("blue")}
                      className={`color-tool ${
                        state.borderColor === "blue" ? "active" : ""
                      }`}
                      style={{ backgroundColor: "blue" }}
                    ></button>
                    <button
                      onClick={() => setBorderColor("green")}
                      className={`color-tool ${
                        state.borderColor === "green" ? "active" : ""
                      }`}
                      style={{ backgroundColor: "green" }}
                    ></button>
                    <button
                      onClick={() => setBorderColor("yellow")}
                      className={`color-tool ${
                        state.borderColor === "yellow" ? "active" : ""
                      }`}
                      style={{ backgroundColor: "yellow" }}
                    ></button>
                  </div> */}
                  <div className="tool">
                    <button className="tool-button active" id="enableSelector">
                      <i
                        className="fa fa-hand-paper-o"
                        title="Free Hand"
                        onClick={(event) => enableSelector(event)}
                      ></i>
                    </button>
                  </div>

                  <div className="tool">
                    <button
                      className="tool-button"
                      id="enableAddTextHighlight"
                      onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        handleClick("brush");
                      }}
                    >
                      <i
                        className="fa fa-paint-brush"
                        aria-hidden="true"
                        title="Text Highlightor"
                        // onClick={(event) => enableAddTextHighlight(event)}
                      ></i>
                    </button>
                  </div>

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={menuStatus.brush}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      onClick={(event) => {
                        handleClose();
                        highlighter(event, "rgba(90, 13, 244, 0.978)");
                      }}
                      style={{
                        margin: "5px",
                        marginLeft: "10px",
                        marginRight: "10px",
                        width: "5px",
                        height: "25px",
                        borderRadius: "10%",
                        backgroundColor: "rgba(90, 13, 244, 0.978)",
                      }}
                    ></MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        handleClose();
                        highlighter(event, "rgba(247, 35, 11)");
                      }}
                      style={{
                        margin: "5px",
                        marginLeft: "10px",
                        marginRight: "10px",
                        width: "5px",
                        height: "25px",
                        borderRadius: "10%",
                        backgroundColor: "rgb(247, 35, 11)",
                      }}
                    ></MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        handleClose();
                        highlighter(event, "rgba(8, 249, 28)");
                      }}
                      style={{
                        margin: "5px",
                        marginLeft: "10px",
                        marginRight: "10px",
                        width: "5px",
                        height: "25px",
                        borderRadius: "10%",
                        backgroundColor: "rgb(8, 249, 28)",
                      }}
                    ></MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        handleClose();
                        highlighter(event, "rgba(249, 233, 5)");
                      }}
                      style={{
                        margin: "5px",
                        marginLeft: "10px",
                        marginRight: "10px",
                        width: "5px",
                        height: "25px",
                        borderRadius: "10%",
                        backgroundColor: "rgb(249, 233, 5)",
                      }}
                    ></MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        handleClose();
                        highlighter(event, "#212121");
                      }}
                      style={{
                        margin: "5px",
                        marginLeft: "10px",
                        marginRight: "10px",
                        width: "5px",
                        height: "25px",
                        borderRadius: "10%",
                        backgroundColor: "black",
                      }}
                    ></MenuItem>
                  </Menu>

                  <div className="tool">
                    <button
                      className="tool-button"
                      id="enablePencil"
                      onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        handleClick("textHighlights");

                        // enablePencil(event);
                        // checkColor();
                      }}
                    >
                      <FaHighlighter
                        style={{ color: "#2699fb" }}
                      ></FaHighlighter>
                    </button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={menuStatus.textHighlights}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem
                        onClick={(event) => {
                          handleClose();
                          highlighter(event, "rgba(111, 76, 182, 0.315)");
                        }}
                        style={{
                          margin: "5px",
                          marginLeft: "10px",
                          marginRight: "10px",
                          width: "5px",
                          height: "25px",
                          borderRadius: "10%",
                          backgroundColor: "rgba(90, 13, 244, 0.978)",
                        }}
                      ></MenuItem>
                      <MenuItem
                        onClick={(event) => {
                          handleClose();
                          highlighter(event, "rgba(182, 87, 76, 0.396)");
                        }}
                        style={{
                          margin: "5px",
                          marginLeft: "10px",
                          marginRight: "10px",
                          width: "5px",
                          height: "25px",
                          borderRadius: "10%",
                          backgroundColor: "rgb(247, 35, 11)",
                        }}
                      ></MenuItem>
                      <MenuItem
                        onClick={(event) => {
                          handleClose();
                          highlighter(event, "rgba(76, 182, 85, 0.396)");
                        }}
                        style={{
                          margin: "5px",
                          marginLeft: "10px",
                          marginRight: "10px",
                          width: "5px",
                          height: "25px",
                          borderRadius: "10%",
                          backgroundColor: "rgb(8, 249, 28)",
                        }}
                      ></MenuItem>
                      <MenuItem
                        onClick={(event) => {
                          handleClose();
                          highlighter(event, "rgba(175, 182, 76, 0.396)");
                        }}
                        style={{
                          margin: "5px",
                          marginLeft: "10px",
                          marginRight: "10px",
                          width: "5px",
                          height: "25px",
                          borderRadius: "10%",
                          backgroundColor: "rgb(249, 233, 5)",
                        }}
                      ></MenuItem>
                      <MenuItem
                        onClick={(event) => {
                          handleClose();
                          highlighter(event, "rgba(7, 7, 7, 0.31)");
                        }}
                        style={{
                          margin: "5px",
                          marginLeft: "10px",
                          marginRight: "10px",
                          width: "5px",
                          height: "25px",
                          borderRadius: "10%",
                          backgroundColor: "black",
                        }}
                      ></MenuItem>
                    </Menu>
                  </div>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={menuStatus.brush}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      onClick={(event) => {
                        handleClose();
                        highlighter(event, "rgba(90, 13, 244, 0.978)");
                      }}
                      style={{
                        margin: "5px",
                        marginLeft: "10px",
                        marginRight: "10px",
                        width: "5px",
                        height: "25px",
                        borderRadius: "10%",
                        backgroundColor: "rgba(90, 13, 244, 0.978)",
                      }}
                    ></MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        handleClose();
                        highlighter(event, "rgba(247, 35, 11)");
                      }}
                      style={{
                        margin: "5px",
                        marginLeft: "10px",
                        marginRight: "10px",
                        width: "5px",
                        height: "25px",
                        borderRadius: "10%",
                        backgroundColor: "rgb(247, 35, 11)",
                      }}
                    ></MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        handleClose();
                        highlighter(event, "rgba(8, 249, 28)");
                      }}
                      style={{
                        margin: "5px",
                        marginLeft: "10px",
                        marginRight: "10px",
                        width: "5px",
                        height: "25px",
                        borderRadius: "10%",
                        backgroundColor: "rgb(8, 249, 28)",
                      }}
                    ></MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        handleClose();
                        highlighter(event, "rgba(249, 233, 5)");
                      }}
                      style={{
                        margin: "5px",
                        marginLeft: "10px",
                        marginRight: "10px",
                        width: "5px",
                        height: "25px",
                        borderRadius: "10%",
                        backgroundColor: "rgb(249, 233, 5)",
                      }}
                    ></MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        handleClose();
                        highlighter(event, "#212121");
                      }}
                      style={{
                        margin: "5px",
                        marginLeft: "10px",
                        marginRight: "10px",
                        width: "5px",
                        height: "25px",
                        borderRadius: "10%",
                        backgroundColor: "black",
                      }}
                    ></MenuItem>
                  </Menu>

                  <div className="tool">
                    <button
                      className="tool-button"
                      id="enablePencil"
                      onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        handleClick("pencil");
                      }}
                    >
                      <i className="fa fa-pencil" title="Pencil"></i>
                    </button>
                  </div>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={menuStatus.pencil}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      onClick={(event) => {
                        handleClose();
                        enablePencil(event, "rgba(90, 13, 244, 0.978)");
                      }}
                      style={{
                        margin: "5px",
                        marginLeft: "10px",
                        marginRight: "10px",
                        width: "5px",
                        height: "25px",
                        borderRadius: "10%",
                        backgroundColor: "rgba(90, 13, 244, 0.978)",
                      }}
                    ></MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        handleClose();
                        enablePencil(event, "rgba(247, 35, 11)");
                      }}
                      style={{
                        margin: "5px",
                        marginLeft: "10px",
                        marginRight: "10px",
                        width: "5px",
                        height: "25px",
                        borderRadius: "10%",
                        backgroundColor: "rgb(247, 35, 11)",
                      }}
                    ></MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        handleClose();
                        enablePencil(event, "rgba(8, 249, 28)");
                      }}
                      style={{
                        margin: "5px",
                        marginLeft: "10px",
                        marginRight: "10px",
                        width: "5px",
                        height: "25px",
                        borderRadius: "10%",
                        backgroundColor: "rgb(8, 249, 28)",
                      }}
                    ></MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        handleClose();
                        enablePencil(event, "rgba(249, 233, 5)");
                      }}
                      style={{
                        margin: "5px",
                        marginLeft: "10px",
                        marginRight: "10px",
                        width: "5px",
                        height: "25px",
                        borderRadius: "10%",
                        backgroundColor: "rgb(249, 233, 5)",
                      }}
                    ></MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        handleClose();
                        enablePencil(event, "#212121");
                      }}
                      style={{
                        margin: "5px",
                        marginLeft: "10px",
                        marginRight: "10px",
                        width: "5px",
                        height: "25px",
                        borderRadius: "10%",
                        backgroundColor: "black",
                      }}
                    ></MenuItem>
                  </Menu>
                  <div className="tool">
                    <button
                      className="tool-button"
                      id="enableAddText"
                      onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        handleClick("text");
                      }}
                    >
                      <i className="fa fa-font" title="Add Text"></i>
                    </button>
                  </div>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={menuStatus.text}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      onClick={(event) => {
                        handleClose();
                        enableAddText(event, "rgba(90, 13, 244, 0.978)");
                      }}
                      style={{
                        margin: "5px",
                        marginLeft: "10px",
                        marginRight: "10px",
                        width: "5px",
                        height: "25px",
                        borderRadius: "10%",
                        backgroundColor: "rgba(90, 13, 244, 0.978)",
                      }}
                    ></MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        handleClose();
                        enableAddText(event, "rgba(247, 35, 11)");
                      }}
                      style={{
                        margin: "5px",
                        marginLeft: "10px",
                        marginRight: "10px",
                        width: "5px",
                        height: "25px",
                        borderRadius: "10%",
                        backgroundColor: "rgb(247, 35, 11)",
                      }}
                    ></MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        handleClose();
                        enableAddText(event, "rgba(8, 249, 28)");
                      }}
                      style={{
                        margin: "5px",
                        marginLeft: "10px",
                        marginRight: "10px",
                        width: "5px",
                        height: "25px",
                        borderRadius: "10%",
                        backgroundColor: "rgb(8, 249, 28)",
                      }}
                    ></MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        handleClose();
                        enableAddText(event, "rgba(249, 233, 5)");
                      }}
                      style={{
                        margin: "5px",
                        marginLeft: "10px",
                        marginRight: "10px",
                        width: "5px",
                        height: "25px",
                        borderRadius: "10%",
                        backgroundColor: "rgb(249, 233, 5)",
                      }}
                    ></MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        handleClose();
                        enableAddText(event, "#212121");
                      }}
                      style={{
                        margin: "5px",
                        marginLeft: "10px",
                        marginRight: "10px",
                        width: "5px",
                        height: "25px",
                        borderRadius: "10%",
                        backgroundColor: "black",
                      }}
                    ></MenuItem>
                  </Menu>

                  <div className="tool">
                    <button
                      className="tool-button"
                      id="enableAddArrow"
                      onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        handleClick("arrow");
                      }}
                    >
                      <i
                        className="fa fa-long-arrow-right"
                        title="Add Arrow"
                      ></i>
                    </button>
                  </div>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={menuStatus.arrow}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      onClick={(event) => {
                        handleClose();
                        enableAddArrow(event, "rgba(90, 13, 244, 0.978)");
                      }}
                      style={{
                        margin: "5px",
                        marginLeft: "10px",
                        marginRight: "10px",
                        width: "5px",
                        height: "25px",
                        borderRadius: "10%",
                        backgroundColor: "rgba(90, 13, 244, 0.978)",
                      }}
                    ></MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        handleClose();
                        enableAddArrow(event, "rgba(247, 35, 11)");
                      }}
                      style={{
                        margin: "5px",
                        marginLeft: "10px",
                        marginRight: "10px",
                        width: "5px",
                        height: "25px",
                        borderRadius: "10%",
                        backgroundColor: "rgb(247, 35, 11)",
                      }}
                    ></MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        handleClose();
                        enableAddArrow(event, "rgba(8, 249, 28)");
                      }}
                      style={{
                        margin: "5px",
                        marginLeft: "10px",
                        marginRight: "10px",
                        width: "5px",
                        height: "25px",
                        borderRadius: "10%",
                        backgroundColor: "rgb(8, 249, 28)",
                      }}
                    ></MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        handleClose();
                        enableAddArrow(event, "rgba(249, 233, 5)");
                      }}
                      style={{
                        margin: "5px",
                        marginLeft: "10px",
                        marginRight: "10px",
                        width: "5px",
                        height: "25px",
                        borderRadius: "10%",
                        backgroundColor: "rgb(249, 233, 5)",
                      }}
                    ></MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        handleClose();
                        enableAddArrow(event, "#212121");
                      }}
                      style={{
                        margin: "5px",
                        marginLeft: "10px",
                        marginRight: "10px",
                        width: "5px",
                        height: "25px",
                        borderRadius: "10%",
                        backgroundColor: "black",
                      }}
                    ></MenuItem>
                  </Menu>
                  <div className="tool">
                    <button
                      className="tool-button"
                      id="enableRectangle"
                      onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        handleClick("rectangle");
                      }}
                    >
                      <i className="fa fa-square-o" title="Add rectangle"></i>
                    </button>
                  </div>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={menuStatus.rectangle}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      onClick={(event) => {
                        handleClose();
                        enableRectangle(event, "rgba(90, 13, 244, 0.978)");
                      }}
                      style={{
                        margin: "5px",
                        marginLeft: "10px",
                        marginRight: "10px",
                        width: "5px",
                        height: "25px",
                        borderRadius: "10%",
                        backgroundColor: "rgba(90, 13, 244, 0.978)",
                      }}
                    ></MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        handleClose();
                        enableRectangle(event, "rgba(247, 35, 11)");
                      }}
                      style={{
                        margin: "5px",
                        marginLeft: "10px",
                        marginRight: "10px",
                        width: "5px",
                        height: "25px",
                        borderRadius: "10%",
                        backgroundColor: "rgb(247, 35, 11)",
                      }}
                    ></MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        handleClose();
                        enableRectangle(event, "rgba(8, 249, 28)");
                      }}
                      style={{
                        margin: "5px",
                        marginLeft: "10px",
                        marginRight: "10px",
                        width: "5px",
                        height: "25px",
                        borderRadius: "10%",
                        backgroundColor: "rgb(8, 249, 28)",
                      }}
                    ></MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        handleClose();
                        enableRectangle(event, "rgba(249, 233, 5)");
                      }}
                      style={{
                        margin: "5px",
                        marginLeft: "10px",
                        marginRight: "10px",
                        width: "5px",
                        height: "25px",
                        borderRadius: "10%",
                        backgroundColor: "rgb(249, 233, 5)",
                      }}
                    ></MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        handleClose();
                        enableRectangle(event, "#212121");
                      }}
                      style={{
                        margin: "5px",
                        marginLeft: "10px",
                        marginRight: "10px",
                        width: "5px",
                        height: "25px",
                        borderRadius: "10%",
                        backgroundColor: "black",
                      }}
                    ></MenuItem>
                  </Menu>

                  <div className="tool">
                    <button
                      className="tool-button"
                      id="addImage"
                      onClick={(event) => addImage(event)}
                    >
                      <i className="fa fa-picture-o" title="Add an Image"></i>
                    </button>
                  </div>
                  <div className="tool">
                    <button
                      className="btn  btn-danger btn-sm"
                      id="deleteSelectedObject"
                      onClick={(event) => deleteSelectedObject(event)}
                    >
                      <i className="fa fa-trash" color="error"></i>
                    </button>
                  </div>
                  <div className="tool">
                    <button
                      className="btn btn-danger btn-sm"
                      id="clearPage"
                      onClick={(event) => clearPage(event)}
                    >
                      Clear Page
                    </button>
                  </div>
                  {/* <div className="tool">
        <button className="btn btn-info btn-sm" onClick={ (event) => showPdfData(event) }>{}</button>
      </div> */}

                  <div className="tool">
                    <button
                      className="btn btn-primary btn-sm"
                      id="savePDF"
                      onClick={(event) => savePDF(event)}
                    >
                      <i className="fa fa-save"></i> Next
                    </button>
                  </div>
                </div>
              </div>
            )}
            {isViewOnly && (
              <div className="flex toolbarmain editpdfpage">
                <div className="toolbar" style={{ width: "100%" }}>
                  <div className="tool">
                    <button
                      className="btn btn-primary btn-sm"
                      id="savePDF"
                      onClick={(event) => submit(event)}
                    >
                      <i className="fa fa-save"></i>{" "}
                      {submitting ? "Submitting" : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            )}
            <div className=" backgtable editpdfpage wrapper-14">
              <div className="row">
                <div className="col-3 pl-0"></div>

                <div
                  className="col-6 foroverflow"
                  style={{ height: "100vh", overflow: "auto" }}
                >
                  <div id="pdf-container"></div>
                </div>

                <div className="col-3 pl-0">
                  <div className="grid backgtable mt-2">
                    <div className="w-full file_container custom-scroller">
                      {data?.map((row, idx) => {
                        // console.log(row)
                        return (
                          <div className="bg-white">
                            <div
                              className="splited_pdf_file m-2"
                              key={idx}
                              style={{
                                border: "1px solid lightgray",
                                borderRadius: "5px",
                              }}
                            >
                              <img
                                src={
                                  baseURL +
                                  "public/docFiles/splitFiles/" +
                                  row.pdf_image
                                }
                                alt="img"
                                onClick={() => {
                                  tempSavePDF();
                                  editPdf(row.id);
                                }}
                                className="splited_pdf_file_canvac"
                              ></img>

                              {/* <Document
                              file={baseURL + row.url}
                              onLoadSuccess={onDocumentLoadSuccess}
                              onClick={() => { tempSavePDF(); editPdf(currentPage, row.id, idx) }}
                              className="splited_pdf_file_canvac"
                            >
                              <Page pageNumber={1}
                                width="240"
                                scale={1.0}
                              />
                            </Document> */}
                            </div>
                            <p
                              style={{
                                color: "#2699fb",
                                textAlign: "center",
                                fontSize: 12,
                                maxWidth: 240,
                                fontFamily: "Arial",
                                fontWeight: 400,
                              }}
                            >
                              Page {idx + 1}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-4 vertylegular">
              <div className="tool">
                {/* <button
                className="btn btn-primary btn-sm mr-2"
                id="savePDF"
                disabled={!(activePage - 1)}
                onClick={() => editPdf(data[activePage - 2].id, activePage-2)}
              >
                <i className="fa fa-angle-left"></i> Prev
              </button> */}
                <div className="pdfbackandnext">
                  {/* <button
                    className="btn btn-primary btn-sm"
                    id="savePDF"
                    onClick={(event) => {
                      tempSavePDF();
                      editPdf(activePage - 1);
                    }}
                  >
                    Back
                  </button> */}
                  {!isViewOnly && (
                    <button
                      className="addbutne logoutno d-flex justify-content-center m-auto"
                      style={{ width: 'max-content' }}
                      id="savePDF"
                      // disabled={activePage >= data?.length}
                      onClick={() => {
                        savePDF();
                        // tempSavePDF();
                        // editPdf(activePage + 1);
                      }}
                    >
                      Next
                    </button>
                  )}
                  {isViewOnly && !submitting && (
                   <>
                      <button
                        className="addbutne logoutno d-flex justify-content-center m-auto"
                        style={{ width: 'max-content' }}
                        id="submit"
                        // disabled={activePage >= data?.length}
                        onClick={(event) => {
                          submit(event);
                          // tempSavePDF();
                          // editPdf(activePage + 1);
                        }}
                      >
                        Submit
                      </button>
                    </>
                  )}
                  {isViewOnly && submitting && (
                   <>
                      <button
                        className="addbutne logoutno d-flex justify-content-center m-auto"  style={{ width: 'max-content' }}>
                         
                        <><div className="dot-pulse-text">Processing</div> <div className="dot-pulse-container m-auto"><div className="dot-pulse"></div></div></>
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PdfEditor;
