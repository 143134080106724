import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import rendervideoReducer from '../redux/Reducers/rendervideoReducer'
import headerReducer from '../redux/Reducers/headerReducer'
import photoeditorReducer from '../redux/Reducers/photoeditorReducer';


export default configureStore({
  reducer: {
    counter: counterReducer,
    rendervideoReducer,
    headerReducer,
    photoeditorReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }),
});

// export default {
//   counter: counterReducer,
//   rendervideoReducer
// };