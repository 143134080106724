import React, { useEffect } from "react";
import { useState } from "react";
import { contactusDelete, contactusList } from "../../API";
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";

import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

import AOS from "aos";
import "aos/dist/aos.css";
import { toast } from "react-toastify";
import { DataGrid } from "@mui/x-data-grid";

import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Stack } from "react-bootstrap";

const style = {
  top: "50%",
  left: "50%",
  right: "auto",
  bottom: "auto",
  marginRight: "-50%",
  transform: "translate(-50%, -50%)",
  zIndex: 9999999999999,
  border: "1px solid blue",
};

const ContactListComponent = () => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    console.log("-------------------------");
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const [tableConfig, setTableConfig] = useState({
    page: 0,
    pageSize: 15,
    orderBy: "id",
    orderType: "desc",
  });

  const [state, setState] = useState({
    data: [],
    count: 0,
    selectedItem: {},
  });

  useEffect(() => {
    contactusListGet();
  }, [tableConfig]);
  useEffect(() => {
    AOS.init({
      easing: "ease-out-sine",
      duration: 800,
    });
    AOS.refresh();
  }, []);
  const contactusListGet = async () => {
    const items = await contactusList(
      `orderby=${tableConfig.orderBy}&ordertype=${tableConfig.orderType}&limit=${tableConfig.pageSize}&page=${tableConfig.page}`
    );
    setState({
      ...state,
      data: items.data.data.rows,
      count: items.data.data.count,
    });
  };

  const contacDelete = async (id) => {
    const items = await contactusDelete(id);
    console.log(items);
    if (items.data && items.data.success) {
      toast.success("Delete record successfully");
      contactusListGet();
    }
  };

  const columns = [
    { field: "firstName", headerName: "Name", width: 300 },
    { field: "mobile", headerName: "Contact No.", width: 400 },
    { field: "email", headerName: "Email", width: 450 },
    {
      field: "id",
      headerName: "Action",
      sortable: false,
      width: 200,
      renderCell: (cellValues) => {
        return (
          <Stack
            direction="row"
            className="editbut"
            style={{ flexDirection: "row" }}
          >
            <Button
              className="nocolor"
              variant="contained"
              style={{ width: "fit-content" }}
              onClick={() => {
                setState({ ...state, selectedItem: cellValues.row });
                handleOpen();
              }}
            >
              <VisibilityIcon />
            </Button>{" "}
            <Button
              className="nocolor delete"
              color="error"
              variant="contained"
              style={{ width: "fit-content" }}
              onClick={() => contacDelete(cellValues.row?.id)}
            >
              <DeleteIcon />
            </Button>
          </Stack>
        );
      },
    },
  ];

  const setPage = (data) => {
    setTableConfig({
      ...tableConfig,
      page: data,
    });
  };
  const setPageSize = (data) => {
    setTableConfig({
      ...tableConfig,
      pageSize: data,
    });
  };

  const handleSortModelChange = (data) => {
    if (data && data[0]) {
      setTableConfig({
        ...tableConfig,
        orderBy: data[0].field,
        orderType: data[0].sort,
      });
    } else {
      setTableConfig({
        ...tableConfig,
        orderBy: "id",
        orderType: "desc",
      });
    }
  };
  return (
    <>
      <div >
        <div className="flex-row bg-primary2 text-white h-screen w-full font-comfortaa user-list">
          <div className="bordernone" style={{ height: 500, width: "100%" }}>
            <DataGrid
              className="painnation"
              getRowHeight={() => "auto"}
              columns={columns}
              disableColumnMenu
              rowsPerPageOptions={[15, 50, 100]}
              rows={state.data}
              rowCount={state.count}
              paginationModel={tableConfig}
              pagination
              paginationMode="server"
              page={tableConfig.page}
              pageSize={tableConfig.pageSize}
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              onSortModelChange={handleSortModelChange}
            />
          </div>
          {/* <div className="grid backgtable">
            <div className="w-full flex" >
              <table className="flex w-full text-black text-center items-center font-comfortaa" style={{ justifyContent: "space-between" }}>
                <th className="py-4 " style={{ width: "30%" }}>Name</th>
                <th className="py-4 " style={{ width: "30%" }}>Contact No.</th>
                <th className="py-4 " style={{ width: "60%" }}>Email</th>
                <th className="py-4 " style={{ width: "20%" }}>View</th>
                <th className="py-4 " style={{ width: "20%" }}>Delete</th>
              </table>
            </div>

            {state.data.map((item, index) => {
              return <div key={index} className="flex backtablecolor ">
                <table className="flex w-full  text-black text-center">
                  <tr className="flex w-full" style={{ justifyContent: "space-between" }}>
                    <td className="py-2" style={{ width: "30%" }}>{item.firstName}</td>
                    <td className="py-2" style={{ width: "30%" }}>{item.mobile}</td>
                    <td className="py-2" style={{ width: "60%" }}>{item.email}</td>
                    <td className="py-2" style={{ width: "20%" }}><Button
                      variant="contained"
                      className="addbutne addsopbtn"
                      onClick={() => {
                        setState({...state, selectedItem : item})
                        handleOpen()
                      }}
                    >
                      View
                    </Button></td>
                    <td className="py-2" style={{ width: "20%" }}><Button
                      variant="contained"
                      className="btn btn-danger"
                      style={{ background: 'red' }}
                      onClick={() => {
                        contacDelete(item.id) 
                      }}
                    >
                      Delete
                    </Button></td>
                  </tr>
                </table>
              </div>
            })}
          </div> */}
        </div>
      </div>

      <Modal
        isOpen={open}
        style={{
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          zIndex: 9999999999999,
          border: "1px solid blue",
        }}
        contentLabel="Example Modal"
      >
        <div>
          <div>
            <h4>Contact Us Details</h4>
          </div>

          <div className="row ">
            <div className="pt-3">
              <div>
                <span>
                  <b>First Name : </b>
                  {state.selectedItem.firstName}
                </span>
              </div>
              <div>
                <span>
                  <b>Last Name : </b>
                  {state.selectedItem.lastName}
                </span>
              </div>
              <div>
                <span>
                  <b>Email : </b>
                  {state.selectedItem.email}
                </span>
              </div>
              <div>
                <span>
                  <b>Mobile : </b>
                  {state.selectedItem.mobile}
                </span>
              </div>
              <div>
                <span>
                  <b>Description : </b>
                  {state.selectedItem.discription}
                </span>
              </div>
            </div>

            <div className="d-flex justify-content-end pt-3">
              <button
                className="btn btn-secondary"
                style={{ marginRight: "10px" }}
                onClick={() => {
                  handleClose();
                }}
              >
                Close
              </button>
            </div>
          </div>
          <button
            type="button"
            className="btn butclose btn-black"
            onClick={() => {
              handleClose();
            }}
          >
            <CloseIcon />
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ContactListComponent;
