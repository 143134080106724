import React, { useState } from "react";
import "./styles.css";
import { Line } from "rc-progress";
import Upload from "rc-upload";

import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import { apiBaseUrl, reactAppImageUri } from "../../utils/constants";

export default function FileUpload({ fileType, imageData,onUpload ,showImage=false}) {
  const [percentage, setPercentage] = useState(0);
  const [imgData, setImgdata] = useState(imageData);
  const [isUploading, setIsUploading] = useState(false);
  const [fileName, setFileName] = useState();
  const [fileSize, setFileSize] = useState();
  // const token = localStorage.getItem("token");
  // let headers = {
  //   "Content-Type": "multipart/form-data",
  //   Authorization: `Bearer ${token}`,
  // };
  const props = React.useMemo(() => ({
    action: `${apiBaseUrl}/files/upload`,
    accept: fileType ,
    beforeUpload(file) {
      // Start upload
      setIsUploading(true);
      // Set file details
      setFileName(file.name);
      setFileSize(Math.floor(file.size / 1000));
      // Display image for .png format
      if (file.type === "image/png") {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImgdata(reader.result);
        };
        reader.readAsDataURL(file);
      }
    },
    onSuccess({ data }) {
      // Finish upload
      setImgdata(`${apiBaseUrl + "/public"}/${reactAppImageUri}/${data[0]}`);
      onUpload(data[0])
      setIsUploading(false);
    },
    onProgress(step) {
      // Update progress
      setPercentage(Math.round(step.percent));
    },
    onError(err) {
      console.log("onError", err);
    },
  }), [fileType, onUpload]);

  return (
    <div onClick={(e)=>{
      e.preventDefault()
      e.stopPropagation()
    }}>
    <div className="FileUpload"  > 
    
      <Upload {...props}>
        <button id="upload-button">
          <AddPhotoAlternateOutlinedIcon />
        </button>
      </Upload>
      {fileName && (
        <React.Fragment>
          {showImage&&imgData && (
            <div>
              <img src={imgData} alt="uploaded" width="100px" height="100px" />
            </div>
          )}
          <div className="upload-list">
            <div className="file-name">
              <b>{fileName}</b>
            </div>
            <div className="progress-container">
              <Line
                percent={percentage}
                strokeWidth={9}
                trailWidth={9}
                trailColor="#FFF"
                strokeColor={isUploading ? "#41C3D2" : "#92ed14"}
              />
              <div className="progress-text">
                {isUploading ? `Uploading ${percentage}% ` : `Finished`}
              </div>
            </div>
            <div className="file-size">{`${fileSize} KB`}</div>
          </div>
        </React.Fragment>
      )}
      </div>
    </div>
  );
}
