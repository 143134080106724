import axios from "axios";
import env from "react-dotenv";
import {
  deleteApi,
  getApi,
  postApiWithoutToken,
  postApi,
  putApi,
  patchApi,
  fileUpload,
  fileUploadOLD,
  postApiWithFile,
  patchApiWithFile,
} from "./api-interface";

export const apiCall = async (path, method, data, auth, fileUpload) => {
  return new Promise((resolve, reject) => {
    console.log(data, "update apiiiiiiiii");
    // console.log(data[0])
    // console.log("env.URL", env);
    // console.log(
    //     `${URL}${path} path ${path} method ${method} data ${JSON.stringify(
    //         data
    //     )}`
    // );
    // console.log("rendervideoReducer/videofromActions 2-", data);
    const formData = new FormData();

    formData.append("file", data.file);
    formData.append("id", data.id);
    formData.append("groupId", data.groupId);
    formData.append("title", data.title);
    formData.append("details", data.details);
    formData.append("docId", data.docId);

    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    if (auth.userToke !== null && !fileUpload) {
      headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.userToke}`,
      };
    } else if (fileUpload) {
      headers = {
        // "Content-Type": "multipart/form-data",
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.userToke}`,
      };
    }
    // console.log("headers", headers);
    axios({
      method: method,
      url: `${path}`,
      // timeout: 1000 * 5,
      headers,
      // data: fileUpload ? formData : data,
      data: data,
    })
      .then((response) => {
        console.log("API Response", response);
        resolve(response);
      })
      .catch((error) => {
        console.log("API Error", error);
        reject(error.response);
      });
  });
};
export const adminLogin = async (data) => {
  return await postApiWithoutToken(`/user/login`, data);
};
export const allUserListApi = (endpoint) => {
  return getApi(endpoint);
};

export const registerUser = async (data) => {
  return await postApiWithoutToken(`/user/signup`, data);
};

export const deleteUser = async (data) => {
  return await deleteApi(`/user/delete-user`, data);
};

export const updateUserApi = async (data) => {
  return await putApi(`/user/update`, data);
};

export const getUserDetailsById = (id) => {
  return getApi(`/user/detailsById?id=${id}`);
};

export const allUserSplitedPdfApi = (values) => {
  return getApi(`/document/splited_pdf?groupId=${values.groupId}`);
};

export const allUserPdfListApi = (params) => {
  return getApi(`/document/getAll?`+params);
};
export const allUserPdfInfo = (id) => {
  return getApi(`/document/get-info/${id}`);
};

export const SingleSplitedPdftApi = (id) => {
  return getApi(`/document/get_single_pdf_files?id=${id}`);
};

export const updatePdfApi = async (data) => {
  console.log("data : ", data);
  return putApi(`/document/update_pdf`, data);
};

export const uploadSvgApi = async (data) => {
  console.log("data : ", data);
  return await putApi(`/document/upload_svg`, data);
};

export const deletePdf = async (data) => {
  return await deleteApi(`/document/delete`, data);
};

export const bannerTextAdd = async (data) => {
  return await postApiWithFile(`/banner/add`, data);
};

export const bannerTextGet = async () => {
  return await getApi(`/banner`);
};

export const bannerupdate = async (data, id) => {
  return await patchApiWithFile(`/banner/${id}`, data);
};

export const reportList = async (data) => {
  return await getApi(`/mobile/report?${data}`);
};

export const reportDelete = async (id) => {
  return await deleteApi(`/mobile/report/${id}`);
};

export const reportDetails = async (id) => {
  return await getApi(`/mobile/report/${id}`);
};

export const updateReportStatus = async (id, data) => {
  return await putApi(`/mobile/report/status-change/${id}`, data);
};

export const mobileDoc = async (data) =>{
  return await getApi(`/mobiledoc?`+data)
}

export const mobileDocDetails = async (id) =>{
  return await getApi(`/mobiledoc/${id}`)
}

export const UploadFiles = async (data) => {
  return fileUpload(`/files/upload`, data);
};

export const fetchDashboardDataAPI = () => {
  return getApi(`/dashboard/data`);
};
export const submitContactUs = async (data) => {
  return  postApi(`/user/contactUs`, data);
};

export const faqSubmit = async (data) => {
  return await postApi(`/faq/add`, data);
};

export const faqUpdate = async (data, id) => {
  return await patchApi(`/faq/${id}`, data);
};

export const faqDelete = async (id) => {
  return await deleteApi(`/faq/${id}`);
};

export const faqList = async (data) => {
  return await getApi(`/faq`, data);
};

export const usersList = async () => {
  return await getApi(`/user/list`, "data");
};

export const contactusList = async (data) => { 
  return await getApi(`/user/contactUs?${data}`);
}
export const contactusDelete = async (id) => { 
  return await deleteApi(`/user/contactUs/${id}`);
}

export const getPptDetails = async (id) => {
  return getApi(`/ppt_editing/get_details/${id}`);
};
export const convertAudio = async (data) => {
  return fileUpload(`/ppt_editing/convert_audio`, data);
};
export const editPpt = async (data) => {
  return postApi(`/ppt_editing/editPpt`, data);
};
export const getFiles = async (path) => {
  return new Promise((resolve, reject) => {
    // setTimeout(()=> {
    //     resolve('aaaaaaaaaa')
    // }, 5000)
    getApi(path).then((data) => {
      console.log(data);
      resolve(data);
    });
  });
};

export const updatePrivacyPolicy = async (id, data) => {
  return await putApi(`/front_end_setting/privacyPolicy/${id}`, data);
};

export const fetchPrivacyPolicy = (id) => {
  return getApi(`/front_end_setting/privacyPolicy/${id}`);
};

export const updateTermsAndConditions = async (id, data) => {
  return await putApi(`/front_end_setting/termsAndConditions/${id}`, data);
};

export const fetchTermsAndConditions = (id) => {
  return getApi(`/front_end_setting/termsAndConditions/${id}`);
};

export const quizVideoList = (params) => {
  return getApi(`/video/quiz_video_list?${params}`);
}

export const quizUserList = (params) => {
  return getApi(`/video/quiz_answer_list?${params}`);
}
export const quizUserResult = (id) => {
  return getApi(`/video/quiz_answer_details?id=${id}`);
}
export const deleteQuizUserResult = (id) => {
  return deleteApi(`/video/delete_quiz_answer/${id}`);
}

export const updateStatus = (url, data) => {
  return putApi(url, data);
}
export const deleteSOP = (url) => {
  return deleteApi(url);
}