import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { apiBaseUrl } from "./../../utils/constants";
import { fabric } from "fabric";
import Box from "@mui/material/Box";
import Modal from "react-modal";

import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import StopIcon from "@mui/icons-material/Stop";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import $ from "jquery";
import { convertAudio, editPpt, getFiles, getPptDetails } from "../../API";
// var WaveSurfer = require('wavesurfer.js');

import { useReactMediaRecorder } from "react-media-recorder";

let febricRef;
let undoObjects = [];
let isTextEnable = false;
let commonState;
let pageWiseFebObj = {};
const EditPpt = (props) => {
  const [activePage, setActivePage] = useState(0);
  const [canvas, setCanvas] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  let { id, pageId } = useParams();
  const editCanvasRef = useRef(null);
  const editImageRef = useRef(null);

  const [second, setSecond] = useState("00");
  const [minute, setMinute] = useState("00");
  const [isActive, setIsActive] = useState(false);
  const [counter, setCounter] = useState(0);
  const [isViewOnly, setIsViewOnly] = useState(false);
  const [submitting, SetSubmitting] = useState(false);
  const [state, setState] = useState({
    loading: false,
    pdfFile: "",
    images: [],
    viewer: useRef(null),
    brushSize: 1,
    showCanvas: false,
    borderColor: "#212121",
    canvasWidth: 0,
    canvasHeight: 0,
    backgroundColor: "#000",
    fontSize: 12,
    isShowAudio: false,
    lastAudioPath: "",
    pageLoaded: 0,
  });

  useEffect(() => {
    console.log("-------------------------Editttttttttttttttt");
    console.log(state.pageLoaded);
    console.log(pageId);
    console.log("-------------------------");
    if (state.pageLoaded !== pageId) {
      pageLoad();
    }

    // }, 500)
    return () => {
      // Cleanup function
    };
  }, [pageId, state.pageLoaded, state.images, febricRef]);

  const pageLoad = () => {
    let index = state.images.findIndex(
      (ob) => ob.id.toString() === pageId.toString()
    );
    if (index > -1) {
      setActivePage(index);
      if (!pageWiseFebObj[pageId]) {
        loadObjects(state.images[index].elements, pageId);
      } else {
        if (pageWiseFebObj[pageId] && pageWiseFebObj[pageId].objects) {
          for (let obj of pageWiseFebObj[pageId].objects) {
            if (obj.imagePath) {
              obj.src = apiBaseUrl + "/public/images/audio.png";
            }
          }
        }

        febricRef.loadFromJSON(pageWiseFebObj[pageId]);
        febricRef.renderAll();
        setState({
          ...state,
          pageLoaded: pageId,
        });
      }
    }
  };

  const loadObjects = async (fileName, pageId) => {
    if (fileName && febricRef) {
      getFiles(`/public/ppt/${fileName}`).then((data) => {
        console.log(data);
        if (data.data) {
          pageWiseFebObj[pageId] = data.data;
          if (pageWiseFebObj[pageId] && pageWiseFebObj[pageId].objects) {
            for (let obj of pageWiseFebObj[pageId].objects) {
              if (obj.imagePath) {
                obj.src = apiBaseUrl + "/public/images/audio.png";
              }
            }
          }
          febricRef.loadFromJSON(data.data);
          febricRef.renderAll();
          setState({
            ...state,
            pageLoaded: pageId,
          });
        }
      });
    }
  };
  useEffect(() => {
    let intervalId;

    if (isActive) {
      intervalId = setInterval(() => {
        const secondCounter = counter % 60;
        const minuteCounter = Math.floor(counter / 60);

        let computedSecond =
          String(secondCounter).length === 1
            ? `0${secondCounter}`
            : secondCounter;
        let computedMinute =
          String(minuteCounter).length === 1
            ? `0${minuteCounter}`
            : minuteCounter;

        setSecond(computedSecond);
        setMinute(computedMinute);

        setCounter((counter) => counter + 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isActive, counter]);

  function stopTimer() {
    setIsActive(false);
    setCounter(0);
    setSecond("00");
    setMinute("00");
  }

  let { startRecording, stopRecording, pauseRecording, mediaBlobUrl } =
    useReactMediaRecorder({
      video: false,
      audio: true,
      echoCancellation: true,
    });
  console.log("url", mediaBlobUrl);

  //   const editPdf = async (currentPage, page, idx) => {};
  const navigate = useNavigate();

  const initCanvas = (height, width) =>
    new fabric.Canvas("edit_ppt_canvas", {
      isDrawingMode: false,
      // color: "red",
      freeDrawingBrush: {
        width: 10,
        color: "rgba(255,0,0,.5)",
      },
      // selection : true,
      height: height,
      width: width,
      position: "absolute",
      backgroundColor: "rgba(0,0,0,0)",
    });

  function changeActiveTool(event) {
    var { $ } = window;
    var element = $(event.target).hasClass("tool-button")
      ? $(event.target)
      : $(event.target).parents(".tool-button").first();
    $(".tool-button.active").removeClass("active");
    $(element).addClass("active");
  }

  const enableSelector = (event) => {
    event.preventDefault();
    changeActiveTool(event);
    window.pdf.enableSelector();
  };

  const enablePencil = (event) => {
    event.preventDefault();
    changeActiveTool(event);
    febricRef.isDrawingMode = 1;
    // febricRef.renderAll();
  };

  const checkColor = () => {
    // if (
    //     window.pdf.color === "rgba(111, 76, 182, 0.315)" ||
    //     window.pdf.color === "rgba(182, 87, 76, 0.396)" ||
    //     window.pdf.color === "rgba(76, 182, 85, 0.396)" ||
    //     window.pdf.color === "rgba(175, 182, 76, 0.396)" ||
    //     window.pdf.color === "rgba(7, 7, 7, 0.31)"
    // ) {
    //     window.pdf.setColor(state.borderColor);
    //     window.pdf.setBrushSize(1);
    // }
  };

  const enableAddText = (event) => {
    event.preventDefault();
    changeActiveTool(event);
    febricRef.isDrawingMode = 0;
    isTextEnable = 1;
  };

  const enableRectangle = (event) => {
    event.preventDefault();
    changeActiveTool(event);
    var rect = new fabric.Rect({
      width: 100,
      height: 100,
      fill: "transparent",
      stroke: state.borderColor,
      strokeSize: 1,
    });
    rect.typeInfo = "rectangle";
    febricRef.add(rect);

    febricRef.isDrawingMode = 0;
  };

  const deleteSelectedObject = (event) => {
    event.preventDefault();
    const objs = febricRef.getActiveObject();
    febricRef.remove(objs);
    febricRef.renderAll();
    // window.pdf.deleteSelectedObject();
  };

  const enableAddArrow = (event) => {
    event.preventDefault();
    changeActiveTool(event);
    febricRef.isDrawingMode = 0;
    addArrow(50, 10, 150, 0, 0);
  };

  const addArrow = (x1, y1, width, id, angle) => {
    var points = [x1, y1, x1 + width, y1];
    var rotation_origin = new fabric.Point(points[0], points[1]);
    var angle_radians = fabric.util.degreesToRadians(angle ? angle : 0);
    var start = fabric.util.rotatePoint(
      new fabric.Point(points[0], points[1]),
      rotation_origin,
      angle_radians
    );
    var end = fabric.util.rotatePoint(
      new fabric.Point(points[2], points[3]),
      rotation_origin,
      angle_radians
    );

    let line = new fabric.Line([start.x, start.y, end.x, end.y], {
      fill: "rgb(38, 153, 251)",
      strokeWidth: 3,
      stroke: "rgb(38, 153, 251)",
      originX: "center",
      originY: "center",
    });

    let dx = line.x2 - line.x1,
      dy = line.y2 - line.y1;

    /* calculate angle of arrow */
    angle = Math.atan2(dy, dx);
    angle *= 180 / Math.PI;
    angle += 90;

    let arrow = new fabric.Triangle({
      angle: angle,
      fill: "rgb(38, 153, 251)",
      strokeUniform: true,
      top: line.y2,
      left: line.x2,
      width: 15,
      height: 15,
      originX: "center",
      originY: "center",
      stroke: "rgb(38, 153, 251)",
    });
    let selectedShape = new fabric.Group([line, arrow], {
      hasBorders: true,
      lockScalingY: true,
      id: id,
    });

    selectedShape.setControlsVisibility({
      tr: false,
      br: false,
      bl: false,
      ml: true,
      mt: false,
      mr: true,
      mb: false,
      // mtr: false,
      tl: false,
    });
    selectedShape.typeInfo = "image";
    febricRef.add(selectedShape);

    return selectedShape;
  };

  const addImage = (event) => {
    event.preventDefault();
    var inputElement = document.createElement("input");
    inputElement.type = "file";
    inputElement.accept = ".jpg,.jpeg,.png,.PNG,.JPG,.JPEG";
    inputElement.onchange = function () {
      var reader = new FileReader();
      reader.addEventListener(
        "load",
        function () {
          inputElement.remove();
          var image = new Image();
          image.onload = function () {
            const selectedShape = new fabric.Image(image);
            selectedShape.typeInfo = "image";
            febricRef.add(new fabric.Image(image));
          };
          image.src = this.result;
        },
        false
      );
      reader.readAsDataURL(inputElement.files[0]);
    };
    document.getElementsByTagName("body")[0].appendChild(inputElement);
    inputElement.click();
  };

  const addAudio = async () => {
    let audioPath = state.lastAudioPath;
    if (!state.lastAudioPath) {
      const audioBlob = await fetch(mediaBlobUrl).then((r) => r.blob());
      const audiofile = new File([audioBlob], `test.wav`, {
        type: "audio/wav",
      });
      const res = await convertAudio([audiofile]);
      if (res.status === 200) {
        audioPath = res.data.data.path;
        setState({
          ...state,

          lastAudioPath: res.data.data.path,
        });
      }
    }
    var image = new Image();
    image.onload = function () {
      const selectedShape = new fabric.Image(image);

      selectedShape.setControlsVisibility({
        mtr: false,
      });
      selectedShape.scaleToHeight(80);
      selectedShape.scaleToWidth(80);
      selectedShape.audioPath = audioPath;
      selectedShape.imagePath = "audio.png";
      selectedShape.typeInfo = "audio";
      febricRef.add(selectedShape);
    };

    image.src = apiBaseUrl + "/public/images/audio.png";
    setModalOpen(false);
  };

  const undoAction = (event) => {
    event.preventDefault();
    // window.pdf.undoAction();
    if (febricRef._objects.length > 0) {
      var lastObject = febricRef._objects[febricRef._objects.length - 1];
      undoObjects.push(lastObject);
      febricRef.remove(lastObject);
    }
  };

  const redoAction = (event) => {
    event.preventDefault();
    if (undoObjects && undoObjects.length > 0) {
      const lastObject = undoObjects.pop();
      febricRef.add(lastObject);
    }
  };

  const getjson = () => {
    if (febricRef && febricRef._objects && febricRef._objects.length > 0) {
      for (let obj of febricRef._objects) {
        let angle = obj.angle;
        obj.rotate(0);
        obj.x0 = obj.left;
        obj.y0 = obj.top;
        if (obj.type === "path" || obj.type === "group") {
          obj.typeInfo = "image";
          obj.src = obj.toDataURL("image/png");
        }
        obj.rotate(angle);
      }
      return febricRef.toJSON([
        "x0",
        "y0",
        "typeInfo",
        "src",
        "audioPath",
        "imagePath",
      ]);
    } else {
      return febricRef.toJSON([
        "x0",
        "y0",
        "typeInfo",
        "src",
        "audioPath",
        "imagePath",
      ]);
    }
  };

  function savePPT() {
    console.log("enter..............................");
    // pageWiseFebObj[pageId] = getjson();
    // for (let pageIdIn in pageWiseFebObj) {
    //   await editPpt({
    //     pageId: pageIdIn,
    //     pptId: id,
    //     objects: pageWiseFebObj[pageIdIn],
    //   });
    // }
    setIsViewOnly(true);
    // navigate(`/ppt-split-view/${id}?submit=1`);
  }

  async function saveAPIPPT() {
    try {
      pageWiseFebObj[pageId] = getjson();
      SetSubmitting(true);
      for (let pageIdIn in pageWiseFebObj) {
        if (pageWiseFebObj[pageIdIn] && pageWiseFebObj[pageIdIn].length > 0) {
          for (let obj of pageWiseFebObj[pageIdIn]) {
            obj.src = "";
          }
        }
        await editPpt({
          pageId: pageIdIn,
          pptId: id,
          objects: pageWiseFebObj[pageIdIn],
        });
      }
      SetSubmitting(false);
      navigate(`/editors?type=ppt`);
      toast.success("Edit Successfully!");
    } catch (e) {
      SetSubmitting(false);
      toast.error("something went wrong!");
    }
  }

  const clearPage = () => {
    febricRef.clear();
  };

  const highlighter = (event, bg) => {
    event.preventDefault();
    changeActiveTool(event);
    var width = 15;
    window.pdf.setBrushSize(width);
    var color = bg;
    window.pdf.setColor(color);
    window.pdf.enablePencil();
  };

  const setBorderColor = (bdrColor) => {
    setState({
      ...state,
      borderColor: bdrColor,
    });
    febricRef.freeDrawingBrush.color = bdrColor;
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  //   const goBack = () => {
  //     navigate(-1);
  //   };

  const canvsClickHandler = (event) => {
    if (isTextEnable) {
      isTextEnable = false;
      var text = new fabric.IText("Sample text", {
        left:
          event.clientX - febricRef.upperCanvasEl.getBoundingClientRect().left,
        top:
          event.clientY - febricRef.upperCanvasEl.getBoundingClientRect().top,
        fill: commonState.borderColor,
        fontSize: commonState.fontSize,
        selectable: true,
        typeInfo: "text",
      });
      febricRef.add(text);
    }
  };

  const loadCanvas = () => {
    if (document.getElementById("editing-ppt")) {
      const info = document
        .getElementById("editing-ppt")
        .getBoundingClientRect();
      console.log(info);
      setState({
        ...state,
        showCanvas: true,
        canvasHeight: info.height,
        canvasWidth: info.width,
      });
      setTimeout(() => {
        if (!canvas) {
          if (!febricRef) {
            const c = initCanvas(info.height, info.width);
            febricRef = c;

            $(febricRef.upperCanvasEl).click(canvsClickHandler);
            setCanvas(c);
          }
        }
      }, 500);
    }
  };

  useEffect(() => {
    commonState = state;
    if (state.images && state.images.length > 0 && state.showCanvas === false) {
      setTimeout(() => {
        loadCanvas();
      }, 500);
    }
    return () => {
      // Cleanup
    };
  }, [state, state.images]);
  const fetchAllSplitedPpt = async (values) => {
    try {
      const res = await getPptDetails(values);
      if (res.status === 200 && res.data.data.pages.length > 0) {
        setState({
          ...state,
          images: res.data.data.pages,
        });
        let index = res.data.data.pages.findIndex(
          (ob) => ob.id.toString() === pageId.toString()
        );

        if (index > -1) {
          setActivePage(index);
        } else {
          navigate("/editors?type=ppt");
        }
      } else {
        navigate("/editors?type=ppt");
      }
    } catch (error) {
      toast.error("something went wrong");
    }
  };

  useEffect(() => {
    fetchAllSplitedPpt(id);

    return () => {
      febricRef = undefined;
      undoObjects = [];
      isTextEnable = false;
      commonState = undefined;
      pageWiseFebObj = {};
    };
  }, []);

  function SaveToDisk(fileURL, fileName) {
    // for non-IE
    if (!window.ActiveXObject) {
      var save = document.createElement("a");
      save.href = fileURL;
      save.target = "_blank";
      save.download = fileName || "unknown";

      var evt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: false,
      });
      save.dispatchEvent(evt);

      (window.URL || window.webkitURL).revokeObjectURL(save.href);
    }

    // for IE < 11
    else if (!!window.ActiveXObject && document.execCommand) {
      var _window = window.open(fileURL, "_blank");
      _window.document.close();
      _window.document.execCommand("SaveAs", true, fileName || fileURL);
      _window.close();
    }
  }

  const downloadAudio = async () => {
    if (state.lastAudioPath) {
      SaveToDisk(apiBaseUrl + "/" + state.lastAudioPath, "test.mp3");
      return true;
    }
    const audioBlob = await fetch(mediaBlobUrl).then((r) => r.blob());
    const audiofile = new File([audioBlob], `test.wav`, { type: "audio/wav" });
    const res = await convertAudio([audiofile]);
    if (res.status === 200) {
      SaveToDisk(apiBaseUrl + "/" + res.data.data.path, "test.mp3");
      setState({
        ...state,
        lastAudioPath: res.data.data.path,
      });
    }
  };
  const nextPage = (row) => {
    if (activePage < state.images.length) {
      pageWiseFebObj[pageId] = getjson();
      febricRef.clear();

      setTimeout(() => {
        navigate(`/Edit-Ppt/${id}/${row.id}`);
      });
    }
  };

  const prevPage = (row) => {
    if (activePage > 0) {
      pageWiseFebObj[pageId] = getjson();
      febricRef.clear();

      setTimeout(() => {
        navigate(`/Edit-Ppt/${id}/${row.id}`);
      });
    }
  };
  useEffect(() => {
    if (mediaBlobUrl) {
      setState({
        ...state,
        isShowAudio: true,
      });
    }
    return () => {
      // Cleanup
    };
  }, [mediaBlobUrl]);

  return (
    <>
      <div className="rightsidebar">
        <div className="d-flex ">
          <div className="cursor-pointer" onClick={() => navigate(-1)}>
            <i className="fa fa-arrow-left back_arrow_style" style={{margin: 10}} aria-hidden="true"></i>
          </div>
        </div>

        <div className="flex-row pt-4 pb-4 bg-primary2 text-white h-screen w-full font-comfortaa user-list">
          <div className="mainprogrs">
            <ul className="multi-step-bar">
              <li className="sucses">
                <p>Details</p>
              </li>
              <li className={!isViewOnly ? "active" : "sucses"}>
                <p>Edit PPT</p>
              </li>
              <li className={isViewOnly ? "active" : ""}>
                <p>Submit</p>
              </li>
            </ul>
          </div>

          <div className="flex ">
            {!isViewOnly && (
              <div className="toolbar" style={{ width: "100%" }}>
                <div className="tool">
                  {/* <a href="#" onClick={()=> {dispatch(UNDO_ANOTATIONactions())}} style={{textAlign:"right",paddingRight:"5px",borderRight:"1px solid gray"}}><UndoIcon style={{fill:"#659EC7"}}/></a>
              <a href="#"  onClick={()=> {dispatch(REDO_ANOTATIONactions())}} style={{textAlign:"right",paddingLeft:"5px"}}><RedoIcon style={{fill:"#659EC7"}}/></a> */}

                  <button
                    href="#"
                    onClick={undoAction}
                    style={{
                      textAlign: "right",
                      paddingRight: "5px",
                      borderRight: "1px solid gray",
                    }}
                  >
                    <UndoIcon style={{ fill: "#659EC7" }} />
                  </button>
                  <button
                    href="#"
                    onClick={redoAction}
                    style={{ textAlign: "right", paddingLeft: "5px" }}
                  >
                    <RedoIcon style={{ fill: "#659EC7" }} />
                  </button>
                </div>
                <div className="tool">
                  <label for="">Pencil size</label>
                  <input
                    type="number"
                    className="form-control text-right"
                    value={state.brushSize}
                    id="brush-size"
                    max="50"
                    onChange={(e) => {
                      febricRef.freeDrawingBrush.width = e.target.value;
                      setState({ ...state, brushSize: e.target.value });
                      // window.pdf.setBrushSize(e.target.value);
                    }}
                  ></input>
                </div>
                <div className="tool">
                  <label for="">Font size</label>
                  <select
                    id="font-size"
                    value={state.fontSize}
                    className="form-control"
                    onChange={(e) => {
                      setState({
                        ...state,
                        fontSize: e.target.value,
                      });
                    }}
                  >
                    <option value="10">10</option>
                    <option value="12">12</option>
                    <option value="16">16</option>
                    <option value="18">18</option>
                    <option value="24">24</option>
                    <option value="32">32</option>
                    <option value="48">48</option>
                    <option value="64">64</option>
                    <option value="72">72</option>
                    <option value="108">108</option>
                  </select>
                </div>
                <div className="tool">
                  <button
                    onClick={() => setBorderColor("#212121")}
                    className={`color-tool ${
                      state.borderColor === "#212121" ? "active" : ""
                    }`}
                    style={{ backgroundColor: "#212121" }}
                  ></button>
                  <button
                    onClick={() => setBorderColor("red")}
                    className={`color-tool ${
                      state.borderColor === "red" ? "active" : ""
                    }`}
                    style={{ backgroundColor: "red" }}
                  ></button>
                  <button
                    onClick={() => setBorderColor("blue")}
                    className={`color-tool ${
                      state.borderColor === "blue" ? "active" : ""
                    }`}
                    style={{ backgroundColor: "blue" }}
                  ></button>
                  <button
                    onClick={() => setBorderColor("green")}
                    className={`color-tool ${
                      state.borderColor === "green" ? "active" : ""
                    }`}
                    style={{ backgroundColor: "green" }}
                  ></button>
                  <button
                    onClick={() => setBorderColor("yellow")}
                    className={`color-tool ${
                      state.borderColor === "yellow" ? "active" : ""
                    }`}
                    style={{ backgroundColor: "yellow" }}
                  ></button>
                </div>
                <div className="tool">
                  <button className="tool-button active" id="enableSelector">
                    <i
                      className="fa fa-hand-paper-o"
                      title="Free Hand"
                      onClick={(event) => enableSelector(event)}
                    ></i>
                  </button>
                </div>

                <div className="tool">
                  <button
                    className="tool-button"
                    onClick={(event) => setModalOpen(true)}
                  >
                    <i
                      className="fa fa-volume-up"
                      aria-hidden="true"
                      title="Text Highlightor"
                    ></i>
                  </button>
                </div>

                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    onClick={(event) => {
                      handleClose();
                      highlighter(event, "rgba(111, 76, 182, 0.315)");
                    }}
                    style={{
                      margin: "5px",
                      marginLeft: "10px",
                      marginRight: "10px",
                      width: "5px",
                      height: "25px",
                      borderRadius: "10%",
                      backgroundColor: "rgba(90, 13, 244, 0.978)",
                    }}
                  ></MenuItem>
                  <MenuItem
                    onClick={(event) => {
                      handleClose();
                      highlighter(event, "rgba(182, 87, 76, 0.396)");
                    }}
                    style={{
                      margin: "5px",
                      marginLeft: "10px",
                      marginRight: "10px",
                      width: "5px",
                      height: "25px",
                      borderRadius: "10%",
                      backgroundColor: "rgb(247, 35, 11)",
                    }}
                  ></MenuItem>
                  <MenuItem
                    onClick={(event) => {
                      handleClose();
                      highlighter(event, "rgba(76, 182, 85, 0.396)");
                    }}
                    style={{
                      margin: "5px",
                      marginLeft: "10px",
                      marginRight: "10px",
                      width: "5px",
                      height: "25px",
                      borderRadius: "10%",
                      backgroundColor: "rgb(8, 249, 28)",
                    }}
                  ></MenuItem>
                  <MenuItem
                    onClick={(event) => {
                      handleClose();
                      highlighter(event, "rgba(175, 182, 76, 0.396)");
                    }}
                    style={{
                      margin: "5px",
                      marginLeft: "10px",
                      marginRight: "10px",
                      width: "5px",
                      height: "25px",
                      borderRadius: "10%",
                      backgroundColor: "rgb(249, 233, 5)",
                    }}
                  ></MenuItem>
                  <MenuItem
                    onClick={(event) => {
                      handleClose();
                      highlighter(event, "rgba(7, 7, 7, 0.31)");
                    }}
                    style={{
                      margin: "5px",
                      marginLeft: "10px",
                      marginRight: "10px",
                      width: "5px",
                      height: "25px",
                      borderRadius: "10%",
                      backgroundColor: "black",
                    }}
                  ></MenuItem>
                </Menu>

                <div className="tool">
                  <button
                    className="tool-button"
                    id="enablePencil"
                    onClick={(event) => {
                      enablePencil(event);
                      checkColor();
                    }}
                  >
                    <i className="fa fa-pencil" title="Pencil"></i>
                  </button>
                </div>
                <div className="tool">
                  <button
                    className="tool-button"
                    id="enableAddText"
                    onClick={(event) => enableAddText(event)}
                  >
                    <i className="fa fa-font" title="Add Text"></i>
                  </button>
                </div>
                <div className="tool">
                  <button
                    className="tool-button"
                    id="enableAddArrow"
                    onClick={(event) => enableAddArrow(event)}
                  >
                    <i className="fa fa-long-arrow-right" title="Add Arrow"></i>
                  </button>
                </div>
                <div className="tool">
                  <button
                    className="tool-button"
                    id="enableRectangle"
                    onClick={(event) => enableRectangle(event)}
                  >
                    <i className="fa fa-square-o" title="Add rectangle"></i>
                  </button>
                </div>
                <div className="tool">
                  <button
                    className="tool-button"
                    id="addImage"
                    onClick={(event) => addImage(event)}
                  >
                    <i className="fa fa-picture-o" title="Add an Image"></i>
                  </button>
                </div>
                <div
                  className="tool"
                  onClick={(event) => deleteSelectedObject(event)}
                >
                  <button
                    className="btn  btn-danger btn-sm"
                    id="deleteSelectedObject"
                  >
                    <i className="fa fa-trash" color="error"></i>
                  </button>
                </div>
                <div className="tool" onClick={(event) => clearPage(event)}>
                  <button className="btn btn-danger btn-sm" id="clearPage">
                    Clear Page
                  </button>
                </div>
                <div className="tool" onClick={(event) => savePPT(event)}>
                  <button className="btn btn-primary btn-sm" id="savePPT">
                    <i className="fa fa-save"></i> Save
                  </button>
                </div>
              </div>
            )}
            {isViewOnly && (
              <div
                className="toolbar"
                style={{ width: "100%", justifyContent: "end" }}
              >
                <div className="tool">
                  {submitting && (
                    <button className="d-flex m-auto btn btn-primary btn-sm" id="savePPT">
                       <><div className="dot-pulse-text">Submitting</div> <div className="dot-pulse-container m-auto"><div className="dot-pulse"></div></div></>
                    </button>
                  )}
                  {!submitting && (
                    <button
                      onClick={(event) => saveAPIPPT(event)}
                      className="btn btn-primary btn-sm"
                      id="savePPT"
                    >
                      <i className="fa fa-save"></i> Submit
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className=" backgtable wrapper-14">
            <div className="row">
              <div
                className="col-9 mt-4"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingBottom: "15px",
                }}
              >
                <Box
                  id="ppt-container"
                  component={"div"}
                  sx={{
                    width: "fit-content",
                    // height: '100%',
                    // width: "66%",
                    display: "flex",
                    // backgroundColor: '#000000',
                    // border: '1px solid #FFFFFF',
                    borderRadius: "7px",
                    // overflow: 'hidden',
                    flexDirection: "column",
                    alignItems: "center",
                    // justifyContent: 'center',
                    padding: "0%",
                    position: "relative",
                  }}
                >
                  <img
                    alt="img"
                    style={{ maxWidth: "5000000000000000px" }}
                    ref={editImageRef}
                    id="editing-ppt"
                    src={
                      apiBaseUrl +
                      "/public/ppt/split/" +
                      state.images[activePage]?.file
                    }
                  ></img>
                  {state.showCanvas && (
                    <canvas
                      id="edit_ppt_canvas"
                      ref={editCanvasRef}
                      width={state.canvasWidth}
                      height={state.canvasHeight}
                      style={{ position: "absolute", left: 0 }}
                    ></canvas>
                  )}
                </Box>
              </div>

              <div className="col-3 pl-0">
                <div className="grid backgtable mt-2">
                  <div className="w-full file_container custom-scroller">
                    {state.images?.map((row, idx) => {
                      // console.log(row)
                      return (
                        <div className="bg-white" key={idx}>
                          <div
                            className="splited_ppt_file m-2"
                            style={{
                              border: "1px solid lightgray",
                              borderRadius: "5px",
                            }}
                            onClick={() => {
                              pageWiseFebObj[pageId] = getjson();
                              febricRef.clear();

                              setTimeout(() => {
                                navigate(`/Edit-Ppt/${id}/${row.id}`);
                              });
                            }}
                          >
                            <img
                              alt="img"
                              src={apiBaseUrl + "/public/ppt/split/" + row.file}
                            ></img>
                            {/* <Document
                              file={baseURL + row.url}
                              onLoadSuccess={onDocumentLoadSuccess}
                              onClick={() => { tempSavePDF(); editPdf(currentPage, row.id, idx) }}
                              className="splited_pdf_file_canvac"
                            >
                              <Page pageNumber={pageNumber}
                                width="240"
                                scale={1.0}
                              />
                            </Document> */}
                          </div>
                          <p
                            style={{
                              color: "#2699fb",
                              textAlign: "center",
                              fontSize: 12,
                              maxWidth: 240,
                              fontFamily: "Arial",
                              fontWeight: 400,
                            }}
                          >
                            Page {idx + 1}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="mb-4 vertylegular">
            <div className="tool">
              <div className="pdfbackandnext">
                <button
                  className="btn btn-primary addbutne btn-sm"
                  id="savePDF"
                  disabled={activePage === 0}
                  onClick={(event) => prevPage(state.images[activePage - 1])}
                >
                  Back
                </button>
                <button
                  className="btn btn-primary addbutne btn-sm mr-2"
                  id="savePDF"
                  disabled={activePage >= state.images?.length - 1}
                  onClick={() => nextPage(state.images[activePage + 1])}
                >
                  Next
                </button>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <Modal
        isOpen={modalOpen}
        //  onRequestClose={modalOpenFunc}
        style={{
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          zIndex: 9999999999999,
          border: "1px solid blue",
        }}
        contentLabel="Example Modal"
      >
        <div>
          <div>
            <h4>Recording</h4>
          </div>
          {mediaBlobUrl && state.isShowAudio && (
            <div className="flex justify-center ">
              {" "}
              <div id="waveform"></div>
              <video src={mediaBlobUrl} controls loop />
            </div>
          )}
          <div className="row justify-center">
            <div className="Recording ">
              <div style={{ textAlign: "center", fontSize: "54px" }}>
                <span className="minute">{minute}</span>
                <span>:</span>
                <span className="second">{second}</span>
              </div>

              <div style={{ display: "flex", justifyContent: "center" }}>
                <label
                  style={{
                    fontSize: "15px",
                    fontWeight: "Normal",
                    // marginTop: "20px"
                  }}
                  htmlFor="icon-button-file"
                >
                  {/* <h3 style={{ marginLeft: "15px", fontWeight: "normal" }}></h3> */}

                  <div className="puchbuton">
                    <button
                      className="start"
                      style={{
                        padding: "0.5rem 2rem",
                        border: "none",
                        fontSize: "15px",
                        cursor: "pointer",
                        borderRadius: "5px",
                        fontWeight: "bold",
                        backgroundColor: "#42b72a",
                        color: "white",
                        transition: "all 300ms ease-in-out",
                        transform: "translateY(0)",
                      }}
                      onClick={() => {
                        if (!isActive) {
                          setState({
                            ...state,
                            lastAudioPath: "",
                          });
                          startRecording();
                        } else {
                          pauseRecording();
                        }

                        setIsActive(!isActive);
                      }}
                    >
                      {isActive ? <PauseIcon /> : <PlayArrowIcon />}
                    </button>
                    <button
                      className="stop"
                      style={{
                        padding: "0.5rem 2rem",
                        border: "none",
                        marginLeft: "15px",
                        backgroundColor: "#df3636",
                        fontSize: "15px",
                        cursor: "pointer",
                        color: "white",
                        borderRadius: "5px",
                        fontWeight: "bold",
                        transition: "all 300ms ease-in-out",
                        transform: "translateY(0)",
                      }}
                      onClick={() => {
                        setIsActive(false);
                        setCounter(0);
                        stopRecording();
                        pauseRecording();
                      }}
                    >
                      <StopIcon />
                    </button>
                  </div>
                  {mediaBlobUrl && state.isShowAudio && (
                    <div className="alleidertbut">
                      <button
                        type="button"
                        className="btn btn-primary"
                        style={{ marginRight: "10px" }}
                        onClick={downloadAudio}
                      >
                        <DownloadIcon />
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        style={{ marginRight: "10px" }}
                        onClick={addAudio}
                      >
                        <SaveIcon />
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => {
                          mediaBlobUrl = "";
                          stopTimer();
                          setState({
                            ...state,
                            isShowAudio: false,
                            lastAudioPath: "",
                          });
                        }}
                      >
                        <DeleteIcon />
                      </button>
                    </div>
                  )}
                  <div className="backbut">
                    <button
                      className="backon btn-primary"
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        setModalOpen(false);
                      }}
                    >
                      Close
                    </button>
                    {mediaBlobUrl && state.isShowAudio && (
                      <button
                        className="addbutons btn-primary"
                        onClick={addAudio}
                      >
                        ADD TO SELECTED PAGE
                      </button>
                    )}
                  </div>
                </label>
              </div>
              <b></b>
            </div>
          </div>
          <button
            type="button"
            className="btn butclose btn-danger"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            <CloseIcon />
          </button>
        </div>
      </Modal>
    </>
  );
};

export default EditPpt;
