// import { MdOutlineSpaceDashboard } from "react-icons/md";
// import { TbUsers } from "react-icons/tb";
// import { AiOutlineBook } from "react-icons/ai";
// import { AiOutlineEdit } from "react-icons/ai";
// import { MdOutlineQuiz } from "react-icons/md";
// import { FaQuora } from "react-icons/fa";
// import { HiOutlineDocumentDuplicate } from "react-icons/hi";
// import { TiContacts } from "react-icons/ti";
// import { BiMinusFront } from "react-icons/bi";
// import { BiFilterAlt } from "react-icons/bi";
// import MessageIcon from '@mui/icons-material/Message';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

export const sidebarRoutes = [
    {
        id: "1",
        title: "DASHBOARD",
        link: "/",
        icon: <DashboardOutlinedIcon style={{color:'rgb(255 255 255 / 43%)'}}/>,
    },
    {
        id: "2",
        title: "USER",
        link: "/usersmain",
        // link: ""
        icon: <PersonOutlineOutlinedIcon style={{color:'rgb(255 255 255 / 43%)'}}/>,
        // submenu:[
        //    { subtitle: "ADD ADMIN",
        //     link: "/user/add/admin",
        //    },
        //    { subtitle: "ADD USER",
        //    link: "/user/add/user",
        //   },
        //     { subtitle: "LIST OF USERS",
        //      link: "/user/list/user",
        //     },
        //     { subtitle: "LIST OF ADMIN USERS",
        //     link: "/user/list/admin",
        //    },
        // ],
    },


    // {
    //     id: "3",
    //     title: "COURSES",
    //     link: "",
    //     icon: <MessageIcon style={{color:'rgb(255 255 255 / 43%)'}}/>,
     
    // },
   

    {
        id: "4",
        title: "DIGITAL - SOP",
        link: "/editors",
        icon: <NotificationsOutlinedIcon style={{color:'rgb(255 255 255 / 43%)'}}/>,
        // submenu: [
        //     {
        //         subtitle: "PDF EDITOR",
        //         link: "/pdflist",
        //     },
        //     {
        //         subtitle: "VIDEO EDITOR",
        //         link: "/videolist",
        //     },
        //     {
        //         subtitle: "IMAGE EDITOR",
        //         link: "/imagelist",
        //     },
        //     {
        //         subtitle: "PPT EDITOR",
        //         link: "/pptlist",
        //     },
        // ],
    },

    {
        id: "5",
        title: "ASSIG / QUIZES",
        link: "/quiz-result",
        icon: <NotificationsOutlinedIcon style={{color:'rgb(255 255 255 / 43%)'}}/>,
       
    },

    {
        id: "6",
        title: "FAQ",
        link: "/faq",
        icon: <ImageOutlinedIcon style={{color:'rgb(255 255 255 / 43%)'}}/>,
       
    },
    {
        id: "7",
        title: "REPORTS",
        link: "/report",
        icon: <OndemandVideoOutlinedIcon style={{color:'rgb(255 255 255 / 43%)'}}/>,
       
    },
    {
        id: "8",
        title: "CONTACT US",
        link: "/contactUs",
        icon: <PlaceOutlinedIcon from  style={{color:'rgb(255 255 255 / 43%)'}}/>,
       
    },

    {
        id: "9",
        title: "FRONTEND SETTINGS",
        link: "/frontend-settings",
        icon: <SettingsOutlinedIcon style={{color:'rgb(255 255 255 / 43%)'}} />,

        submenu: [
            // {
            //     subtitle: "SLIDER CONTROL",
            //     link: "/frontend-settings/slider-control",
            // },
            // {
            //     subtitle: "CATEGORY & CONTROL",
            //     link: "/frontend-settings/category-n-control",
            // },
            {
                subtitle: "BANNER TEXT CONTROL",
                link: "/frontend-settings/banner-text-control",
            },
            {
                subtitle: "PRIVACY POLICY",
                link: "/frontend-settings/privacy-policy",
            },
            {
                subtitle: "TERMS & CONDITION ",
                link: "/frontend-settings/terms-n-conditions",
            },
        ],
    }

    // {
    //     id: "10",
    //     title: "FILTER MANAGEMENT",
    //     link: "",
    //     icon: <SearchOutlinedIcon from  style={{color:'rgb(255 255 255 / 43%)'}}/>,
       
    // },
];
