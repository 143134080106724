import {
    deleteApi,
    getApi,
    postApi,
    putApi,
} from "./api-interface";

export const createPptEditing = async (data) => {
    return await postApi(`/ppt_editing/add`, data);
};

export const UpdateEditedPpt = async (data) => {
    return await postApi(`/ppt_editing/UpdateEditedPpt`, data);
};

export const GetPptEditingDetail = async (id) => {
    return await getApi("/ppt_editing/get_details/" + id);
};
export const GetAllPptEditings = async (params) => {
    return await getApi("/ppt_editing/getAll?"+params);
};
export const updatePptEditing = async (id, data) => {
    return await putApi("/ppt_editing/update/" + id, data);
};
export const deletePptEditing = async (id) => {
    return await deleteApi("/ppt_editing/delete/" + id);
};