import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../appContext";
import Header from "../components/Header/Header";
import Sidebar from "../components/Sidebar/Sidebar";
import { getToken } from "../utils/helperFunctions";

const PrivateRoutes = ({ Component, pageName }) => { 
  const navigate = useNavigate();

  const contextApi = useContext(AppContext)
  const { sidebar } = contextApi?.state;

  useEffect(() => {
    const user = Boolean(getToken());
    if(user && JSON.parse(user)) { 
    } else {
      navigate("/login");
    }
  }, []);

  return (
    <>
      <Header  pageName={pageName}/>
      
      <Sidebar />
      
      <div className={`sidbarmain toppadding ${!sidebar ? 'closemenu': ''}`}>
        <Component />
      </div>
      
    </>
  );
};

export default PrivateRoutes;
