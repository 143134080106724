import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  Button,
  Container,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  Paper,
  Stack,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import { ExternalLink } from "react-external-link";
import { Padding } from "@mui/icons-material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { apiBaseUrl } from "../../utils/constants";
import {
  GetAllVideoEditings,
  deleteVideoEditing,
} from "../../API/videoEditorApi";
import { updateStatus } from "../../API";
import moment from "moment/moment";

const ListOfVideo = () => {
  const [allRecords, setAllRecords] = useState({
    data: [],
    count: 0,
  });
  const [options, setOptions] = useState({
    page: 0,
    pageSize: 15,
    orderBy: "id",
    orderType: "desc",
  });

  const getAllRecords = async () => {
    try {
      const params = `orderby=${options.orderBy}&ordertype=${options.orderType}&limit=${options.pageSize}&page=${options.page}`;
      const res = await GetAllVideoEditings(params);
      if (res.status === 200) {
        setAllRecords({
          data: res.data.data.rows,
          count: res.data.data.count
        });
      }
    } catch (error) {
      toast.error(error.response.data.message || error.response.statusText);
    }
  };

  const DeleteVideo = async (id) => {
    try {
      if (window.confirm("Are you sure you want to delete?")) {
        await deleteVideoEditing(id);
        getAllRecords();
        toast.success("SOP deleted successfully");
      }
    } catch (error) {
      toast.error("something went wrong!");
    }
  };
  const download = (url, filename) => {
    toast.info("Downloading");
    if (url === null) {
      toast.error("Edited video not available !!");
      return false;
    } else {
      fetch(`${apiBaseUrl}/` + url)
        .then((response) => response.blob())
        .then((blob) => {
          const blobURL = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = blobURL;
          a.style = "display: none";

          if (filename && filename.length) a.download = filename;
          document.body.appendChild(a);
          a.click();
        })
        .catch(() => {});
    }
  };

  const changeStatus = async (id, status) => {
    await updateStatus("/video_editing/update-status/" + id, { status });
    toast.success("update status successfully");
    getAllRecords();
  };

  const columns = [
    { field: "title", width: 300, headerName: "SOP Title" },
    { field: "details", width: 450, headerName: "Description" },
    {
      field: "createdAt",
      headerName: "Time & Date",
      renderCell: (cellValues) => {
        return (
          <>
            {cellValues.row.createdAt
              ? moment(cellValues.row.createdAt).format("DD/MM/YY, HH:mm A")
              : ""}
          </>
        );
      },
      width: 200,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <>
            <div className="custom_switch my-2">
              <label className="switch">
                <input
                  checked={cellValues.row.status}
                  type="checkbox"
                  id="togBtn"
                  onChange={(e) => {
                    changeStatus(cellValues.row.id, e.target.checked);
                  }}
                ></input>
                <div className="ch_slider round"></div>
              </label>
            </div>
          </>
        );
      },
    },
    {
      field: "id",
      headerName: "Action",
      width: 300,
      sortable: false,
      renderCell: (cellValues) => {
        const link =
          !!cellValues.row.download_link && cellValues.row.download_link;
        const redirectedLink =
          !!link &&
          (link.includes("zip") ? link.replace(".zip", "/index.html") : link);
        return (
          <Stack direction="row" className="editbut">
            <Button
              className="nocolor"
              variant="contained"
              component={Link}
              to={`/videoform/${cellValues.row.id}/manage`}
            >
              <ModeEditIcon />
            </Button>{" "}
            <a
              className="nocolor"
              variant="contained"
              component={Link}
              target="blank"
              href={`${apiBaseUrl}/${redirectedLink}`}
            >
              <VisibilityIcon />
            </a>
            {/* <Button variant="contained" component={Link} to={`/video-player/${cellValues.row.id}/`}>Video Edit</Button>
          {" "} */}
            <Button
              className="nocolor"
              variant="contained"
              onClick={() => {
                download(
                  `${cellValues.row.download_link}`,
                  cellValues.row.download_filename
                );
              }}
            >
              <FileDownloadIcon />
            </Button>{" "}
            <Button
              className="nocolor delete"
              color="error"
              variant="contained"
              onClick={() => {
                DeleteVideo(cellValues.row.id);
              }}
            >
              <DeleteIcon />
            </Button>
          </Stack>
        );
      },
    },
  ];

  useEffect(() => {
    getAllRecords();
  }, [options]);

  const setPage = (data) => {
    setOptions({
      ...options,
      page: data,
    });
  };
  const setPageSize = (data) => {
    setOptions({
      ...options,
      pageSize: data,
    });
  };
  const handleSortModelChange = (data) => {
    if (data && data[0]) {
      setOptions({
        ...options,
        orderBy: data[0].field,
        orderType: data[0].sort,
      });
    } else {
      setOptions({
        ...options,
        orderBy: "id",
        orderType: "desc",
      });
    }
  };

  return (
    <>
      <div className="rightsidebar">
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper
                sx={{ p: 2 }}
                className="paddnone"
                style={{ boxShadow: "none", padding: 0 }}
              >
                <ListItem
                  secondaryAction={
                    <Button
                      variant="contained"
                      component={Link}
                      to="/videoform"
                      className="addbutne"
                    >
                      Add Video
                    </Button>
                  }
                >
                  <ListItemText className="black-text" primary="Video Editor" />
                </ListItem>
                <div
                  className="bordernone"
                  style={{ height: 500, width: "100%" }}
                >
                  <DataGrid
                    className="painnation"
                    // rowHeight={120}
                    rows={allRecords.data}
                    columns={columns}

                    disableColumnMenup
                    rowsPerPageOptions={[15, 50, 100]}
                    getRowHeight={() => "auto"}
                    rowCount={allRecords.count}
                    paginationModel={options}
                    disableColumnMenu
                    pagination
                    paginationMode="server"
                    page={options.page}
                    pageSize={options.pageSize}
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    onSortModelChange={handleSortModelChange}
                  />
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default ListOfVideo;
