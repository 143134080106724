import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Document } from "react-pdf/dist/esm/entry.webpack";
import PDFMerger from "pdf-merger-js/browser";
import { allUserSplitedPdfApi } from "../../API";

import { apiBaseUrl } from "./../../utils/constants";
function SplitPdfView(props) {
  const baseURL = apiBaseUrl + "/";
  const { groupId } = useParams();

  const url = window.location.pathname;
  const lastSegment = url.split("/").pop();

  const navigate = useNavigate();

  // const [numPages, setNumPages] = useState(null);
  // const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    // setNumPages(numPages);
  }
  const [state, setState] = useState({
    loading: false,
    data: [],
    message: "",
    status: 0,
  });

  const fetchAllSplitedPdf = async (values) => {
    try {
      const res = await allUserSplitedPdfApi(values);
      if (res.status === 200) {
        // toast.success(res.data.message);
        // setUserData((v) => ({
        //   ...v,
        //   loading: false,
        //   data: res.data.data
        // }))
        setState({
          ...state,
          data: res.data.data,
        });
      }
    } catch (error) {
      toast.error("something went wrong");
      //   setUserData((v) => ({
      //     ...v,
      //     loading: false,
      //     data: null
      //   }))
    }
  };

  useEffect(() => {
    // if (localStorage.getItem("canvas-appear")) {
    //   localStorage.removeItem("canvas-appear");
    // }
    let values = {
      groupId: lastSegment,
    };
    fetchAllSplitedPdf(values);
  }, []);

  const editPdf = (id) => {
    setTimeout(() => {
      navigate("/pdfform/" + groupId + "/" + id);
    }, 100);
  };

  window.Buffer = window.Buffer || require("buffer").Buffer;
  const downloadFile = () => {
    const render = async () => {
      const merger = new PDFMerger();

      for (const file of data) {
        console.log(baseURL + file.url);
        await merger.add(baseURL + file.url);
      }

      await merger.save(`${Date.now()}`);
    };

    render().catch((err) => {
      throw err;
    });
  };

  const { data } = state;

  return (
    <div className="rightsidebar pdfeditform">
      <div className="flex-row text-white  w-full font-comfortaa desbordcard">
        <div class="d-flex ">
          <div
            class="cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <i class="fa fa-arrow-left back_arrow_style" aria-hidden="true"></i>
          </div>
          <div className="handleimg"></div>
        </div>
        <p className="titileditor">Add PDF</p>
        <div className="mainprogrs">
          <ul className="multi-step-bar">
            <li className="sucses">
              <p>Details</p>
            </li>
            {window.localStorage.getItem("submitredirct") ? (
              <li className="sucses">
                <p>Edit PDF</p>
              </li>
            ) : (
              <li className="active">
                <p>Edit PDF</p>
              </li>
            )}
            {window.localStorage.getItem("submitredirct") ? (
              <li className="active">
                <p>Submit</p>
              </li>
            ) : (
              <li>
                <p>Submit</p>
              </li>
            )}
          </ul>
        </div>
        {/* <div className="flex aling-item-center pdfedittitle">
          <div className="  mb-1 w-1/2 pt-2 ">View PDF</div>
          <div className="flex  m-1 w-1/2 justify-end customclass pdflist">
            <Link to={"/editors?type=pdf"} className="btn">
              View PDF List
            </Link>

            <Link
              to={"#"}
              className="btn btn-success"
              onClick={() => downloadFile()}
            >
              Download
            </Link>
          </div>
        </div> */}
        <div className="grid backgtable pdfformedit">
          <div className="w-full splited_pdf_file_container">
            {data?.map((row, idx) => {
              // console.log(row)
              return (
                <>
                  <div className="position-relative">
                    <div
                      className="splited_pdf_file m-2"
                      key={idx}
                      style={{
                        border: "1px solid lightgray",
                        borderRadius: "7px",
                      }}
                    >
                      <img
                        src={`${baseURL}public/docFiles/splitFiles/${row.pdf_image}`}
                        className="splited_pdf_file_canvac"
                        alt="img"
                      ></img>
                      {/* <Document
                  file={baseURL + row.url}
                  onLoadSuccess={onDocumentLoadSuccess}
                  onClick={() => editPdf(row.id)}
                  className="splited_pdf_file_canvac"
                > */}
                      {/* <Page pageNumber={pageNumber}
                  width="240"
                  scale={1.0}
                  /> */}

                      {/* </Document> */}
                      {/* <object
                  data={baseURL + row.url}
                  type="application/pdf"
                  className="innerPdfFile m-2" 
                  onClick={() => editPdf(row.id)}
                  style={{pointerEvents: "none"}}
                ></object>  */}
                    </div>
                    <div className="pdficonsym"></div>
                  </div>
                </>
              );
            })}
          </div>
        </div>

        {window.localStorage.getItem("submitredirct") ? (
          <div className="pdfbackandnext pdfsavebtn">
            <button
              onClick={() => {
                window.localStorage.removeItem("submitredirct");
                toast.success("Pdf successfully saved !!");
                navigate("/editors?type=pdf");
              }}
              className="btn btn-primary addbutne btn-sm mr-2 logoutno"
              id="savePDF"
            >
              Submit
            </button>
          </div>
        ) : (
          <div className="pdfbackandnext pdfsavebtn">
            {/* <button
              className="btn btn-primary addbutne btn-sm logoutno"
              id="savePDF"
            >
              {" "}
              Back
            </button> */}
            <button
              onClick={() => {
                data.length > 0
                  ? editPdf(data[0].id)
                  : alert("No pdf found !!");
              }}
              className="btn btn-primary btn-sm mr-2 addbutne logoutno"
              id="savePDF"
            >
              Next
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default SplitPdfView;
