import React, { useState } from "react";
import "../../index.css";
import "../../styles/Arial-font/stylesheet.css";
import {
  AppBar,
  Toolbar,
  Typography,
  Button
} from "@mui/material";
// import AppContext from "../../appContext";
import { useParams } from "react-router-dom";

import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from "react-router-dom";

const Header = ({pageName}) => {
  // const contextApi = useContext(AppContext);
  // const { sidebar } = contextApi.state;
  // const { setSidebar } = contextApi;
  const navigate = useNavigate();
  const params = useParams();
  // const sidebarToggle = () => {
  //   setSidebar(!sidebar);
  // };

  const [anchorEl] = useState(null);
  const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  function ani() {
    document.getElementById('basic-button').classList.toggle("classname")
  }

  // const steps = ['Select', 'Create', 'Finish'];
  // const [activeStep, setActiveStep] = React.useState(0);

  // const handleNext = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  // };

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };
  //toggle used for hamburger imported from app.js
  return (
    <>
      <AppBar
        position="static"
        className="bashbordmenu"
        id = "headergame"
        sx={{ backgroundColor: "#1B2429", borderBottom: "none" }}
      >
        <Toolbar>
          {/* <div className="userleft">
            <span className="avtar" style={{ overflow: "hidden" }}>
              {Boolean(user?.data?.profileImage) ? (
                <img src={apiBaseUrl + "/" + user?.data?.profileImage} />
              ) : (
                user?.data?.name.charAt(0).toUpperCase()
              )}
            </span>
            <div className="content">
              <h4>{user?.data?.name}</h4>
              <h6>{user?.data?.email}</h6>
            </div>
          </div> */}
          {/* <Grid container spacing={0} md={3}> */}
          {/* <IconButton
            className={`toggler  ${
              !sidebar ? "closemenu" : "menu-item-active"
            }`}
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={sidebarToggle}
          >
            <MenuIcon />
            <ArrowBackIcon />
          </IconButton> */}
          <Typography
            variant="h3"
            className="titletop flex aling-center"
            component="div"
            sx={{ flexGrow: 1 }}
            style={{
              textTransform: "capitalize",
              color: "#fff",
              fontSize: 16,
            }}
          >
            {/* <span className="dotlive"></span>{" "} */}
            {pageName || params?.pageName || "SUPER ADMIN - Dashboard Screen"}
          </Typography>
          <div className="rightsidebut">
            {/* <Button variant="contained" className="publishlive">
              PUBLISH TO LIVE
            </Button> */}

            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={() => {navigate(`/logOut`);
              ani();
            }}
            >
              <div className="abcd">
              <LogoutIcon sx={{ color: "#fff", fontSize: 20 }}>
                {" "}
              </LogoutIcon></div>
            </Button>
            {/* <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                className="py-2 px-4 text-sm font-weight-bold dropdown-item mb-1"
                onClick={() => {
                  navigate(`/user/edit/${user.data.id}`);
                  setAnchorEl(null);
                }}
              >
                My Profile
              </MenuItem>
              <MenuItem
                className="py-2 px-4 text-sm font-weight-bold dropdown-item mb-1"
                onClick={() => navigate(`/logOut`)}
                >
                onClick={() => logout(navigate)}
                Logout
              </MenuItem>
            </Menu> */}
          </div>
          {/* <Box>
            <Button variant="contained" sx={{ width: '30%' }}
              color="success"
              onClick={() => { console.log('Submit Clicked') }} >Submit</Button>
          </Box> */}
          {/* <Box sx={{ width: '70%', }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps} >
                    <StepLabel {...labelProps}></StepLabel>
                  </Step>
                );
              })}
            </Stepper> */}

          {/* <React.Fragment>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleNext}>
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </Box>
            </React.Fragment> */}

          {/* </Box> */}
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
