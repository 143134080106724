import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export const EditorContainer = ({
  editorState,
  onEditorStateChange,
  children,
}) => {
  return (
    <div className="editor">
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        editorStyle={{
          minHeight: "300px",
          border: "1px solid",
          margin: "10px",
        }}
      />
      {children}
    </div>
  );
};
