import React, { useEffect, useState } from "react";
import AOS from "aos";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

import addImageIcon from "../../../src/image/addicon.png";
import { reportDetails, updateReportStatus } from "../../API";
import { useLocation, useSearchParams } from "react-router-dom";
import { apiBaseUrl, imageUrl } from "../../utils/constants";
import "aos/dist/aos";
import { toast } from "react-toastify";

const ReportAction = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [reportDetail, setReportDetail] = useState({});
    const [title, setTitle] = useState('');
    const [thumbnail, setThumbnail] = useState('');
    const [reportId, setReportId] = useState(0)
    const [comment, setComment]= useState('')

    useEffect(() => {
        AOS.init({
            easing: "ease-out-sine",
            duration: 800,
        });
        AOS.refresh();
        // viewReport()
    }, []);

    useEffect(() => {
        console.log(location, 'locatopmmmmm');
        const id = location.pathname.split('/action/')[1];
        console.log(id, 'report id');
        if (id) {
            setReportId(id)
            viewReport(id);
        }
    }, [location])

    // const [queryParameters] = useSearchParams();
    // console.log(queryParameters.get("id"));
    // const id = queryParameters.get("id");

    const viewReport = async (id) => {
        const res = await reportDetails(id);
        console.log(res, 'resssss');
        if (res?.data?.data) {
            const reportData = res?.data?.data.report;

          if(reportData && reportData.status !== 'pending') {
            navigate("/report")
          }
            setTitle(reportData[reportData.type]?.title);
            setThumbnail(reportData[reportData.type]?.thumnail);
            setReportDetail(reportData);
        }
    };

    const updateStatus = async (status) => {
        const data = {
            status,
            comments: comment
        }
        const res = await updateReportStatus(reportId, data);
        console.log(res)
        if (res && res.data && res.data.success) {
            toast.success("Status update successfully");
            navigate("/report");
        }
    }

    return (
        <div className="rightsidebar" data-aos="slide-up">
            <div className=" w-full h-full">
                <div className="handleimg"></div>
                <div
                    className="w-full mt-5"
                    style={{ padding: "10px 20px", marginLeft: "50px" }}
                >
                    <p
                        style={{
                            marginBottom: "40px",
                            fontSize: "22px",
                            fontWeight: "700",
                        }}
                    >
                        {/* {" "} */}
                        {title}
                    </p>
                    <div style={{ display: "flex" }}>
                        <img
                            src={thumbnail ? apiBaseUrl + '/' + imageUrl + '/' + thumbnail : addImageIcon}
                            style={{
                                width: "400px",
                                height: "250px",
                                border: "1px solid black",
                                borderRadius: "15px",
                            }}
                        />
                        <div
                            style={{
                                borderBottom: "1px solid black",
                                borderTop: "1px solid black",
                                marginLeft: "100px",
                            }}
                        >
                            <p style={{ margin: "10px 0px 0px 0px", color: "gray" }}>
                                {reportDetail?.description}
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-10">
                            <textarea
                                className="mt-3"
                                placeholder="Comments"
                                onChange={(e) => setComment(e.target.value)}
                            ></textarea>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center" style={{ margin: " 3rem 24rem" }}>
                        <Button
                            variant="contained"
                            onClick={() => { updateStatus(`success`) }}
                            style={{
                                backgroundColor: "black",
                                padding: "10px 48px"
                            }}
                        >
                            Accept
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => { updateStatus(`reject`) }}
                            className="mx-3"
                            style={{
                                backgroundColor: "red",
                                padding: "10px 48px"
                            }}
                        >
                            Reject
                        </Button>
                        <Button
                            className="btn btn-primary addbutne btn-sm logoutno mx-3"
                            id="savePDF"
                            onClick={(e) => { navigate(`/report/view/${reportId}`) }}
                        > Back
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReportAction;
