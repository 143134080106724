import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import {
  apiBaseUrl,
  reactAppImageUri,
  reactAppVideoUri,
} from "../../utils/constants";
import { locationList } from "../../utils/locationList";
import {
  Grid,
  Button,
  TextField,
  Checkbox,
  Paper,
  Container,
} from "@mui/material";
import Select from "react-select";
import {
  GetVideoEditingDetail,
  createVideoEditing,
  updateVideoEditing,
} from "../../API/videoEditorApi";
import { usersList } from "../../API";
import FileUpload from "../FileUpload";
// import {users} from "../../API/index";

const InputStyle = {
  width: "100%",
  borderRadius: "3px",
};

const SelectStyle = {
  border: "1px solid #bce0fd",
  color: "#2699fb",
  outline: "none",
  width: "100%",
  fontWeight: "400",
};

const FormValidation = yup.object().shape({
  title: yup.string().required("Title is Required"),
  details: yup.string().required("Description is Required"),
  requirement: yup.string().required("Requirement is Required"),
  userIds: yup.array().required("Please select the user."),
  location: yup.object().required("Please select the location."),
  short_detail: yup.string().required("Please select the Short detail"),
});

const VideoForm = (props) => {
  let { id } = useParams();

  // const [state, setState] = useState();
  const [addMore, setaddMore] = useState([{ filename: "" }]);
  const [selectUser, setSelectUser] = useState([]);
  const [locationLists, setLocationLists] = useState([]);
  const [userData, setUserData] = useState({
    loading: false,
    data: null,
    message: "",
    status: 0,
  });
  const [uesrLists, setUserLists] = useState([]);
  const [location, setLocation] = useState();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(FormValidation),
  });
  const [videoState, setVideoState] = useState([]);
  const [thumb, setThumb] = useState(null);

  const onError = (errors, e) => console.log(errors, e, "fi");
  const onSubmit = async (values) => {
    try {
      values.uploaded_image = thumb;
      values.uploaded_videos = videoState;

      setUserData((v) => ({
        ...v,
        loading: true,
      }));
      values.location = values.location.value;
      let res = null;
      if (id !== undefined) {
        res = await updateVideoEditing(id, { ...values });
      } else {
        values.status = 0
        res = await createVideoEditing(values);
      }
      console.log(res);
      console.log(res.status);
      console.log(res.data);

      if (res.status === 200) {
        console.log(res);
        toast.success(res.data.message);
        if (res.data.id) {
          id = res.data.id;
        }
        navigate("/videoeditor/" + id);
        setUserData((v) => ({
          ...v,
          loading: false,
        }));
      } else {
        toast.error(res.data.message || res.response.statusText);
      }
    } catch (error) {
      console.log(error);
      toast.error("something went wrong");
      setUserData((v) => ({
        ...v,
        loading: false,
      }));
    }
  };

  // const handleThumbChange = async (e) => {
  //   console.log("imgFilehandler", e.target.files);
  //   try {
  //     const res = await UploadFiles(e.target.files);
  //     if (res.status === 200) {
  //       const info = res.data.data[0];
  //       console.log(info);
  //       setThumb(info)
  //       setUserData((v) => ({
  //         ...v,
  //         loading: false,
  //       }));
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     toast.error("something went wrong");
  //   }
  // };
  // const VideoArray = ["y2mate.com - 26 HOLY GRAIL KITCHEN TRICKS TO MAKE YOU A PRO_v144P.mp4"]
  // let VideoArray = [];
  // const handleVideoChange = async (e, index) => {
  //   console.log("handleVideoChange", e.target.files);
  //   VideoArray.push(e.target.files[0].name);
  //   setUserData((v) => ({
  //     ...v,
  //     loading: true,
  //   }));
  //   try {
  //     const res = await UploadFiles(e.target.files);
  //     if (res.status === 200) {
  //       const info = res.data.data[0];
  //       setVideoState([
  //         ...videoState,
  //         {
  //           filename: info.filename,
  //         },
  //       ]);

  //       const addMoreVid = (addMore[index].filename = info.filename);
  //       setaddMore([...addMore]);
  //       setUserData((v) => ({
  //         ...v,
  //         loading: false,
  //       }));
  //     }
  //   } catch (error) {
  //     toast.error("something went wrong");
  //   }
  // };

  const GetInfo = async () => {
    const items = await usersList();
    let userDataForSelect = [];
    if (items.status === 200) {
      items.data.data.rows.map((value, key) =>
        userDataForSelect.push({ value: value.id, label: value.name })
      );
      setUserLists(userDataForSelect);
    }

    let dataList = [];
    locationList.map((val) =>
      dataList.push({ label: val.name, value: val.id })
    );
    setLocationLists(dataList);

    if (id !== undefined) {
      try {
        const res = await GetVideoEditingDetail(id);
        if (res.status === 200) {
          const info = res.data.data;
          setValue("title", info.title);
          setValue("details", info.details);
          setValue("requirement", info.requirement);
          setValue("short_detail", info.short_detail);
          if (
            info.user &&
            info.user.length > 0 &&
            userDataForSelect.length > 0
          ) {
            const dataSelected = [];
            for (let obj of info.user) {
              const user = userDataForSelect.find(
                (o) => Number(o.value) === Number(obj.user_id)
              );
              if (user) {
                dataSelected.push(user);
              }

              console.log(
                userDataForSelect.find(
                  (o) => Number(o.value) === Number(obj.user_id)
                )
              );
            }
            setSelectUser(dataSelected);
            // setValue("user_id", JSON.stringify(value));
            if (dataSelected.length > 0) {
              setValue(
                "userIds",
                dataSelected.map((e) => e.value)
              );
            }
          }
          setValue("user_id", info.user_id);
          setValue("thumnail", info.thumnail);
          setThumb(info.thumnail);

          setValue(
            "location",
            dataList.find((ob) => ob.label === info.location)
          );
          setLocation(dataList.find((ob) => ob.label === info.location));
          // setState(JSON.parse(info.location_id))

          setThumb(info.thumnail);
          if (info.video_urls !== null) {
            setaddMore(JSON.parse(info.video_urls));
          }
        }
      } catch (error) {
        console.log(error);
        toast.error("something went wrong");
      }
    }
  };

  const HandleAddMore = () => {
    setaddMore([...addMore, { filename: "" }]);
  };

  const handleRemove = (index) => {
    const list = [...addMore];
    list.splice(index, 1);
    setaddMore(list);
  };
  useEffect(() => {
    console.log("0000000000000000");
    GetInfo();
  }, []);

  return (
    <>
      <div className="rightsidebar pdfeditform">
        <div className=" adduser text-font1 w-full desbordcard">
          <div class="d-flex ">
            <div class="cursor-pointer" onClick={() => navigate(-1)}>
              <i
                class="fa fa-arrow-left back_arrow_style"
                aria-hidden="true"
              ></i>
            </div>
            <div className="handleimg"></div>
          </div>
          <p className="titileditor">Add Video</p>
          <Container
            maxWidth="xl"
            sx={{ mt: 4, mb: 4 }}
            className="addeditorform"
          >
            <Grid container>
              <div className="mainprogrs">
                <ul className="multi-step-bar">
                  <li className="active">
                    <p>Details</p>
                  </li>
                  <li>
                    <p>Edit Video</p>
                  </li>
                  <li>
                    <p>Submit</p>
                  </li>
                </ul>
              </div>
              <Grid item xs={12}>
                <Paper
                  sx={{ p: 2 }}
                  style={{
                    boxShadow: "none",
                    padding: 0,
                    backgroundColor: "transparent",
                  }}
                >
                  {/* <ListItem>
                  <ListItemText className="black-text" primary="Video Editor" />
                </ListItem> */}
                  <div className="ditgitalform">
                    <form onSubmit={handleSubmit(onSubmit, onError)}>
                      <div className="formgrop">
                        <div className="flexfileds">
                          <div className="forcontrol">
                            {/* <input
                            className=" p-3 form-control  font-normal text-left"
                            type="text"
                            style={InputStyle}
                            placeholder="Title"
                            {...register("title")}
                          ></input> */}

                            <TextField
                              label="Title"
                              variant="outlined"
                              style={InputStyle}
                              type="text"
                              className="font-normal text-left "
                              placeholder="Title"
                              // name="title"
                              // defaultValue="Title"
                              // value={state.title}
                              // onChange={(e) => inputHandle(e)}
                              {...register("title")}
                            />

                            <p className="text-Red font-bold">
                              {errors?.title?.message}
                            </p>
                          </div>
                          <div className="forcontrol">
                            {/* <input
                            type="text"
                            className=" p-3 form-control  font-normal text-left"
                            placeholder="Requirement"
                            {...register("requirement")}
                          /> */}

                            <TextField
                              label="Requirement"
                              variant="outlined"
                              style={InputStyle}
                              type="text"
                              className="font-normal text-left "
                              placeholder="Requirement"
                              // name="Requirement"
                              // defaultValue="Requirement"
                              // value={state.requirement}
                              // onChange={(e) => inputHandle(e)}
                              {...register("requirement")}
                            />

                            <p className="text-Red font-bold">
                              {" "}
                              {errors?.requirement?.message}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="formgrop">
                        <div className="flexfiledss">
                          {/* <input
                          type="text"
                          className=" p-3 form-control  font-normal text-left"
                          placeholder="Short detail"
                          {...register("short_detail")}
                        /> */}

                          <TextField
                            style={InputStyle}
                            placeholder="Short Details"
                            label="Short Details"
                            // name="shortDetails"
                            multiline
                            rows={3}
                            // defaultValue="Short Details"
                            // value={state.shortDetails}
                            // onChange={(e) => inputHandle(e)}
                            {...register("short_detail")}
                          />

                          <p className="text-Red font-bold">
                            {" "}
                            {errors?.short_detail?.message}
                          </p>
                        </div>
                      </div>

                      <div className="formgrop">
                        {/* <textarea
                        style={InputStyle}
                        placeholder="Details"
                        rows={3}
                        {...register("details")}
                      ></textarea> */}

                        <TextField
                          style={InputStyle}
                          placeholder="Details"
                          label="Details"
                          // name="details"
                          multiline
                          rows={3}
                          {...register("details")}
                          // defaultValue="Details"
                          // value={state.details}
                          // onChange={(e) => inputHandle(e)}
                        />
                        <p className="text-Red font-bold">
                          {errors?.details?.message}
                        </p>
                      </div>

                      <div className="formgrop">
                        <div className="flex" style={{ gap: "5%" }}>
                          <div className="flexfileds userlist locationpdf">
                            {/* <select {...register("user_id")} value={selectUser} className="p-3" onChange={handleChange}>
                                                        <option value="">Select Users</option>
                                                        {uesrLists.map((user,i)=>
                                                            <option key={i} value={user.id}>{user.name}</option>
                                                        )}
                                                    </select> */}
                            <Select
                              styles={SelectStyle}
                              isMulti
                              value={selectUser}
                              onChange={(value) => {
                                setSelectUser(value);
                                // setValue("user_id", JSON.stringify(value));
                                setValue(
                                  "userIds",
                                  value.map((e) => e.value)
                                );
                              }}
                              options={uesrLists}
                            />
                            <p className="text-Red font-bold">
                              {errors?.userIds?.message}
                            </p>
                            {/* <Button variant="contained" component="label">
                                                        Upload users excel file
                                                        <input hidden accept="image/*" multiple type="file" />
                                                        </Button> */}
                          </div>

                          <div className="flexfileds locationpdf">
                            <Select
                              styles={SelectStyle}
                              value={location}
                              onChange={(e) => {
                                // setState(e)
                                setValue("location", e);
                                setLocation(e);
                              }}
                              options={locationLists}
                            />
                            <p className="text-Red font-bold">
                              {errors?.location?.message}
                            </p>
                          </div>
                        </div>
                      </div>

                      {/* <div
                        className="formgrop"
                        style={{ margin: "-30px 0 10px 0" }}
                      >
                        <Checkbox
                          {...register("status")}
                          defaultChecked
                          defaultValue={1}
                        />
                        <label className="actij">Active</label>
                      </div> */}

                      <div className="formgrop imagecover">
                        <div className="pdfupone uploadstyle">
                          {/* <div className="imagesuplod"> */}
                          {/* <h5>* Thumbnail Image </h5> */}
                          {/* <div className="flexbox"> */}
                          {/* <div className="flexibal"> */}
                          {/* <input
                            className="noborrder"
                            {...register("thumnail")}
                            onChange={handleThumbChange}
                            accept="image/*"
                            type="file"

                          /> */}

                          <FileUpload
                            fileType={`.png, .jpeg, .jpg, .JPG, .PNG, .JPEG`}
                            onUpload={(ppt) => {
                              setThumb(ppt);
                            }}
                          />
                          <div style={{ paddingLeft: "10px" }}>
                            <p>
                              Upload<span>| Thumbnail Image</span>
                            </p>
                            <p>png,jpeg,jpg</p>
                          </div>
                          {/* </div>                         */}
                          <div className="uplodevideoall">
                            {thumb !== null && (
                              <img
                                alt="img"
                                src={`${
                                  apiBaseUrl + "/public"
                                }/${reactAppImageUri}/${thumb}`}
                              />
                            )}
                          </div>
                          {/* </div> */}
                          {/* </div> */}
                        </div>

                        <div className="pdfuptwo uploadstyle">
                          <div className="repoternon">
                            {console.log(addMore, "addMore")}
                            {addMore.length > 0 &&
                              addMore.map((row, index) => {
                                return (
                                  <div className="uplodevideoall">
                                    {console.log(row.filename, row, "Faiz")}
                                    {row.filename !== null &&
                                      row.filename !== "" && (
                                        <video
                                          width="400"
                                          controls
                                          marginbottom="10px"
                                          marginright="10px"
                                        >
                                          <source
                                            src={`${
                                              apiBaseUrl + "/public"
                                            }/${reactAppVideoUri}/${
                                              row.filename
                                            }`}
                                          ></source>{" "}
                                        </video>
                                      )}
                                    {row.filename === "" && (
                                      // <input
                                      //   className="noborrder"
                                      //   {...register("video")}
                                      //   onChange={(e) => handleVideoChange(e, index)}
                                      //   accept="video/mp4,video/x-m4v,video/*"
                                      //   type="file"
                                      // />
                                      // <div className="pdfuptwo uploadstyle">
                                      <FileUpload
                                        fileType={`.mp4, .x-m4v, .video/*`}
                                        onUpload={(filename) => {
                                          setVideoState([
                                            ...videoState,
                                            {
                                              filename: filename,
                                            },
                                          ]);
                                        }}
                                      />
                                    )}

                                    {index > 0 && (
                                      <Button
                                        className="removevid"
                                        variant="contained"
                                        color="error"
                                        type="button"
                                        onClick={() => handleRemove(index)}
                                        fullWidth
                                      >
                                        X
                                      </Button>
                                    )}
                                  </div>
                                );
                              })}
                            <div style={{ paddingLeft: "10px" }}>
                              <p>
                                Upload<span>| Video File</span>
                              </p>
                              <p>.mp4,.avi</p>
                            </div>
                            <div className="buttonsave addvidbtn ">
                              <Button
                                variant="contained"
                                onClick={HandleAddMore}
                                fullWidth
                              >
                                +
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <Box>
                                                    <Button
                                                        variant="contained"
                                                        component="label"
                                                    >
                                                        Add Video
                                                        <AddIcon className="ml-1" />
                                                        <input
                                                            hidden
                                                            multiple
                                                            {...register(
                                                                "video"
                                                            )}
                                                            onChange={
                                                                handleVideoChange
                                                            }
                                                            accept="video/*"
                                                            type="file"
                                                        />
                                                    </Button>
                                                </Box>

                                                <Box>
                                                    <Typography
                                                        sx={{
                                                            color: "#ffffff",
                                                        }}
                                                    >
                                                        Video Name
                                                    </Typography>
                                                </Box> */}
                      <div className="buttonsave pdfsavebtn ">
                        <Button
                          variant="contained"
                          type="submit"
                          style={{ width: 'max-content' }}
                          fullWidth
                          disabled={userData.loading}
                          className="addbutne logoutno imgedit"
                        >
                          {userData.loading === true
                            ? <><div className="dot-pulse-text">Processing</div> <div className="dot-pulse-container"><div className="dot-pulse"></div></div></>
                            : "NEXT"}
                        </Button>
                      </div>
                    </form>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </>
  );
};

export default VideoForm;
