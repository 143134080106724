import React from "react";
import { useEffect,useState } from "react";
import moment from "moment";

import { useSelector, useDispatch } from 'react-redux';
import { useParams,useNavigate } from "react-router-dom";
import Modal from 'react-modal';

import {
  Box,
  // Typography,
  Grid,
  Button,
  OutlinedInput,
  CircularProgress
} from '@mui/material';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import useResizeObserver from '@react-hook/resize-observer'

import { 
    SET_VIDEOURLactions,
    submitQuiz 
} from '../../redux/Reducers/rendervideoReducer';
import {
  GetVideoEditingDetail
} from "../../API/videoEditorApi";
// import { videosubmitActions } from '../../redux/actions/rendervideoActions'
import { SET_VIDEO_CURRENTTIMEactions } from '../../redux/Reducers/rendervideoReducer'
import { SET_EDITEDTITLEactions,UNDO_ANOTATIONactions, REDO_ANOTATIONactions } from './../../redux/Reducers/rendervideoReducer';

 

import TimeLine from "../../components/VideoEditor/innercomponents/TimeLine";
import TimeStamps from "../../components/VideoEditor/innercomponents/TimeStamps";
import TimeStampsNew from "../../components/VideoEditor/innercomponents/TimeStamps/index1";
import RenderPlayer from "../../components/VideoEditor/innercomponents/RenderPlayer";
import QuizForm from '../../components/VideoEditor/innercomponents/VideoEditTools/components/QuizForm';
import NewVideoEditTools from "../../components/VideoEditor/innercomponents/VideoEditTools/NewVideoEditTools";
import InputBox from "../../components/VideoEditor/innercomponents/VideoEditTools/components/InputBox";



export const QuizContext = React.createContext();

const useSize = (target) => {
  const [size, setSize] = React.useState()
  

  // const dispatch = useDispatch();
  React.useLayoutEffect(() => {
      
    setSize(target.current.getBoundingClientRect())
  }, [target])
 
  // React.useEffect(() => {
      
  //     let getBoundingClientRect = target.current.getBoundingClientRect();
  //     dispatch(SET_HEIGHTWIDTHactions({width:getBoundingClientRect.width,height:getBoundingClientRect.height}));
  // }, [size])

  // Where the magic happens
  useResizeObserver(target, (entry) => setSize(entry.contentRect))
  return size
}



export default function VideoPlayer() {
    let rendervideostate = useSelector(state => state.rendervideoReducer);
    const dispatch = useDispatch();
    const { id } = useParams();
    const [textfield, setTextfield] = useState(null); //state for single item selection of anotation or text
    const [textCaption, setTextCaption] = useState(null);
    const [videoPlayerUrl, setVideoPlayerUrl] = useState('');
    const [currentQuizID, setCurrentQuizID] = useState(null);
    const [timelineRef, setTimelineRef] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTextOpen, setModalTextOpen] = useState(false);
    const [modalCaptionOpen, setModalCaptionOpen] = useState(false);
    const [scrollTop, setScrollTop] = useState(0);
    const [reset, setReset] = useState(false);
    const [scale,setScale] = useState(100)
    let [questionlist, setQuestionList] = React.useState([{"name":"Question 1",value:"",right_answer:0,question_type:0,answerlist:[]}]);
    const navigate = useNavigate();

    const target = React.useRef(null)
    const size = useSize(target)
    
    // const customStyles = {
    //   content: {
    //     top: '50%',
    //     left: '50%',
    //     right: 'auto',
    //     bottom: 'auto',
    //     marginRight: '-50%',
    //     transform: 'translate(-50%, -50%)',
    //   },
    // };
    
    
    // const [isPlaying, setIsPlaying] = useState(false);
    const [isplay, setIsplay] = useState(false);

    const GetInfo = async () => {
      if (id !== undefined) {
          try {
              const res = await GetVideoEditingDetail(id);
              if (res.status === 200) {
                  
                  let info = res.data.data;
                  const video_urls = JSON.parse(info.merge_video_url);
                  
                  dispatch(SET_VIDEOURLactions(info));
                  console.log('00000000000000000000000000')
                  console.log(video_urls.filename)
                  setVideoPlayerUrl(video_urls.filename);
                  /* setValue("title", info.title);
                  setValue("details", info.details);
                  setThumb(info.thumnail);
                  if (info.video_urls !== null) {
                      setaddMore(JSON.parse(info.video_urls));
                  } */
              }
          } catch (error) {
              console.log("Err ",error);
              /* toast.error(
                  error.response.data.message || error.response.statusText
              ); */
          }
      }
  };

  useEffect(() => {
    GetInfo();
    
  },[]);

  const setNewRef = (newref) => {
      
      setTimelineRef(newref.current);
  };


  const modalOpenFunc = (quizid) => {
    
    setCurrentQuizID(quizid);
    if(rendervideostate.project.margeArr[quizid])
    {
      console.log("Types of "+ typeof rendervideostate.project.margeArr[quizid].actions[0].questionlist);
    
      let newdata = rendervideostate.project.margeArr[quizid].actions[0].questionlist;
      setQuestionList(newdata);
    }
    else 
    {
      setQuestionList([{"name":"Question 1",value:"",right_answer:0,question_type:2,answerlist:[]}]);
      
    }
    setModalOpen(!modalOpen);
  };

  const submitQuizFunc = () => {
    let data = {questionlist:questionlist,index:currentQuizID}
    dispatch(submitQuiz(data));
    setModalOpen(!modalOpen);
    setQuestionList([{"name":"Question 1",value:"",right_answer:0,question_type:2,answerlist:[]}]);
  };


  // const submit = () => {
  //   console.log("Project Submit : ",rendervideostate.project);
  //   dispatch(videosubmitActions(rendervideostate.project)).then(()=>{
  //       navigate("/videoeditfinish/"+id);
  //   });
    
  // }

  const handleScroll = (e) => {
    e.clientHeight = 500;
    // console.log("mockData ", mockData);
    setScrollTop(e.scrollTop);
    console.log("e ",e);
  }

  const setPlayFuc = () => {
    if(reset) {
      setReset(false)
      console.log('1...........')
      dispatch(SET_VIDEO_CURRENTTIMEactions({ sec: 0, bytimeline: false })) 
    }
    setTimeout(()=> {
      setIsplay(!isplay)
    })
   
  }
 
  return (
     
    <div className="rightsidebar">
    <Grid spacing={0} md={12} >
    <div className="mainprogrs">
      <ul className="multi-step-bar">
          <li className="sucses"><p>Details</p></li>
          <li className="active"><p>Edit Video</p></li>
          <li><p>Submit</p></li>
      </ul>
    </div>


        <Modal
          isOpen={modalOpen}
          onRequestClose={modalOpenFunc}
          style={{
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            zIndex:9999999999999,
            border:"1px solid blue",
          }}
          contentLabel="Example Modal"
        >
          <QuizContext.Provider value={{questionlist , setQuestionList,modalOpenFunc, submitQuizFunc}}>
            <QuizForm />
          </QuizContext.Provider>
        </Modal>
        <Modal
          isOpen={modalTextOpen}
          onRequestClose={() => { setModalTextOpen(false) }}
          style={{
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            zIndex:10000,
            border:"1px solid blue",
          }}
          contentLabel="Example Modal"
        >
          <Box sx={{ p: 1, height: '390px', overflowY: 'scroll' }}>
            <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
              {/* <TextField id="outlined-basic" label="Add Text" variant="outlined"
                  onChange={(e) => { setTextfield(e.target.value) }}
                /> */}
              <OutlinedInput
                placeholder="Enter text"
                inputComponent={'input'}
                onChange={(e) => { setTextfield(e.target.value) }}
                sx={{  borderColor: '#ffffff', height: '45px', }}
                required={true}
                value={textfield !== null ? textfield : ""}

              />
              <Button variant="contained" sx={{ marginTop: '-3px',color:'black', marginLeft: '0px', height: '42px', width: '30%' }}
                onClick={() => {
                  // console.log(state.project)

                  var text = {
                    id: rendervideostate.project.margeArr.length,
                    // margeId: rendervideostate.project.margeArr.length,
                    // anotationId: rendervideostate.project.texts.length,


                    actions: [{
                      id: `text ${rendervideostate.project.margeArr.length}`,
                      anotationId: rendervideostate.project.margeArr.length,
                      type: 'TEXT',
                      start: rendervideostate.project.video.video_currentTime.seconds,
                      end: rendervideostate.project.video.video_currentTime.seconds + 5,
                      text: textfield,
                      effectId: `effect${1}`
                    }]
                  }
                  if (textfield !== null && textfield !== '') {
                    dispatch(SET_EDITEDTITLEactions(text))
                  }
                  setTextfield(null)
                }}
              >Add</Button>

            </Box>
            <Box sx={{ justifyContent: 'center', alignItems: 'center', marginTop: '2%' }}>
              
              {rendervideostate.project.margeArr.map((track, trackIndex) => {
                return track.actions.forEach((action, index) => {
                  if (action.type !== undefined) {
                    if (action.type === 'TEXT') {
                      return (
                        <InputBox action={action} key={index} trackKey={trackIndex} key2={index} />
                      )
                    }
                  }
                })
              })}
            </Box>
          </Box>
        </Modal>
        <Modal
          isOpen={modalCaptionOpen}
          onRequestClose={() => { setModalCaptionOpen(false) }}
          style={{
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            zIndex:10000
          }}
          contentLabel="Example Modal"
        >
          <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
            {/* Caption related fild <TextField id="outlined-basic" label="Add Text" variant="outlined"
                onChange={(e) => { setTextfield(e.target.value) }}
              /> */}
            <OutlinedInput
              inputComponent={'input'}
              placeholder="Enter text"
              onChange={(e) => { setTextCaption(e.target.value) }}
              sx={{ borderColor: '#ffffff', height: '45px' }}
              required={true}
              inputProps={{ maxLength: 100 }}
              value={textCaption !== null ? textCaption : ""}
            />
            <Button variant="contained" sx={{ marginTop: '-3px', marginLeft: '0px', height: '42px', width: '30%' }}
              onClick={() => {
                // console.log(state.project)

                var text = {
                  id: rendervideostate.project.margeArr.length,
                  // margeId: rendervideostate.project.margeArr.length,
                  // anotationId: rendervideostate.project.texts.length,


                  actions: [{
                    id: `text ${rendervideostate.project.margeArr.length}`,
                    anotationId: rendervideostate.project.margeArr.length,
                    type: 'CAPTION',
                    start: rendervideostate.project.video.video_currentTime.seconds,
                    end: rendervideostate.project.video.video_currentTime.seconds + 5,
                    text: textCaption,
                    effectId: `effect${1}`
                  }]
                }
                if (textCaption !== null && textCaption !== '') {
                  dispatch(SET_EDITEDTITLEactions(text))
                }
                setTextCaption(null)
              }}
            >Caption</Button>
          </Box>

          <Box sx={{ justifyContent: 'center', alignItems: 'center', marginTop: '2%' }}>
            
            {rendervideostate.project.margeArr.forEach((track, trackIndex) => {
              return track.actions.forEach((action, index) => {
                if (action.type !== undefined) {
                  if (action.type === 'CAPTION') {
                    return (
                      <InputBox action={action} key={index} trackKey={trackIndex} key2={index} />
                    )
                  }
                }
              })
            })}
          </Box>
        </Modal>
        <Grid container spacing={0} md={12} style={{backgroundColor:'#ffff',border: "1px solid #659EC7",padding:"10px" }} className="mmmmmmmm">
         <Grid item md={2} style={{borderRight:"1px solid gray", paddingTop:"5px", paddingBottom:"5px"}}  >
          <p style={{fontSize:"12px",color:"#2597f8",fontWeight: "800",}}>SELECT CAPTION & MARKERS</p>
         </Grid>
         <Grid item md={8} style={{display:"flex", alignItems:"right"}}  >
         <Grid item md={10} style={{textAlign:"center"}} >
         <p style={{fontSize:"12px",color:"#2597f8",textAlign: "center", fontWeight: "800"}}>EDITOR / REVIEW</p>

         </Grid>
         <Grid item md={2} style={{display:"flex", alignItems:"right"}} className="new-header-containt13" >
         <button onClick={()=> {dispatch(UNDO_ANOTATIONactions())}} style={{textAlign:"right",paddingRight:"5px",borderRight:"1px solid gray"}}><UndoIcon style={{fill:"#659EC7"}}/></button>
          <button  onClick={()=> {dispatch(REDO_ANOTATIONactions())}} style={{textAlign:"right",paddingLeft:"5px"}}><RedoIcon style={{fill:"#659EC7"}}/></button>

         </Grid>
         

          </Grid>
         <Grid item md={2} className="new-header-containt3" >
          {(rendervideostate.project.submitloading)
            ?
            (<CircularProgress className="finalSubmitBtn" color="success"/>)
            :
            (<Button variant="contained"
            className="newfinalSubmitBtn"
            color="success"
            onClick={() => { navigate("/videoeditfinish/"+id);  }} >SUBMIT CHANGES</Button>)
          }
         </Grid>
        </Grid>
        

      <Grid container spacing={0} md={12} style={{ height: '57%',backgroundColor:'#ffff' }} className="neineineineiiii">
        <Grid item md={2.5} className="video-edit-tools" sx={{ backgroundColor:'#ffff', zIndex: '3' }}>
          {/* <VideoEditTools setModalOpen={modalOpenFunc}/> */}
          <NewVideoEditTools setModalOpen={modalOpenFunc} setTextModalOpen={()=>{setModalTextOpen(true)}} setModalCaptionOpen={()=> {setModalCaptionOpen(true)}}/>
        </Grid>
        <Grid item className="video-player-show" ref={target} md={7} sx={{ backgroundColor: '#C4CAD6',height:'450px',marginTop:'0px',position:'relative' }}
          container
          direction="row"
          justifyContent="center"
        // alignItems="center"
        >
          <RenderPlayer videoPlayerUrl = {videoPlayerUrl} size={size}  onFinish={() =>{
            setReset(true)
            setIsplay(false)
          }}/>
        </Grid>
        <Grid item className="inner-Track-Grid" md={2.5}>
          <TimeStamps stamps={rendervideostate.project.margeArr}  />
        </Grid>
        {/* <Grid item md={3} sx={{ backgroundColor: '#000000' }}>
          <Layers />
        </Grid> */}
      </Grid> 

      <Grid container spacing={0} md={12} style={{backgroundColor:'#ffff',paddingLeft:"0px",paddingRight:"0px",paddingTop:"10px",paddingBottom:"10px" }} className="mmmmmmmm">
         <Grid item md={2}  className="new-header-containt1" >
          <button className="btnAddQuix"
          onClick={() => {
            let totalQuiz = 1;
            rendervideostate.project.margeArr.forEach((track, trackIndex) => {
              track.actions.forEach((action, index) => {
                if (action.type !== undefined) {
                  if (action.type === 'QUIZ') {
                    totalQuiz = totalQuiz + 1;
                  }
                }
              })
            })
            modalOpenFunc(rendervideostate.project.margeArr.length)

            var text = {
              id: rendervideostate.project.margeArr.length,
              // margeId: rendervideostate.project.margeArr.length,
              // anotationId: rendervideostate.project.texts.length,


              actions: [{
                id: `Quiz ${totalQuiz}`,
                anotationId: rendervideostate.project.margeArr.length,
                type: 'QUIZ',
                start: rendervideostate.project.video.video_currentTime.seconds,
                end: rendervideostate.project.video.video_currentTime.seconds + 2,
                text: `Quiz ${totalQuiz}`,
                effectId: `effect${1}`,
                questionlist: []
              }]
            }
            dispatch(SET_EDITEDTITLEactions(text))


          }}
          
          >ADD QUIZ</button>

          <div className="playiconall">
            
            <div className="icons">
            <SkipPreviousIcon/>
            </div>
            <div className="icons">
            <button onClick={()=> setPlayFuc()}>{(isplay) ? (<PauseIcon />) : (<PlayArrowIcon/>)} </button>
             
            </div>
            <div className="icons">
            <SkipNextIcon/>
            </div>
          </div>

          <div className="mint-sec">
           <h5>{(timelineRef != null) ? moment.utc(timelineRef.getTime()*1000).format('HH:mm:ss') : '00:00:00'} / {(rendervideostate.project.video.timelineTiming.duration != null) ? moment.utc(rendervideostate.project.video.timelineTiming.duration*1000).format('HH:mm:ss') : '00:00:00'}  </h5>
            </div>

            <div className="zoominout">
              <div className="zoomout">
                <button onClick={() => { if(scale <= 450) setScale(scale+50)}}><ZoomInIcon/></button>
              </div>
              <div className="inprane">
              <input type="range" value={scale/5} />
              </div>
              <div className="zoomout">
                <button onClick={() => {if(scale > 100)setScale(scale-50)}}><ZoomOutIcon/></button>
              </div>
            </div>
  
         </Grid>
         <Grid item md={8} style={{display:"flex", alignItems:"right"}} className="new-header-containt2" >
       
         
         </Grid>
         <Grid item md={2} className="new-header-containt3" >
          
         </Grid>
      </Grid>
      <Grid className="outer-Track-Grid" sx={{ backgroundColor: '#ffff', border: "1px solid #659EC7",height:"240px" }} container spacing={0} md={12}>
        <Grid  item md={3} sx={{ backgroundColor: '#ffff', border: "1px solid #659EC7",zIndex: '3' }}>
        <div>
          <TimeStampsNew stamps={rendervideostate.project.margeArr} setScrollTop={(e)=> {
            setScrollTop(e);
          }} scrollTop={scrollTop} timelineRef={timelineRef} />
        </div>
        
        
      </Grid>
        <Grid className="inner-inner-Track-Grid timeLineBG" item md={9} sx={{ zIndex: '3' }}>
          <TimeLine scalewidth={scale}  handleScroll={handleScroll} play={isplay} setNewRef={setNewRef} scrollTop={scrollTop} currentTime={(timelineRef && timelineRef.getTime)? timelineRef.getTime()*1000: 0} />
        </Grid>

      </Grid>

      
      <Grid container spacing={0} md={12} style={{backgroundColor:'#ffff',padding:"10px",marginTop:"98px" }} className="nexttt">

      {/* <Grid item md={12}   className="new-header-containt1" >
          <Button variant="contained"
            className="NextButton"
            color="success"
            onClick={() => { "submit11()" }} >NEXT</Button>
          
         </Grid> */}
      </Grid>
    </Grid>
    </div>
  )
}
