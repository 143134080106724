import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos";
import { TextField, Card, Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import { faqSubmit, faqList, faqDelete } from "../../API";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";

const Faq = () => {
  const [faqArr, setFaqArr] = useState([
    { question: "", answer: "", display_order: "" },
  ]);

  const submitFaqHandler = async (e) => {
    e.preventDefault();
    for (const faq of faqArr) {
      if (
        faq.question === "" ||
        faq.answer === "" ||
        faq.display_order === ""
      ) {
        toast.error("All input fields required", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        return;
      }
      if (faq.display_order <= 0) {
        toast.error("negative number are invalid", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        return;
      }
    }
    let res = await faqSubmit({ faqArrary: faqArr });
    if (res.data.success) {
      if (res.data.data.length > 0) {
        setFaqArr([...res.data.data]);
        toast.success("add successfully", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    }
  };

  const inputHandle = (e, index) => {
    const { name, value } = e.target;
    const list = [...faqArr];
    list[index][name] = value;
    setFaqArr(list);
  };

  const addFaqQuestionHandler = () => {
    const localFaqArray = faqArr;
    localFaqArray.push({
      question: "",
      answer: "",
      display_order: "",
    });
    setFaqArr([...localFaqArray]);
    toast.success("Add faq model ", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const removeFaqQuestionHandler = async (index, id) => {
    const localFaqArray = faqArr;
    localFaqArray.splice(index, 1);
    setFaqArr([...localFaqArray]);
    if (id !== "") {
      const faqDel = await faqDelete(id);
      if (faqDel.data.success) {
        toast.error("Delete successfully ", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    }
  };

  useEffect(() => {
    AOS.init({
      easing: "ease-out-sine",
      duration: 800,
    });
    AOS.refresh();
    faqlistFunction();
  }, []);

  const faqlistFunction = async () => {
    const faqlist = await faqList();
    if (faqlist.data.success) {
      const list = faqlist.data.data;
      if (list.length > 0) setFaqArr([...list]);
    }
  };
  return (
    <div className="rightsidebar" data-aos="slide-up">
      <div className=" w-full h-full">
        <div className="handleimg"></div>
        <div className="flex contacthead">
          <h3 className=" dsopForm-heading text-2xl">
            Raise Your Faq's Question here
          </h3>
        </div>
        <div className="w-full mt-5" style={{ padding: "10px 20px" }}>
          <Grid container spacing={2}>
            <div style={{ overflowY: "scroll", height: "55vh", width: "100%" }}>
              {faqArr.map((faq, index) => {
                return (
                  <Grid item md={12} key={index}>
                    {faqArr.length - 1 === index && (
                      <Button
                        variant="contained"
                        color="success"
                        onClick={addFaqQuestionHandler}
                        style={{ cursor: "pointer", margin: "6px" }}
                        startIcon={<AddIcon style={{ marginLeft: "9px" }} />}
                      ></Button>
                    )}
                    {faqArr.length > 1 && (
                      <Button
                        startIcon={
                          <DeleteOutlineIcon style={{ marginLeft: "9px" }} />
                        }
                        style={{ cursor: "pointer", margin: "6px" }}
                        variant="contained"
                        color="error"
                        onClick={() => removeFaqQuestionHandler(index, faq.id)}
                      ></Button>
                    )}
                    <Card style={{ padding: "15px" }}>
                      <form>
                        <div className="gap-5">
                          <div className="contactinputform1">
                            <div className="">
                              <TextField
                                label="question"
                                variant="standard"
                                style={{ width: "100%", marginBottom: "15px" }}
                                type="text"
                                className="placeholder:text-font1 font-normal text-left inputformwidth1 "
                                placeholder="question"
                                name="question"
                                value={faq.question}
                                onChange={(e) => inputHandle(e, index)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="gap-5">
                          <div className="contactinputform1">
                            <div className="">
                              <TextField
                                label="answer"
                                variant="standard"
                                style={{ width: "100%", marginBottom: "15px" }}
                                type="text"
                                className="placeholder:text-font1 font-normal text-left inputformwidth1 "
                                placeholder="answer"
                                multiline
                                rows={2}
                                name="answer"
                                value={faq.answer}
                                onChange={(e) => inputHandle(e, index)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="gap-5">
                          <div className="contactinputform1">
                            <div className="">
                              <TextField
                                label="display order"
                                variant="standard"
                                style={{ width: "100%", marginBottom: "15px" }}
                                type="number"
                                InputProps={{ inputProps: { min: 0 } }}
                                className="placeholder:text-font1 font-normal text-left inputformwidth1 "
                                placeholder="display order"
                                name="display_order"
                                value={faq.display_order}
                                onChange={(e) => inputHandle(e, index)}
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </Card>
                  </Grid>
                );
              })}
            </div>
            <Grid item md={12} s>
              <Button
                onClick={submitFaqHandler}
                type="submit"
                variant="contained"
              >
                submit
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Faq;
