import React,{useState} from 'react'
import {
  ListItem,
  ListItemText
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector, useDispatch } from 'react-redux';
import { DELETEactions,Editactions } from '../../../../../../redux/Reducers/rendervideoReducer'

export default function Listitem(props) {
  const state = useSelector(state => state.rendervideoReducer)
  const dispatch = useDispatch()
  const [toggle, setToggle] = useState(true)
const [name, setName] = useState()
  const onDelete = (id) => {
    console.log('state.project.margeArr ', id)
    var newMargeArr = state.project.margeArr.filter((n) => {
      console.log('n ', n, id)
      return n.id !== id.id;
    })

    dispatch(DELETEactions(newMargeArr))
  }

  const onEdit = (id) => {
    const dataToSave = JSON.parse(JSON.stringify(state.project.margeArr));  
    const newMargeArr = dataToSave.find((o) =>  o.id === id.id  )
    newMargeArr.name=name;
    dispatch(Editactions(dataToSave))
  }
 
  return (
    <ListItem
      secondaryAction={
        <IconButton edge="end" aria-label="comments" component="a" >
          {Number(props.stamps.id) !== 0 ? <DeleteIcon sx={{ color: 'red' }} onClick={() => { onDelete(props.stamps) }} /> : <></>}
        </IconButton>
      }
      sx={{
        borderRadius: 1,
        border: '1px dotted #659EC7',
        color:  '#659EC7',
        backgroundColor: '#ffff',
        marginTop:'2px',
        height:'46px',
        // marginY: '8px'
      }} >
      { }
      {/* <ListItemButton> */}
     { toggle ? (
   <ListItemText onDoubleClick={() => {
      setToggle(false)
      setName( props.stamps.name ? props.stamps.name :  'Track' + props.stamps.id)
    }} primary={ !!props.stamps.name ? props.stamps.name : 'Track' + props.stamps.id } />
) : (
  <input
  type="text"
  value={name}
  onChange={(event) => {
    setName(event.target.value)
  }}
  onBlur={(event) => {
    
      setToggle(true)
      onEdit(props.stamps)
      event.preventDefault()
      event.stopPropagation()
    
  }}
/>
)}
      {/* <ListItemText onDoubleClick={()=>console.log("here")} primary={'Track ' + props.stamps.id} /> */}
      {/* </ListItemButton> */}
    </ListItem>
  )
}
