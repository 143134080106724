import React, { useEffect, useState } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify"
import AddIcon from '@mui/icons-material/Add';
import { allUserListApi } from "../../API";
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import PdfEditor from '../PdfEditor/ListOfPdfPage';
import VideoEditor from '../VideoPlayerPages/ListOfVideoPage'
import ImageEditor from '../ImageEditor/ListOfImagePage'
import PptEditor from '../PptEditor/ListOfPptPage' 
import MobileSop from "../MobileSop/MobileSop";
import AOS from "aos";
import "aos/dist/aos.css";


function ListOfEditors() {
  // const navigate = useNavigate()
  const params = useParams()
  const [type, setType] = useState('pdf');

  // const [userData, setUserData] = useState({
  //   loading: false,
  //   data: null,
  //   message: "",
  //   status: 0
  // })

  const fetchAllUserList = async (values) => {
    try {
      // setUserData((v) => ({
      //   ...v,
      //   loading: true
      // }))
      const res = await allUserListApi("/user/list");
         console.log("user List")
      console.log(values)
      if (res.status === 200) {
        // toast.success(res.data.message); 
        // setUserData((v) => ({
        //   ...v,
        //   loading: false,
        //   data: res.data.data
        // }))

      }
    } catch (error) {
      toast.error("something went wrong");
      // setUserData((v) => ({
      //   ...v,
      //   loading: false,
      //   data: null
      // }))
    }
  };

  useEffect(() => { 
    console.log(params)
    fetchAllUserList(params?.userType)
  }, [params?.userType])

  // const handleDeleteUser = async (id) => {
  //   try {
  //     // eslint-disable-next-line
  //     if (confirm("Are you sure you want to delete?")) {
  //       await deleteUser({ id })
  //       fetchAllUserList()
  //     }

  //   } catch (error) {
  //     toast.error("something went wrong");
  //   }
  // }
  // const { data, loading } = userData;

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const type = params.get('type');
    if(type) {
      setType(type)
    }
    console.log('type.............................', type)
    AOS.init({
      easing: 'ease-out-sine',
      duration: 800,
    });
    AOS.refresh();
  }, []);

  return (
    <>


    <div className="rightsidebar userlistmain"  data-aos="slide-up">
    {/* <div className="usermainaddbtn">
          <Link to="/user/add/user" className="btn">
            <AddIcon />
            Add User</Link>
        </div> */}


      <div className="userstab">

    <Tabs
    defaultActiveKey={type}
    transition={false}
    activeKey={type}
    onSelect={(k) => setType(k)}
    id="noanim-tab-example"
    className=""
  >
    <Tab eventKey="pdf" title={<span> <PeopleAltOutlinedIcon/> PDF EDITOR</span>}  >
    <PdfEditor />
    </Tab>
    <Tab  eventKey="video" title={<span> <AdminPanelSettingsOutlinedIcon/> VIDEO EDITOR </span>} >
    <VideoEditor />
    </Tab>
    <Tab  eventKey="image" title={<span> <AdminPanelSettingsOutlinedIcon/> IMAGE EDITOR</span>} >
    <ImageEditor />
    </Tab>
    <Tab  eventKey="ppt" title={<span> <AdminPanelSettingsOutlinedIcon/> PPT EDITOR</span>} >
    <PptEditor />
    </Tab>
    <Tab eventKey="report" title={<span> <AdminPanelSettingsOutlinedIcon /> Mobile Sop's</span>} >
              <MobileSop />
            </Tab>
  </Tabs>
  </div>


    </div>
    </>
  );
}

export default ListOfEditors;
