import React from "react";
import { Card } from "@mui/material";

import { QuizContext } from "./../../../../../../pages/VideoPlayerPages/VideoPlayer";
export default function QuizForm(props) {
  let { questionlist, setQuestionList, modalOpenFunc, submitQuizFunc } =
    React.useContext(QuizContext);

  const addAnswerFunc = (firstindex, index) => {
    console.log(questionlist, "answer listttt");
    let newquestionlist = questionlist.slice();
    newquestionlist[firstindex].answerlist.push({ value: "" });
    setQuestionList(newquestionlist);
  };

  const removeAnswerFunc = (firstindex, index) => {
    let newanswerlist = questionlist.slice();
    newanswerlist[firstindex].answerlist.splice(index, 1);
    setQuestionList(newanswerlist);
  };

  const removeQuestionFunc = (firstindex, index) => {
    let newanswerlist = questionlist.slice();
    newanswerlist.splice(firstindex, 1);
    setQuestionList(newanswerlist);
  };

  const addMoreQuestionFunc = () => {
    let newquestionlist = questionlist.slice();
    newquestionlist.push({
      name: "Question 1",
      question_type: 2,
      right_answer: 0,
      answerlist: [],
    });
    setQuestionList(newquestionlist);
  };

  const changeTypeFunc = (firstindex, value) => {
    console.log(value, "select anser typpeeeeeee");
    let newquestionlist = questionlist.slice();
    if (Number(value) === 0 || Number(value) === 1) {
      if (newquestionlist[firstindex].answerlist.length <= 0) {
        for (let i = 0; i < 4; i++) {
          console.log("input box =" + i);
          let newquestionlist = questionlist.slice();
          newquestionlist[firstindex].answerlist.push({ value: "" });
          setQuestionList(newquestionlist);
        }
      }
    }

    newquestionlist[firstindex].question_type = value;

    setQuestionList(newquestionlist);
  };

  const changeRightTypeFunc = (firstindex, value) => {
    console.log("changeRightTypeFunc.....................")
    let newquestionlist = questionlist.slice();
    newquestionlist[firstindex].right_answer = Number(value);
    setQuestionList(newquestionlist);
  };

  let changeInputValue = (firstindex, value) => {
    let newquestionlist = questionlist.slice();
    newquestionlist[firstindex].value = value;
    setQuestionList(newquestionlist);
  };

  const changeAnswerInputValue = (firstindex, index, value) => {
    let newquestionlist = questionlist.slice();
    console.log("value : ", value);
    newquestionlist[firstindex].answerlist[index] = { value: value };
    setQuestionList(newquestionlist);
  };

  return (
    <div className=" adduser text-font1 w-full" style={{ height: "600px" }}>
      <div className="add-userform">
        <div className="heigfull">
          <div
            className="flex widthd"
            style={{
              width: "100%",
              "justify-content": "space-between",
              "background-color": "#bce0fd",
              "align-content": "center",
              "padding-bottom": "1rem",
            }}
          >
            <div className="mt-8">
              <h3 className="w-4/5 dsopForm-heading text-2xl">Add Quiz</h3>
            </div>
            <div className="mt-4">
              <button
                type="button"
                onClick={() => {
                  addMoreQuestionFunc();
                }}
                className="btn btn-primary "
              >
                {"Add More Question"}
              </button>
            </div>
          </div>

          <div className="flex w-full h-4/5 mt-4 ">
            <form className="justify-center w-full">
              <div className="w-full">
                {questionlist.map((question, firstindex) => {
                  return (
                    <Card
                      variant="outlined"
                      className="cardLayout"
                      style={{ "box-shadow": "0px 0px 10px #cccccc" }}
                    >
                      <div
                        className="flex form-werp"
                        style={{ marginBottom: 50 }}
                      >
                        <div className="widthd" style={{ width: "100%" }}>
                          {firstindex !== 0 ? (
                            <div className="widthd" style={{ width: "100%" }}>
                              <div className="form-group">
                                <button
                                  type="button"
                                  onClick={() => {
                                    removeQuestionFunc(firstindex);
                                  }}
                                  className="btn btn-danger "
                                  style={{
                                    background: "red",
                                    "border-color": "#fff",
                                  }}
                                >
                                  {"Remove Question"}
                                </button>
                              </div>
                            </div>
                          ) : null}
                          <div className="form-group">
                            <input
                              type="text"
                              className=" p-3 form-control rounded-lg  text-font1 placeholder:text-font1 color-black font-normal text-left "
                              placeholder={
                                "Question Name " + parseInt(firstindex + 1)
                              }
                              onKeyUp={(event) => {
                                changeInputValue(
                                  firstindex,
                                  event.target.value
                                );
                              }}
                              defaultValue={question.value}
                            />
                            <p className="text-Red font-bold"></p>
                          </div>
                        </div>
                        <div className="widthd" style={{ width: "100%" }}>
                          <div className="form-group">
                            <select
                              className="form-control "
                              onChange={(event) => {
                                changeTypeFunc(firstindex, event.target.value);
                              }}
                            >
                              <option value="">--Select--</option>
                              <option value="0">Multi Choice</option>
                              {/* <option value="1">Fill in the Blank</option> */}
                              {/* <option value="2" selected="selected">Short Answer</option> */}
                              <option value="3">True False</option>
                            </select>
                          </div>
                        </div>
                        {Number(question.question_type) === 0 ||
                        Number(question.question_type) === 1 ? (
                          <div className="widthd" style={{ width: "100%" }}>
                            <div
                              className="addanswerdiv"
                              style={{ width: "100%" }}>
                              {question.answerlist.map((newanswer, index) => {
                                return (
                                  <div
                                    style={{ width: "100%", display: "flex" }}
                                  >
                                    <div style={{ width: "10%" }}>
                                      <input
                                        type="radio"
                                        value={index}
                                        name={`ans` + firstindex}
                                        onChange={(event) => {
                                          changeRightTypeFunc(
                                            firstindex,
                                            event.target.value
                                          );
                                        }}
                                        checked={
                                          question.right_answer === index
                                            ? true
                                            : false
                                        }
                                      />
                                    </div>
                                    <div
                                      className="form-group"
                                      style={{ width: "70%" }}
                                    >
                                      <input
                                        type="text"
                                        className=" p-3 form-control rounded-lg  text-font1 placeholder:text-font1 font-normal text-left "
                                        placeholder={
                                          "Answer " + (parseInt(index) + 1)
                                        }
                                        onKeyUp={(event) => {
                                          changeAnswerInputValue(
                                            firstindex,
                                            index,
                                            event.target.value
                                          );
                                        }}
                                        defaultValue={newanswer.value}
                                      />
                                      <p className="text-Red font-bold"></p>
                                    </div>
                                    <div style={{ width: "20%" }}>
                                      <button
                                        type="button"
                                        className="ml-4 btn btn-danger btnRemove "
                                        onClick={() => {
                                          removeAnswerFunc(firstindex, index);
                                        }}
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                            <div className="widthd" style={{ width: "100%" }}>
                              <div className="form-group">
                                <button
                                  type="button"
                                  onClick={() => {
                                    addAnswerFunc(
                                      firstindex,
                                      parseInt(question.answerlist.length - 1)
                                    );
                                  }}
                                  className="btn btn-primary "
                                >
                                  {"Add Answer"}
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : null}

                        {(Number(question.question_type) === 0 ||
                          Number(question.question_type) === 1) &&
                        question.answerlist.length > 0 ? (
                          <div className="widthd">
                            <div className="form-group">
                              <select
                                className="custom-select form-control rounded-lg dropdownRemoveNew  text-font1 p-3  font-normal text-left border-yellow-900 "
                                onChange={(event) => {
                                  changeRightTypeFunc(
                                    firstindex,
                                    event.target.value
                                  );
                                }}
                              >
                                {question.answerlist.map((val, ind) => {
                                  return (
                                    <option value={ind}>{val.value}</option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        ) : Number(question.question_type) === 2 ? (
                          <div className="widthd">
                            <div className="form-group">
                              <input
                                type="text"
                                className=" p-3 form-control rounded-lg  text-font1 placeholder:text-font1 font-normal text-left "
                                placeholder={"Right Answer"}
                                onKeyUp={(event) => {
                                  changeRightTypeFunc(
                                    firstindex,
                                    event.target.value
                                  );
                                }}
                                defaultValue={question.right_answer}
                              />
                            </div>
                          </div>
                        ) : Number(question.question_type) === 3 ? (
                          <div className="widthd">
                            <div className="form-group">
                              <select
                                className="form-control "
                                onChange={(event) => {
                                  changeRightTypeFunc(
                                    firstindex,
                                    event.target.value
                                  );
                                }}
                              >
                                <option value="1">True</option>
                                <option value="0">False</option>
                              </select>
                            </div>
                          </div>
                        ) : null}
                      </div>{" "}
                    </Card>
                  );
                })}
                <div className="flex w-full">
                  <div className="m-2 ml-6 p-1.5 text-center w-full customclass">
                    <button
                      type="button"
                      onClick={() => {
                        submitQuizFunc();
                      }}
                      className="btn btn-primary "
                    >
                      {"Submit"}
                    </button>
                    <button
                      type="button"
                      className="ml-4 btn btn-danger "
                      onClick={() => {
                        modalOpenFunc();
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
