import React, { useEffect, useState } from "react";
import AOS from "aos";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

import addImageIcon from "../../../src/image/addicon.png";
import { reportDetails } from "../../API";
import { useLocation, useSearchParams } from "react-router-dom";
import { apiBaseUrl, imageUrl } from "../../utils/constants";
import "aos/dist/aos";
const ReportView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [reportDetail, setReportDetail] = useState({});
  const [title, setTitle] = useState('');
  const [thumbnail, setThumbnail] = useState('');
  const [reportId, setReportId] = useState(0)

  useEffect(() => {
    AOS.init({
      easing: "ease-out-sine",
      duration: 800,
    });
    AOS.refresh();
    // viewReport()
  }, []);

  useEffect(() => {
    console.log(location, 'locatopmmmmm');
    const id = location.pathname.split('/view/')[1];
    console.log(id, 'report id');
    if (id) {
      setReportId(id)
      viewReport(id);
    }
  }, [location])

  // const [queryParameters] = useSearchParams();
  // console.log(queryParameters.get("id"));
  // const id = queryParameters.get("id");

  const viewReport = async (id) => {
    const res = await reportDetails(id);
    console.log(res, 'resssss');
    if (res?.data?.data) {
      const reportData = res?.data?.data.report;

      console.log('..................................')
      console.log(reportData)
      setTitle(reportData[reportData.type]?.title);
      setThumbnail(reportData[reportData.type]?.thumnail);
      // if (reportData.type === "ppt_editing") {
      //   setTitle(reportData?.pptEditing?.title);
      //   setThumbnail(reportData?.pptEditing?.thumnail)
      // } else if (reportData.type === "mobile_doc") {
      //   setTitle(reportData?.mobileDoc?.doc_name);
      //   setThumbnail(reportData?.mobileDoc?.thumnail)
      // } else if (reportData.type === "pdfs") {
      //   setTitle(reportData?.pdfData?.title);
      //   setThumbnail(reportData?.pdfData?.thumnail)
      // } else if (reportData.type === "video_editings") {
      //   setTitle(reportData?.videoEditing?.title);
      //   setThumbnail(reportData?.videoEditing?.thumnail)
      // } else if (reportData.type === "image_editings") {
      //   setTitle(reportData?.imageEditing?.title);
      //   setThumbnail(reportData?.imageEditing?.thumnail)
      // }
      setReportDetail(reportData);
    }
  };

  return (
    <div className="rightsidebar" data-aos="slide-up">
      <div className=" h-full">
        <div className="handleimg"></div>
        <div
          className="mt-5"
          style={{ padding: "10px 20px", marginLeft: "50px" }}
        >
          <div className="d-flex justify-content-between">
            <p
              style={{
                marginBottom: "40px",
                fontSize: "22px",
                fontWeight: "700",
              }}
            >
              {/* {" "} */}
              {title}
            </p>
            <div>
              <p><span><b>Status :</b> </span><span>{reportDetail.status}</span></p>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <img
              src={thumbnail ? apiBaseUrl + '/' + imageUrl + '/' + thumbnail : addImageIcon}
              style={{
                width: "400px",
                height: "250px",
                border: "1px solid black",
                borderRadius: "15px",
              }}
            />
            <div
              style={{
                borderBottom: "1px solid black",
                borderTop: "1px solid black",
                marginLeft: "100px",
              }}
            >
              <p style={{ margin: "10px 0px 0px 0px", color: "gray" }}>
                {reportDetail?.description}
              </p>
            </div>
          </div>
          <div style={{ margin: " 3rem 24rem" }} className="d-flex justify-content-center">
            {reportDetail.status == 'pending' && <Button
              variant="contained"
              onClick={() => { navigate(`/report/action/${reportId}`) }}
              style={{
                backgroundColor: "black",
                padding: "10px 36px"
              }}
            >
              Next
            </Button>}
            <Button
              className="btn btn-primary addbutne btn-sm logoutno mx-3"
              id="savePDF"
              onClick={(e) => { navigate(`/report`) }}
            > Back
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportView;
