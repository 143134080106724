import {
  Button,
  Container,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  Paper,
  Stack,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import {
  GetAllImageEditings,
  deleteImageEditing,
  downLoadAllImages,
} from "../../API/imageEditorApi";
import {
  apiBaseUrl,
  reactAppImageUri,
  reactAppVideoUri,
} from "../../utils/constants";
import fileDownload from "js-file-download";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { updateStatus } from "../../API";
import moment from "moment";
const ImageList = () => {
  const [options, setOptions] = useState({
    page: 0,
    pageSize: 15,
    orderBy: "id",
    orderType: "desc",
  });

  const [allRecords, setAllRecords] = useState({
    data: [],
    count: 0,
  });
  const getAllRecords = async () => {
    try {
      const params = `orderby=${options.orderBy}&ordertype=${options.orderType}&limit=${options.pageSize}&page=${options.page}`;
      const res = await GetAllImageEditings(params);
      if (res.status === 200) {
        setAllRecords({
          data: res.data.data.rows,
          count: res.data.data.count
        });
      }
    } catch (error) {
      toast.error(error.response.data.message || error.response.statusText);
    }
  };

  const DeleteImage = async (id) => {
    try {
      if (window.confirm("Are you sure you want to delete?")) {
        await deleteImageEditing(id);
        getAllRecords();
        toast.success("SOP deleted successfully");
      }
    } catch (error) {
      toast.error("something went wrong!");
    }
  };

  const DownLoadAll = async () => {
    const res = await downLoadAllImages();
    fileDownload(res.data, "allImages.zip");
  };

  async function toDataURL(url) {
    const blob = await fetch(url).then((res) => res.blob());
    return URL.createObjectURL(blob);
  }

  const DownLoadImage = async (url) => {
    console.log(url);
    var el = document.createElement("a");
    el.setAttribute(
      "href",
      await toDataURL(`${apiBaseUrl + "/public"}/${reactAppImageUri}/${url}`)
    );
    el.setAttribute("download", url);
    el.setAttribute("target", "_blank");
    document.body.appendChild(el);
    el.click();
    el.remove();
  };
  const changeStatus = async (id, status) => {
    await updateStatus("/image_editing/update-status/" + id, { status });
    toast.success("update status successfully");
    getAllRecords();
  };

  const columns = [
    { field: "title", width: 300, headerName: "SOP Title" },
    { field: "details", width: 450, headerName: "Description" },
    {
      field: "createdAt",
      headerName: "Time & Date",
      renderCell: (cellValues) => {
        return (
          <>
            {cellValues.row.createdAt
              ? moment(cellValues.row.createdAt).format("DD/MM/YY, HH:mm A")
              : ""}
          </>
        );
      },
      width: 200,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <>
            <div className="custom_switch my-2">
              <label className="switch">
                <input
                  checked={cellValues.row.status}
                  type="checkbox"
                  id="togBtn"
                  onChange={(e) => {
                    changeStatus(cellValues.row.id, e.target.checked);
                  }}
                ></input>
                <div className="ch_slider round"></div>
              </label>
            </div>
          </>
        );
      },
    },
    {
      field: "id",
      headerName: "Action",
      width: 300,
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <Stack spacing={2} direction="row" className="editbut">
            <Button
              className="nocolor"
              variant="contained"
              component={Link}
              to={`/imageform/${cellValues.row.id}/manage`}
            >
              <ModeEditIcon />
            </Button>{" "}
            {/* <Button color="success" variant="contained" component={Link} to={`/Edit-Image/${cellValues.row.id}/manage`}>Edit Image</Button>
          {" "} */}
            <Button
              className="nocolor"
              variant="contained"
              onClick={() => {
                DownLoadImage(
                  cellValues.row.edited_image
                    ? cellValues.row.edited_image
                    : cellValues.row.editable_image
                );
              }}
            >
              <FileDownloadIcon />
            </Button>{" "}
            <Button
              className="nocolor delete"
              color="error"
              variant="contained"
              onClick={() => {
                DeleteImage(cellValues.row.id);
              }}
            >
              {" "}
              <DeleteIcon />
            </Button>
          </Stack>
        );
      },
    },
  ];

  useEffect(() => {
    getAllRecords();
  }, [options]);

  const setPage = (data) => {
    setOptions({
      ...options,
      page: data,
    });
  };
  const setPageSize = (data) => {
    setOptions({
      ...options,
      pageSize: data,
    });
  };
  const handleSortModelChange = (data) => {
    if (data && data[0]) {
      setOptions({
        ...options,
        orderBy: data[0].field,
        orderType: data[0].sort,
      });
    } else {
      setOptions({
        ...options,
        orderBy: "id",
        orderType: "desc",
      });
    }
  };

  return (
    <>
      <div className="rightsidebar">
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper
                sx={{ p: 2 }}
                className="paddnone"
                style={{ boxShadow: "none", padding: 0 }}
              >
                <ListItem
                  secondaryAction={
                    <>
                      <Button
                        variant="contained"
                        className="mr-15 addbutne "
                        onClick={DownLoadAll}
                      >
                        DownLoad All Image
                      </Button>
                      <Button
                        variant="contained"
                        className="addbutne"
                        component={Link}
                        to="/imageform"
                      >
                        Add Image
                      </Button>
                    </>
                  }
                >
                  <ListItemText className="black-text" primary="Image Editor" />
                </ListItem>
                <div
                  className="bordernone"
                  style={{ height: 500, width: "100%" }}
                >
                  <DataGrid
                    className="painnation"
                    rows={allRecords.data}
                    disableColumnMenu
                    columns={columns}
                    rowsPerPageOptions={[15, 50, 100]}
                    getRowHeight={() => "auto"}
                    rowCount={allRecords.count}
                    paginationModel={options}
                    pagination
                    paginationMode="server"
                    page={options.page}
                    pageSize={options.pageSize}
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    onSortModelChange={handleSortModelChange}
                  />
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default ImageList;
