import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Button, Container, Grid, ListItem, Paper, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import { apiBaseUrl } from "./../../utils/constants";
import { allUserPdfListApi, deletePdf, updateStatus } from "../../API";

import { toast } from "react-toastify";
import moment from "moment";

function ListOfPdf() {
  const baseURL = apiBaseUrl + "/";
  const [state, setState] = useState({
    loading: true,
    data: [],
    message: "",
    status: 0,
    count: 0,
    id: localStorage.getItem("userId"),
  });

  const [options, setOptions] = useState({
    page: 0,
    pageSize: 15,
    orderBy: "id",
    orderType: "desc",
  });

  const fetchAllPdfList = async (values) => {
    try {
      setState({
        ...state,
        loading: true,
      });

      const params = `orderby=${options.orderBy}&ordertype=${options.orderType}&limit=${options.pageSize}&page=${options.page}`;
      console.log(params)
      const res = await allUserPdfListApi(params);
      if (res.status === 200) {
        console.log(res.data.data, "Video Data List");
        setState({
          ...state,
          loading: false,
          data: res.data.data.rows,
          count: res.data.data.count
        });
      }
    } catch (error) {
      toast.error("something went wrong");
      setState((v) => ({
        ...v,
        loading: false,
        data: null,
      }));
    }
  };
  function fetchFile(url) {
    fetch(url)
      .then((res) => res.blob())
      .then((file) => {
        let tempUrl = URL.createObjectURL(file);
        const aTag = document.createElement("a");
        aTag.href = tempUrl;
        aTag.download = url.replace(/^.*[\\/]/, "");
        document.body.appendChild(aTag);
        aTag.click();
        // downloadBtn.innerText = "Download File";
        URL.revokeObjectURL(tempUrl);
        aTag.remove();
      })
      .catch(() => {
        alert("Failed to download file!");
        // downloadBtn.innerText = "Download File";
      });
  }
  const downloadPdf = async (row) => {
    let link = document.createElement("a");
    if (row.edited_pdf) {
      link.href = baseURL + "public/docFiles/uploadFiles/" + row.edited_pdf;
      // link.download = 'row.edited_pdf';
    } else {
      link.href = baseURL + "public/docFiles/uploadFiles/" + row.filename;
      // link.download = 'row.filename';
    }
    fetchFile(link.href);
    // link.target = '__blank'
    // link.download = 'file.pdf';
    // link.dispatchEvent(new MouseEvent('click'));
    // var link = document.createElement("a");
    // link.download = name;
    // link.href = uri;
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
    // delete link;
  };
  useEffect(() => {
    fetchAllPdfList();
    // if (localStorage.getItem("canvas-appear")) {
    //   localStorage.removeItem("canvas-appear");
    // }
  }, [options]);

  const handleDeletePdf = async (id) => {
    try {
      // eslint-disable-next-line
      if (confirm("Are you sure you want to delete?")) {
        await deletePdf({ id });
        fetchAllPdfList();
        toast.success("SOP deleted successfully");
      }
    } catch (error) {
      toast.error("something went wrong");
    }
  };

  const changeStatus = async (id, status) => {
    await updateStatus("/document/update-status/" + id, { status });
    toast.success("update status successfully");
    fetchAllPdfList();
  };

  // const setUrlForEditPdf = (data) => {
  //   console.log(data,"abcrrrrrr")
  //   const urlForEditPdf = {
  //     user:data.user,
  //     title: data.title,
  //     details: data.details,
  //     requirement: data.requirement,
  //     shortDetails: data.shortDetails,
  //     status: data.status,
  //     pdfUser:data.pdfUser,
  //     location:data.location,
  //     url: data.filename,
  //     thumnail: data.thumnail
  //   }
  //   localStorage.setItem("urlForEditPdf", JSON.stringify(urlForEditPdf));
  // };

  const columns = [
    { field: "title", width: 300, headerName: "SOP Title" },
    { field: "details", width: 450, headerName: "Description" },
    {
      field: "createdAt",
      headerName: "Time & Date",
      renderCell: (cellValues) => {
        return (
          <>
            {cellValues.row.createdAt
              ? moment(cellValues.row.createdAt).format("DD/MM/YY, HH:mm A")
              : ""}
          </>
        );
      },
      width: 200,
    },
    // { field: "pdfUserDetails_name", headerName: "User", width: 120,  renderCell: (d)=>( d.value.name ) },
    // { field: "pdfUserDetails_email", headerName: "Email", width: 120,  renderCell: (d)=>( d.value.email ) },
    // { field: "pdfUserDetails_phone", headerName: "Phone", width: 120,  renderCell: (d)=>( d.value.phone ) },
    // { field: "pdfUserDetails_role", headerName: "User Type", width: 120,  renderCell: (d)=>( d.value.role ) },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <div className="custom_switch my-2">
            <label class="switch">
              <input
                checked={cellValues.row.status}
                type="checkbox"
                id="togBtn"
                onChange={(e) => {
                  changeStatus(cellValues.row.id, e.target.checked);
                }}
              ></input>
              <div class="ch_slider round"></div>
            </label>
          </div>
        );
      },
    },
    {
      field: "id",
      headerName: "Action",
      width: 300,
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <>
            <div>
              <Stack direction="row" className="editbut">
                <Button
                  className="nocolor"
                  // onClick={() => setUrlForEditPdf(cellValues.row)}
                  variant="contained"
                  component={Link}
                  to={`/pdfform/${cellValues.row.id}`}
                >
                  <ModeEditIcon />
                </Button>
                <Button
                  className="nocolor"
                  variant="contained"
                  onClick={() => downloadPdf(cellValues.row)}
                >
                  <FileDownloadIcon />
                </Button>
                <Button
                  className="nocolor delete"
                  color="error"
                  variant="contained"
                  onClick={() => {
                    handleDeletePdf(cellValues.row.id);
                  }}
                >
                  <DeleteIcon />
                </Button>
              </Stack>
            </div>
          </>
        );
      },
    },
  ];

  const setPage = (data) => {
    setOptions({
      ...options,
      page: data,
    });
  };
  const setPageSize = (data) => {
    setOptions({
      ...options,
      pageSize: data,
    });
  };
  const handleSortModelChange = (data) => {
    if (data && data[0]) {
      setOptions({
        ...options,
        orderBy: data[0].field,
        orderType: data[0].sort,
      });
    } else {
      setOptions({
        ...options,
        orderBy: "id",
        orderType: "desc",
      });
    }
  };

  return (
    <div className="rightsidebar neweditorstyle">
      <Container className="Editorcustom" maxWidth="xl">
        <Grid container className="Editorgridcustom">
          <Grid item xs={12}>
            <Paper
              sx={{ p: 2 }}
              className="paddnone"
              style={{ boxShadow: "none", padding: 0 }}
            >
              <ListItem
                secondaryAction={
                  <Button
                    variant="contained"
                    component={Link}
                    to="/pdfform"
                    className="addbutne"
                  >
                    Add PDF
                  </Button>
                }
              >
                {/* <ListItemText className="black-text" primary="PDF Editor" /> */}
              </ListItem>
              <div
                className="bordernone"
                style={{ height: 500, width: "100%" }}
              >
                <DataGrid
                  className="painnation"
                  loading={state.loading}
                  rows={state.data.map((el) => ({
                    ...el,
                    pdfUserDetails_name: el.title,
                    pdfUserDetails_email: el.requirement,
                  }))}
                  columns={columns}
                  disableColumnMenu
                  rowsPerPageOptions={[15, 50, 100]}
                  getRowHeight={() => "auto"}
                  rowCount={state.count}
                  paginationModel={options}
                  pagination
                  paginationMode="server"
                  page={options.page}
                  pageSize={options.pageSize}
                  onPageChange={(newPage) => setPage(newPage)}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  onSortModelChange={handleSortModelChange}
                />
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default ListOfPdf;
