import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { allUserListApi, deleteUser } from "../../API";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Stack } from "react-bootstrap";

import ModeEditIcon from "@mui/icons-material/ModeEdit";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import editIcon from "../../../src/assets/svg1/editicon.svg";
import deleteIcon from "../../../src/assets/svg1/deleteicon.svg";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";

function ListOfUser(props) {
  const navigate = useNavigate();
  var params = { userType: props.userType };
  const [userData, setUserData] = useState({
    loading: false,
    data: [],
    count: 0,
    message: "",
    status: 0,
  });
  const [options, setOptions] = useState({
    page: 0,
    pageSize: 15,
    orderBy: "id",
    orderType: "desc",
  });

  const fetchAllUserList = async (values) => {
    if (!userData.loading) {
      try {
        setUserData((v) => ({
          ...v,
          loading: true,
        }));
        const endPoint = `/user/list?role=${
          values === "all" ? "" : values
        }&orderby=${options.orderBy}&ordertype=${options.orderType}&limit=${
          options.pageSize
        }&page=${options.page}`;

        const res = await allUserListApi(endPoint);
        console.log("user List");
        console.log(res);
        if (res.status == 200) {
          setUserData((v) => ({
            ...v,
            loading: false,
            data: res.data.data.rows,
            count: res.data.data.count,
          }));
        }
      } catch (error) {
        toast.error(error.response.data.message || error.response.statusText);
        setUserData((v) => ({
          ...v,
          loading: false,
          data: [],
        }));
      }
    }
  };

  useEffect(() => {
    if (params && params.userType) {
      fetchAllUserList(params.userType);
    }
  }, [options, params?.userType]);

  const handleDeleteUser = async (id) => {
    try {
      // eslint-disable-next-line
      if (confirm("Are you sure you want to delete?")) {
        await deleteUser({ id });
        fetchAllUserList(params.userType);
        toast.success("User deleted successfully");
      }
    } catch (error) {
      toast.error(error.response.data.message || error.response.statusText);
    }
  };

  const columns = [
    { field: "name", headerName: "Name", width: 300 },
    { field: "phone", headerName: "Contact No.", width: 300 },
    { field: "email", headerName: "Email", width: 350 },
    { field: "role", headerName: "Role", width: 200 },
    {
      field: "id",
      headerName: "Action",
      sortable: false,
      width: 200,
      renderCell: (cellValues) => {
        return (
          <Stack direction="row" className="editbut" style={{flexDirection: 'row'}}>
            <Button
              className="nocolor"
              variant="contained"
              component={Link}
              style={{width: 'fit-content'}}
              to={`/videoform/${cellValues.row.id}/manage`}
              onClick={() => {
                navigate(`/user/edit/${cellValues.row.id}`);
              }}
            >
              <ModeEditIcon />
            </Button>{" "}
            <Button
              className="nocolor delete"
              color="error"
              variant="contained"
              style={{width: 'fit-content'}}
              onClick={() => handleDeleteUser(cellValues.row?.id)}
            >
              <DeleteIcon />
            </Button>
          </Stack>
        );
      },
    },
  ];

  const setPage = (data) => {
    setOptions({
      ...options,
      page: data,
    });
  };
  const setPageSize = (data) => {
    setOptions({
      ...options,
      pageSize: data,
    });
  };
  const handleSortModelChange = (data) => {
    if (data && data[0]) {
      setOptions({
        ...options,
        orderBy: data[0].field,
        orderType: data[0].sort,
      });
    } else {
      setOptions({
        ...options,
        orderBy: "id",
        orderType: "desc",
      });
    }
  };

  return (
    <div className="rightsidebar">
      <div className="flex-row bg-primary2 text-white h-screen w-full font-comfortaa user-list">
        <div
          className="bordernone autoheight_table"
          style={{ height: 500, width: "100%" }}
        >
          <DataGrid
            className="painnation"
            // rowHeight={120}
            getRowHeight={() => "auto"}
            rows={userData.data}
            rowCount={userData.count}
            columns={columns}
            rowsPerPageOptions={[15, 50, 100]}
            paginationModel={options}
            disableColumnMenu
            pagination
            paginationMode="server"
            page={options.page}
            pageSize={options.pageSize}
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            onSortModelChange={handleSortModelChange}
          />
        </div>
        {/* <div className="grid backgtable">
          <div className="w-full flex">
            <table
              className="flex w-full text-black text-center items-center font-comfortaa"
              style={{ justifyContent: "space-between" }}
            >
              <th className="py-4 " style={{ width: "10%" }}>
                S.No
              </th>
              <th className="py-4 " style={{ width: "15%" }}>
                Name
              </th>
              <th className="py-4 " style={{ width: "15%" }}>
                Contact No.
              </th>
              <th className="py-4 " style={{ width: "30%", textAlign: "left" }}>
                Email
              </th>
              <th className="py-4 " style={{ width: "10%" }}>
                Role
              </th>
              <th className="py-4" style={{ width: "10%" }}>
                <SettingsOutlinedIcon />
              </th>
            </table>
          </div>
          {userData.loading ? (
            <div className="flex backgtablecolor">
              <table className="flex w-full  text-black text-center">
                <tr className="flex w-full">
                  <td colSpan={7} className="text-center w-full">
                    Please wait ...{" "}
                  </td>
                </tr>
              </table>
            </div>
          ) : data?.length ? (
            data?.map((el, i) => (
              <div className="flex backtablecolor ">
                <table className="flex w-full  text-black text-center">
                  <tr
                    className="flex w-full"
                    style={{ justifyContent: "space-between" }}
                  >
                    <td className="py-2" style={{ width: "10%" }}>
                      {i + 1}
                    </td>
                    <td className="py-2" style={{ width: "15%" }}>
                      {el.name}
                    </td>
                    <td className="py-2" style={{ width: "15%" }}>
                      {el.phone || "-"}
                    </td>
                    <td
                      className="py-2"
                      style={{ width: "30%", justifyContent: "left" }}
                    >
                      {el.email}
                    </td>
                    <td className="py-2" style={{ width: "10%" }}>
                      {el.role}
                    </td>
                    <td className="py-2 " style={{ width: "10%" }}>
                      <Dropdown>
                        <Dropdown.Toggle
                          id="dropdown-basic"
                          className="userlistmenu"
                        >
                          <MoreVertOutlinedIcon />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              navigate(`/user/edit/${el.id}`);
                            }}
                          >
                            <div className="menuitemusers">
                              <img src={editIcon} />
                              Edit
                            </div>
                          </Dropdown.Item>
                          {el?.id != 1 && (
                            <Dropdown.Item
                              onClick={() => handleDeleteUser(el?.id)}
                            >
                              <div className="menuitemusers">
                                <img src={deleteIcon} />
                                Delete
                              </div>
                            </Dropdown.Item>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                </table>
              </div>
            ))
          ) : (
            <div className="flex backtablecolor">
              <table className="flex w-full  text-black text-center">
                <tr className="flex w-full">
                  <td colSpan={7} className="text-center w-full">
                    No data found{" "}
                  </td>
                </tr>
              </table>
            </div>
          )}
        </div> */}
      </div>
    </div>
  );
}

export default ListOfUser;
