import { Button } from "@mui/material";
import { convertToRaw, EditorState } from "draft-js";
import { useCallback, useEffect, useState } from "react";
import draftToHtml from "draftjs-to-html";
import { EditorContainer } from "../../../components/TextEditor";
import { stateFromHTML } from "draft-js-import-html";
import {
  fetchTermsAndConditions,
  updateTermsAndConditions,
} from "../../../API";
import { toast } from "react-toastify";

export const TermsAndConditions = () => {
  
  const [editorState, onEditorStateChange] = useState(
    EditorState.createEmpty()
  );
  const [actualHtmlFormatData, setActualHtmlFormatData] = useState("");

  const getTermsAndConditions = useCallback(async () => {
    try {
      const {
        data: { data = "" },
      } = await fetchTermsAndConditions(1);
      onEditorStateChange(EditorState.createWithContent(stateFromHTML(data)));
    } catch (e) {}
  }, []);

  const onSaveClick = useCallback(async () => {
    try {
      await updateTermsAndConditions(1, {
        description: actualHtmlFormatData,
      });

      toast.success("Terms And Conditions updated  successfully")
    } catch (e) {
      toast.error("Something went wrong")
    }

      
  }, [actualHtmlFormatData]);

  const onEditChange = (event) => {
    
    setActualHtmlFormatData(
      "" + draftToHtml(convertToRaw(event.getCurrentContent()))
    );
    onEditorStateChange(event);
  };

  useEffect(() => {
    getTermsAndConditions();
  }, []);

  return (
    <div style={{ backgroundColor: 'white'}}>
      <div className=" flex m-3">
        <h3 className=" dsopForm-heading text-2xl">Terms & Condition </h3>
      </div>
      <EditorContainer
        editorState={editorState}
        onEditorStateChange={onEditChange}
      >
        <Button variant="contained" onClick={onSaveClick}>
          Save
        </Button>
      </EditorContainer>
    </div>
  );
};
