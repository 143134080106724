import React from "react";
import { useEffect,useState } from "react";
// import Editor from "../../components/VideoPlayerComponents/Editor/Editor";

import moment from "moment";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";

import {
  Grid,
  Button,
  LinearProgress,
  Stack,
} from '@mui/material';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';


import TimeLine from "../../components/VideoEditor/innercomponents/TimeLine";
import RenderPlayer from "../../components/VideoEditor/innercomponents/RenderPlayer";

import { SUBMIT_DONE } from '../../redux/Reducers/rendervideoReducer'
import { videosubmitActions } from '../../redux/actions/rendervideoActions'
import {
  GetVideoEditingDetail
} from "../../API/videoEditorApi";




export const QuizContext = React.createContext();
export default function VideoPlayer() {
  let rendervideostate = useSelector(state => state.rendervideoReducer);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [videoPlayerUrl, setVideoPlayerUrl] = useState('');
  // const [scrollTop, setScrollTop] = useState(0);
  // let [questionlist, setQuestionList] = React.useState([{"name":"Question 1",value:"",right_answer:0,question_type:0,answerlist:[]}]);
  const navigate = useNavigate();
  // const customStyles = {
  //   content: {
  //     top: '50%',
  //     left: '50%',
  //     right: 'auto',
  //     bottom: 'auto',
  //     marginRight: '-50%',
  //     transform: 'translate(-50%, -50%)',
  //   },
  // };

  const [timelineRef, setTimelineRef] = useState(null);
  // const [isPlaying, setIsPlaying] = useState(false);
  const [isplay, setIsplay] = useState(false);

  const GetInfo = async () => {
    if (id !== undefined) {
        try {
            const res = await GetVideoEditingDetail(id);
            if (res.status === 200) {
              let info = res.data.data;
              const video_urls = JSON.parse(info.merge_video_url);
              
              // dispatch(SET_VIDEOURLactions(info));

              setVideoPlayerUrl(video_urls.filename);
            }
        } catch (error) {
            console.log("Err ",error);
            /* toast.error(
                error.response.data.message || error.response.statusText
            ); */
        }
    }
};

useEffect(() =>{
  console.log("rendervideostate.submit.apiMsg.status..........................", rendervideostate.submit.apiMsg.status)
  if( rendervideostate.submit.apiMsg.status === 'fulfilled') {
    dispatch(SUBMIT_DONE(''))
    navigate("/editors?type=video");
  }
}, [rendervideostate.submit.apiMsg.status])

const submit = () => {
   
  dispatch(videosubmitActions(rendervideostate.project)).then((data)=>{
      //navigate("/Video-Player-Finish/"+id);
      // navigate("/DigitalSop-Video-Editor/");
    console.log(data)
  });
  
}

useEffect(() => {
  GetInfo();
  
},[]);



const handleScroll = (e) => {
  e.clientHeight = 500;
  // console.log("mockData ", mockData);
  // setScrollTop(e.scrollTop);
  console.log("e ",e);
}

const setPlayFuc = () => {
  console.warn("Call");
  setIsplay(!isplay)
}

const setNewRef = (newref) => {
  setTimelineRef(newref.current);
};


  return (
    <div className="rightsidebar">
    <Grid spacing={0} md={12} className="finishplayer">
    
      <div className="mainprogrs">
        <ul className="multi-step-bar">
            <li className="sucses"><p>Details</p></li>
            <li className="sucses"><p>Edit Video</p></li>
            <li className="active"><p>Submit</p></li>
        </ul>
      </div>


        

      <Grid container spacing={0} md={12} style={{ height: '57%',backgroundColor:'#ffff' }} className="neineineineiiii">
        <Grid item className="video-player-show" md={12} sx={{ backgroundColor: '#C4CAD6',height:'500px',marginTop:'12px',position:'relative' }}
          container
          direction="row"
          justifyContent="center"
        // alignItems="center"
        >
          <RenderPlayer videoPlayerUrl = {videoPlayerUrl} size={{width:rendervideostate.project.videosize.width,height:rendervideostate.project.videosize.height}}
          onFinish={() =>{
          }}/>
        </Grid>
      </Grid> 

      <Grid container spacing={0} md={12} style={{backgroundColor:'#ffff',paddingLeft:"0px",paddingRight:"0px",paddingTop:"10px",paddingBottom:"10px" }} className="mmmmmmmm">
         <Grid item md={2}  className="new-header-containt1" >
          

          <div className="playiconall">
            
            <div className="icons">
            <SkipPreviousIcon/>
            </div>
            <div className="icons">
            <button onClick={()=> setPlayFuc()}>{(isplay) ? (<PauseIcon />) : (<PlayArrowIcon/>)} </button>
             
            </div>
            <div className="icons">
            <SkipNextIcon/>
            </div>
          </div>

          <div className="mint-sec">
           <h5>{(timelineRef != null) ? moment.utc(timelineRef.getTime()*1000).format('HH:mm:ss') : '00:00:00'} / {(rendervideostate.project.video.timelineTiming.duration != null) ? moment.utc(rendervideostate.project.video.timelineTiming.duration*1000).format('HH:mm:ss') : '00:00:00'}  </h5>
            </div>

            <div className="zoominout">
              
            </div>
  
         </Grid>
         <Grid item md={8} style={{display:"flex", alignItems:"right"}} className="new-header-containt2" >
       
         
         </Grid>
         <Grid item md={2} className="new-header-containt3" >
          
         </Grid>
      </Grid>
      <Grid className="inner-inner-Track-Grid timeLineBG" item md={9} sx={{ zIndex: '3',display:'none' }}>
        <TimeLine handleScroll={handleScroll} play={isplay} setNewRef={setNewRef} />
      </Grid>

      
      <Grid container spacing={0} md={12} style={{backgroundColor:'#ffff',border: "1px solid #659EC7",padding:"10px" }} className="nexttt">

        <Grid item md={12}   className="new-header-containt1" style={{textAlign:'center'}}>
        {(rendervideostate.project.submitloading)
            ?
            (
              <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
            <LinearProgress color="success"  className="finalSubmiarrtBtn"/>
            </Stack>
            )
            :
            (<Button variant="contained"
            className="NextButton"
            color="success"
            onClick={() => { submit()  }} >Finish</Button>)
          }
          
         </Grid>
      </Grid>
    </Grid>
    </div>
  )
}
