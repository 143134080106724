import React from "react";
// import { Rnd } from "react-rnd";
import Triangle2 from "../../../../assets/svg/Triangle2.png";
import Circle2 from "../../../../assets/svg/Circle2.png";
import Arrow2 from "../../../../assets/svg/Arrow2.png";
import Line2 from "../../../../assets/svg/Line2.png";
// import Cloud2 from "../../../../assets/svg/Cloud2.png";
import Square2 from "../../../../assets/svg/Square2.png";
import Rectangle2 from "../../../../assets/svg/Rectangle2.png"  
import Text from "../../../../assets/svg/Text.png"  
import Caption2 from "../../../../assets/svg/Caption2.png"  
import {  SET_ANOTATIONactions } from '../../../../redux/Reducers/rendervideoReducer';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  // Typography,
  Grid,
  Button
} from '@mui/material';
/* import TabPanel from './components/TabPanel';
import { CleaningServices } from "@mui/icons-material";
import { VisibilityOff, Delete } from '@mui/icons-material'; */
// import InputBox from "./components/InputBox";
// import DivBox from "./components/DivBox";
// import { videosubmitActions } from '../../../../redux/actions/rendervideoActions'
// import List from '@mui/material/List';
// import Divider from '@mui/material/Divider';
// import ListItem from '@mui/material/ListItem';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
// import MailIcon from '@mui/icons-material/Mail';
// import Drawer from '@mui/material/Drawer';

// import {
//   Accordion,
//   AccordionItem,
//   AccordionItemHeading,
//   AccordionItemButton,
//   AccordionItemPanel,
// } from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

const NewVideoEditTools = (props) =>{
    const rendervideostate = useSelector(state => state.rendervideoReducer);
    const dispatch = useDispatch();
    
    
    return (
        <>
            <Box className="outerGrid" style={{
                border: '1px solid #659EC7',
               // padding: '24px',
                height:'450px',
                margin: '0',
                padding:'10px',
               
                
            }}>
                <Grid className="gridClass" container  spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                {/* Array.from(Array(6)) */}
                {[ Square2, Rectangle2,Circle2,Triangle2,Line2,Arrow2].map((_, index) => (
                    <Grid item xs={2} sm={4} md={6} key={index} alignItems="center"  className="innerGrid">
                    <Button
                       
                        sx={{
                        p: 2,
                        backgroundColor: '#FFFF',
                        height: '100%', width: '85%',
                        borderRadius: 2,
                       // boxShadow: '2px 4px 16px -6px rgba(0,0,0,0.61)'
                        }}
                        onClick={() => {
                        
                        var icon = {
                            id: rendervideostate.project.margeArr.length,
                            // margeId: rendervideostate.project.margeArr.length,
                            // anotationId: rendervideostate.project.anotation.length,
                            
                            actions: [{
                            type: 'ICON',
                            id: `anotion ${rendervideostate.project.margeArr.length}`,
                            // id:rendervideostate.project.anotation.length,
                            anotationId: rendervideostate.project.margeArr.length,
                            key: index,
                            img: '',
                            rx: index === 2 ? 50:0,
                            ry: index === 2 ? 50:0,
                            start: rendervideostate.project.video.video_currentTime.seconds - 0.01,
                            end: rendervideostate.project.video.video_currentTime.seconds + 5,
                            effectId: `effect${1}`,
                            x: index === 5 ? 50 : 10,
                            y: index === 5 ? 10 :  10,
                            x2: index === 5 ? 50 : 100,
                            y2: index === 5 ? 70 : 10
                            }]
                        }
                        console.log("new........................1")
                        console.log(icon);
                        
                        dispatch(SET_ANOTATIONactions(icon))
                        }}
                    >
                        <img
                        alt="img"
                        style={{ 'pointer-events': "none" }}
                        src={_}
                        // style={{height:'80%', width:'80%'}}
                        />
                    </Button>
                    </Grid>
                ))}
                <Grid item xs={2} sm={4} md={6} key={'a12'} alignItems="center"  className="innerGrid">
                    <Button
                       sx={{
                        p: 2,
                        backgroundColor: '#FFFF',
                        height: '100%', width: '65%',
                        borderRadius: 2,
                       // boxShadow: '2px 4px 16px -6px rgba(0,0,0,0.61)'
                        }}
                        onClick={() => {
                            var text = {
                                id: rendervideostate.project.margeArr.length,
                                name: `text ${rendervideostate.project.margeArr.length}`,
                                actions: [{
                                  id: `text ${rendervideostate.project.margeArr.length}`,
                                  anotationId: rendervideostate.project.margeArr.length,
                                  type: 'TEXT',
                                  x: 50,
                                  y: 50,
                                  start: rendervideostate.project.video.video_currentTime.seconds - 0.01,
                                  end: rendervideostate.project.video.video_currentTime.seconds + 5,
                                  text: 'SAMPLE TEXT',
                                  effectId: `effect${1}`
                                }]
                        }
                        dispatch(SET_ANOTATIONactions(text))
                    }}
                    >
                        <img
                        alt="img"
                        style={{ 'pointer-events': "none" }}
                        src={Text}
                        // style={{height:'80%', width:'80%'}}
                        />
                    </Button>
                </Grid>
                <Grid item xs={2} sm={4} md={6} key={'a13'} alignItems="center"  className="innerGrid">
                    <Button
                       sx={{
                        p: 2,
                        backgroundColor: '#FFFF',
                        height: '100%', width: '85%',
                        borderRadius: 2,
                       // boxShadow: '2px 4px 16px -6px rgba(0,0,0,0.61)'
                        }}
                        onClick={() => {
                            var text = {
                                id: rendervideostate.project.margeArr.length,
                                name : `text ${rendervideostate.project.margeArr.length}`,
                                actions: [{
                                  id: `text ${rendervideostate.project.margeArr.length}`,
                                  anotationId: rendervideostate.project.margeArr.length,
                                  type: 'TEXT',
                                  x: 50,
                                  y: 50,
                                  start: rendervideostate.project.video.video_currentTime.seconds - 0.01,
                                  end: rendervideostate.project.video.video_currentTime.seconds + 5,
                                  text: 'SAMPLE TEXT',
                                  effectId: `effect${1}`
                                }]
                        }
                        dispatch(SET_ANOTATIONactions(text))
                    }}
                    >
                        <img
                        alt="img"
                        style={{ 'pointer-events': "none" }}
                        src={Caption2}
                        // style={{height:'80%', width:'80%'}}
                        />
                    </Button>
                </Grid>
                
                </Grid>
            </Box>
        </>
    )
}
export default NewVideoEditTools;