import React, { useEffect, useState } from "react";
import "../../styles/dashbord.css";
import AOS from "aos";
import "aos/dist/aos";
import {
  Button
} from "@mui/material";
import { toast } from "react-toastify";
import { quizUserResult, quizVideoList } from "../../API";
import { useParams, useNavigate } from "react-router-dom";
import { apiBaseUrl, imageUrl } from "../../utils/constants"

const QuizesResult = () => {

  const { videoId, resultId } = useParams();
  const navigate = useNavigate();
  const [quizeResult, setQuizeResult] = useState({});
  const [tableConfig, setTableConfig] = useState({
    isApiCall: false
  })
  useEffect(() => {
    AOS.init({
      easing: "ease-out-sine",
      duration: 800,
    });
    AOS.refresh();
  }, []);

  useEffect(() => {
    if(videoId && resultId) {
        getQuizeResult()
    } else{ 
        setQuizeResult({})
    }
  }, [videoId])

  const getQuizeResult = async () => {
    setTableConfig((conf) => ({...conf, isApiCall: true}))
    const userResult = await quizUserResult(resultId);
    setTableConfig((conf) => ({...conf, isApiCall: false}))
    if (userResult.data && userResult.status ) {
        setQuizeResult(userResult.data.data);
    }
  };

  return (
    <>

      <div className="rightsidebar" data-aos="slide-up">

        <div className=" bg-primary2 text-white w-full font-comfortaa user-list">
        <div className="grid backgtable">
          <div className="w-full flex">
            <div className="row w-100" >
                <div className="col-4" onClick={() => {
                  navigate("/user/edit/"+quizeResult?.user?.id)
                }}>
                    <img className="quize_profile_pic" width={350} height={350} src={apiBaseUrl+'/'+imageUrl+"/"+quizeResult?.user?.profileImage} alt="profileImage"  />
                </div>
                <div className="col-4 p-5 quiz_text">
                    <div className="pt-5">
                        <h4>{quizeResult?.user?.name}</h4>
                    </div>
                    <div>
                        <h4>{quizeResult?.video?.title}</h4>
                    </div>
                    {/* <div>
                        <h4>Assignment Title</h4>
                    </div> */}
                    {/* <div className="pt-5">
                        <h6><b>REVIEW ASSIGNMENT</b></h6>
                    </div> */}
                    {/* <div className="d-flex">
                    <img  width={100} height={100} src={'../../check-mark.png'} alt="checkmark"  />
                      <h4 className="checked_text">Checked</h4>
                    </div> */}
                    <div>

                    </div>
                </div>
                <div className="col-4 p-5 quiz_text text-center">
                  <div className="pt-5">
                        <h6><b>GIVE SCORES (1 OF {quizeResult.totalquestion})</b></h6>
                    </div>
                    <div>
                      <h2>{quizeResult.score}</h2>
                    </div>
                </div>
                
            </div>
            
          </div>
          <div className="row w-100 py-5" >
              <div className="col-12 text-center">
              <button type="button" className="btn btn-dark btn-lg mr-2">SAVE</button>
              <button type="button" className="btn btn-info btn-lg">DOWNLOAD</button>


              </div>
            </div>
        </div>
        </div>
        
      </div>
    </>
  );
};

export default QuizesResult;
