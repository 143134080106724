import {
    deleteApi,
    getApi,
    getBufferApi,
    postApi,
    putApi,
} from "./api-interface";

export const createImageEditing = async (data) => {
    return await postApi(`/image_editing/add_images`, data);
};

export const UpdateEditedImage = async (data) => {
    return await postApi(`/image_editing/UpdateEditedImage`, data);
};

export const GetImageEditingDetail = async (id) => {
    return await getApi("/image_editing/get_details/" + id);
};
export const GetAllImageEditings = async (params) => {
    return await getApi("/image_editing/getAll?"+params);
};
export const downLoadAllImages = async () => {
    return await getBufferApi("/image_editing/download_all");
};
export const updateImageEditing = async (id, data) => {
    return await putApi("/image_editing/update/" + id, data);
};
export const deleteImageEditing = async (id) => {
    return await deleteApi("/image_editing/delete/" + id);
};

export const getUserDetails = async () => {
    return await getApi("/user/user-info");
};
export const createSessions = async () => {
    return await getApi("/user/session");
};
export const logoutUser = async () => {
    return getApi("/user/logout");
};
