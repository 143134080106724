import { createSlice } from '@reduxjs/toolkit';

import { videofromActions, videosubmitActions } from '../actions/rendervideoActions'
import {apiBaseUrl,reactAppVideoUri,reactAppCutVideosUri} from './../../utils/constants'
var INITIAL_STATE = {
  video_duration: 30,
  video_currentTime: {
    seconds: 0,
    start: false
  },
  submitData: {

  },
  mergedVideos: {
    "data": {
      "Id": 16,
      "userId": 1,
      "groupId": null,
      "filename": "",
      "url": "",
      "formate": "video/mp4",
      "editType": "merge",
      "length": null,
      "sequence": null,
      "position": null,
      "symbols": null,
      "annotation": null,
      "status": null,
      "details": null,
      "clip": null,
      "Created_Date": "2022-07-19T11:22:32.000Z",
      "Updated_Date": "2022-07-19T11:22:32.000Z"
    },
    apiStatus: {
      message: null,
      status: null
    }
  },
  "project": {
    "projectId": "",
    "title": "",
    "submitloading":false,
    "videosize" : {
      "height":'425',
      "width":'600'
    },
    margeArr: [{
      name:"track",
      id: "0",
      actions: [
          {
              id: "action00",
              start: 0,
              end: 30,
              effectId: "effect0",
              img: ''
          },
      ],
  
  }],
    tmpmargeArr: [],
    "anotation": [
      // {
      //     "anotationId":"anotation_1",
      //     "imageUrl": "D:/S.M.Patel/My_Files/removedBg.png",
      //     "scale_X": 0,
      //     "scale_Y": 0,
      //     "videoTiming":{
      //         "startTime": 3,
      //         "endTime": 6,
      //         "duration": 25
      //     },
      //     "timelineTiming":{
      //         "startTime": 3,
      //         "endTime": 6,
      //         "duration": 25
      //     }
      // }
    ],
    "texts": [
      // {
      //     "textsId":"text_1",
      //     "text": "This is first Text",
      //     "fontsize": 32,
      //     "fontcolor": "red",
      //     "scale_X": 100,
      //     "scale_Y": 100,
      //     "videoTiming":{
      //         "startTime": 3,
      //         "endTime": 6,
      //         "duration": 25
      //     },
      //     "timelineTiming":{
      //         "startTime": 3,
      //         "endTime": 6,
      //         "duration": 25
      //     }
      // },

    ],
    "quiz": [
    ],
    "video": {
      "filename": "1657525162495",
      "path": "",
      "videoId": "video_1",
      "videoTiming":{
          "startTime": 0,
          "endTime": 36,
          "duration": 30
      },
      "timelineTiming":{
          "startTime": 0,
          "endTime": 30,
          "duration": 30
      },
      video_currentTime: {
        seconds: 0,
        start: false,
        bytimeline: false,

      },
    },
    videos: {
      videoList: [
        {
          
      "filename": "",
      "url": "",
      "Id": "video_1",
      "videoTiming":{
          "startTime": 0,
          "endTime": 0,
          "duration": 0
      },
      "timelineTiming":{
          "startTime": 0,
          "endTime": 0,
          "duration": 0
      },
      video_currentTime: {
        seconds: 0,
        start: false,
        bytimeline: false,

      },
        }
      ],
      apiMsg: {
        message: null,
        status: null,
        statusCode: null
      }
    },
    visibility: {
      visibility: null,
      id: null
    },
    videoVisibility: {
      visibility: null,
      id: null
    }
  },

  submit: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      statusCode: null
    }
  }

};

const rendervideoReducer = createSlice({
  name: 'rendervideoReducer',
  initialState: INITIAL_STATE,
  reducers: {
    SET_DURATIONactions(state, action) {
      state.video_duration = action.payload;
    },
    SET_PROJECT_VIDEOaction(state, action) {
      console.log('action project-> ', action.payload)
      state.project.video = action.payload.video;
      state.project.margeArr.push({ ...action.payload.margeArrdata })
    },
    SET_EDITEDTITLEactions(state, action) {
      // var i = state.edited_tile.length;
      // state.project.texts.push({ ...action.payload });
      state.project.margeArr.push({ ...action.payload });
      // state.project.texts = [...state.project.texts]
      state.project.margeArr = [...state.project.margeArr]
      console.log('SET_EDITEDTITLEactions..............')
    },
    SET_ANOTATIONactions(state, action) {
      // var i = state.edited_tile.length;
      // state.project.anotation = action.payload;
      console.log('action ', action.payload)
      // state.project.anotation.push({ ...action.payload });
      state.project.margeArr.push({ ...action.payload });
      // state.project.anotation = [...state.project.anotation]
      state.project.margeArr = [...state.project.margeArr]
      console.log('SET_ANOTATIONactions..............')

    },
    UNDO_ANOTATIONactions(state, action) {
      if(state.project.margeArr.length > 1){
        state.project.tmpmargeArr.push(state.project.margeArr[state.project.margeArr.length - 1]);
      state.project.margeArr.pop();

      }
    },
    REDO_ANOTATIONactions(state, action) {
      if(state.project.tmpmargeArr.length > 0 ){
        state.project.margeArr.push(state.project.tmpmargeArr[state.project.tmpmargeArr.length - 1]);
        state.project.tmpmargeArr.pop();
      }
    },
    SET_HEIGHTWIDTHactions(state, action) {
      const info = action.payload;
      state.project.videosize = {
        "height":info.height,
        "width":info.width
      };
      
    },
    SET_VIDEOURLactions(state, action) {
      const info = action.payload;
      if(info.margeArr != null)
      {
        var tmpmargeArr = JSON.parse(info.margeArr);
        if(typeof tmpmargeArr !== 'object')
        {
          tmpmargeArr = JSON.parse(tmpmargeArr);
        }
        delete tmpmargeArr[0];
        state.project.margeArr = [state.project.margeArr[0]];
        console.log('SET_VIDEOURLactions1..............')
        tmpmargeArr.forEach((valuedata)=> {
          state.project.margeArr.push(valuedata);
        });
      }
      else 
      {
        state.project.margeArr = [state.project.margeArr[0]];
        console.log('SET_VIDEOURLactions2..............')
      }
      const video_urls = JSON.parse(info.video_urls);
      const merge_video_url = JSON.parse(info.merge_video_url);
      var videostate = state.project.video;
      videostate.filename = merge_video_url.filename;
      
      videostate.path = `${apiBaseUrl+'/public'}/${reactAppVideoUri}/${merge_video_url.filename}`;
      // videostate.path = `${apiBaseUrl}/${merge_video_url.path}`;
      videostate.videoId = info.id;
       
      videostate.videoTiming.startTime = 0; 
      videostate.videoTiming.endTime = parseInt(merge_video_url.duration); 
      videostate.videoTiming.duration = parseInt(merge_video_url.duration); 
     
      videostate.timelineTiming.startTime = 0; 
      videostate.timelineTiming.endTime = parseInt(merge_video_url.duration); 
      videostate.timelineTiming.duration = parseInt(merge_video_url.duration); 
       
      var videoList = [];
      var starttime = 0;
      video_urls.forEach((video_url)=> {
        var endtime = starttime + video_url.duration;
        videoList.push({
          "filename": video_url.filename,
          "url": `${apiBaseUrl+'/public'}/${reactAppVideoUri}/${video_url.filename}`,
          "Id": info.id,
          "videoTiming":{
              "startTime": parseInt(starttime),
              "endTime": parseInt(endtime),
              "duration": parseInt(video_url.duration)
          },
          "timelineTiming":{
              "startTime": parseInt(starttime),
              "endTime": parseInt(endtime),
              "duration": parseInt(video_url.duration)
          },
          video_currentTime: {
            seconds: 0,
            start: false,
            bytimeline: false,
    
          },
        });
        starttime = video_url.duration;
      });
      console.log("videoList : ",videoList);
      state.project.videos.videoList =  videoList;
      state.project.margeArr[0].actions[0].end = parseInt(merge_video_url.duration);
      
      
    },
    SET_CUTVIDEOURLactions(state, action) {
        const info = action.payload;
        console.warn('info : ',info);
        console.log("state.project.margeArr : ",state.project.margeArr);
        // var duration = state.project.video.videoTiming.duration;
        var toBySlice = [];
        state.project.margeArr.forEach((val,index)=> {
          val.actions.forEach((val1,index1)=> {
            console.log("val1 : "+index1,val1.type);
            if(val1.type)
            {
              if(val1.type === 'ICON' || val1.type === 'CAPTION' || val1.type === 'TEXT')
              {
                if(state.project.margeArr[index].actions[index1].start < info.start && state.project.margeArr[index].actions[index1].end > info.start && state.project.margeArr[index].actions[index1].end < info.endtime)
                {
                   
                  state.project.margeArr[index].actions[index1].end = parseInt(info.start);
                }
                else if(state.project.margeArr[index].actions[index1].start > info.start && state.project.margeArr[index].actions[index1].end > info.endtime)
                {
                  state.project.margeArr[index].actions[index1].start = parseInt(info.start);
                  state.project.margeArr[index].actions[index1].end = parseInt(state.project.margeArr[index].actions[index1].end) - parseInt(info.endtime);
                }
                else if(state.project.margeArr[index].actions[index1].start < info.start && state.project.margeArr[index].actions[index1].end > info.endtime)
                {
                  console.log("rrrr cutterrrrrrr11111111r");
                  state.project.margeArr[index].actions[index1].end = parseInt(state.project.margeArr[index].actions[index1].end) - parseInt(info.endtime - info.start);
                }
                else if(state.project.margeArr[index].actions[index1].start >= info.start && state.project.margeArr[index].actions[index1].end <= info.endtime)
                {
                  console.log("rrrr cutterrrrrrrr");
                  toBySlice.push({"mergeIndex":index,"actionIndex":index1});
                }
                else 
                {

                }
              }
              else if(val1.type === 'CUTER')
              {
                toBySlice.push({"mergeIndex":index,"actionIndex":index1});
              }
            }
          });
        });
        toBySlice.forEach((toRemove)=> {
          state.project.margeArr[toRemove.mergeIndex].actions.splice(toRemove.actionIndex, 1);
        });
        var videostate = state.project.video;
        videostate.filename = `${info.filename}`;
        videostate.path = `${apiBaseUrl+'/public'}/${reactAppVideoUri}/${info.filename}`;
        videostate.videoTiming.startTime = 0; 
        videostate.videoTiming.endTime = parseInt(info.end); 
        videostate.videoTiming.duration = parseInt(info.end); 
      
        videostate.timelineTiming.startTime = 0; 
        videostate.timelineTiming.endTime = parseInt(info.end); 
        videostate.timelineTiming.duration = parseInt(info.end); 
        state.project.margeArr[0].actions[0].end = parseInt(info.end);
        
        console.warn('videostate  : ',videostate);
    },
    submitQuiz(state, action) {
      console.log("submitQuiz : ",state.project.margeArr[action.payload.index]);
      /* state.project.margeArr[action.payload.index].actions.map((val,ind) => {
        console.log("vdsfsdf :",val);
      }) */
      state.project.margeArr[action.payload.index].actions[0].questionlist = action.payload.questionlist;
    },
    EDITTEXTaction(state, action) {
      state.project.margeArr = [...action.payload]
      console.log('EDITTEXTaction..............')
    },
    SET_POSITION_ANOTATIONactions(state, action) {
      console.log(action.payload)
      state.project.margeArr = [...action.payload]
      console.log('SET_POSITION_ANOTATIONactions..............')

    },
    SET_POSITION_EDITEDTITLEactions(state, action) {
      // var i = state.edited_tile.length;
      state.project.texts[action.payload.key] = action.payload;
      state.project.texts = [...state.project.texts]

    },
    SET_VIDEO_CURRENTTIMEactions(state, action) {
      // var i = state.edited_tile.length;
      // if(action.payload.sec !== state.project.video.video_currentTime.seconds) {
        state.project.video.video_currentTime.seconds = action.payload.sec;
        state.project.video.video_currentTime.bytimeline = action.payload.bytimeline;
     //   state.project.margeArr = [...state.project.margeArr]
      // }
    },
    SET_VIDEO_CURRENTTIME_STARTactions(state, action) {
      // var i = state.edited_tile.length;
      console.log('action.payload', action.payload)
      state.project.video.video_currentTime.start = action.payload;

    },
    SET_EFFECTVNONEactions(state, action) {
      // var i = state.edited_tile.length;
      console.log("SET_EFFECTVNONEactions",state.project.visibility);
      // state.project.visibility.push(action.payload)
      // state.project.visibility = [...state.project.visibility];
      // state.project.visibility = action.payload;
    },
    SET_EFFECTactions(state, action) {
      // var i = state.edited_tile.length;;
      // state.project.visibility.push(action.payload)
      // state.project.visibility = [...state.project.visibility];
      state.project.visibility = action.payload;
    },
    SET_VIDEOactions(state, action) {

      state.project.videoVisibility = action.payload;
    },
    SHOW_subimtLoading(state, action) {
      console.log("state.video_currentTime.start 12 " ,action.payload)
      state.project.submitloading = action.payload;
    },
    LEAVEL_EFFECTactions(state, action) {
      // var i = state.edited_tile.length; 
      state.project.visibility = [...action.payload];

    },
    DELETEactions(state, action) {
      console.log('action--> ', action.payload, state.project.margeArr)
      //  var marge = _.remove(state.project.margeArr, (n)=>{

      //   console.log('n ',n)
      //   return n.key === action.payload;
      // });
      // console.log('action-marge-> ',marge)

      state.project.margeArr = [...action.payload]
    },
    Editactions(state, action) {
      console.log('action--> ', action.payload, state.project.margeArr)
      state.project.margeArr = [...action.payload]
    },
    SUBMIT_DONE(state, action) {
      console.log("ennnnnnnnnnnnnnnnnnnnnnnnnn", state)
      console.log("ennnnnnnnnnnnnnnnnnnnnnnnnn", action)
      state.submit.apiMsg.status = action.payload
    }
  },
  extraReducers: {
    //------------------------------- videofromActions start -------------------------

    [videofromActions.fulfilled]: (state, action) => {

      console.log('state fulfilled ', state, ' action', action);
      state.project.videos.apiMsg.message = typeof (action.payload) === 'object' ? null : action.payload.message;
      state.project.videos.apiMsg.statusCode = action.payload.message.status;
      state.project.videos.apiMsg.status = 'fulfilled';
      state.project.videos.videoList = typeof (action.payload) === 'object' ? action.payload.data : state.project.videos.videoList;
    },

    [videofromActions.pending]: (state, action) => {

      console.log('state pending ', state, ' action', action);
      // state.project.videos.apiMsg.message = action.payload.message;
      // state.project.videos.apiMsg.statusCode =  action.payload.message.status;
      state.project.videos.apiMsg.status = 'pending';
    },

    [videofromActions.rejected]: (state, action) => {

      console.log('state rejected ', state, ' action', action);
      state.project.videos.apiMsg.message = action.payload.message;
      state.project.videos.apiMsg.statusCode = action.payload.message.status;
      state.project.videos.apiMsg.status = 'rejected';

    },

    //------------------------------- videofromActions end -------------------------

    //------------------------------- submitActions start -------------------------

    [videosubmitActions.fulfilled]: (state, action) => {

      console.log('state fulfilled ', state, ' action', action);
      state.project.submitloading = false;
      // state.submit.apiMsg.message = typeof (action.payload) === 'object' ? null : action.payload.message;
      // state.submit.apiMsg.statusCode = action.payload.message.status;
      state.submit.apiMsg.status = 'fulfilled';
      
      // state.submit.data = typeof (action.payload) === 'object' ? action.payload.data : state.project.videos.videoList;

    },

    [videosubmitActions.pending]: (state, action) => {
      state.project.submitloading = true;
      console.log('state pending ', state, ' action', action);
      // state.project.videos.apiMsg.message = action.payload.message;
      // state.project.videos.apiMsg.statusCode =  action.payload.message.status;
      state.submit.apiMsg.status = 'pending';
    },

    [videosubmitActions.rejected]: (state, action) => {

      state.project.submitloading = false;
      console.log('state rejected ', state, ' action', action);
      state.submit.apiMsg.message = action.payload.message;
      state.submit.apiMsg.statusCode = action.payload.message.status;
      state.submit.apiMsg.status = 'rejected';

    },

    //------------------------------- submitActions end -------------------------
  }

})

export const {
  SET_DURATIONactions,
  SET_PROJECT_VIDEOaction,
  SET_EDITEDTITLEactions,
  EDITTEXTaction,
  SET_POSITION_EDITEDTITLEactions,
  SET_ANOTATIONactions,
  SET_VIDEOURLactions,
  SET_POSITION_ANOTATIONactions,
  SET_VIDEO_CURRENTTIMEactions,
  SET_VIDEO_CURRENTTIME_STARTactions,
  SET_EFFECTactions,
  SET_EFFECTVNONEactions,
  SET_VIDEOactions,
  LEAVEL_EFFECTactions,
  DELETEactions,
  submitQuiz,
  UNDO_ANOTATIONactions,
  Editactions,
  REDO_ANOTATIONactions,
  SET_HEIGHTWIDTHactions,
  SUBMIT_DONE,
  SET_CUTVIDEOURLactions,SHOW_subimtLoading } = rendervideoReducer.actions;
export default rendervideoReducer.reducer;