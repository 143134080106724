import { Grid, Card, Switch } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import addImageIcon from "../../../image/addicon.png";
import { bannerTextAdd, bannerTextGet, bannerupdate } from "../../../API";
import { apiBaseUrl, bannerImg } from "../../../utils/constants";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const BannerSchema = yup.object().shape({
  home: yup
    .string()
    .matches(
      /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
      "It is not valid URL, Please use https://"
    ),
  contactUs: yup
    .string()
    .matches(
      /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
      "It is not valid URL, Please use https://"
    ),
    share_url: yup
    .string()
    .matches(
      /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
      "It is not valid URL, Please use https://"
    ),
});

const BannerTextControl = () => {
  const [checked, setChecked] = useState();
  const [banner, setBanner] = useState({
    home_name: "home",
    home_image: "",
    homeimagepreview: "",
    home_url: "",
    home_file: "",
    home_file_name: "",
    home_button: false,
    share_name: "share",
    share_file: "",
    share_image: "",
    share_file_name: "",
    shareimagepreview: "",
    share_url: "",
    share_button: false,
    contactUs_name: "contact",
    contactUs_image: "",
    contactUsimagepreview: "",
    contactUs_url: "",
    contactUs_button: false,
    contactUs_file_name: "",
    contactUs_file: "",
    id: "",
  });
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(BannerSchema),
  });

  useEffect(() => {
    bannerListFunction();
  }, []);

  const bannerListFunction = async () => {
    const banners = await bannerTextGet();
    if (banners.data.success) {
      const list = banners.data.data;
      setBanner(list);
    }
  };

  const switchHandler = (e, type) => {
    setBanner({ ...banner, [`${type}_button`]: e.target.checked });

    console.log(checked);
  };

  const handleUrlChange = (e, name) => {
    setBanner({ ...banner, [`${name}_url`]: e.target.value });
    console.log(banner);
  };

  function handleFileChange(e) {
    const name = e.target.name;
    const fileName = e.target.files[0]["name"];
    const filepre = URL.createObjectURL(e.target.files[0]);

    console.log(name, "nameeee");
    setBanner({
      ...banner,
      [`${name}imagepreview`]: filepre,
      [`${name}_file_name`]: fileName,
      [`${name}_file`]: e.target.files[0],
    });
  }

  const submitForm = async () => {
    // event.preventDefault();
    // const data = new FormData();
    // for (var x = 0; x < banner.length; x++) {
    //   data.append("file", banner[x]);
    // }
    const formDataa = new FormData();
    // Object.keys(banner).forEach(function (key) {
    //   console.log(key, banner[key],'222');
    //   // formDataa.append();
    // });
    formDataa.append("home_name", banner.home_name);
    if (banner.home_file) {
      formDataa.append("home_image", banner.home_file, banner.home_file_name);
    }
    if (banner.home_button) {
      formDataa.append("home_url", banner.home_url);
      formDataa.append("home_button", banner.home_button);
    } else {
      formDataa.append("home_url", "");
      formDataa.append("home_button", false);
    }

    formDataa.append("share_name", banner.share_name);
    if (banner.share_file) {
      formDataa.append(
        "share_image",
        banner.share_file,
        banner.share_file_name
      );
    }
    if (banner.share_button) {
      formDataa.append("share_url", banner.share_url);
      formDataa.append("share_button", banner.share_button);
    } else {
      formDataa.append("share_url", "");
      formDataa.append("share_button", false);
    }

    formDataa.append("contactUs_name", banner.contactUs_name);
    if (banner.contactUs_file) {
      formDataa.append(
        "contactUs_image",
        banner.contactUs_file,
        banner.contactUs_file_name
      );
    }

    if (banner.contactUs_button) {
      formDataa.append("contactUs_url", banner.contactUs_url);
      formDataa.append("contactUs_button", banner.contactUs_button);
    } else {
      formDataa.append("contactUs_url", "");
      formDataa.append("contactUs_button", false);
    }

    if (banner.id) {
      console.log("ifff");
      let res = await bannerupdate(formDataa, banner.id);
      console.log(res, "banner updateeeeeeee");
      if (res?.data?.success) {
        setBanner(res?.data?.data);
        toast.success("Update successfully");
      }
    } else {
      console.log("elseeeeee");
      const addBan = await bannerTextAdd(formDataa);
      if (addBan?.data?.success) {
        setBanner(addBan?.data?.data);
        toast.success("Update successfully");
      }
    }
    //     let res = await bannerupdate(formDataa, ban.id);

    // const newArray = banner;
    // for await (const ban of banner) {
    //   const formDataa = new FormData();
    //   if (ban.file) {
    //     formDataa.append("image", ban.file, ban.fileName);
    //   }
    //   formDataa.append("name", ban.name);
    //   formDataa.append("url", ban.url);
    //   formDataa.append("button", ban.button);
    //   if (ban.id) {
    //     let res = await bannerupdate(formDataa, ban.id);
    //     // bannerupdate()
    //   } else {
    //     const addBan = await bannerTextAdd(formDataa);
    //     if (
    //       addBan.data.success &&
    //       addBan?.data?.data?.name === "Share Banner"
    //     ) {
    //       newArray[1] = addBan?.data?.data;
    //     } else if (
    //       addBan.data.success &&
    //       addBan?.data?.data?.name === "Home Banner"
    //     ) {
    //       newArray[0] = addBan?.data?.data;
    //     } else if (
    //       addBan.data.success &&
    //       addBan?.data?.data?.name === "Contact Us Banner"
    //     ) {
    //       newArray[2] = addBan?.data?.data;
    //     }
    //   }
    // }
    // setBanner([...newArray]);
    // axios.post("http://localhost:5000/upload", data)
    // .then(res => {
    //     console.log(res.statusText)
    //   })
  };

  return (
    <div>
      <form onSubmit={handleSubmit(submitForm)}>
        <Grid container spacing={4}>
          <Grid item md={4}>
            <Card style={{ boxShadow: "none", backgroundColor: "whitesmoke" }}>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  margin: "15px 0px 30px 15px",
                }}
              >
                Home banner image
              </p>
              <div style={{ display: "flex", marginLeft: "15px" }}>
                <img
                  style={{
                    border: "1px solid black",
                    width: "80px",
                    height: "80px",
                    borderRadius: "8px",
                  }}
                  src={
                    banner?.homeimagepreview
                      ? banner?.homeimagepreview
                      : banner?.home_image
                      ? apiBaseUrl + "/" + bannerImg + "/" + banner?.home_image
                      : addImageIcon
                  }
                  alt="tas"
                />
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    lineHeight: ".8",
                    marginLeft: "10px",
                    marginTop: "22px",
                  }}
                >
                  <label
                    style={{ color: "blue", cursor: "pointer" }}
                    htmlFor={`home-fileUpload-banner`}
                  >
                    upload{" "}
                    <span style={{ color: "#585353", fontSize: "10px" }}>
                      {banner?.home_file_name}
                    </span>
                  </label>
                  <input
                    type="file"
                    id={`home-fileUpload-banner`}
                    style={{ display: "none", fontSize: "14px" }}
                    accept="image/jpeg"
                    name="home"
                    onChange={handleFileChange}
                  />
                  <p style={{ fontSize: "12px" }}>jpeg , jpg , JPG , JPEG</p>
                  <span style={{ fontSize: "12px" }}>
                    Recommend Size 428 × 409px
                  </span>
                </div>
              </div>
              <div style={{ marginTop: "18px" }}>
                <p style={{ marginLeft: "14px", fontWeight: "600" }}>
                  Add Button
                </p>
                <Switch
                  checked={banner?.home_button}
                  name="home"
                  onClick={(e) => switchHandler(e, "home")}
                />
                {banner?.home_button ? (
                  <>
                    <input
                      style={{
                        width: "90%",
                        marginLeft: "10px",
                        marginBottom: "12px",
                      }}
                      type="text"
                      placeholder="url"
                      {...register("home")}
                      value={banner?.home_url}
                      onChange={(e) => handleUrlChange(e, "home")}
                    />
                    <p className="text-Red font-bold">
                      {errors.home && <span>{errors.home.message}</span>}
                    </p>
                  </>
                ) : (
                  ""
                )}
              </div>
            </Card>
          </Grid>
          <Grid item md={4}>
            <Card style={{ boxShadow: "none", backgroundColor: "whitesmoke" }}>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  margin: "15px 0px 30px 15px",
                }}
              >
                share banner image
              </p>
              <div style={{ display: "flex", marginLeft: "15px" }}>
                <img
                  style={{
                    border: "1px solid black",
                    width: "80px",
                    height: "80px",
                    borderRadius: "8px",
                  }}
                  src={
                    banner?.shareimagepreview
                      ? banner?.shareimagepreview
                      : banner?.share_image
                      ? apiBaseUrl + "/" + bannerImg + "/" + banner?.share_image
                      : addImageIcon
                  }
                  alt="tas"
                />
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    lineHeight: ".8",
                    marginLeft: "10px",
                    marginTop: "22px",
                  }}
                >
                  <label
                    style={{ color: "blue", cursor: "pointer" }}
                    htmlFor={`share-fileUpload-banner`}
                  >
                    upload{" "}
                    <span style={{ color: "#585353", fontSize: "10px" }}>
                      {banner?.share_file_name}
                    </span>
                  </label>
                  <input
                    type="file"
                    id={`share-fileUpload-banner`}
                    style={{ display: "none", fontSize: "14px" }}
                    name="share"
                    accept="image/jpeg"
                    {...register("share")}
                    onChange={handleFileChange}
                  />
                  <p style={{ fontSize: "12px" }}>jpeg , jpg , JPG , JPEG</p>
                  <span style={{ fontSize: "12px" }}>
                    Recommend Size 428 × 409px
                  </span>
                </div>
              </div>
              <div style={{ marginTop: "18px" }}>
                <p style={{ marginLeft: "14px", fontWeight: "600" }}>
                  Add Button
                </p>
                <Switch
                  checked={banner?.share_button}
                  name="share"
                  onClick={(e) => switchHandler(e, "share")}
                />
                {banner?.share_button ? (
                  <>
                    <input
                      style={{
                        width: "90%",
                        marginLeft: "10px",
                        marginBottom: "12px",
                      }}
                      type="text"
                      name="share"
                      placeholder="url"
                      value={banner?.share_url}
                      {...register("share_url")}
                      onChange={(e) => handleUrlChange(e, "share")}
                    />
                    <p className="text-Red font-bold">
                       {errors.share_url && <span>{errors.share_url.message}</span>}
                    </p>
                    
                  </>
                ) : (
                  ""
                )}
              </div>
            </Card>
          </Grid>
          <Grid item md={4}>
            <Card style={{ boxShadow: "none", backgroundColor: "whitesmoke" }}>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  margin: "15px 0px 30px 15px",
                }}
              >
                Contact Us banner image
              </p>
              <div style={{ display: "flex", marginLeft: "15px" }}>
                <img
                  style={{
                    border: "1px solid black",
                    width: "80px",
                    height: "80px",
                    borderRadius: "8px",
                  }}
                  src={
                    banner?.contactUsimagepreview
                      ? banner?.contactUsimagepreview
                      : banner?.contactUs_image
                      ? apiBaseUrl +
                        "/" +
                        bannerImg +
                        "/" +
                        banner?.contactUs_image
                      : addImageIcon
                  }
                  alt="tas"
                />
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    lineHeight: ".8",
                    marginLeft: "10px",
                    marginTop: "22px",
                  }}
                >
                  <label
                    style={{ color: "blue", cursor: "pointer" }}
                    htmlFor={`conatctUs-fileUpload-banner`}
                  >
                    upload{" "}
                    <span style={{ color: "#585353", fontSize: "10px" }}>
                      {banner?.contactUs_file_name}
                    </span>
                  </label>
                  <input
                    type="file"
                    id={`conatctUs-fileUpload-banner`}
                    style={{ display: "none", fontSize: "14px" }}
                    accept="image/jpeg"
                    name="contactUs"
                    onChange={handleFileChange}
                  />
                  <p style={{ fontSize: "12px" }}>jpeg , jpg , JPG , JPEG</p>
                  <span style={{ fontSize: "12px" }}>
                    Recommend Size 428 × 409px
                  </span>
                </div>
              </div>
              <div style={{ marginTop: "18px" }}>
                <p style={{ marginLeft: "14px", fontWeight: "600" }}>
                  Add Button
                </p>
                <Switch
                  checked={banner?.contactUs_button}
                  name="contact"
                  onClick={(e) => switchHandler(e, "contactUs")}
                />
                {banner?.contactUs_button ? (
                  <>
                    <input
                      style={{
                        width: "90%",
                        marginLeft: "10px",
                        marginBottom: "12px",
                      }}
                      type="text"
                      placeholder="url"
                      name="contactUs"
                      value={banner?.contactUs_url}
                      {...register("contactUs")}
                      onChange={(e) => handleUrlChange(e, "contactUs")}
                    />
                    {errors.contactUs && (
                      <p className="text-Red font-bold">
                          <span>{errors.contactUs.message}</span>
                      </p>
                      
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
            </Card>
          </Grid>
          <div style={{ margin: "auto", marginTop: "30px" }}>
            <Button
              style={{
                color: "white",
                backgroundColor: "black",
                border: "none",
              }}
              type="submit"
            >
              submit
            </Button>
          </div>
        </Grid>
      </form>
    </div>
  );
};

export default BannerTextControl;
