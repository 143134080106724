// export const apiBaseUrl = "http://52.172.190.66:4022";
// export const apiBaseUrl = "https://dsop.simulanis.io:3001";
//:wexport const apiBaseUrl = "http://20.244.94.35:4001";
 export const apiBaseUrl = "https://api.digitization.simulanis.io";
export const projectDomainUrl = "https://api.digitization.simulanis.io";
export const reactAppImageUri= 'images/UploadedImages';
export const UploadFilesUrl = "public/docFiles/uploadFiles"
export const reactAppVideoUri = 'videos/UploadedVideos';
export const reactAppCutVideosUri = 'videos/cutVideos';
export const appVideoUri = 'public/videos/UploadedVideos/';
export const imageUrl= 'public/images/UploadedImages'
export const bannerImg = 'public/images'
