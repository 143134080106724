import React, { useEffect, useState } from "react";
import "../../styles/dashbord.css";
import AOS from "aos";
import "aos/dist/aos";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import { deleteQuizUserResult, quizVideoList } from "../../API";
import { useParams, useNavigate } from "react-router-dom";
import { quizUserList } from "../../API";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import { Stack } from "react-bootstrap";
import DeleteIcon from "@mui/icons-material/Delete";

const QuizesUser = () => {
  const { videoId } = useParams();
  const navigate = useNavigate();
  const [userOfVideoList, setUserOfVideoList] = useState({
    data: [],
    count: 0,
  });
  const [tableConfig, setTableConfig] = useState({
    page: 0,
    pageSize: 15,
    orderBy: "id",
    orderType: "desc",
  });
  useEffect(() => {
    AOS.init({
      easing: "ease-out-sine",
      duration: 800,
    });
    AOS.refresh();
  }, []);

  useEffect(() => {
    if (videoId) {
      getUserOfVideoListfunction();
    }
  }, [tableConfig, videoId]);

  const getUserOfVideoListfunction = async () => {
    const params = `video_id=${videoId}&orderby=${tableConfig.orderBy}&ordertype=${tableConfig.orderType}&limit=${tableConfig.pageSize}&page=${tableConfig.page}`;

    const userList = await quizUserList(params);

    if (
      userList.data &&
      userList.data.data &&
      userList.data.data.rows &&
      userList.data.data.rows.length > 0
    ) {
      setUserOfVideoList({
        data: userList.data.data.rows,
        count: userList.data.data.count,
      });
    }
  };

  const viewResult = async (e, id) => {
    navigate(`/quiz-result/${videoId}/${id}`);
  };

  const deleteResult = async (e, id) => {
    await deleteQuizUserResult(id);
    toast.success("Delete Quize result successfully ");
    setTimeout(() => {
      getUserOfVideoListfunction();
    }, 500);
  };

  const columns = [
    {
      field: "name",
      width: 300,
      headerName: "Name",
      renderCell: (cellValues) => {
        return <>{cellValues.row.user.name}</>;
      },
    },
    {
      field: "title",
      width: 300,
      headerName: "SOP Title",
      renderCell: (cellValues) => {
        return <>{cellValues.row.video.title}</>;
      },
    },
    {
      field: "email",
      width: 300,
      headerName: "Email",
      renderCell: (cellValues) => {
        return <>{cellValues.row.user.email}</>;
      },
    },
    {
      field: "date",
      headerName: "Time & Date",
      renderCell: (cellValues) => {
        return (
          <>
            {cellValues.row.createdAt
              ? moment(cellValues.row.createdAt).format("DD/MM/YY, HH:mm A")
              : ""}
          </>
        );
      },
      width: 200,
    },
    {
      headerName: "Result",
      renderCell: (cellValues) => {
        return (
          <>
            <Button
              variant="contained"
              className="black_button"
              style={{ maxWidth: "100%" }}
              onClick={(e) => viewResult(e, `${cellValues.row.id}`)}
            >
              View
            </Button>
          </>
        );
      },
      width: 150,
    },
    {
      field: "id",
      headerName: "Action",
      width: 150,
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <Stack spacing={2} direction="row" className="editbut">
            <Button
              className="nocolor delete"
              color="error"
              variant="contained"
              onClick={() => {
                deleteResult(cellValues.row.id);
              }}
            >
              <DeleteIcon />
            </Button>
          </Stack>
        );
      },
    },
  ];

  const setPage = (data) => {
    setTableConfig({
      ...tableConfig,
      page: data,
    });
  };
  const setPageSize = (data) => {
    setTableConfig({
      ...tableConfig,
      pageSize: data,
    });
  };

  const handleSortModelChange = (data) => {
    if (data && data[0]) {
      setTableConfig({
        ...tableConfig,
        orderBy: data[0].field,
        orderType: data[0].sort,
      });
    } else {
      setTableConfig({
        ...tableConfig,
        orderBy: "id",
        orderType: "desc",
      });
    }
  };

  return (
    <>
      <div className="rightsidebar" data-aos="slide-up">
        <div className="top_button">
          <button className="gray_button p-2 mx-2">Download CSV</button>
        </div>
        <div className="flex-row bg-primary2 text-white w-full font-comfortaa user-list">
          <div className="bordernone" style={{ height: 500, width: "100%" }}>
            <DataGrid
              className="painnation"
              rows={userOfVideoList.data}
              disableColumnMenu
              columns={columns}
              rowsPerPageOptions={[15, 50, 100]}
              getRowHeight={() => "auto"}
              rowCount={userOfVideoList.count}
              paginationModel={tableConfig}
              pagination
              paginationMode="server"
              page={tableConfig.page}
              pageSize={tableConfig.pageSize}
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              onSortModelChange={handleSortModelChange}
            />
          </div>
          {/* <div className="grid backgtable">
          <div className="w-full flex">
            <table
              className="flex w-full text-black text-center items-center font-comfortaa"
              style={{ justifyContent: "space-between" }}
            >
              <input style={{ width: "20%" }} type="checkbox" />

              <th className="py-4 " style={{ width: "20%" }}>
                User
              </th>
              <th className="py-4 " style={{ width: "20%" }}>
                SOP
              </th>
              <th className="py-4 " style={{ width: "20%" }}>
                View
              </th>
              <th className="py-4 " style={{ width: "20%" }}>
                Delete
              </th>
              
            </table>
          </div>
          {tableConfig.isApiCall ? (
            <div className="flex backgtablecolor">
              <table className="flex w-full  text-black text-center">
                <tr className="flex w-full">
                  <td colSpan={7} className="text-center w-full">
                    Please wait ...{" "}
                  </td>
                </tr>
              </table>
            </div>
          ) : userOfVideoList?.length ? (
            userOfVideoList?.map((el, i) => (
              <div className="flex backtablecolor ">
                <table className="flex w-full  text-black text-center">
                  <tr
                    
                    className="flex w-full"
                    style={{ justifyContent: "space-between" }}
                  >
                    <td className="py-4" style={{ width: "20%" }}>
                      <input  type="checkbox" />
                    </td>
                    <td className="py-4" style={{ width: "20%" }}>
                      {el.user?.name}
                    </td>
                    <td className="py-4" style={{ width: "20%" }}>
                      {el.video?.title}
                    </td>
                    <td
                      className="py-2"
                      style={{ width: "20%" }}
                    >
                      <Button
                        variant="contained"
                        className="black_button"
                        style={{maxWidth: '100%'}}
                        onClick={(e) => viewResult(e, `${el.id}`)}
                      >
                        View
                      </Button>
                    </td>
                    <td className="py-2"
                      style={{ width: "20%" }}
                      >
                    <button className="btn  btn-danger"
                      style={{maxWidth: '100%'}}
                      onClick={(e) => deleteResult(e, `${el.id}`)}>
                        Delete
                      </button>
                    </td>
                  </tr>
                </table>
              </div>
            ))
          ) : (
            <div className="flex backtablecolor">
              <table className="flex w-full  text-black text-center">
                <tr className="flex w-full">
                  <td colSpan={7} className="text-center w-full">
                    No data found{" "}
                  </td>
                </tr>
              </table>
            </div>
          )}
        </div> */}
        </div>
      </div>
    </>
  );
};

export default QuizesUser;
