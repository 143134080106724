import React, { useEffect, useState } from "react";
import {
  Button,
  Switch,
  Container,
  Grid,
  ListItem,
  ListItemText,
  Paper,
  Stack,
} from "@mui/material";

import Dropdown from "react-bootstrap/Dropdown";
import AOS from "aos";
import "aos/dist/aos";
import { mobileDoc, updateStatus, deleteSOP } from "../../API";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment/moment";

const MobileSop = () => {
  const navigate = useNavigate();

  const [mobile_doc, setmobile_doc] = useState({
    count: 0,
    rows: [],
  });
  const [options, setOptions] = useState({
    page: 0,
    pageSize: 15,
    orderBy: "id",
    orderType: "desc",
  });
  useEffect(() => {
    AOS.init({
      easing: "ease-out-sine",
      duration: 800,
    });
    AOS.refresh();
  }, []);

  useEffect(() => {
    mobilelistfunction();
  }, [options]);

  const mobilelistfunction = async () => {
    const mobile_doc = await mobileDoc(
      `orderby=${options.orderBy}&ordertype=${options.orderType}&limit=${options.pageSize}&page=${options.page}`
    );
    console.log(mobile_doc, "mobileeee");
    if (mobile_doc.data.success) {
      const list = mobile_doc.data.data.rows;
      if (list.length > 0) {
        setmobile_doc(mobile_doc.data.data);
      } else {
        setmobile_doc({
          count: 0,
          rows: [],
        });
      }
    }
  };

  const changeStatus = async (id, status) => {
    await updateStatus("/mobiledoc/update-status/" + id, { status });
    toast.success("update status successfully");
    mobilelistfunction();
  };

  const deleteSelected = async (el) => {
    try {
      if (window.confirm("Are you sure you want to delete?")) {
        await deleteSOP(`/mobiledoc/${el.id}`);
        mobilelistfunction();
        toast.success("SOP deleted successfully");
      }
    } catch (e) {
      console.log(e);
      toast.error("Something went wrong");
    }
  };

  const columns = [
    { field: "doc_name", headerName: "SOP Title", width: 200 },
    {
      field: "name",
      headerName: "Name",
      sortable: false,
      renderCell: (cellValues) => {
        return <>{cellValues.row.own?.name}</>;
      },
      width: 200,
    },
    {
      field: "EmailId",
      headerName: "Email Id",
      sortable: false,
      renderCell: (cellValues) => {
        return <>{cellValues.row.own?.email}</>;
      },
      width: 300,
    },
    {
      field: "createdAt",
      headerName: "Time & Date",
      renderCell: (cellValues) => {
        return (
          <>
            {cellValues.row.createdAt
              ? moment(cellValues.row.createdAt).format("DD/MM/YY, HH:mm A")
              : ""}
          </>
        );
      },
      width: 200,
    },
    {
      field: "shared",
      headerName: "Shared with",
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <>
            {cellValues.row.user && cellValues.row.user.length > 0
              ? cellValues.row.user.map((ob) => ob?.userDetails?.name).join(",")
              : ""}
          </>
        );
      },
      width: 200,
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      width: 150,
      align:'center',
      headerAlign: 'center',
      renderCell: (cellValues) => {
        return (
          <>
            <div className="custom_switch my-2">
              <label class="switch">
                <input checked={cellValues.row.status} type="checkbox" id="togBtn" onChange={(e)=> {
                  changeStatus(cellValues.row.id, e.target.checked)
                }}></input>
                <div class="ch_slider round"></div>
              </label>
            </div>

          </>
        );
      },
    },
    {
      field: "id",
      headerName: "Action",
      sortable: false,
      width: 120,
      renderCell: (cellValues) => {
        return (
          <div className="d-flex">
            <i
              class="fa fa-eye table_icon"
              aria-hidden="true"
              onClick={() => navigate(`/mobile-sop/${cellValues.row.id}`)}
            ></i>
            <i
              class="fa fa-trash table_icon color_red"
              aria-hidden="true"
              onClick={() => deleteSelected(cellValues.row)}
            ></i>
          </div>
        );
      },
    },
  ];

  const setPage = (data) => {
    setOptions({
      ...options,
      page: data,
    });
  };
  const setPageSize = (data) => {
    setOptions({
      ...options,
      pageSize: data,
    });
  };
  const handleSortModelChange = (data) => {
    if(data && data[0]) {
      setOptions({
        ...options,
        orderBy: data[0].field,
        orderType: data[0].sort,
      });
    } else {
      setOptions({
        ...options,
        orderBy: 'id',
        orderType: 'desc',
      });
    }
  };
  return (
    <div className="rightsidebar">
      <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper
              sx={{ p: 2 }}
              className="paddnone"
              style={{ boxShadow: "none", padding: 0 }}
            >
              <div
                className="bordernone autoheight_table"
                style={{ height: 500, width: "100%" }}
              >
                <DataGrid
                  className="painnation"
                  // rowHeight={120}
                  getRowHeight={() => 'auto'}
                  rows={mobile_doc.rows}
                  rowCount={mobile_doc.count}
                  columns={columns}
                  rowsPerPageOptions={[15, 50, 100]}
                  paginationModel={options}
                  disableColumnMenu
                  pagination
                  paginationMode="server"
                  page={options.page}
                  pageSize={options.pageSize}
                  onPageChange={(newPage) => setPage(newPage)}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  onSortModelChange={handleSortModelChange}
                />
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>

    // <div className="flex-row bg-primary2 text-white w-full font-comfortaa user-list">

    //   <div className="grid backgtable">
    //     <div className="w-full flex">
    //       <table
    //         className="flex w-full text-black text-center items-center font-comfortaa"
    //         style={{ justifyContent: "space-evenly" }}
    //       >
    //         <input style={{ width: "19%" }} type="checkbox" />

    //         <th className="py-4 " style={{ width: "10%" }}>
    //           Title
    //         </th>
    //         <th className="py-4 " style={{ width: "60%" }}>
    //           Name
    //         </th>
    //         <th className="py-4 " style={{ width: "60%" }}>
    //           View
    //         </th>
    //         <th className="py-4 " style={{ width: "60%" }}>
    //           Status
    //         </th>

    //         <th className="py-4 " style={{ width: "60%" }}>
    //           setting
    //         </th>
    //       </table>
    //     </div>
    //     {false ? (
    //       <div className="flex backgtablecolor">
    //         <table className="flex w-full  text-black text-center">
    //           <tr className="flex w-full">
    //             <td colSpan={7} className="text-center w-full">
    //               Please wait ...{" "}
    //             </td>
    //           </tr>
    //         </table>
    //       </div>
    //     ) : mobileDoc?.length ? (
    //       mobile_doc?.map((el, i) => (
    //         <div className="flex backtablecolor ">
    //           <table className="flex w-full  text-black text-center">
    //             <tr
    //               className="flex w-full"
    //             >
    //               <td className="py-2" style={{ width: "7%" }}>
    //                 <input type="checkbox" />
    //               </td>
    //               <td className="py-2" style={{ width: "4%" }}>
    //                 {el?.doc_name}
    //               </td>
    //               <td className="py-2" style={{ width: "22%" }}>
    //                 {el?.doc_name}
    //               </td>
    //               <td className="py-2" style={{ width: "270px" }}>
    //                 <Button
    //                   variant="contained"
    //                   color="success"
    //                   style={{}}
    //                   onClick={(e) => navigate(`/mobile-sop/${el.id}`)}
    //                 >
    //                   View
    //                 </Button>
    //               </td>
    //               <td style={{ width: "310px", marginTop: "-6" }}>
    //                 <Switch
    //                   checked={el.status}
    //                   value={true}
    //                   onChange={(e) => updateStatusData(el)}
    //                   name="home"
    //                 />
    //               </td>

    //               <Dropdown style={{ width: "210px" }}>
    //                 <Dropdown.Toggle
    //                   id="dropdown-basic"
    //                   className="userlistmenu"
    //                 >
    //                   <MoreVertOutlinedIcon />
    //                 </Dropdown.Toggle>

    //                 <Dropdown.Menu>
    //                   <Dropdown.Item
    //                     className="nocolor delete"
    //                     color="error"
    //                     variant="contained"
    //                     onClick={() => deleteSelected(el)}
    //                   >
    //                     <div className="menuitemusers">Delete</div>
    //                   </Dropdown.Item>

    //                   <Dropdown.Item className="nocolor" variant="contained">
    //                     <div className="menuitemusers">Download</div>
    //                   </Dropdown.Item>
    //                 </Dropdown.Menu>
    //               </Dropdown>
    //             </tr>
    //           </table>
    //         </div>
    //       ))
    //     ) : (
    //       <div className="flex backtablecolor">
    //         <table className="flex w-full  text-black text-center">
    //           <tr className="flex w-full">
    //             <td colSpan={7} className="text-center w-full">
    //               No data found{" "}
    //             </td>
    //           </tr>
    //         </table>
    //       </div>
    //     )}
    //   </div>
    // </div>
  );
};

export default MobileSop;
