import { createSlice } from '@reduxjs/toolkit'
const INITIAL_STATE = {
  toggle: false
};

const headerReducer = createSlice({
  name: 'headerReducer',
  initialState: INITIAL_STATE,
  reducers: {
    DRAWERactions(state, action) {
      state.toggle = action.payload;
    },
    
  },
  extraReducers: {

  }
})

export const {
    DRAWERactions
   } = headerReducer.actions;
export default headerReducer.reducer;