import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getToken } from '../utils/helperFunctions';

const PublicRoutes = ({Component}) => {

  const navigate = useNavigate();
  const [login, setLogin] = useState(Boolean(getToken()))
  useEffect(() => {
    login && navigate('/')
  },[])
  
  return (
    <div><Component/></div>
  )
}

export default PublicRoutes