import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Grid, Button, Paper, Container, Checkbox } from "@mui/material";
import {
  GetImageEditingDetail,
  createImageEditing,
  updateImageEditing,
} from "../../API/imageEditorApi";
import { usersList } from "../../API";

import { apiBaseUrl, reactAppImageUri } from "../../utils/constants";
import { locationList } from "../../utils/locationList";
import FileUpload from "../FileUpload";
import Select from "react-select";

/* const InputStyle = {
    width: "100%",
    padding: "3%",
    border: "2px solid #c1c1c1",
    borderRadius: "3px",
    backgroundColor: "#e8e8e8",
}; */
const InputStyle = {
  width: "100%",
  borderRadius: "3px",
};

const SelectStyle = {
  border: "1px solid #bce0fd",
  color: "#2699fb",
  outline: "none",
  width: "100%",
  fontWeight: "400",
};

const FormValidation = yup.object().shape({
  title: yup.string().required("Title is Required"),
  requirement: yup.string().required("Requirement is Required"),
  userIds: yup.array().required("Please select the user."),
  location: yup.object().required("Please select the location."),
  short_detail: yup.string().required("Please select the Short detail"),
  details: yup.string().required("Description is Required"),
});

const ImageForm = (props) => {
  // const [addMore, setaddMore] = useState([{ filename: "" }]);
  const [userData, setUserData] = useState({
    loading: false,
    data: null,
    message: "",
    status: 0,
  });

  // const [state, setState] = useState({
  //     location: '',
  //     errors: {},
  // });

  const { id } = useParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(FormValidation),
  });

  const [thumb, setThumb] = useState(null);
  const [newthumb, setNewThumb] = useState(null);
  // const [edited_thumb, setEditedThumb] = useState(null);
  const [uesrLists, setUserLists] = useState([]);
  const [selectUser, setSelectUser] = useState([]);
  const [locationLists, setLocationLists] = useState([]);
  const [selectLocation, setSelectLocation] = useState({});

  const onError = (errors, e) => console.log(errors, e, "fi");
  const onSubmit = async (values) => {
    try {
      console.log(values, "values");
      values.thumnail = newthumb;
      values.editable_image = thumb;
      //   values.edited_image = thumb;
      values.location = values.location.value;
      setUserData((v) => ({
        ...v,
        loading: true,
      }));
      let res = null;
      if (id !== undefined) {
        res = await updateImageEditing(id, { ...values });
      } else {
        values.status = 0
        res = await createImageEditing(values);
      }

      if (res.status === 200) {
        toast.success(res.data.message);
        navigate("/editimage/" + res.data.data.id + "/manage");
        setUserData((v) => ({
          ...v,
          loading: false,
        }));
      } else {
        toast.error(res.data.message || res.response.statusText);
      }
    } catch (error) {
      toast.error("something went wrong");
      setUserData((v) => ({
        ...v,
        loading: false,
      }));
    }
  };

  // const handleThumbChange = async (e) => {
  //     console.log("imgFilehandler", e.target.files);
  //     setUserData((v) => ({
  //         ...v,
  //         loading: true,
  //     }));
  //     try {
  //         const res = await UploadFiles(e.target.files);
  //         if (res.status === 200) {
  //             const info = res.data.data[0];
  //             console.log(info);
  //             setThumb(info);
  //             setUserData((v) => ({
  //                 ...v,
  //                 loading: false,
  //             }));
  //         }
  //     } catch (error) {
  //         console.log(error);
  //         toast.error(
  //             error.response.data.message || error.response.statusText
  //         );
  //     }
  // };

  // const handleNewThumbChange = async (e) => {

  //     console.log("imgFilehandler", e.target.files);
  //     setUserData((v) => ({
  //         ...v,
  //         loading: true,
  //     }));
  //     try {
  //         const res = await UploadFiles(e.target.files);
  //         if (res.status === 200) {
  //             const info = res.data.data[0];
  //             console.log(info);
  //             setNewThumb(info);
  //             setUserData((v) => ({
  //                 ...v,
  //                 loading: false,
  //             }));
  //         }
  //     } catch (error) {
  //         console.log(error);
  //         toast.error(
  //             error.response.data.message || error.response.statusText
  //         );
  //     }
  // };

  const GetInfo = async () => {
    let userDataForSelect = await userListGet();
    let dataList = [];
    locationList.map((val) =>
      dataList.push({ label: val.name, value: val.id })
    );
    setLocationLists(dataList);

    if (id !== undefined) {
      try {
        const res = await GetImageEditingDetail(id);
        if (res.status === 200) {
          const info = res.data.data;
          setValue("title", info.title);
          setValue("details", info.details);
          setValue("requirement", info.requirement);
          setValue("short_detail", info.short_detail);
          setValue("edited_image", info.edited_image);
          setValue("user_id", info.user_id);
          setValue(
            "location",
            dataList.find((ob) => ob.value === info.location)
          );
          setSelectLocation(dataList.find((ob) => ob.value === info.location));
          if (
            info.user &&
            info.user.length > 0 &&
            userDataForSelect.length > 0
          ) {
            const dataSelected = [];
            for (let obj of info.user) {
              const user = userDataForSelect.find(
                (o) => Number(o.value) === Number(obj.user_id)
              );
              dataSelected.push(user);
            }
            setSelectUser(dataSelected);
            // setValue("user_id", JSON.stringify(value));
            setValue(
              "userIds",
              dataSelected.map((e) => e.value)
            );
          }

          setThumb(info.editable_image);

          // setState({
          //     location: info.location,
          //     errors: {},
          // });
          setNewThumb(info.thumnail);
          // if (info.image_urls !== null) {
          //     setaddMore(JSON.parse(info.image_urls));
          // }
        }
      } catch (error) {
        toast.error("something went wrong");
      }
    }
  };

  useEffect(() => {
    GetInfo();
  }, []);

  const userListGet = async () => {
    const items = await usersList();
    let userDataForSelect = [];
    if (items.status === 200) {
      items.data.data.rows.map((value, key) =>
        userDataForSelect.push({ value: value.id, label: value.name })
      );
      // setUserLists(items.data.data);
      setUserLists(userDataForSelect);
    }
    return userDataForSelect;
  };

  return (
    <>
      <div className="rightsidebar pdfeditform">
        <div className=" adduser text-font1 w-full desbordcard">
          <div class="d-flex ">
            <div class="cursor-pointer" onClick={() => navigate(-1)}>
              <i
                class="fa fa-arrow-left back_arrow_style"
                aria-hidden="true"
              ></i>
            </div>
            <div className="handleimg"></div>
          </div>
          <p className="titileditor">Add Image</p>
          <Container
            maxWidth="xl"
            sx={{ mt: 4, mb: 4 }}
            className="addeditorform"
          >
            <Grid container>
              <div className="mainprogrs">
                <ul className="multi-step-bar">
                  <li className="active">
                    <p>Details</p>
                  </li>
                  <li>
                    <p>Edit Image</p>
                  </li>
                  <li>
                    <p>Submit</p>
                  </li>
                </ul>
              </div>
              <Grid item xs={12}>
                <Paper
                  sx={{ p: 2 }}
                  style={{
                    boxShadow: "none",
                    padding: 0,
                    backgroundColor: "transparent",
                  }}
                >
                  {/* <ListItem>
                                <ListItemText className="black-text" primary="Image Editor" />
                            </ListItem> */}
                  <form
                    className="w-full ditgitalform"
                    onSubmit={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {/* <div className=" m-2 ml-3 p-1.5  w-full">
                                        <input
                                            type="text"
                                            // style={InputStyle}
                                            className=" p-1.5 pr-96   placeholder:text-font1 font-normal text-left "
                                            placeholder="Title"
                                            {...register("title")}
                                        ></input>
                                        <p className="text-Red font-bold">
                                            {errors?.title?.message}
                                        </p>
                                    </div> */}

                    <div className="formgrop">
                      <div className="flexfileds">
                        <div className="forcontrol">
                          {/* <input
                                                className=" p-3 form-control  font-normal text-left"
                                                    type="text"
                                                    style={InputStyle}
                                                    placeholder="Title"
                                                    {...register("title")}
                                                ></input> */}

                          <TextField
                            label="Title"
                            variant="outlined"
                            style={InputStyle}
                            type="text"
                            className="font-normal text-left "
                            placeholder="Title"
                            // name="title"
                            // defaultValue="Title"
                            {...register("title")}
                          />

                          <p className="text-Red font-bold">
                            {errors?.title?.message}
                          </p>
                        </div>
                        <div className="forcontrol">
                          {/* <input
                                                    type="text"
                                                    className=" p-3 form-control  font-normal text-left"
                                                    placeholder="Requirement"
                                                    {...register("requirement")}
                                                /> */}

                          <TextField
                            label="Requirement"
                            variant="outlined"
                            type="text"
                            style={InputStyle}
                            placeholder="Requirement"
                            className="font-normal text-left "
                            {...register("requirement")}
                          />

                          <p className="text-Red font-bold">
                            {" "}
                            {errors?.requirement?.message}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="formgrop">
                      <div className="flexfiledss">
                        {/* <input
                                                type="text"
                                                className=" p-3 form-control  font-normal text-left"
                                                placeholder="Short detail"
                                                {...register("short_detail")}
                                            /> */}

                        <TextField
                          label="Short detail"
                          variant="outlined"
                          style={InputStyle}
                          type="text"
                          multiline
                          rows={3}
                          placeholder="Short Details"
                          {...register("short_detail")}
                        />

                        <p className="text-Red font-bold">
                          {" "}
                          {errors?.short_detail?.message}
                        </p>
                      </div>
                    </div>

                    <div className="formgrop">
                      {/* <textarea
                                            style={InputStyle}
                                            placeholder="Details"
                                            rows={3}
                                            {...register("details")}
                                        ></textarea> */}

                      <TextField
                        style={InputStyle}
                        placeholder="Details"
                        label="Details"
                        // name="details"
                        multiline
                        rows={3}
                        {...register("details")}
                      />

                      <p className="text-Red font-bold">
                        {errors?.details?.message}
                      </p>
                    </div>

                    <div className="formgrop">
                      <div className="flex" style={{ gap: "5%" }}>
                        <div className="flexfileds userlist locationpdf">
                          <Select
                            styles={SelectStyle}
                            placeholder="Select Users.."
                            isMulti
                            value={selectUser}
                            onChange={(value) => {
                              setSelectUser(value);
                              // setValue("user_id", JSON.stringify(value))
                              const userIds = value.map((e) => e.value);
                              setValue("userIds", userIds);
                            }}
                            options={uesrLists}
                          />
                          <p className="text-Red font-bold">
                            {errors?.user_id?.message}
                          </p>
                        </div>

                        <div className="flexfileds userlist locationpdf">
                          <Select
                            styles={SelectStyle}
                            placeholder="Select Location.."
                            value={selectLocation}
                            onChange={(value) => {
                              setSelectLocation(value);
                              setValue("location", value);
                            }}
                            options={locationLists}
                          />
                          <p className="text-Red font-bold">
                            {errors?.location?.message}
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* <div
                      className="formgrop"
                      style={{ margin: "-30px 0 10px 0" }}
                    >
                      <Checkbox
                        {...register("status")}
                        defaultChecked
                        defaultValue={1}
                      />
                      <label className="actij">Active</label>
                    </div> */}

                    <div className="formgrop imagecover">
                      {/* <label for="uploadThumb">Thumbnail</label> */}
                      <div className="pdfupone uploadstyle">
                        <FileUpload
                          fileType={`.png, .jpeg, .jpg, .JPG, .PNG, .JPEG`}
                          onUpload={(img) => {
                            setNewThumb(img);
                          }}
                        />
                        <div style={{ paddingLeft: "10px" }}>
                          <p>
                            Upload<span>| Thumbnail Image</span>
                          </p>
                          <p>png,jpeg,jpg</p>
                        </div>
                      </div>

                      <div className="formgrop">
                        {newthumb !== null && (
                          <Box className="w-full m-2 ml-3 mt-3">
                            <img
                              alt="img"
                              src={`${
                                apiBaseUrl + "/public"
                              }/${reactAppImageUri}/${newthumb}`}
                              width={`150px`}
                              height={`150px`}
                            />
                          </Box>
                        )}
                      </div>

                      {/* <label for="uploadThumb">Image To Edit</label> */}
                      <div className="pdfupone uploadstyle">
                        <FileUpload
                          fileType={`.png, .jpeg, .jpg, .JPG, .PNG, .JPEG`}
                          onUpload={(img) => {
                            setThumb(img);
                          }}
                        />

                        <div style={{ paddingLeft: "10px" }}>
                          <p>
                            Upload<span>| Image File</span>
                          </p>
                          <p>png,jpeg,jpg</p>
                        </div>
                      </div>

                      <div className="formgrop">
                        {thumb !== null && (
                          <Box className="w-full m-2 ml-3 mt-3">
                            <img
                              alt="img"
                              src={`${
                                apiBaseUrl + "/public"
                              }/${reactAppImageUri}/${thumb}`}
                              width={`150px`}
                              height={`150px`}
                            />
                          </Box>
                        )}
                      </div>
                    </div>
                    {/* <div className="formgrop">
                                        {edited_thumb !== null && (<label for="uploadThumb">Edited Image</label>)}
                                        {edited_thumb !== null && (
                                            <Box className="w-full mt-3  m-2 ml-3"><img
                                                src={`${apiBaseUrl+'/public'}/${reactAppImageUri}/${edited_thumb}`}
                                                width={`150px`}
                                                height={`150px`}
                                            /></Box>
                                        )}
                                    </div> */}

                    <div
                      className="formgrop pdfsavebtn"
                      style={{ paddingTop: 10, paddingBottom: 10 }}
                    >
                      <Button
                        variant="contained"
                        className="addbutne logoutno imgedit"
                        style={{ width: 'max-content' }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          console.log("sangharsh");
                          handleSubmit(onSubmit, onError)();
                        }}
                        fullWidth
                        disabled={userData.loading}
                      >
                        {userData.loading === true ? 
                        <><div className="dot-pulse-text">Processing</div> <div className="dot-pulse-container"><div className="dot-pulse"></div></div></> : "Next"}
                      </Button>{" "}
                    </div>
                    {/* {id !== undefined && (
                                        <Button
                                            color="success"
                                            variant="contained"
                                            component={Link}
                                            to={`/Edit-Image/${id}/manage`}
                                        >
                                            Edit Image
                                        </Button>
                                    )} */}
                  </form>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </>
  );
};

export default ImageForm;
