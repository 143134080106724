import React from "react";

import QuizesVideo from "../../components/QuizesVideo/QuizesVideo";

const AssignmentsQuizes = () => {
  
  return (
    <>

    <QuizesVideo></QuizesVideo>
    </>
  );
};

export default AssignmentsQuizes;
