import { updatePdfApi, uploadSvgApi } from "../API/index";
import axios from "axios";
// import { useNavigate } from "react-router-dom";
import { apiBaseUrl } from './../utils/constants';
let febObj = {}
let pageInfo = {}
let editedFilePath = "public/docFiles/splitFiles/"
let editType = "split + edit"
setTimeout(() => {
  let { pdfjsLib, $, fabric, jspdf } = window;
  // -------------------------------- SCRIPT 1 -----------------------------
  // Arrow Functionality
  const baseURL = apiBaseUrl + '/';
  // -------------------------------- SCRIPT 2 -----------------------------
  let activeObjectData = [];
  // Extended fabric line class
  fabric.LineArrow = fabric.util.createClass(fabric.Line, {
    type: "lineArrow",

    initialize: function (element, options) {
      options || (options = {});
      this.callSuper("initialize", element, options);
    },

    toObject: function () {
      return fabric.util.object.extend(this.callSuper("toObject"));
    },

    _render: function (ctx) {
      this.callSuper("_render", ctx);

      // do not render if width/height are zeros or object is not visible
      if (this.width === 0 || this.height === 0 || !this.visible) return;

      ctx.save();

      var xDiff = this.x2 - this.x1;
      var yDiff = this.y2 - this.y1;
      var angle = Math.atan2(yDiff, xDiff);
      ctx.translate((this.x2 - this.x1) / 2, (this.y2 - this.y1) / 2);
      ctx.rotate(angle);
      ctx.beginPath();
      //move 10px in front of line to start the arrow so it does not have the square line end showing in front (0,0)
      ctx.moveTo(10, 0);
      ctx.lineTo(-20, 15);
      ctx.lineTo(-20, -15);
      ctx.closePath();
      ctx.fillStyle = this.stroke;
      ctx.fill();
      ctx.restore();
    },
  });

  fabric.LineArrow.fromObject = function (object, callback) {
    callback &&
      callback(
        new fabric.LineArrow(
          [object.x1, object.y1, object.x2, object.y2],
          object
        )
      );
  };

  fabric.LineArrow.async = true;

  // var Arrow = (function () {
  //   function Arrow(canvas, color, callback) {
  //     this.canvas = canvas;
  //     this.className = "Arrow";
  //     this.isDrawing = false;
  //     this.color = color;
  //     this.callback = callback;
  //     this.bindEvents();
  //   }

  //   Arrow.prototype.bindEvents = function () {
  //     var inst = this;
  //     inst.canvas.on("mouse:down", function (o) {
  //       inst.onMouseDown(o);
  //     });
  //     inst.canvas.on("mouse:move", function (o) {
  //       inst.onMouseMove(o);
  //     });
  //     inst.canvas.on("mouse:up", function (o) {
  //       inst.onMouseUp(o);
  //     });
  //     inst.canvas.on("object:moving", function (o) {
  //       inst.disable();
  //     });
  //   };

  //   Arrow.prototype.unBindEventes = function () {
  //     var inst = this;
  //     inst.canvas.off("mouse:down");
  //     inst.canvas.off("mouse:up");
  //     inst.canvas.off("mouse:move");
  //     inst.canvas.off("object:moving");
  //   };

  //   Arrow.prototype.onMouseUp = function (o) {
  //     var inst = this;
  //     inst.disable();
  //     inst.unBindEventes();
  //     if (inst.callback) inst.callback();
  //   };

  //   Arrow.prototype.onMouseMove = function (o) {
  //     var inst = this;
  //     if (!inst.isEnable()) {
  //       return;
  //     }

  //     var pointer = inst.canvas.getPointer(o.e);
  //     var activeObj = inst.canvas.getActiveObject();
  //     activeObj.set({
  //       x2: pointer.x,
  //       y2: pointer.y,
  //     });
  //     activeObj.setCoords();
  //     inst.canvas.renderAll();
  //   };

  //   Arrow.prototype.onMouseDown = function (o) {
  //     var inst = this;
  //     inst.enable();
  //     var pointer = inst.canvas.getPointer(o.e);

  //     var points = [pointer.x, pointer.y, pointer.x, pointer.y];
  //     var line = new fabric.LineArrow(points, {
  //       strokeWidth: 5,
  //       fill: inst.color ? inst.color : "red",
  //       stroke: inst.color ? inst.color : "red",
  //       originX: "left",
  //       originY: "top",
  //       hasBorders: false,
  //       hasControls: true,
  //       selectable: true,
  //     });

  //     inst.canvas.add(line).setActiveObject(line);
  //   };

  //   Arrow.prototype.isEnable = function () {
  //     return this.isDrawing;
  //   };

  //   Arrow.prototype.enable = function () {
  //     this.isDrawing = true;
  //   };

  //   Arrow.prototype.disable = function () {
  //     this.isDrawing = false;
  //   };

  //   return Arrow;
  // })();

  // =========================================================================
  var PDFAnnotate = function (container_id, url, options = {}) {
    this.number_of_pages = 0;
    this.pages_rendered = 0;
    this.active_tool = 1; // 1 - Free hand, 2 - Text,
    this.fabricObjects = [];
    this.fabricObjectsData = [];
    this.color = "#212121";
    this.borderColor = "#000000";
    this.borderSize = 1;
    this.font_size = 16;
    this.active_canvas = 0;
    this.container_id = container_id;
    this.url = url;
    this.pageImageCompression = options.pageImageCompression
      ? options.pageImageCompression.toUpperCase()
      : "NONE";
    var inst = this;

    var loadingTask = pdfjsLib.getDocument(this.url);
    loadingTask.promise.then(
      function (pdf) {
        var scale = options.scale ? options.scale : 1.3;
        inst.number_of_pages = pdf.numPages;
        document.getElementById(inst.container_id).innerHTML = '';
        for (var i = 1; i <= pdf.numPages; i++) {
          pdf.getPage(i).then(function (page) {
            console.log("page : ", page)
            var viewport = page.getViewport({ scale: scale });
            var canvas = document.createElement("canvas");
            document.getElementById(inst.container_id).appendChild(canvas);
            canvas.className = "pdf-canvas";
            canvas.id = "pdf-canvas-page"
            /*   canvas.height = 1120;
              canvas.width = 790; */
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            var context = canvas.getContext("2d");

            var renderContext = {
              canvasContext: context,
              viewport: viewport,
            };
            var renderTask = page.render(renderContext);
            renderTask.promise.then(function () {
              $(".pdf-canvas").each(function (index, el) {
                $(el).attr("id", "page-" + (index + 1) + "-canvas");
              });
              inst.pages_rendered++;
              if (inst.pages_rendered == inst.number_of_pages)
                inst.initFabric();
            });
          });
        }
      },
      function (reason) {
        console.error(reason);
      }
    );

    this.initFabric = function () {
      var inst = this;
      let canvases = $("#" + inst.container_id + " canvas");
      canvases.each(function (index, el) {
        var background = el.toDataURL("image/png");
        var fabricObj = new fabric.Canvas(el.id, {
          freeDrawingBrush: {
            width: 1,
            color: inst.color,
          },
        });
        inst.fabricObjects.push(fabricObj);
        const currentPageId = window.pdfInfo.splitFileId
        if (currentPageId && febObj[currentPageId] && febObj[currentPageId].length > 0) {
          for (let ob of febObj[currentPageId]) {
            inst.fabricObjects[0].add(ob);
          }
        }
        if (typeof options.onPageUpdated == "function") {
          fabricObj.on("object:added", function () {
            var oldValue = Object.assign({}, inst.fabricObjectsData[index]);
            inst.fabricObjectsData[index] = fabricObj.toJSON();
            options.onPageUpdated(
              index + 1,
              oldValue,
              inst.fabricObjectsData[index]
            );
          });
        }
        fabricObj.setBackgroundImage(
          background,
          fabricObj.renderAll.bind(fabricObj)
        );
        $(fabricObj.upperCanvasEl).click(function (event) {
          inst.active_canvas = index;
          inst.fabricClickHandler(event, fabricObj);
        });
        fabricObj.on("after:render", function () {
          inst.fabricObjectsData[index] = fabricObj.toJSON();
          fabricObj.off("after:render");
        });

        if (
          index === canvases.length - 1 &&
          typeof options.ready === "function"
        ) {
          options.ready();
        }
      });
    };

    this.fabricClickHandler = function (event, fabricObj) {
      var inst = this;
      if (inst.active_tool == 2) {
        var text = new fabric.IText("Sample text", {
          left:
            event.clientX -
            fabricObj.upperCanvasEl.getBoundingClientRect().left,
          top:
            event.clientY - fabricObj.upperCanvasEl.getBoundingClientRect().top,
          fill: inst.color,
          fontSize: inst.font_size,
          selectable: true,
        });
        fabricObj.add(text);
        inst.active_tool = 0;
      }
    };
  };
  // =============================================================================================
  PDFAnnotate.prototype.enableRectangle = function () {
    var inst = this;
    var fabricObj = inst.fabricObjects[inst.active_canvas];
    inst.active_tool = 4;
    if (inst.fabricObjects.length > 0) {
      $.each(inst.fabricObjects, function (index, fabricObj) {
        fabricObj.isDrawingMode = false;
      });
    }

    var rect = new fabric.Rect({
      width: 100,
      height: 100,
      fill: inst.color,
      stroke: inst.borderColor,
      strokeSize: inst.borderSize,
    });
    fabricObj.add(rect);
  };
  // =============================================================================================
  PDFAnnotate.prototype.setBorderColor = function (color) {
    var inst = this;
    inst.borderColor = color;
  };
  // =============================================================================================
  PDFAnnotate.prototype.enableAddArrow = function () {
    var inst = this;
    inst.active_tool = 3;
    var fabricObj = inst.fabricObjects[inst.active_canvas];

    var id = Date.now();
    // inst.canvas.isDrawingMode = false;
    var triangle = new fabric.Triangle({
        width: 9,
        height: 15,
        fill: inst.color,
        left: 150,
        top: 137,
        angle: 90
    });

    var line = new fabric.Rect({
        left: 100,
        top: 140,
        width: 40,
        height: 3,
        fill: inst.color,
        originX: 'left',
        originY: 'top',
        centeredRotation: true
    });

    var arrow = new fabric.Group([line, triangle], {
        id: id,
        left: 50,
        top: 50,
        angle: 0
    });

    fabricObj.add(arrow);

    // if (inst.fabricObjects.length > 0) {
    //   $.each(inst.fabricObjects, function (index, fabricObj) {
    //     var activeObj = inst.fabricObjects;

    //     fabricObj.isDrawingMode = false;
    //     new Arrow(fabricObj, inst.color, function () {
    //       inst.active_tool = 0;
    //     });
    //   });
    // }
  };
  // =============================================================================================
  PDFAnnotate.prototype.enableSelector = function () {
    var inst = this;
    inst.active_tool = 0;
    if (inst.fabricObjects.length > 0) {
      $.each(inst.fabricObjects, function (index, fabricObj) {
        fabricObj.isDrawingMode = false;
      });
    }
  };
  // =============================================================================================
  PDFAnnotate.prototype.enablePencil = function () {
    var inst = this;

    inst.active_tool = 1;
    if (inst.fabricObjects.length > 0) {
      $.each(inst.fabricObjects, function (index, fabricObj) {
        fabricObj.isDrawingMode = true;
      });
    }
  };
  // =============================================================================================
  PDFAnnotate.prototype.enableAddText = function () {
    var inst = this;
    inst.active_tool = 2;
    if (inst.fabricObjects.length > 0) {
      $.each(inst.fabricObjects, function (index, fabricObj) {
        fabricObj.isDrawingMode = false;
      });
    }
  };
  // =============================================================================================
  PDFAnnotate.prototype.enableAddTextHighlight = function () {
    var inst = this;
    inst.active_tool = 3;
    if (inst.fabricObjects.length > 0) {
      $.each(inst.fabricObjects, function (index, fabricObj) {
        fabricObj.isDrawingMode = false;
      });
    }
  };
  // =============================================================================================
  PDFAnnotate.prototype.addImageToCanvas = function () {
    var inst = this;  
    var fabricObj = inst.fabricObjects[inst.active_canvas];

    if (fabricObj) {
      var inputElement = document.createElement("input");
      inputElement.type = "file";
      inputElement.accept = ".jpg,.jpeg,.png,.PNG,.JPG,.JPEG";
      inputElement.onchange = function () {
        var reader = new FileReader();
        reader.addEventListener(
          "load",
          function () {
            console.log('eeeeeeeeeeeeee')
            inputElement.remove();
            var image = new Image();
            image.onload = function () {
              fabricObj.add(new fabric.Image(image));
            };
            image.src = this.result;
          },
          false
        );
        reader.readAsDataURL(inputElement.files[0]);
      };
      document.getElementsByTagName("body")[0].appendChild(inputElement);
      inputElement.click();
    }
  };

  PDFAnnotate.prototype.undoAction = function () {
    var inst = this;
    var fabricObj = inst.fabricObjects[inst.active_canvas];
    if (inst.fabricObjects.length > 0) {
      var activeObject = inst.fabricObjects[inst.fabricObjects.length - 1]._objects[inst.fabricObjects[inst.fabricObjects.length - 1]._objects.length - 1];
      activeObjectData.push(activeObject);
      inst.fabricObjects[inst.fabricObjects.length - 1].remove(activeObject);
    }
  };

  PDFAnnotate.prototype.redoAction = function () {
    var inst = this;
    if (activeObjectData.length > 0) {
      inst.fabricObjects[inst.fabricObjects.length - 1].add(activeObjectData[activeObjectData.length - 1]);
      activeObjectData.pop();
    }
  };

  // =============================================================================================
  PDFAnnotate.prototype.deleteSelectedObject = function () {
    var inst = this;
    var activeObject = inst.fabricObjects[inst.active_canvas].getActiveObject();
    if (activeObject) {
      inst.fabricObjects[inst.active_canvas].remove(activeObject);
    }
  };
  // =============================================================================================

  const svgGenerate = (fabricObj) => {
    let newObj = [];
    fabricObj.forEach(function (singleObj, _index) {
      let pngFile = singleObj.toDataURL({ format: "png" });
      newObj.push({
        "originX": singleObj.originX, "originY": singleObj.originY,
        "width": singleObj.width,
        "height": singleObj.height,
        "left": singleObj.left,
        "top": singleObj.top,
        // "svgUrl": res.data.data,
        "stroke": singleObj.stroke,
        "angle": singleObj.angle,
        "aCoords": singleObj.aCoords,
        "strokeWidth": singleObj.strokeWidth,
        "pngFile": pngFile
      });
    });
    return newObj;
  };
  PDFAnnotate.prototype.tempSavePDF = function () {
    console.log('0000000000000000000000000000000000000000')
    if (this.fabricObjects[0]) {
      const currentPageId = window.pdfInfo.splitFileId
      febObj[currentPageId] = []
      pageInfo[currentPageId] = {}
      for (let obj of this.fabricObjects[0]._objects) {
        febObj[currentPageId].push(obj);
        pageInfo[currentPageId] = {
          editFilename: window.pdfInfo.editFilename,
          splitFileId: window.pdfInfo.splitFileId,
          editedFilePath: editedFilePath,
        }
      }
    }
  }
  const savePdfApiCall = (fabricObject, fileName, fileInfo) => {
    return new Promise(async (resolve, reject) => {
      // var inst = this;
      // var doc = new jspdf.jsPDF();
      if (typeof fileName === "undefined") {
        fileName = `${new Date().getTime()}.pdf`;
      }
      let data = svgGenerate(fabricObject)
      let newObj = data;
      try {
        let pdfData = {
          userId: window.pdfInfo.userId,
        //  file: fabricObject.toDataURL(),
          editType: editType,
          editFilename: fileInfo.editFilename,
          editFormate: window.pdfInfo.editFormate,
          groupId: window.pdfInfo.groupId,
          splitFileId: fileInfo.splitFileId,
          editedFilePath: fileInfo.editedFilePath,
          width: $('#page-1-canvas').width(),
          height: $('#page-1-canvas').height(),
          fabricObj: newObj,
        };

        if(pdfData.fabricObj && pdfData.fabricObj.length > 0) {
          const res = await updatePdfApi(pdfData);
          resolve(res)
        } else {
          resolve(true)
        }
      } catch (error) {
        reject(error)
        console.log(error);
      }


    })
  }
  PDFAnnotate.prototype.savePdf = function (fileName) {
    return new Promise((resolve, reject) => {
      let promiseInfo = []
      if (febObj && Object.keys(febObj).length > 0) {
        for (let key in febObj) {
          promiseInfo.push(savePdfApiCall(febObj[key], undefined, pageInfo[key]))
        }
      }
      const cId = Number(window.pdfInfo.splitFileId);
      if (!febObj[cId]) {
        promiseInfo.push(savePdfApiCall(this.fabricObjects[0]._objects, undefined, {
          editFilename: window.pdfInfo.editFilename,
          splitFileId: window.pdfInfo.splitFileId,
          editedFilePath: editedFilePath,
        }))
      }

      Promise.all(promiseInfo).then((values) => {
        resolve(true)
      });
    })
  };
  // =============================================================================================
  PDFAnnotate.prototype.setBrushSize = function (size) {
    var inst = this;

    $.each(inst.fabricObjects, function (index, fabricObj) {
      // fabricObj.isDrawingMode = false;
      fabricObj.freeDrawingBrush.width = parseInt(size) ;
    });

    // setTimeout(() => {
    //   $.each(inst.fabricObjects, function (index, fabricObj) {
    //   //  fabricObj.isDrawingMode = true;
    //     // fabricObj.freeDrawingBrush.width = size;
    //   });
    // }, 500)
    // 334
  };
  // =============================================================================================
  PDFAnnotate.prototype.setColor = function (color) {
    var inst = this;
    inst.color = color;
    $.each(inst.fabricObjects, function (index, fabricObj) {
      fabricObj.freeDrawingBrush.color = color;
    });
  };
  // =============================================================================================
  PDFAnnotate.prototype.setFontSize = function (size) {
    this.font_size = size;
  };
  // =============================================================================================
  PDFAnnotate.prototype.clearActivePage = function () {
    var inst = this;
    var fabricObj = inst.fabricObjects[inst.active_canvas];
    var bg = fabricObj.backgroundImage;
    // if (confirm('Are you sure?')) {
    {
      fabricObj.clear();
      fabricObj.setBackgroundImage(bg, fabricObj.renderAll.bind(fabricObj));
    }
  };
  // =============================================================================================
  PDFAnnotate.prototype.serializePdf = function () {
    var inst = this;
    return JSON.stringify(inst.fabricObjects, null, 4);
  };

  // -------------------------------- SCRIPT 3 -----------------------------
  let currentFile = ''
  function setPdfToCanvas(isreset) {
    if(isreset) {
      febObj = {}
      pageInfo = {} 
      currentFile = ''
    }
    if (currentFile === window.pdfInfo.editingFile) {
      return false
    }
    
    currentFile = window.pdfInfo.editingFile
    window.pdf = new PDFAnnotate(
      "pdf-container",
      baseURL + window.pdfInfo.editingFile,
      {
        // pdf path here sample.pdf
        onPageUpdated(page, oldData, newData) {
          // console.log(page, oldData, newData);
        },
        ready() {
          console.log("Plugin initialized successfully");
        },
        scale: 1,
        pageImageCompression: "MEDIUM", // FAST, MEDIUM, SLOW(Helps to control the new PDF file size)
      }
    );
  }

  window.setPdfToCanvas = setPdfToCanvas;

}, 200);
