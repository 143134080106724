import axios from "axios";
import { apiBaseUrl } from "../utils/constants";

export const postApiWithoutToken = (path, data) => {
  return axios.post(apiBaseUrl + path, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const putApiWithoutToken = (path, data) => {
  return axios.put(apiBaseUrl + path, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getApiWithoutToken = (path) => {
  return axios.get(apiBaseUrl + path, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const postApi = (path, data) => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("token");

    axios
      .post(apiBaseUrl + path, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        resolve(res);
        console.log(res);
      });
  });
};
export const postApiWithFile = (path, data) => {
  return new Promise((resolve, reject) => {
  const token = localStorage.getItem("token");

  axios.post(apiBaseUrl + path, data, {
    headers: {
      "Content-type": "multipart/form-data",
      Authorization: `Bearer ${token}`
    } 
  }).then((res)=> {
    resolve(res)
    console.log(res)
  })
})
};
export const getApi = (path, data) => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("token");

    return fetch(apiBaseUrl + path, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then(function (resJson) {
        console.log(resJson);
        resolve({
          status: 200,
          data: resJson,
        });
      });
  });
};

export const getBufferApi = (path, data) => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("token");

    return fetch(apiBaseUrl + path, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        return res.blob();
      })
      .then(function (resJson) {
        console.log(resJson);
        resolve({
          status: 200,
          data: resJson,
        });
      });
  });
};

export const putApi = (path, data) => {
  const token = localStorage.getItem("token");

  return axios.put(apiBaseUrl + path, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  
};
export const patchApi = (path, data) => {
  const token = localStorage.getItem("token");

  return axios.patch(apiBaseUrl + path, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  })
};

export const patchApiWithFile = (path, data) => {
  const token = localStorage.getItem("token");

  return axios.patch(apiBaseUrl + path, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`
    }
  })
};
export const fileUploadOLD = (path, data) => {
  const token = localStorage.getItem("token");

  return axios.post(apiBaseUrl + path, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const fileUpload = (path, data) => {
  const token = localStorage.getItem("token");
  const formData = new FormData();
  formData.append("userId", localStorage.getItem("userId"));
  formData.append("file", data[0]);
  let headers = {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  };
  console.log(formData);
  return axios({
    method: "POST",
    url: `${apiBaseUrl}${path}`,
    headers,
    data: formData,
  });
};

export const deleteApi = (path, data) => {
  const token = localStorage.getItem("token");

  return axios.delete(apiBaseUrl + path, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};
